// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormRelatComponent, CMInputConfig,
         CMFormGlobaisComponent
       } from './../../../@cmcore/component';

@Component({
    selector: 'dados-assinaturas',
    templateUrl: 'dados-assinaturas.component.html'
})
export class RelatDadosAssinaturasComponent extends CMFormRelatComponent {

  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = 'ImpressaoDadosAssinatura';
    this.filterConfig = {
      dataini: new CMInputConfig({form: this,
                                  caption: 'Data Inicial',
                                  mask: '99/99/9999'
                                 }),
      datafim: new CMInputConfig({form: this,
                                  caption: 'Data Final',
                                  mask: '99/99/9999'
                                 }),
    }
    this.filter.dataini = this.firstDate();
    this.filter.datafim = this.lastDate();
  }

  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.dataini);
      if (!r)
        CMFormGlobaisComponent.toastErro('Data inicial não informada');
    }
    if (r) {
      r = !this.isEmpty(this.filter.datafim);
      if (!r)
        CMFormGlobaisComponent.toastErro('Data final não informada');
    }
    return r;
  }

}