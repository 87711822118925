// tslint:disable
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent,
         CMInputConfig } from './../../@cmcore/component';
import { CFOPEditComponent } from './cfop-edit.component';

@Component({
  selector: 'cfop-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class CFOPBrowseComponent extends CMFormBrowse2Component {

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'cfop';
    this.fixedFooterFormEdit = false;
    this.formEditComponent = CFOPEditComponent;
    this.columns = [{field: 'codigo', caption: '#',
                     filter: new CMInputConfig({
                       mask: '9.999'
                     }),
                     width: '100',
                    },
                    {field: 'descricao', caption: 'Descrição', filter: true},
                    {field: 'ativo', caption: 'Ativo',
                     visible: false,
                     filter: new CMInputConfig({
                       values: [{value: 'Todos'}, {value: 'Sim'}, {value: 'Não'}]
                     }),
                     value: 'Todos',
                    },
                    ];
  }

  getTitle() {
    return 'CFOP';
  }

  static pesquisa(sender: any, target: any, targetProp: any,
                  tpOperacao?: string, finalidade?: string , onAfterSelect?: any) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'cfop',
        'Pesquisa de CFOP',
        [{field: 'codigo', caption: '#',  width: '100'},
         {field: 'descricao', caption: 'Descrição', filter: true},
         {field: 'ativo', caption: 'Ativo', filter: true, value: 'Sim', visible: false, filterVisible: false},
         {field: 'tpoperacao', caption: 'tpOperacao', filter: true, value: tpOperacao, visible: false, filterVisible: false},
         {field: 'finalidade', caption: 'finalidade', filter: true, value: finalidade, visible: false, filterVisible: false},
        ],
        target,
        targetProp,
        'codigo',
        onAfterSelect,
        false);
    }
  }

}