// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMFormModalComponent,
} from "./../../@cmcore/component";
import { OrcamentoEditComponent } from "./orcamento-edit.component";
import { ClienteBrowseComponent } from "./../cliente/cliente-browse.component";
import { VendedorBrowseComponent } from "./../vendedor/vendedor-browse.component";
import { OrcamentoRelatoriosComponent } from "../orcamento-relatorios/orcamento-relatorios.component";
import { FormaPagamentoBrowseComponent } from "./../formapagamento/formapagamento-browse.component";
import { FuncsGlobais } from "../funcs.globais";

@Component({
  selector: "orcamento-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class OrcamentoBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "orcamento";
    this.formEditComponent = OrcamentoEditComponent;
    this.fixedFooterFormEdit = false;
    this.buttonsExtra = 2;
    this.showPrintButton = true;
    this.showEmailButton = true;
    this.columns = [
      {
        field: "numero",
        caption: "#",
        width: 100,
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "data",
        caption: "Data",
        width: 130,
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {
        field: "cliente_nome",
        caption: "Cliente",
        filterField: "cliente.id",
        filterValue: "cliente.codigo",
        fieldBottom: "cliente.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            ClienteBrowseComponent.pesquisa(sender, this.valuesFilters);
          },
        }),
      },
      {
        field: "vendedor_nome",
        caption: "Vendedor",
        width: 250,
        filterField: "vendedor.id",
        filterValue: "vendedor.codigo",
        fieldBottom: "vendedor.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            VendedorBrowseComponent.pesquisa(sender, this.valuesFilters);
          },
        }),
      },
      {
        field: "formapag_descricao",
        caption: "Forma de Pagamento",
        width: 250,
        filterField: "formapagamento.id",
        fieldBottom: "formapagamento.descricao",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            FormaPagamentoBrowseComponent.pesquisa("todos", sender, this.valuesFilters);
          },
        }),
      },
    ];
    if (OrcamentoRelatoriosComponent.getRelatorioCustomizados().length === 0){
      this.getRelatoriosCustomizados();
    }
    console.log(OrcamentoRelatoriosComponent.getRelatorioCustomizados());
  }

  
  _print() {
    console.log('method override')
    if (this.diretoImprimir) this.print();
  }
  
  print() {
    console.log('method overriden');
    if (OrcamentoRelatoriosComponent.getRelatorioCustomizados().length > 0){
      this.openFormRelatoriosOrcamento();
    }
    else{
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat("Print", data, this.controllerName);
    }
  }

  
  getRelatoriosCustomizados(){
    this.postSubscribeT<Array<Object>>(
      "/api/orcamento/GetRelatoriosCustomizados",
      JSON.stringify({}),
      (result) => {
        result.forEach(element => {
          if (element['tipo'] === 'Orcamento'){
            OrcamentoRelatoriosComponent.addRelatorioCustomizado({value: element['id'], text: element['descricao']});
          }
        });
      },
    );
  }
  
  openFormRelatoriosOrcamento() {
    this.beforeComponent = OrcamentoRelatoriosComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formRelatoriosOrcamento");
    }, 100);
  }
  getTitle() {
    return "Orçamento";
  }

  getDataPrint(data) {
    data.numero = this.grid.fieldByName("numero");
    return data;
  }
  

  loadDireitoButtonExtra(index: number) {
    if (index === 0) {
      this.getDireitosMenuBrowseButtonsExtra(index, "GerarPreVenda");
    } else if (index === 1) {
      this.getDireitosMenuBrowseButtonsExtra(
        index,
        "ImpressaoOrcamentoReduzida"
      );
    }
  }

  buttonsExtraVisible(index: number) {
    let r: boolean = super.buttonsExtraVisible(index);
    if (r) {
      let isCurrent: boolean = false;
      if (this.grid) {
        isCurrent = this.grid.isCurrent;
      }
      if (index === 0 || index === 1) r = isCurrent;
    }
    return r;
  }

  buttonsExtraIcon(index: number) {
    let r: string = "";
    if (index === 0) r = "shopping_cart";
    else if (index === 1) r = "receipt";
    return r;
  }

  buttonsExtraHint(index: number) {
    let r: string = "";
    if (index === 0) r = "Gerar Pré-Venda";
    else if (index === 1) r = "Imprimir Reduzido";
    
    return r;
  }

  buttonsExtraClick(index: number) {
    if (index === 0) {
      let msg: string = "<p>Confirma geração de Pré-Venda?</p>";
      CMFormGlobaisComponent.showConfirmation(
        "Pré-Venda",
        msg,
        () => {
          this.gerarPreVenda();
        }
      );
    } else if (index === 1) {
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat(
        "/ImpressaoOrcamentoReduzida/FuncaoExtra",
        data,
        this.controllerName
      );
    }
  }

  gerarPreVenda() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/orcamento/GerarPreVenda/FuncaoExtra",
      JSON.stringify({
        id: this.grid.fieldByName("id"),
      }),
      (result) => {
        if (result["result"] === "ok") {
          let prevenda = result["prevenda"];
          CMFormGlobaisComponent.toastSucesso(
            "Gerado Pré-Venda " + prevenda
          );
          this.refresh();
        } else CMFormGlobaisComponent.showMessageAviso(result["msg"]);
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

}
