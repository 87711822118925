// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormEditComponent, CMInputConfig
       } from './../../@cmcore/component';

@Component({
    selector: 'nfeinutilizacao-edit',
    templateUrl: 'nfeinutilizacao-edit.component.html',
})
export class NFeInutilizacaoEditComponent extends CMFormEditComponent {

  getFirstComponentName() {
    return 'model_ano';
  }

  getTitle() {
    return 'NF-e Inutilização';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'NFeInutilizacao';
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      ano: new CMInputConfig({form: this,
                              caption: 'Ano',
                              dataType: 'integer',
                              maxlength: 4,
                              required: true}),
      serie: new CMInputConfig({form: this,
                                caption: 'Série',
                                dataType: 'integer',
                                required: true}),
      nnfini: new CMInputConfig({form: this,
                                 caption: 'Nota Inicial',
                                 dataType: 'integer',
                                 maxlength: 4,
                                 required: true}),
      nnffin: new CMInputConfig({form: this,
                                 caption: 'Nota Final',
                                 dataType: 'integer',
                                 maxlength: 4,
                                 required: true}),
      xjust: new CMInputConfig({form: this,
                                caption: 'Justificativa',
                                required: true}),
    };
	}

  afterGetModelData() {
    super.afterGetModelData();
    if (!this.disabledAll)
      this.disabledAll = (this.model.podeeditar !== 'Sim');
  }

  isHomologacao() {
    let r: boolean = true;
    if (this.model)
      r = (this.model.tpamb === 'Homologacao');
    return r;
  }

}