// tslint:disable

import { HttpClient } from "@angular/common/http";

import { CMFormGlobaisComponent, CMInputConfig } from "./../@cmcore/component";
import { CMFormComponent } from "./../@cmcore/component/cmform/cmform.component";
import { MenuLateralComponent } from "./menu-lateral";

export class FuncsGlobais {
  private static _paramEmpresa = {
    idEmpresa: "",
    nomeGrade1: "",
    nomeGrade2: "",
    usaGrade: false,
    usaGrade1: false,
    usaGrade2: false,
    usaLote: false,
    qtdeMaximaParcelas: 0,
    solicitaPermissaoVendaContaVencida: false,
    usaCopiaProduto: false,
    copiaProdutoPreco: false,
    copiaProdutoFiscal: false,
    copiaProdutoGrade: false,
    copiaProdutoFornecedor: false,
    codigoProdutoSequencial: false,
    modoOperacao: "",
    estado: "",
    nomePreco1: "",
    Convenio: "",
  };
  public static get paramEmpresa() {
    if (FuncsGlobais._paramEmpresa["loaded"]) {
      const dados: any = CMFormComponent.currentUserStorage();
      if (FuncsGlobais._paramEmpresa.idEmpresa !== dados["Empresa.Id"])
        FuncsGlobais._paramEmpresa["loaded"] = undefined;
    }
    if (FuncsGlobais._paramEmpresa["loaded"] == undefined) {
      const dados: any = CMFormComponent.currentUserStorage();
      FuncsGlobais._paramEmpresa.idEmpresa = dados["Empresa.Id"];
      FuncsGlobais._paramEmpresa.nomeGrade1 = dados["Empresa.NomeGrade1"];
      FuncsGlobais._paramEmpresa.nomeGrade2 = dados["Empresa.NomeGrade2"];
      FuncsGlobais._paramEmpresa.Convenio = dados["Empresa.Convenio"];
      FuncsGlobais._paramEmpresa.usaGrade1 = dados["Empresa.UsaGrade1"] != "0";
      FuncsGlobais._paramEmpresa.usaGrade2 = dados["Empresa.UsaGrade2"] != "0";
      FuncsGlobais._paramEmpresa.usaGrade =
        FuncsGlobais._paramEmpresa.usaGrade1 ||
        FuncsGlobais._paramEmpresa.usaGrade2;
      FuncsGlobais._paramEmpresa.usaLote = dados["Empresa.UsaLote"] != "0";
      FuncsGlobais._paramEmpresa.usaCopiaProduto =
        dados["Empresa.UsaCopiaProduto"] != "0";
      FuncsGlobais._paramEmpresa.copiaProdutoPreco =
        dados["Empresa.CopiaProdutoPreco"] != "0";
      FuncsGlobais._paramEmpresa.copiaProdutoFiscal =
        dados["Empresa.CopiaProdutoFiscal"] != "0";
      FuncsGlobais._paramEmpresa.copiaProdutoGrade =
        dados["Empresa.CopiaProdutoGrade"] != "0";
      FuncsGlobais._paramEmpresa.copiaProdutoFornecedor =
        dados["Empresa.CopiaProdutoFornecedor"] != "0";
      FuncsGlobais._paramEmpresa.codigoProdutoSequencial =
        dados["Empresa.CodigoProdutoSequencial"] != "0";
      FuncsGlobais._paramEmpresa.qtdeMaximaParcelas = CMInputConfig.valueToInteger(
        dados["Empresa.QtdeMaximaParcelas"]
      );
      FuncsGlobais._paramEmpresa.solicitaPermissaoVendaContaVencida =
        dados["Empresa.SolicitaPermissaoVendaContaVencida"] != "0";
      FuncsGlobais._paramEmpresa.estado = dados["Empresa.Estado"];
      FuncsGlobais._paramEmpresa.modoOperacao =
        dados["ConfigGeral.ModoOperacao"];
        FuncsGlobais._paramEmpresa.nomePreco1 = dados["Empresa.NomePreco1"];
      FuncsGlobais._paramEmpresa["loaded"] = true;
    }
    return FuncsGlobais._paramEmpresa;
  }

  private static _empresas = [];
  public static get empresas() {
    if (
      (this._empresas && this._empresas.length == 0) ||
      localStorage.getItem("empresas_cache") !== "1"
    ) {
      localStorage.setItem("empresas_cache", "1");
      this._selectedEmpresa = undefined;
      this._empresas = CMFormComponent.currentUserStorage()["empresas"];
      if (!(this._empresas instanceof Array)) this._empresas = [];
    }
    return this._empresas;
  }

  public static get idServerEmpresa(): string {
    return CMFormComponent.trimValue(
      CMFormComponent.currentUserStorage()["Empresa.IdServer"]
    );
  }

  public static get titleEmpresaAtual(): string {
    let r = "";
    if (FuncsGlobais.selectedEmpresa) {
      r = FuncsGlobais.selectedEmpresa.nome;
    }
    return r;
  }

  public static setIdEmpresa(http: HttpClient, id: string, onChange: Function) {
    this.changeServer(http, "Empresa", id, () => {
      this._selectedEmpresa = undefined;
      setTimeout(() => {
        MenuLateralComponent.defaultInstance.closeMenu();
        MenuLateralComponent.defaultInstance.loadMenus();
        if (
          MenuLateralComponent.defaultInstance.router.url !==
          "/empresa-associado"
        )
          MenuLateralComponent.defaultInstance.router.navigate(["/home"]);
      }, 10);
      if (onChange) onChange();
    });
  }

  private static _selectedEmpresa = undefined;
  public static get selectedEmpresa() {
    if (this._selectedEmpresa == undefined) {
      if (this.empresas) {
        for (let i = 0; i < this.empresas.length; i++) {
          const empresa = this.empresas[i];
          if (empresa.id === this.idServerEmpresa) {
            this._selectedEmpresa = empresa;
            break;
          }
        }
      }
    }
    return this._selectedEmpresa;
  }

  public static changeServer(
    http: HttpClient,
    tipo: string,
    id: string,
    onChange: Function
  ) {
    CMFormGlobaisComponent.showSpinner();

    let dados = {};
    dados["id" + tipo.toLowerCase()] = id;
    dados["user"] = CMFormComponent.currentUserStorage();
    CMFormGlobaisComponent.postSubscribe(
      http,
      "/api/funcs/Seleciona" + tipo,
      JSON.stringify(dados),
      (ret) => {
        if (ret["result"] === "ok") {
          localStorage.setItem("user", JSON.stringify(ret["user"]));
          localStorage.setItem("lastHashReload", Date.now().toString());
          FuncsGlobais._paramEmpresa["loaded"] = undefined;
          CMFormComponent.clearCacheAllDireitos();
          if (onChange) onChange();
        } else {
          CMFormGlobaisComponent.toastErro(ret["result"]);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }
}
