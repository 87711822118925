// tslint:disable
import { Component, Input } from '@angular/core';

import { CMInput } from '../../decorator';

@Component({
    selector: 'cmlayout',
    template: `
      <div [hidden]="!visible" [attr.style]="getStyle() | cmsafe:'style'">
        <ng-content></ng-content>
      </div>
    `
})
export class CMLayoutComponent {
  @Input()
  @CMInput()
  visible: boolean = true;

  @Input()
  style: string = '';

  @Input()
  width: string = '';

  @Input()
  height: string = '';

  getStyle() {
    let r: string = '';
    if (this.width !== '')
      r += 'width: '+this.width+';';
    if (this.height !== '')
      r += 'height: '+this.height+';';
    return r;
  }

}