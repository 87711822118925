// tslint:disable
import { Component, Injector } from "@angular/core";
import { CategoriaContaContabilBrowse, ContaBancariaBrowseComponent, ContaContabilBrowseComponent, FormaPagamentoBrowseComponent, PessoaBrowseComponent } from "..";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
  CMFormGlobaisComponent,
} from "./../../@cmcore/component";

import { FornecedorBrowseComponent } from "./../fornecedor/fornecedor-browse.component";

@Component({
  selector: "conta-pagar-receber-edit",
  templateUrl: "conta-pagar-receber-edit.component.html",
})
export class ContaPagarReceberEditComponent extends CMFormEditComponent {
  valuesQtdParcelas = []

  setValuesQtdParcelas() {
    this.valuesQtdParcelas = [
      { value: "Um", text: "1x" },
      { value: "Dois", text: "2x" },
      { value: "Tres", text: "3x" },
      { value: "Quatro", text: "4x" },
      { value: "Cinco", text: "5x" },
      { value: "Seis", text: "6x" },
      { value: "Sete", text: "7x" },
      { value: "Oito", text: "8x" },
      { value: "Nove", text: "9x" },
      { value: "Dez", text: "10x" },
      { value: "Onze", text: "11x" },
      { value: "Doze", text: "12x" },
    ]
  }

  getFirstComponentName() {
    return "model_fatura";
  }

  getTitle() {
    if (this.model.hasOwnProperty("contacontabil")){
      if (this.model.contacontabil.entsai === 'Pagamento')
        return "Conta a Pagar";
      else if (this.model.contacontabil.entsai === 'Recebimento')
        return "Conta a Receber";
      else
        return "Conta a Pagar e Receber";
    }
      else
        return "Conta a Pagar e Receber";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "contapagarreceber";
    this.setValuesQtdParcelas();
    this.setModelConfig();
  }

  afterGetModelData() {
    super.afterGetModelData();
    console.log('this.model: ', this.model);
    /*
    if (!this.disabledAll) {
      this.disabledAll = this.model.podeeditar !== "Sim";
    }
    */
    let dataprimeirovencimento = this.valueToDate(this.date);
    dataprimeirovencimento.setMonth(dataprimeirovencimento.getMonth() + 1);
    this.model.local = {
      dataprimeirovencimento: this.dateToValue(dataprimeirovencimento),
    };
  }

  setModelConfig() {
    this.modelConfig = {
      codigo: new CMInputConfig({ form: this, caption: "#", disabled: true }),
      nfatura: new CMInputConfig({
        form: this,
        caption: "Fatura",
        required: true,
        maxlength: 56,
      }),
      voriginal: new CMInputConfig({
        form: this,
        caption: "Valor bruto",
        dataType: "double(2)",
        onExit: () => {
          this.calcValorLiquido();
        },
      }),
      vdesconto: new CMInputConfig({
        form: this,
        caption: "Desconto financeiro",
        dataType: "double(2)",
        onExit: () => {
          this.calcValorLiquido();
        },
      }),
      vliquido: new CMInputConfig({
        form: this,
        caption: "Valor liquído",
        dataType: "double(2)",
        disabled: true,
      }),
      destinatario: new CMInputConfig({
        form: this,
        caption: "Destinatário",
        required: true,
        pesqFunc: (sender) => {
          PessoaBrowseComponent.pesquisa(
            sender,
            this.model,
            "Todos",
            "destinatario"
          );
        },
      }),
      datacadastro: new CMInputConfig({
        form: this,
        caption: "Data de cadastro",
        mask: "99/99/9999",
        required: true,
        disabled: true,
      }),
      vacrescimo: new CMInputConfig({
        form: this,
        caption: "Acréscimo financeiro",
        dataType: "double(2)",
        onExit: () => {
          this.calcValorLiquido();
        },
      }),
      contabancaria: new CMInputConfig({
        form: this,
        caption: "Conta Bancária",
        visible: false,
        pesqFunc: (sender) => {
          ContaBancariaBrowseComponent.pesquisa(sender, this.model, "Conta Bancária", this.model.contabancaria);
          console.log('thismodel: ', this.model);
        },
      }),

      qtdparcelas: new CMInputConfig({
        form: this,
        required: true,
        caption: "Condição de pagamento",
        values: this.valuesQtdParcelas
      }),
      
      tipoconta: new CMInputConfig({
        form: this,
        required: true,
        caption: "Tipo",
        values: [
          { value: "Pagamento", text: "Pagamento"},
          { value: "Recebimento", text: "Recebimento"},
        ],
        onExit: () => {
          console.log(this);
          if (this.model.tipoconta === 'Pagamento')
            this.controllerName = "contapagar";
          if (this.model.tipoconta === 'Recebimento')
            this.controllerName = "contareceber";
        }
      }),
      
      contacontabil: new CMInputConfig({
        form: this,
        caption: "Conta Contabil",
        required: true,
        pesqFunc: (sender) => {
          ContaContabilBrowseComponent.pesquisa(sender, this.model, "Conta Contábil");
        },
      }),
      formapagamento: new CMInputConfig({
      form: this,
      caption: "Forma de pagamento",
      required: true,
      gridConfig: new CMGridEditConfigColumn({
      tipo: "select",
      onGetValue: (row) =>
        this.getValueObject(row, "formapagamento.id"),
      onSetValue: (row, value) => {
        this.modelConfig.contareceber.duplicatas.fields.formapagamento.setValueInObject(
          row,
          "formapagamento",
          value
        );
      },
      }),
      values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
      valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
      }),
      
      local: {
        dataprimeirovencimento: new CMInputConfig({
          form: this,
          required: true,
          caption: "Data primeiro vencimento para nova geração",
          mask: "99/99/9999",
        }),
      },
      duplicatas: new CMGridEditConfig({
        form: this,
        caption: "Documentos",
        visibleRow: (row) => {
          return row.alteradoformapagamento === 'Não';
        },
        onNewItem: (item) => {
          if (this.model.duplicatas.length === 1) {
            Object.assign(item, {
              nduplicata: this.model.nfatura + "/000",
              dvenc: this.date,
              valor: this.model.vliquido,
              formapagamento : this.model.formapagamento,
              alteradoformapagamento: 'Não'
            });
          }
        },
        /*
        moreActions: [
          new CMGridEditConfigMore({
            hint: "Realizar baixa total",
            icone: () => {
              return "attach_money";
            },
            onClick: (row) => {
              CMFormGlobaisComponent.showConfirmation(
                "Baixa total",
                "Deseja realizar a baixa total do documento?",
                () => {
                  CMFormGlobaisComponent.showSpinner();
                  this.postSubscribeT<Array<string>>(
                    "/api/" + this.controllerName.toLowerCase() + "/baixatotal/FuncaoExtra",
                    JSON.stringify({ id: row.id, observacao: row.observacao}),
                    (result) => {
                      let result_str = result.pop();
                      if (result_str === "ok") {
                        row.vsaldo = this.floatToValue(0,2);
                        row.databaixa = this.date;
                        row.disabled = true;
                        CMFormGlobaisComponent.hideSpinner();
                        CMFormGlobaisComponent.toastSucesso("Parcela baixada");
                        return true;
                      } else {
                        CMFormGlobaisComponent.hideSpinner();
                        CMFormGlobaisComponent.toastErro(result_str);
                        return false;
                      }
                    },
                    (error) => {
                      CMFormGlobaisComponent.hideSpinner();
                      this.showMessageErrorHTTP("onPage", error);
                    }
                  );
                }
              );
            },
          }),
        ],
        */
        fields: {
          nduplicata: new CMInputConfig({
            form: this,
            caption: "Núm. documento",
            maxlength: 60,
            gridConfig: new CMGridEditConfigColumn(),
            required: true,
          }),
          dvenc: new CMInputConfig({
            form: this,
            caption: "Data de vencimento",
            mask: "99/99/9999",
            gridConfig: new CMGridEditConfigColumn(),
            required: true,
          }),
          valor: new CMInputConfig({
            form: this,
            caption: "Valor",
            dataType: "double(2)",
            gridConfig: new CMGridEditConfigColumn(),
            required: true,
          }),
          vsaldo: new CMInputConfig({
            form: this,
            disabled: true,
            caption: "Valor do Saldo",
            dataType: "double(2)",
            gridConfig: new CMGridEditConfigColumn(),
          }),
          databaixa: new CMInputConfig({
            form: this,
            disabled: true,
            caption: "Data da baixa",
            mask: "99/99/9999",
            gridConfig: new CMGridEditConfigColumn(),
          }),
          formapagamento: new CMInputConfig({
            form: this,
            caption: "Forma de pagamento",
            gridConfig: new CMGridEditConfigColumn({
              tipo: "select",
              onGetValue: (row) =>
                this.getValueObject(row, "formapagamento.id"),
              onSetValue: (row, value) => {
                this.modelConfig.duplicatas.fields.formapagamento.setValueInObject(
                  row,
                  "formapagamento",
                  value
                );
              },
            }),
            values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
            valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
          }),
          observacao: new CMInputConfig({ 
            form: this, 
            caption: "Observação",
            gridConfig: new CMGridEditConfigColumn(), }),
          numero: new CMInputConfig({ form: this, caption: "#", disabled: true }),

        },
      }),
    };
  }

  calcValorLiquido() {
    let vliquido =
      this.valueToFloat(this.model.voriginal, 0) -
      this.valueToFloat(this.model.vdesconto, 0) +
      this.valueToFloat(this.model.vacrescimo, 0);
    this.model.vliquido = this.floatToValue(vliquido);
  }

  customValid() {
    let r = super.customValid();
    if (r === "") {
      if (this.valueToFloat(this.model.vliquido, 0) <= 0)
        r = "Valor líquido deve ser maior que zero";
    }
    if (r === "") {
      let duplicatas = this.model.duplicatas;
      let vTotalDup: number = 0;
      for (let i = 0; i < duplicatas.length; i++){
        if (duplicatas[i].alteradoformapagamento === 'Não')
          vTotalDup += this.valueToFloat(duplicatas[i].valor);
      }
      let vliquido = this.valueToFloat(this.model.vliquido, 0);

      console.log('vliquido: ', vliquido);
      console.log('vTotalDup: ', vTotalDup);

      if (this.arredondar(vliquido) !== this.arredondar(vTotalDup))
        r = "Valor líquido difere do valor total dos documentos";
    }
    return r;
  }

  gerarDuplicatas() {
    CMFormGlobaisComponent.showSpinner();
    let duplicatas = [];
    let numeroParcelas;
    //let numeroParcelas = this.valueToInteger(this.model.local.qtdparcelas.num, 0);
    if (this.model.qtdparcelas === "Um") {
      numeroParcelas = "1";
    }
    else if (this.model.qtdparcelas === "Dois" ) {
      numeroParcelas = "2";
    }
    else if (this.model.qtdparcelas === "Tres" ) {
      numeroParcelas = "3";
    }
    else if (this.model.qtdparcelas === "Quatro" ) {
      numeroParcelas = "4";
    }
    else if (this.model.qtdparcelas === "Cinco" ) {
      numeroParcelas = "5";
    }
    else if (this.model.qtdparcelas === "Seis" ) {
      numeroParcelas = "6";
    }
    else if (this.model.qtdparcelas === "Sete" ) {
      numeroParcelas = "7";
    }
    else if (this.model.qtdparcelas === "Oito" ) {
      numeroParcelas = "8";
    }
    else if (this.model.qtdparcelas === "Nove" ) {
      numeroParcelas = "9";
    }
    else if (this.model.qtdparcelas === "Dez" ) {
      numeroParcelas = "10";
    }
    else if (this.model.qtdparcelas === "Onze" ) {
      numeroParcelas = "11";
    }
    else if (this.model.qtdparcelas === "Doze" ) {
      numeroParcelas = "12";
    }
    console.log(this.model.qtdparcelas)
    console.log(numeroParcelas);
    if (numeroParcelas > 0) {
      let vliquido = this.valueToFloat(this.model.vliquido, 0);
      let valorParcela = this.valueToFloat(
        this.arredondar(vliquido / numeroParcelas)
      );
      let dvenc = this.valueToDate(this.model.local.dataprimeirovencimento);
      dvenc.setMonth(dvenc.getMonth() - 1);
      for (let i = 0; i < numeroParcelas; i++) {
        dvenc.setMonth(dvenc.getMonth() + 1);
        duplicatas.push({
          id: CMGridEditConfig.newId(),
          nduplicata:
            this.model.nfatura + "/" + (i + 1).toString().padStart(3, "0"),
          dvenc: this.dateToValue(dvenc),
          valor: this.floatToValue(valorParcela),
          vsaldo: this.floatToValue(valorParcela),
          formapagamento: this.model.formapagamento,
          alteradoformapagamento: 'Não',
        });
      }
      let dif = this.valueToFloat(
        this.arredondar(vliquido - valorParcela * numeroParcelas)
      );
      if (dif !== 0) {
        let valor = this.valueToFloat(duplicatas[0].valor);
        valor += dif;
        duplicatas[0].valor = this.floatToValue(valor);
        duplicatas[0].vsaldo = this.floatToValue(valor);
      }
      setTimeout(() => {
        this.modelConfig.duplicatas.unSelect();
        this.model.duplicatas = duplicatas;
        CMFormGlobaisComponent.hideSpinner();
      }, 500);
    }
  }
}
