// tslint:disable
import { Component, Injector } from "@angular/core";
import { ProdutoBrowseComponent } from "../produto";

import {
  CMFormEditComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMInputConfig,
} from "./../../@cmcore/component";

@Component({
  selector: "promocao-edit",
  templateUrl: "promocao-edit.component.html",
})
export class PromocaoEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_nome";
  }

  getTitle() {
    return "Promoção de Produtos";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "promocao";
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      nome: new CMInputConfig({
        form: this,
        caption: "Nome",
        required: true,
        maxlength: 10,
      }),
      datainicio: new CMInputConfig({
        form: this,
        caption: "Data de Início",
        required: true,
        mask: "99/99/9999",
      }),
      datatermino: new CMInputConfig({
        form: this,
        caption: "Data de Término",
        required: true,
        mask: "99/99/9999",
      }),
      vuncom: new CMInputConfig({
        form: this,
        caption: "Preço de Venda",
        required: true,
        dataType: "double(2)",
      }),
      produtos: new CMGridEditConfig({
        form: this,
        required: true,
        caption: "Produtos",
        fields: {
          extraColumns: {
            produto_descr: new CMInputConfig({
              form: this,
              caption: "Descrição",
              gridConfig: new CMGridEditConfigColumn({
                ordem: 2,
                getValue: (row) => this.getValueObject(row, "produto.descr"),
              }),
            }),
          },
          produto: new CMInputConfig({
            form: this,
            caption: "Produto",
            required: true,
            maxlength: 30,
            padInfo: { size: 30 },
            gridConfig: new CMGridEditConfigColumn({
              ordem: 1,
              width: "350px",
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "produto.codigo");
              },
            }),
            pesqFunc: (sender) => {
              ProdutoBrowseComponent.pesquisa(
                sender,
                this.modelConfig.produtos.current,
                "Ativos"
              );
            },
          }),
        },
      }),
    };
  }
}
