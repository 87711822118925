// tslint:disable
import { Component, Injector } from "@angular/core";
import { ContaBancariaBrowseComponent } from "../../conta-bancaria";
import { ContaContabilBrowseComponent } from "../../conta-contabil";
import { FormaPagamentoBrowseComponent } from "../../formapagamento";

import {
  CMFormRelatComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
} from "../../../@cmcore/component";
import { ClienteBrowseComponent } from "../../cliente/cliente-browse.component";
import { PessoaBrowseComponent } from "../../pessoa";

@Component({
  selector: "exportar-dados",
  templateUrl: "exportar-dados.component.html",
})
export class RelatExportarDadosComponent extends CMFormRelatComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = "ImpressaoExportarFinanceiro";
    this.filterConfig = {
      tipoduplicata: new CMInputConfig({
        form: this,
        caption: "Tipo de Documento",
        values: [
          { value: "Pagamento", text: "Pagamento"},
          { value: "Recebimento", text: "Recebimento"},
          { value: "Todas"},
        ]
      }),
      dataini: new CMInputConfig({
        form: this,
        required: true,
        caption: "Data Inicial",
        mask: "99/99/9999",
      }),
      datafim: new CMInputConfig({
        form: this,
        required: true,
        caption: "Data Final",
        mask: "99/99/9999",
      }),
      somentecontasemaberto: new CMInputConfig({
        form: this,
        caption: "Somente contas em aberto",
      }),
      demonstrarpagamentosavista: new CMInputConfig({
        form: this,
        caption: "Demonstra pagamentos a vista",
      }),
      tipopagamento: new CMInputConfig({
        form: this,
        caption: "Tipo de Recebimento",
        values: [
          { value: "Todos", text: "Todos" },
          { value: "AVista", text: "A Vista" },
          { value: "FormaPagamento", text: "Por forma de pagamento" },
        ],
      }),
      tipodata: new CMInputConfig({
        form: this,
        caption: "Pesquisar por",
        values: [
          { value: "Vencimento", text: "Vencimento" },
          { value: "Liquidacao", text: "Liquidação" },
          { value: "VencimentoAberto", text: "Vencimento - Liquidação Aberta" },
          { value: "Emissao", text: "Emissão" },
          { value: "VencimentoRecebimentoAberto", text: "Vencimento - Recebimento Aberto" },
          //{ value: "BomPara", text: "Bom Para" },
          { value: "EmissaoAberto", text: "Emissão - Liquidação Aberta" },
        ],
      }),
      tiporelat: new CMInputConfig({
        form: this,
        caption: "Tipo de Dados",
        values: [
          { value: "ImpressaoExportarFinanceiro", text: "Financeiro" },
          { value: "ImpressaoExportarClientes", text: "Clientes" },
          { value: "ImpressaoExportarProdutos", text: "Produtos" },
          { value: "ImpressaoExportarVendas", text: "Vendas" },
        ],
        onSetValue: () => {
          console.log(this.filter);
        },
      }),
      entsai: new CMInputConfig({
        form: this,
        caption: "Entrada/Saída",
        values: [
          { value: "Todos", text: "Todos" },
          { value: "Entrada", text: "Recebimentos" },
          { value: "Saida", text: "Pagamentos" },
        ],
      }),
    };
    this.filter.dataini = "11/11/2018";
    this.filter.datafim = this.lastDate();
    this.filter.somentecontasemaberto = "Não";
    this.filter.demonstrarpagamentosavista = "Sim";
    this.filter.tipopagamento = "Todos";
    this.filter.tipodata = "Vencimento";
    this.filter.entsai = "Todos";
    this.filter.tipoduplicata = "Todas";
  }

  exportCSV() {
    if (this.valid()) this.downloadCSV(this.filter.tiporelat, this.filter);
    console.log("method overriden");
  }

  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.dataini);
      if (!r) CMFormGlobaisComponent.toastErro("Data inicial não informada");
    }
    if (r) {
      r = !this.isEmpty(this.filter.datafim);
      if (!r) CMFormGlobaisComponent.toastErro("Data final não informada");
    }
    return r;
  }
}
