// tslint:disable
import {
  Component,
  Input,
  EventEmitter,
  Output,
  SimpleChange,
  OnChanges,
} from "@angular/core";

import {
  CMInputConfig,
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMGridConfig,
  CMGridComponent,
} from "./../../component";
import { CMFormComponent } from "../../component/cmform/cmform.component";
import { CMInput } from "../../decorator";
import { Observable, Observer } from "rxjs";

export class CMGridEditConfigColumn {
  readOnly: boolean = false;
  getValue: Function = undefined;
  getBottomLabelColumn: Function = undefined;
  getFooterLabelColumn: Function = undefined;
  width: string = "auto";
  maxHeight: string = "3em";
  whiteSpace: string = "nowrap";
  whiteSpaceRow: string = "nowrap";
  textAlignHead: string = "inherit";
  paddingRight: string = "10px";
  ordem: Number = 0;
  tipo: string | Function = "";
  public disabledFunc: Function = undefined;
  background: string | Function = undefined;
  onGetValue: Function = undefined;
  onSetValue: Function = undefined;
  owner: CMGridEditConfig;
  wordwrap: boolean = false;
  onClick: Function = undefined;
  verticalAlign: string = "sub";

  getTipo(row) {
    if (this.tipo instanceof Function) {
      return this.tipo(row);
    } else {
      return this.tipo;
    }
  }
  innerTipo: string = "";
  getTipoEdit(row) {
    let r = this.getTipo(row);
    if (r === "") r = this.innerTipo;
    return r;
  }

  isReadOnly(row) {
    let r = this.readOnly;
    if (!r && this.getTipo(row) !== "input-pesquisa")
      r = this.getValue !== undefined;
    return r;
  }

  constructor(data?: any) {
    if (data) {
      if (data.readOnly) this.readOnly = data.readOnly;
      if (data.getValue) this.getValue = data.getValue;
      if (data.getBottomLabelColumn)
        this.getBottomLabelColumn = data.getBottomLabelColumn;
      if (data.getFooterLabelColumn)
        this.getFooterLabelColumn = data.getFooterLabelColumn;
      if (data.width) this.width = data.width;
      if (data.maxHeight) this.maxHeight = data.maxHeight;
      if (data.whiteSpace) this.whiteSpace = data.whiteSpace;
      if (data.whiteSpaceRow) this.whiteSpaceRow = data.whiteSpaceRow;
      if (data.textAlignHead) this.textAlignHead = data.textAlignHead;
      if (data.wordwrap) this.wordwrap = data.wordwrap;
      if (data.paddingRight) this.paddingRight = data.paddingRight;
      if (data.ordem) this.ordem = data.ordem;
      if (data.tipo) this.tipo = data.tipo;
      if (data.disabledFunc) this.disabledFunc = data.disabledFunc;
      if (data.background) this.background = data.background;
      if (data.onGetValue) this.onGetValue = data.onGetValue;
      if (data.onSetValue) this.onSetValue = data.onSetValue;
      if (data.onClick) this.onClick = data.onClick;
      if (data.verticalAlign) this.verticalAlign = data.verticalAlign;
    }
  }
}

export class CMGridEditConfigMore {
  title: string | Function = "";
  index = 0;
  hint = "";
  icone: string | Function = "";
  defaultInAdd: boolean = false;
  allowCancel: boolean = false;
  onClick: Function = undefined;
  visible: boolean | Function = true;

  constructor(data?: any) {
    if (data) {
      if (data.title) this.title = data.title;
      if (data.index) this.index = data.index;
      if (data.hint) this.hint = data.hint;
      if (data.icone) this.icone = data.icone;
      if (data.defaultInAdd) this.defaultInAdd = data.defaultInAdd;
      if (data.onClick) this.onClick = data.onClick;
      if (data.visible) this.visible = data.visible;
    }
  }

  getIcone() {
    if (this.icone instanceof Function) return this.icone();
    else return this.icone;
  }

  getVisible(row) {
    if (this.visible instanceof Function) return this.visible(row);
    else return this.visible;
  }

  getTitle() {
    let r = "";
    if (this.title instanceof Function) r = this.title();
    if (r === "" && !(this.title instanceof Function)) r = this.title;
    if (r === "") r = this.hint;
    return r;
  }
}

export class CMGridEditConfig {
  public fields : any = {};
  public extraColumns = {};

  private innerRequired: boolean = true;
  get required() {
    let r = this.innerRequired;
    if (this.requiredFunc !== undefined) r = this.requiredFunc();
    if (r) r = !this.disabledAll;
    return r;
  }
  set required(value: boolean) {
    this.innerRequired = value;
  }
  public requiredFunc: Function = undefined;
  public gridName: string = "grid_" + CMGridEditConfig.newId();
  public onNewItem: Function = undefined;
  public onSelect: Function = undefined;
  public onRemoveItem: Function = undefined;
  public onAllowRemoveItem: Function = undefined;
  public onAllowRemoveItemCallback: Function = undefined;
  public customValidItem: Function = undefined;
  public caption: string = "";
  public captionFunc: Function = undefined;
  public form: CMFormComponent = null;
  public focusComponentOnSelect: string = "";
  public objName: string = "";
  public moreActions = [];
  public disabledFunc: Function = undefined;
  public visibleRow: Function = undefined;

  public getCaption() {
    if (this.captionFunc !== undefined) return this.captionFunc(this.current);
    else return this.caption;
  }

  constructor(data?: any) {
    if (data) {
      if (data.fields !== undefined) this.fields = data.fields;
      if (data.extraColumns !== undefined)
        this.extraColumns = data.extraColumns;
      if (data.required !== undefined) this.required = data.required;
      if (data.requiredFunc !== undefined)
        this.requiredFunc = data.requiredFunc;
      if (data.grid !== undefined) this.gridName = data.grid;
      if (data.form !== undefined) this.form = data.form;
      if (data.onNewItem !== undefined) this.onNewItem = data.onNewItem;
      if (data.onRemoveItem !== undefined)
        this.onRemoveItem = data.onRemoveItem;
      if (data.onAllowRemoveItem !== undefined)
        this.onAllowRemoveItem = data.onAllowRemoveItem;
      if (data.onAllowRemoveItemCallback !== undefined)
        this.onAllowRemoveItemCallback = data.onAllowRemoveItemCallback;
      if (data.caption !== undefined) this.caption = data.caption;
      if (data.captionFunc !== undefined) this.captionFunc = data.captionFunc;
      if (data.focusComponentOnSelect !== undefined)
        this.focusComponentOnSelect = data.focusComponentOnSelect;
      if (data.onSelect !== undefined) this.onSelect = data.onSelect;
      if (data.customValidItem !== undefined)
        this.customValidItem = data.customValidItem;
      if (data.objName !== undefined) this.objName = data.objName;
      if (data.moreActions !== undefined) this.moreActions = data.moreActions;
      if (data.disabledFunc) this.disabledFunc = data.disabledFunc;
      if (data.visibleRow !== undefined) this.visibleRow = data.visibleRow;
    }
  }



  
  static getDataFields(fields) {
    let propFields = Object.getOwnPropertyNames(fields);
    let ret = {};
    for (var i = 0; i < propFields.length; ++i) {
      let field = propFields[i];
      if (fields[field] instanceof CMInputConfig)
        ret[field] = fields[field].clone();
      else if (fields[field] instanceof CMGridConfig)
        ret[field] = fields[field].clone();
      else if (fields[field] instanceof CMGridEditConfig)
        ret[field] = fields[field].clone();
      //else if (this.fields[field] instanceof CMListCheckBoxConfig)
      //  newFields[field] = this.fields[field].clone();
      else if (CMFormComponent.isBaseObject(fields[field])) {
        ret[field] = CMGridConfig.getDataFields(fields[field]);
      }
    }
    return ret;
  }

  getData() {
    let data: any = {};
    if (this.fields !== undefined)
      data.fields = CMGridConfig.getDataFields(this.fields);
    if (this.extraColumns !== undefined) data.extraColumns = this.extraColumns;
    if (this.required !== undefined) data.required = this.required;
    if (this.requiredFunc !== undefined) data.requiredFunc = this.requiredFunc;
    if (this.gridName !== undefined) data.gridName = this.gridName;
    if (this.form !== undefined) data.form = this.form;
    if (this.onNewItem !== undefined) data.onNewItem = this.onNewItem;
    if (this.onRemoveItem !== undefined) data.onRemoveItem = this.onRemoveItem;
    if (this.onAllowRemoveItem !== undefined)
      data.onAllowRemoveItem = this.onAllowRemoveItem;
    if (this.onAllowRemoveItemCallback !== undefined)
      data.onAllowRemoveItemCallback = this.onAllowRemoveItemCallback;
    if (this.caption !== undefined) data.caption = this.caption;
    if (this.captionFunc !== undefined) data.captionFunc = this.captionFunc;
    if (this.focusComponentOnSelect !== undefined)
      data.focusComponentOnSelect = this.focusComponentOnSelect;
    if (this.onSelect !== undefined) data.onSelect = this.onSelect;
    if (this.customValidItem !== undefined)
      data.customValidItem = this.customValidItem;
    if (this.objName !== undefined) data.objName = this.objName;
    if (this.moreActions !== undefined) data.moreActions = this.moreActions;
    if (this.visibleRow !== undefined) data.visibleRow = this.visibleRow;
    return data;
  }

  public grid: any;

  static newId() {
    return CMGridComponent.newId();
  }

  private emptyModel: any = undefined;
  public get current() {
    let r = undefined;
    if (this.grid) r = this.grid.current;
    if (!r) {
      if (!this.emptyModel) {
        this.emptyModel = {};
        CMFormComponent.generateEmptyModel(this.fields, this.emptyModel);
        r = this.emptyModel;
      }
      r = this.emptyModel;
    }
    return r;
  }

  get count() {
    let g = this.grid;
    if (g) return g.count;
    else return 0;
  }

  unSelect() {
    let g = this.grid;
    if (g) g.unSelect();
  }

  checkVisibleRow() {
    let g = this.grid;
    if (g) g.checkVisibleRow();
  }
  /*
  public unSelectFields() {
    let g = this.grid;
    if (g)
      g.unSelectFields();
  }*/

  validItem(item: any) {
    let r: string = "";
    if (this.customValidItem !== undefined) r = this.customValidItem(item);
    if (r !== "") CMFormGlobaisComponent.toastErro(r, this.getCaption());
    return r === "";
  }

  get isCurrent() {
    let g = this.grid;
    if (g) return g.isCurrent;
    else return false;
  }

  public fieldByName(aFieldName: string) {
    return CMFormComponent.getValueObject(this.current, aFieldName);
  }

  clone() {
    return new CMGridEditConfig(this.getData());
  }

  public selectFirst(setFocus: boolean = true) {
    let g = this.grid;
    if (g) g.selectFirst(setFocus);
  }

  get disabledAll() {
    let r: boolean = false;
    if (!r && this.form) {
      if (this.form.disabledAll !== undefined) r = this.form.disabledAll;
    }
    if (!r) {
      if (this.disabledFunc !== undefined) r = this.disabledFunc();
    }
    return r;
  }

  addNew() {
    let g = this.grid;
    if (g) return g.addNew();
  }

  extraClick(extra) {
    let g = this.grid;
    if (g) g.extraClick(extra);
  }
}

@Component({
  selector: "cmgrid-edit",
  templateUrl: "cmgrid-edit.component.html",
  styleUrls: ["./cmgrid-edit.component.scss"],
})
export class GridEditComponent {
  private internalGridConfig: CMGridEditConfig = null;
  @Input()
  get gridConfig() {
    return this.internalGridConfig;
  }
  set gridConfig(value: CMGridEditConfig) {
    if (this.internalGridConfig) this.internalGridConfig.grid = null;
    this.internalGridConfig = value;
    if (this.internalGridConfig) this.internalGridConfig.grid = this;
  }

  _dataRows = {};
  _rows = [];
  @Input()
  get rows() {
    return this._rows;
  }
  set rows(_rows) {
    this._rows = _rows;
    this._dataRows = {};
    this.clearordemAtual();
    this.selectFirst(false);
  }

  @Output() public onNewItem: EventEmitter<any> = new EventEmitter<any>();

  getCaptionColumn(column: CMInputConfig) {
    let s = column.getCaption();
    if (column.required && !column.disabled) {
      s += " *";
    }
    return s;
  }

  @Input() @CMInput() public newItemInFirst: boolean = false;
  @Input() @CMInput() public showActionAdd: boolean = true;
  @Input() @CMInput() public showActionRemove: boolean = true;
  @Input() @CMInput() public showDivCaption: boolean = true;
  @Input() @CMInput() public allowRemoveMany: boolean = false;
  @Input() @CMInput() public stickyThead: boolean = false;
  @Input() @CMInput() public stickyTfoot: boolean = false;
  @Input() @CMInput() public stickyNavBar: boolean = false;
  @Input() @CMInput() public scrollType: String = "auto";
  @Input() @CMInput() public atualizaWidth: boolean = false;
  @Input() @CMInput() public overflowY: string = "auto";
  @Input()
  showQtdeRows: boolean = false;
  atualizouWidth: boolean = true;

  atualizarWidth(){
    if (this.atualizouWidth && this.atualizaWidth){
      if (document.getElementById('div1') !== null){
        document.getElementById('div1').style.width = CMInputConfig.integerToValue(document.getElementById('div2').offsetWidth);
        this.atualizouWidth = false;
      }
    }
  }

  _columns = [];
  private loadColumns(path, fieldsObject) {
    let fields = Object.keys(fieldsObject);
    fields.forEach((field) => {
      if (CMFormComponent.isBaseObject(fieldsObject[field])) {
        let p = path;
        if (p !== "") p += ".";
        this.loadColumns(p + field, fieldsObject[field]);
      } else {
        let config = fieldsObject[field];
        if (config.gridConfig) {
          let gc = config.gridConfig;
          gc.owner = this.gridConfig;
          if (gc.ordem === 0) {
            gc.ordem = 1000 + this._columns.length;
          }
          if (gc.path === undefined) gc.path = path;
          else gc.path += "." + path;
          if (gc.path !== "") gc.path += ".";
          gc.name = field;
          gc.config = config;
          if (config.values && config.values.length > 0)
            gc.innerTipo = "select";
          else if (config.dataType === "boolean") gc.innerTipo = "checkbox";
          else gc.innerTipo = "input";
          this._columns.push(gc);
        }
      }
    });
  }

  columns() {
    if (this._columns.length == 0) {
      if (this.gridConfig) {
        if (this.gridConfig.fields)
          this.loadColumns("", this.gridConfig.fields);
        if (this.gridConfig.extraColumns)
          this.loadColumns("", this.gridConfig.extraColumns);
      }
      this._columns.sort((a, b) => {
        return a.ordem - b.ordem;
      });
    }
    //document.getElementById('div1').style.width = CMInputConfig.integerToValue(document.getElementById('div2').offsetWidth);
    return this._columns;
  }

  getNameInput(row, _column) {
    let r = "";
    if (this.gridConfig) r += this.gridConfig.gridName;
    r += _column.name;
    if (row["id"]) r += row["id"];
    return r;
  }

  getProp(row, column) {
    let pathField: string = column.path + column.nane;
    if (row && pathField !== "" && pathField !== undefined) {
      let paths = pathField.split(".");
      let obj = row;
      let prop = paths[paths.length - 1];
      for (let i = 0; i < paths.length - 1; i++) {
        if (obj[paths[i]] !== undefined) obj = obj[paths[i]];
        else {
          obj = undefined;
          break;
        }
      }
      return obj[prop];
    }
  }

  getValueInPath(row, _column) {
    if (_column.onGetValue !== undefined)
      return _column.onGetValue(row, _column);
    else
      return CMFormComponent.getValueObject(row, _column.path + _column.name);
  }

  setValueInPath(row, column, value) {
    if (column.onSetValue !== undefined) return column.onSetValue(row, value);
    else CMFormComponent.setValueObject(row, column.path + column.name, value);
  }

  getValueColumn(row, _column) {
    let r = "";
    if (_column.getValue !== undefined) r = _column.getValue(row);
    else if (_column.config.values && _column.config.values.length > 0) {
      let v = CMFormComponent.getValueObject(row, _column.path + _column.name);
      if (v !== "") {
        let nameValue = "$$" + _column.name + "ValueColumnCache";
        let nameText = "$$" + _column.name + "TextColumnCache";
        if (row[nameValue] === v) r = row[nameText];
        if (r === "") {
          for (const item of _column.config.values) {
            if (item.value === v) {
              row[nameValue] = item.value;
              row[nameText] = item.text ? item.text : item.value;
              r = row[nameText];
              break;
            }
          }
        }
      }
    } else r = CMFormComponent.getValueObject(row, _column.path + _column.name);
    return r;
  }

  getBottomLabelColumn(row, _column) {
    let r = "";
    if (_column.getBottomLabelColumn !== undefined)
      r = _column.getBottomLabelColumn(row);
    return r;
  }

  getFooterLabelColumn(_column) {
    let r = "";
    if (this.rows) {
      if (_column == this._columns[0]) r = this.rows.length + " total";
      if (_column.getFooterLabelColumn !== undefined)
        r = _column.getFooterLabelColumn();
    }
    return r;
  }

  styleFooter(_column) {
    let r = "";
    if (_column.config.isDoubleField())
      r = "text-align: right; padding-right: 5px;";
    return r;
  }

  public addNew() {
    this.unSelect();
    let item = {};
    let newId: number = CMGridEditConfig.newId();
    CMFormComponent.generateEmptyModel(this.gridConfig.fields, item);
    item["id"] = newId;
    setTimeout(() => {
      if (this.newItemInFirst) {
        this.rows.unshift(item);
      } else {
        this.rows.push(item);
      }
      this.onNewItem.emit(item);
      setTimeout(() => {
        this.select(item);
        this.executeMoreActionAdd();
      }, 10);
    }, 10);
    return item;
  }

  public ngOnInit() {
    if (this.gridConfig) {
      if (this.gridConfig.onNewItem !== undefined)
        this.onNewItem.subscribe(this.gridConfig.onNewItem);
    }
  }

  unSelect() {
    this.current = undefined;
  }

  selectFirst(setFocus: boolean = true) {
    if (this.rows) {
      if (this.rows.length > 0) this.select(this.rows[0], setFocus);
      else this.unSelect();
    }
  }

  selectId(id, setFocus: boolean = true) {
    if (this.rows.length > 0) {
      this.rows.forEach((row) => {
        if (row.id == id) this.select(row, setFocus);
      });
    } else this.unSelect();
  }

  get count() {
    return this.rows.length;
  }

  current: any = undefined;

  get isCurrent() {
    return this.current !== undefined;
  }

  getNameWapperTable() {
    return "warpper-" + this.gridConfig.gridName;
  }

  select(row, setFocus: boolean = true) {
    if (this.current !== row) {
      this.current = row;
      if (this.gridConfig) {
        if (this.gridConfig.onSelect !== undefined)
          this.gridConfig.onSelect(row);
      }
      if (setFocus && this.gridConfig && row) {
        let comp = "";
        if (this.gridConfig.focusComponentOnSelect !== "")
          comp = this.gridConfig.focusComponentOnSelect;
        if (comp === "" && this._columns.length > 0) {
          comp = this._columns[0].name;
        }
        if (comp !== "") {
          if (row["id"] !== "") comp += row["id"];
          setTimeout(() => {
            $("#" + comp).trigger("focus");
            $("#" + comp).trigger("select");
            setTimeout(() => {
              const wt = $("#" + this.getNameWapperTable());
              wt.scrollTop(wt.scrollTop() + 100);
            }, 10);
          }, 100);
        }
      }
    }
  }

  onEnterInput(row) {
    this.select(row, false);
  }

  isSelected(row) {
    return this.current === row;
  }

  checkVisibleRow() {
    this.rows.forEach((row) => {
      let __hiddenRowObserver = this._dataRows[row.id + "__hiddenRowObserver"];
      if (__hiddenRowObserver !== undefined) {
        __hiddenRowObserver.next(!this.gridConfig.visibleRow(row));
      }
    });
  }

  hiddenRow(row) {
    let __hiddenRow = undefined;
    if (__hiddenRow == undefined) {
      __hiddenRow = new Observable<boolean>((observer: Observer<boolean>) => {
        if (this.gridConfig) {
          if (this.gridConfig.visibleRow !== undefined) {
            let __hiddenRowObserver = observer;
            this._dataRows[row.id + "__hiddenRowObserver"] =
              __hiddenRowObserver;
            observer.next(!this.gridConfig.visibleRow(row));
          } else observer.next(false);
        }
      });
      this._dataRows[row.id + "__hiddenRow"] = __hiddenRow;
    }
    return __hiddenRow;
  }

  private innerRemoveItem() {
    if (this.gridConfig) {
      if (this.gridConfig.onRemoveItem !== undefined)
        this.gridConfig.onRemoveItem(this.current);
    }
    this.rows.splice(this.rows.indexOf(this.current), 1);
    this.unSelect();
  }

  private innerRemoveItemAllow(s: string) {
    let allow: boolean = s === "";
    if (!allow)
      CMFormGlobaisComponent.showMessageAviso(
        '<div class="container"><p>' + s + "</p></div>"
      );
    if (allow) {
      this.innerRemoveItem();
    }
  }

  removeItem() {
    if (this.isCurrent) {
      CMFormGlobaisComponent.showConfirmation(
        "Confirmação",
        '<div class="container"><cmrow><p>Deseja apagar o registro selecionado?</p></cmrow></div>',
        () => {
          this.removeItemDirect();
        }
      );
    }
  }

  removeItemDirect() {
    if (this.isCurrent) {
      if (this.gridConfig) {
        if (this.gridConfig.onAllowRemoveItemCallback !== undefined) {
          this.gridConfig.onAllowRemoveItemCallback((s: string) => {
            this.innerRemoveItemAllow(s);
          }, this.current);
        } else if (this.gridConfig.onAllowRemoveItem !== undefined) {
          this.innerRemoveItemAllow(
            this.gridConfig.onAllowRemoveItem(this.current)
          );
        } else this.innerRemoveItem();
      } else {
        this.innerRemoveItem();
      }
    }
  }

  getShowActionAdd() {
    let r: boolean = true;
    if (this.gridConfig) {
      if (this.gridConfig.disabledAll !== undefined)
        r = !this.gridConfig.disabledAll;
    }
    if (r) r = this.showActionAdd;
    return r;
  }

  columnRemoveMany = {
    name: "removeMany",
    path: "",
    config: new CMInputConfig({}),
  };

  getShowActionRemove() {
    let r: boolean = true;
    if (this.gridConfig) {
      if (this.gridConfig.disabledAll !== undefined)
        r = !this.gridConfig.disabledAll;
    }
    if (r) r = this.showActionRemove && this.isCurrent;
    return r;
  }

  inActionRemoveMany: boolean = false;
  getShowActionRemoveMany() {
    let r: boolean = true;
    if (this.gridConfig) {
      if (this.gridConfig.disabledAll !== undefined)
        r = !this.gridConfig.disabledAll;
    }
    if (r) r = this.allowRemoveMany && this.count > 0;
    return r;
  }

  getShowSelectionActionRemoveMany() {
    let r: boolean = this.getShowActionRemoveMany();
    if (r) r = this.inActionRemoveMany;
    return r;
  }

  removeManyItem() {
    this.inActionRemoveMany = true;
  }

  cancelRemoveManyItem() {
    this.inActionRemoveMany = false;
    Object.keys(this._dataRows).forEach((item) => {
      if (item.endsWith("_data")) {
        this._dataRows[item]["removeMany"] = "Não";
      }
    });
  }

  okRemoveManyItem() {
    CMFormGlobaisComponent.showConfirmation(
      "Confirmação",
      '<div class="container"><cmrow><p>Deseja apagar todos os registros selecionados?</p></cmrow></div>',
      () => {
        Object.keys(this._dataRows).forEach((item) => {
          if (item.endsWith("_data")) {
            if (this._dataRows[item]["removeMany"] == "Sim") {
              this.selectId(this._dataRows[item]["id"]);
              this.removeItemDirect();
            }
          }
        });
        this.inActionRemoveMany = false;
      }
    );
  }

  getValueInPathDataRows(row, _column) {
    if (_column.onGetValue !== undefined)
      return _column.onGetValue(row, _column);
    else
      return CMFormComponent.getValueObject(
        this._dataRows[row.id + "_data"],
        _column.path + _column.name
      );
  }

  setValueInPathDataRows(row, column, value) {
    if (column.onSetValue !== undefined) return column.onSetValue(row, value);
    else if (this._dataRows[row.id + "_data"] == undefined)
      this._dataRows[row.id + "_data"] = { id: row.id };
    CMFormComponent.setValueObject(
      this._dataRows[row.id + "_data"],
      column.path + column.name,
      value
    );
  }

  executeMoreActionAdd() {
    for (const more of this.gridConfig.moreActions) {
      if (more.defaultInAdd) {
        this.moreClick(more);
        break;
      }
    }
  }

  widthMoreActions() {
    return "width: calc(45px * " + this.gridConfig.moreActions.length + ");";
  }

  currentMore: CMGridEditConfigMore = undefined;
  moreClick(more) {
    this.currentMore = more;
    if (more.onClick !== undefined) more.onClick(this.current);
    else this.openMoreRow();
  }

  tmpMoreRow: string;
  openMoreRow() {
    this.tmpMoreRow = JSON.stringify(this.current);
    CMFormModalComponent.open("more_" + this.gridConfig.gridName);
  }

  closeMoreRow() {
    CMFormModalComponent.close("more_" + this.gridConfig.gridName);
  }

  saveMoreRow() {
    this.closeMoreRow();
  }

  cancelMoreRow() {
    let old = JSON.parse(this.tmpMoreRow);
    this.rows[this.rows.indexOf(this.current)] = old;
    this.current = old;
    this.closeMoreRow();
  }

  currentExtra: CMGridEditConfigMore = undefined;
  extraClick(extra) {
    setTimeout(() => {
      if (this.currentExtra == undefined) {
        this.currentExtra = extra;
        this.openExtraRow();
      }
    }, 100);
  }

  tmpExtraRow: string;
  openExtraRow() {
    this.tmpExtraRow = JSON.stringify(this.current);
    CMFormModalComponent.open("extra_" + this.gridConfig.gridName);
  }

  closeExtraRow() {
    CMFormModalComponent.close("extra_" + this.gridConfig.gridName);
    this.currentExtra = undefined;
  }

  saveExtraRow() {
    this.closeExtraRow();
  }

  cancelExtraRow() {
    let old = JSON.parse(this.tmpExtraRow);
    this.rows[this.rows.indexOf(this.current)] = old;
    this.current = old;
    this.closeExtraRow();
  }

  getBackgroundColumn(column: CMGridEditConfigColumn, row) {
    let r = "unset";
    if (column.background) {
      if (column.background instanceof Function) r = column.background(row);
      else if (column.background !== "") r = column.background;
      if (r === "") r = "unset";
    }
    if (r === "unset") {
      if (this.stickyThead) {
        if (row === "head") r = "rgb(251 251 251)";
      }
      if (this.stickyTfoot) {
        if (row === "foot") r = "rgb(251 251 251)";
      }
    }
    return r;
  }

  onClickRowColumn(column: CMGridEditConfigColumn, row) {
    if (column.onClick !== undefined) {
      column.onClick(row);
    }
  }

  getValue(obj, column) {
    let v = obj[column.name];
    if (column.config) {
      if (column.config.dataType.toLowerCase().startsWith("double"))
        v = CMInputConfig.valueToFloat(v);
      else if (column.config.dataType.toLowerCase() === "integer")
        v = CMInputConfig.valueToInteger(v);
    }
    return v;
  }


  wrapper1scroll() {
    $(".wrapper1").on('scroll', function() {
      $(".wrapper2")
        .scrollLeft($(".wrapper1").scrollLeft());
    });
  }

  wrapper2scroll() {
    $(".wrapper2").on('scroll', function() {
      $(".wrapper1")
        .scrollLeft($(".wrapper2").scrollLeft());
    });
  }


  clickHead(column) {
    let __ordemAtual = column.__ordemAtual;
    let sort: boolean = true;
    if (this.rows.length > 0) {
      if (this.rows[0][column.name] instanceof Object) sort = false;
    }
    if (sort) {
      const _this = this;
      if (__ordemAtual !== "desc") {
        __ordemAtual = "desc";
        this.rows.sort(function (a, b) {
          let va = _this.getValue(a, column);
          let vb = _this.getValue(b, column);
          if (va > vb) {
            return 1;
          }
          if (va < vb) {
            return -1;
          }
          return 0;
        });
      } else if (__ordemAtual !== "asc") {
        __ordemAtual = "asc";
        this.rows.sort(function (a, b) {
          let va = _this.getValue(a, column);
          let vb = _this.getValue(b, column);
          if (va > vb) {
            return -1;
          }
          if (va < vb) {
            return 1;
          }
          return 0;
        });
      }
    }
    this.clearordemAtual();
    column.__ordemAtual = __ordemAtual;
  }

  clearordemAtual() {
    this._columns.forEach((columns) => {
      columns.__ordemAtual = undefined;
    });
  }

  widthThead(table, tbody) {
    let r = "100%";
    if (table && tbody) {
      const px = tbody.offsetWidth - tbody.clientWidth + 30;
      r = "calc(100% - " + px + "px)";
    }
    return r;
  }

  _qtdeRows: number = 0;
  @Input()
  get qtdeRows() {
    return this._qtdeRows;
  }
  set qtdeRows(value) {
    this._qtdeRows = value;
    this.heightWarpperTable = this.getHeightWarpperTable();
  }
  heightWarpperTable = "unset";
  qtdeRowsOptions = [
    { text: "05" },
    { text: "10" },
    { text: "20" },
    { text: "30" },
    { text: "40" },
    { text: "50" },
    { value: "0", text: "--" },
  ];
  getHeightWarpperTable() {
    let r = "unset";
    if (this.qtdeRows > 0) {
      let x = this.qtdeRows * 45;
      x += 32; // thead
      x += 32; // tfoot
      r = x + "px";
    }
    return r;
  }

}
