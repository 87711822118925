// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridComponent,
  CMListCheckBoxConfig,
  CMFormGlobaisComponent,
} from "./../../../../@cmcore/component";
import { RecursoBrowseComponent } from "../recurso-browse.component";

@Component({
  selector: "recurso-help-edit",
  templateUrl: "recurso-help-edit.component.html",
})
export class RecursoHelpEditComponent extends CMFormEditComponent {
  getTitle() {
    return "Ajuda do Recurso";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "RecursoHelp";
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      id: new CMInputConfig({
        form: this,
        caption: "#",
        disabled: true,
      }),
      recurso: new CMInputConfig({
        form: this,
        caption: "Recurso",
        required: true,
        pesqOnly: true,
        pesqFunc: (sender) => {
          RecursoBrowseComponent.pesquisa(sender, this.model, [
            "MenuBrowse",
            "MenuRelatorio",
          ]);
        },
      }),
      titulo: new CMInputConfig({
        form: this,
        caption: "Titulo",
        maxlength: 60,
        required: true,
      }),
      tipo: new CMInputConfig({
        form: this,
        caption: "Tipo",
        values: [
          { value: "LinkExterno", text: "Link Externo" },
          { value: "LinkIFrame", text: "Link iFrame" },
        ],
      }),
      link: new CMInputConfig({
        form: this,
        caption: "Link",
        required: true,
      }),
      ordem: new CMInputConfig({
        form: this,
        caption: "Ordem",
        dataType: "double(2)",
        required: true,
      }),
    };
  }
}
