// tslint:disable

import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
} from "./../../@cmcore/component";
import { PadraoDeOperacaoEditComponent } from "./padraodeoperacao-edit.component";

@Component({
  selector: "padraodeoperacao-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class PadraoDeOperacaoBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "padraodeoperacao";
    this.formEditComponent = PadraoDeOperacaoEditComponent;
    this.columns = [
      { field: "codigo", caption: "#", width: "100" },
      { field: "nome", caption: "Nome", filter: true },
    ];
  }

  getTitle() {
    return "Padrão de Operação";
  }

  static pesquisa(sender: any, target: any) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "padraodeoperacao",
        "Pesquisa de Padrão de Operação",
        [
          { field: "codigo", caption: "#", width: "100" },
          { field: "nome", caption: "Nome", filter: true },
        ],
        target,
        undefined,
        "codigo"
      );
    }
  }
  
  static pesquisa2(sender: any, target: any, targetProp: any = undefined) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "padraodeoperacao",
        "Pesquisa de Padrão de Operação",
        [
          { field: "codigo", caption: "#", width: "100" },
          { field: "nome", caption: "Nome", filter: true },
        ],
        target,
        targetProp,
        "codigo",
        undefined,
        false
      );
    }
  }
}
