// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMInputConfig,
} from "./../../@cmcore/component";
import { FechamentoCaixaEditComponent } from "./fechamento-caixa-edit.component";

@Component({
  selector: "fechamento-caixa-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class FechamentoCaixaBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "fechamentocaixa";
    this.formEditComponent = FechamentoCaixaEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = [
      { field: "numerocaixa", caption: "Número do Caixa", filter: true },
      {
        field: "dataabertura",
        caption: "DataAbertura",
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      { field: "horaabertura", caption: "HoraAbertura" },
      { field: "datafechamento", caption: "DataFechamento" },
      { field: "horafechamento", caption: "HoraFechamento" },
    ];
  }

  getTitle() {
    return "Fechamento de Caixa";
  }
}
