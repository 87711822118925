// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormEditComponent, CMInputConfig } from "./../../@cmcore/component";

@Component({
  selector: "subgrupodeproduto-edit",
  templateUrl: "subgrupodeproduto-edit.component.html",
})
export class SubGrupoDeProdutoEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_codigo";
  }

  getTitle() {
    return "Sub-Grupo de Produto";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "subgrupodeproduto";
    this.modelConfig = {
      codigo: new CMInputConfig({
        form: this,
        caption: "Código",
        maxlength: 5,
        required: true,
        upper: true,
      }),
      descricao: new CMInputConfig({
        form: this,
        caption: "Descrição",
        required: true,
        maxlength: 60,
      }),
    };
  }
}
