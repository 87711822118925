// tslint:disable
import { Injector, Component, ViewChild, AfterViewInit } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { SelectionModel } from "@angular/cdk/collections";
import { MatTableDataSource } from "@angular/material/table";

import { CMFormComponent } from "../../../component/cmform/cmform.component";
import {
  CMFormModalComponent,
  CMFormGlobaisComponent,
  CMInputComponent,
  CMInputConfig,
} from "../../../component";
import { merge, Observable, of as observableOf } from "rxjs";
import { catchError, map, startWith, switchMap } from "rxjs/operators";

declare var $: any;

@Component({
  selector: "cmform-pesquisa",
  templateUrl: "./cmform-pesquisa.component.html",
  styleUrls: ["./cmform-pesquisa.component.scss"],
})
export class CMFormPesquisaComponent
  extends CMFormComponent
  implements AfterViewInit {
  static inPesquisa: boolean = false;

  public muiltSelect: boolean = false;

  public limitPesq = 0;

  public columns = [];

  public filters = [];

  public valuesFilters = {};

  protected extraFilters = {};

  protected controllerName: string = "";

  caption: string;

  onOK: Function = undefined;
  afterPostPesq: Function = undefined;

  rowHeight = "40px";

  constructor(_injector: Injector) {
    super(_injector);
    CMFormPesquisaComponent.instance = this;
  }

  displayedColumns: string[] = [];
  data: any[] = [];

  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? "select" : "deselect"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  resultsLength = 0;
  isLoadingResults = true;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  postPesq() {
    if (
      CMFormPesquisaComponent.inPesquisa &&
      this.filtrado &&
      this.controllerName !== ""
    ) {
      let valuesFilters = this.valuesFilters;
      valuesFilters["extra"] = this.extraFilters;

      const hrefPesq =
        "/api/" + this.controllerName.toLowerCase() + "/getbrowseobjects";
      const dataPesq = {
        origem: "pesquisa",
        page: this.paginator.pageIndex,
        limit: this.limit,
        filter: this.valuesFilters,
      };
      return this.post(hrefPesq, dataPesq);
    } else return observableOf([]);
  }

  ngAfterViewInit() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.postPesq();
        }),
        map((data) => {
          this.dataSource.data = [];
          this.selection.clear();
          if (data["data"] !== undefined && data["totalCount"] !== undefined) {
            this.isLoadingResults = false;
            this.resultsLength = data["totalCount"];
            return data["data"];
          } else {
            this.resultsLength = 0;
            return [];
          }
        }),
        catchError((err) => {
          this.showMessageErrorHTTP("onPage", err);
          this.isLoadingResults = false;
          return observableOf([]);
        })
      )
      .subscribe((data) => {
        this.data = data;
        if (this.muiltSelect) {
          this.dataSource.data = data;
        }
        if (this.afterPostPesq !== undefined) this.afterPostPesq(this);
        const table = document.getElementById("wapperTableFormPesquisa");
        if (table) {
          table.scrollTop = 0;
        }
      });
  }

  _OnDblClick(row) {
    this.selectedRow = row;
    if (!this.muiltSelect) {
      setTimeout(() => {
        this._ok();
      }, 10);
    } else {
      if (this.selection.isSelected(row)) {
        this.selection.deselect(row);
      } else {
        this.selection.select(row);
      }
    }
  }

  selectedRow = undefined;

  highlight(row) {
    this.selectedRow = row;
  }

  _close() {
    CMFormModalComponent.close("modalFormPesquisa");
    CMFormPesquisaComponent.inPesquisa = false;
  }

  _ok() {
    if (this.valid()) {
      this._close();
      if (this.onOK) {
        if (this.muiltSelect) {
          let rows = this.selection.selected;
          this.onOK(rows);
        } else this.onOK(this.selectedRow);
      }
    }
  }

  valid() {
    return this.selectedRow !== undefined || !this.selection.isEmpty();
  }

  private filtrado: boolean = false;
  filtrar() {
    this.selectedRow = undefined;
    this.selection.clear();
    $("#bt_pesqusia")[0].focus();
    this.filtrado = true;
    this.paginator.firstPage();
    this.paginator.page.emit();
  }

  limparFiltros() {
    this.columns.forEach((item) => {
      if (item.filter) {
        if (item.value) this.valuesFilters[item.field] = item.value;
        else this.valuesFilters[item.field] = undefined;
      }
    });
    this.filtrar();
  }

  private countLastFilter = 0;
  private countFilterVisible = 0;
  getColumnsFilter(column) {
    if (column.columns !== undefined) return column.columns;
    else {
      if (
        this.countLastFilter == 0 ||
        this.countLastFilter != this.filters.length
      ) {
        this.countFilterVisible = 0;
        this.filters.forEach((filter) => {
          if (filter.filterVisible == true) {
            this.countFilterVisible = this.countFilterVisible + 1;
          }
        });
      }
      let r = Math.floor(12 / this.countFilterVisible);
      if (r < 2) r = 2;
      return r;
    }
  }

  focusFirstFilter() {
    if (this.filters.length > 0)
      $("#" + this.name_pesquisa_filter(this.filters[0])).focus();
  }

  static open(
    controllerName: string,
    caption: string,
    onOK: Function,
    columns = [],
    rowHeight: string,
    extraFilters: Object
  ) {
    CMFormPesquisaComponent.instance.open(
      controllerName,
      caption,
      onOK,
      columns,
      rowHeight,
      extraFilters
    );
  }

  checkClear(controllerName: string, columns = []) {
    let clear: boolean = false;
    if (!clear) clear = this.controllerName !== controllerName;
    if (!clear) clear = this.columns.length !== columns.length;
    if (!clear) clear = this.columns[0].field !== columns[0].field;
    if (!clear) {
      columns.forEach((item) => {
        if (item.value !== undefined) {
          if (!clear) clear = this.valuesFilters[item.field] !== item.value;
        }
      });
    }
    if (!clear) {
      if (this.extraFilters) clear = this.extraFilters !== {};
    }
    if (clear) this.clear(controllerName, columns);
  }

  clear(controllerName: string, columns = []) {
    this.filters = [];
    this.displayedColumns = [];
    if (this.muiltSelect) this.displayedColumns.push("$$select");
    this.valuesFilters = {};
    this.dataSource.data = [];
    this.selection.clear();
    columns.forEach((item) => {
      if (item.filter) this.filters.push(item);
      if (item.value) this.valuesFilters[item.field] = item.value;
      if (item.visible !== false) this.displayedColumns.push(item.field);
    });
    this.filtrado = false;
    this.controllerName = controllerName;
    this.columns = columns;
  }

  open(
    controllerName: string,
    caption: string,
    onOK: Function,
    columns = [],
    rowHeight: string,
    extraFilters: Object
  ) {
    if (rowHeight === undefined) rowHeight = "40px";
    this.rowHeight = rowHeight;
    this.extraFilters = extraFilters;
    CMFormPesquisaComponent.inPesquisa = true;
    this.caption = caption;
    this.onOK = onOK;
    CMFormModalComponent.open("modalFormPesquisa", () => {
      this.focusFirstFilter();
      if (!this.filtrado) this.filtrar();
    });
  }

  getObjectDataPesquisa(
    value: any,
    targetObj: any,
    targetProp: string,
    field: string,
    onAfterSelect: Function,
    onNotExists: Function,
    fieldInteger: boolean,
    row: any,
    extraFilters: Object,
    fieldsAlternativeWhere: Array<string>
  ) {
    if (targetObj) {
      let b: boolean = true;
      if (targetObj[targetProp]) b = targetObj[targetProp][field] !== value;
      if (b) {
        if (value !== "") {
          let _filters = [
            { field: "_Origem", value: "pesquisa" },
            { field: field, value: value },
            { extra: extraFilters },
            { fieldsalternativewhere: fieldsAlternativeWhere },
          ];
          this.filters.forEach((filter) => {
            if (filter.field.indexOf("$") > -1) {
              _filters.push({
                field: filter.field,
                value: this.valuesFilters[filter.field],
              });
            }
          });
          this.getObjectData(this.controllerName, _filters, (object) => {
            let value = this.getValueObject(object, field);
            let valid: boolean = object && value && value !== "";
            if (valid) valid = !this.isEmpty(value);
            if (valid && (field === "id" || fieldInteger))
              valid = this.valueToInteger(value, 0) > 0;
            if (valid) {
              targetObj[targetProp] = object;
              if (onAfterSelect !== undefined) onAfterSelect(object, row);
            } else {
              if (onNotExists !== undefined) onNotExists(row);
              targetObj[targetProp] = {};
            }
          });
        } else targetObj[targetProp] = {};
      }
    }
  }

  static instance: CMFormPesquisaComponent;

  static defaultPesquisa(
    _sender: any,
    _controllerName: string,
    _titlePesquisa: string,
    _columnsPesquisa = [],
    _targetObj: any,
    _targetProp?: string,
    _field?: string,
    _onAfterSelect?: Function,
    _fieldInteger?: boolean,
    _rowHeight?: string,
    _extraFilters?: Object,
    _muiltSelect?: boolean,
    _limitPesq?: number,
    _afterPostPesq?: Function,
    _onNotExists?: Function,
    _fieldsAlternativeWhere?: Array<string>
  ) {
    CMFormPesquisaComponent.defaultPesquisaOnSelect(
      _sender,
      _controllerName,
      _titlePesquisa,
      _columnsPesquisa,
      _targetObj,
      _targetProp,
      _field,
      _onAfterSelect,
      _fieldInteger,
      _rowHeight,
      _extraFilters,
      (row, targetObj, targetProp, field, onAfterSelect, fieldInteger) => {
        if (_sender === undefined) {
          let f = field.split(".");
          let f2 = f.pop();
          CMFormPesquisaComponent.instance.getObjectDataPesquisa(
            row[f2],
            targetObj,
            targetProp,
            field,
            onAfterSelect,
            _onNotExists,
            fieldInteger,
            row,
            _extraFilters,
            _fieldsAlternativeWhere
          );
        } else if (!CMFormPesquisaComponent.inPesquisa) {
          let input: CMInputComponent = <CMInputComponent>_sender;
          CMFormPesquisaComponent.instance.getObjectDataPesquisa(
            input.value,
            targetObj,
            targetProp,
            field,
            onAfterSelect,
            (object) => {
              if (_onNotExists !== undefined) _onNotExists(object);
              CMFormGlobaisComponent.toastErro(
                'Registro "' + input.value + '" não encontrado',
                input.getCaptionText()
              );
              input.value = "";
              input.focus();
            },
            fieldInteger,
            row,
            _extraFilters,
            _fieldsAlternativeWhere
          );
        }
      },
      _muiltSelect,
      _limitPesq,
      _afterPostPesq
    );
  }

  static defaultPesquisaMuiltSelect(
    controllerName: string,
    titlePesquisa: string,
    columnsPesquisa = [],
    onAfterSelect: Function,
    rowHeight?: string,
    extraFilters?: Object,
    limitPesq?: number,
    afterPostPesq?: Function
  ) {
    CMFormPesquisaComponent.defaultPesquisaOnSelect(
      undefined,
      controllerName,
      titlePesquisa,
      columnsPesquisa,
      {},
      undefined,
      undefined,
      undefined,
      undefined,
      rowHeight,
      extraFilters,
      onAfterSelect,
      true,
      limitPesq,
      afterPostPesq
    );
  }

  static defaultPesquisaSelect(
    controllerName: string,
    titlePesquisa: string,
    columnsPesquisa = [],
    onAfterSelect: Function,
    rowHeight?: string,
    extraFilters?: Object,
    limitPesq?: number,
    afterPostPesq?: Function
  ) {
    CMFormPesquisaComponent.defaultPesquisaOnSelect(
      undefined,
      controllerName,
      titlePesquisa,
      columnsPesquisa,
      {},
      undefined,
      undefined,
      undefined,
      undefined,
      rowHeight,
      extraFilters,
      onAfterSelect,
      false,
      limitPesq,
      afterPostPesq
    );
  }

  static defaultPesquisaOnSelect(
    sender: any,
    controllerName: string,
    titlePesquisa: string,
    columnsPesquisa = [],
    targetObj: any,
    targetProp?: string,
    field?: string,
    onAfterSelect?: Function,
    fieldInteger?: boolean,
    rowHeight?: string,
    extraFilters?: Object,
    onSelect?: Function,
    muiltSelect?: boolean,
    limitPesq?: number,
    afterPostPesq?: Function
  ) {
    if (muiltSelect === undefined) muiltSelect = false;
    if (limitPesq === undefined) limitPesq = 0;
    if (fieldInteger === undefined) fieldInteger = true;
    if (targetProp === undefined) targetProp = controllerName.toLowerCase();
    if (field === undefined) field = "id";
    let cl = [];
    columnsPesquisa.forEach((item) => {
      if (item.dataType === "boolean") {
        item.tipo = "select";
        item.options = [
          { text: "", value: "" },
          { text: "Sim", value: "true" },
          { text: "Não", value: "false" },
        ];
      }
      if (item.dataType === "select"){
        item.tipo = "select";
        item.options = item.filter.values;
      }
      if (item.width === undefined) item.width = "auto";
      if (item.filterVisible === undefined) item.filterVisible = true;
      if (item.upper === undefined) item.upper = false;
      if (item.lower === undefined) item.lower = false;
      if (item.tipo === undefined) item.tipo = "input";
      cl.push(item);
    });
    if (CMFormPesquisaComponent.instance.muiltSelect != muiltSelect) {
      CMFormPesquisaComponent.instance.muiltSelect = muiltSelect;
      CMFormPesquisaComponent.instance.clear(controllerName, cl);
    } else {
      CMFormPesquisaComponent.instance.muiltSelect = muiltSelect;
      CMFormPesquisaComponent.instance.checkClear(controllerName, cl);
    }
    CMFormPesquisaComponent.instance.limitPesq = limitPesq;
    CMFormPesquisaComponent.instance.afterPostPesq = afterPostPesq;
    if (sender === undefined) {
      CMFormPesquisaComponent.open(
        controllerName,
        titlePesquisa,
        (row) => {
          onSelect(
            row,
            targetObj,
            targetProp,
            field,
            onAfterSelect,
            fieldInteger
          );
        },
        cl,
        rowHeight,
        extraFilters
      );
    } else if (!CMFormPesquisaComponent.inPesquisa) {
      onSelect(
        undefined,
        targetObj,
        targetProp,
        field,
        onAfterSelect,
        fieldInteger
      );
    }
  }

  protected getKeyDownCodes() {
    let r = super.getKeyDownCodes();
    r.push(13);
    return r;
  }

  protected onKeydown(keyCode: number, event: KeyboardEvent) {
    super.onKeydown(keyCode, event);
    switch (keyCode) {
      case 13: // enter
        if (CMFormPesquisaComponent.inPesquisa) this.filtrar();
        break;
    }
  }

  name_pesquisa_filter(column) {
    let r = "pesquisa_filter_";
    r += column.field;
    return r.replace("$", "");
  }

  get limit() {
    if (this.limitPesq > 0) return this.limitPesq;
    else if (this.muiltSelect) return 1000;
    else return 8;
  }

  getInputConfig(column) {
    let r: CMInputConfig = null;
    if (column.filter) {
      if (column.filter instanceof CMInputConfig) r = column.filter;
    }
    return r;
  }

  getmaxlength(column) {
    let maxlength = 0;
    let filter = this.getInputConfig(column);
    if (filter) maxlength = filter.getMaxLength({});
    return maxlength;
  }

  getpadInfo(column) {
    let padInfo = undefined;
    let filter = this.getInputConfig(column);
    if (filter) padInfo = filter.padInfo;
    return padInfo;
  }

  getValueInPath(_column) {
    return this.valuesFilters[_column.field];
  }

  setValueInPath(column, value) {
    this.valuesFilters[column.field] = value;
  }
}
