// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormRelatComponent,
  CMInputConfig,
} from "./../../../@cmcore/component";
import { FuncsGlobais } from "../../funcs.globais";
import {
  GradeBrowseComponent,
  GrupoDeProdutoBrowseComponent,
  SubGrupoDeProdutoBrowseComponent,
  VendedorBrowseComponent,
} from "../..";

@Component({
  selector: "ranking-produtos",
  templateUrl: "ranking-produtos.component.html",
})
export class RelatRankingProdutosComponent extends CMFormRelatComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = "ImpressaoRankingProdutos";
    this.filterConfig = {
      consideraprevenda: new CMInputConfig({
        form: this,
        caption: "Considera Pré-Venda em aberto",
        disabledFunc: () => {
          if (this.filter.somenteprevenda === "Sim"){
            this.filter.consideraprevenda = "Não";
            return true;
          }
          else{
            return false;
          }

        }
      }),
      somenteprevenda: new CMInputConfig({
        form: this,
        caption: "Somente Pré-Venda em aberto",
        onSelect: (elm) => {
          console.log("onSelect");
        }
      }),
      vendedor: new CMInputConfig({
        form: this,
        caption: "Vendedor",
        pesqFunc: (sender) => {
          VendedorBrowseComponent.pesquisa(sender, this.filter);
        },
      }),
      dataini: new CMInputConfig({
        form: this,
        caption: "Data Inicial",
        mask: "99/99/9999",
      }),
      datafim: new CMInputConfig({
        form: this,
        caption: "Data Final",
        mask: "99/99/9999",
      }),
      tipoquebra1: new CMInputConfig({
        form: this,
        caption: "Quebra",
        values: [{ value: "Geral" }, { value: "Vendedor" }],
      }),
      ordem1: new CMInputConfig({
        form: this,
        caption: "Ordenar por",
        values: [
          { text: "Preço Médio Venda", value: "PrecoMedioVenda" },
          { text: "Quantidade Vendida", value: "QtdeVendida" },
          { text: "Valor Total Lucro", value: "ValorTotalLucro" },
          { text: "% Margem Lucro", value: "PercMargemLucro" },
          { text: "% MCU", value: "MCU" },
        ],
      }),
      ordem1ascdesc: new CMInputConfig({
        form: this,
        caption: "Ordenação",
        values: [
          { text: "Crescente", value: "asc" },
          { text: "Decrescente", value: "desc" },
        ],
      }),
      grupo: new CMInputConfig({
        form: this,
        caption: "Grupo",
        upper: true,
        visibleFunc: () => FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
        pesqFunc: (sender) => {
          GrupoDeProdutoBrowseComponent.pesquisa(sender, this.filter, "grupo");
        },
      }),
      subgrupo: new CMInputConfig({
        form: this,
        caption: "Sub-Grupo",
        upper: true,
        visibleFunc: () => FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
        pesqFunc: (sender) => {
          SubGrupoDeProdutoBrowseComponent.pesquisa(
            sender,
            this.filter,
            "subgrupo"
          );
        },
      }),
    };
    this.filter.dataini = this.decDate(30);
    this.filter.datafim = this.date;
    this.filter.tipoquebra1 = "Geral";
    this.filter.ordem1 = "QtdeVendida";
    this.filter.ordem1ascdesc = "desc";
    this.filter.consideraprevenda = "Não";
  }

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }
}
