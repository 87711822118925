// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormComponent } from './../../@cmcore/component/cmform/cmform.component';
import { FuncsGlobais } from './../funcs.globais';

@Component({
    templateUrl: 'home2.component.html',
    styleUrls: ['home2.component.scss']
})
export class Home2Component extends CMFormComponent {

  avisos: string = '';

  constructor(_injector: Injector) {
    super(_injector);
  }

  segundaViaBoleto() {
    window.open('https://www.sicoob.com.br/web/sicoob/segunda-via-boleto', '_blank');
  }

  ngOnInit() {
    super.ngOnInit();
    this.avisos = this.trimValue(this.currentUserStorage.avisos);
  }

  empresaDefinida() {
    return FuncsGlobais.idServerEmpresa !== "";
  }

  public get empresas() {
    return FuncsGlobais.empresas;
  }

  public get idEmpresa(): string {
    return FuncsGlobais.idServerEmpresa;
  }

  public set idEmpresa(id : string) {
    FuncsGlobais.setIdEmpresa(this.http, id, () => {
      this.avisos = this.trimValue(this.currentUserStorage.avisos);
    });
  }

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

}