// tslint:disable
import { Component, OnInit, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMValidatorEmail,
  CMFormModalComponent
} from "./../../@cmcore/component";

import { CMValidatorCNPJCPF } from "./../../@cmcore/validator";
import {
  SeguimentoBrowseComponent,
  PadraoDeOperacaoBrowseComponent,
  MunicipioBrowseComponent,
  CFOPBrowseComponent
} from "./../index";
import { FuncsGlobais } from "../funcs.globais";

@Component({
  selector: "associado-edit",
  templateUrl: "associado-edit.component.html"
})
export class AssociadoEditComponent extends CMFormEditComponent
  implements OnInit {
  getFirstComponentName() {
    return "model_cnpj";
  }

  getTitle() {
    return "Associado - " + this.fieldByName("nome");
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "associado";
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      id: new CMInputConfig({ form: this, caption: "#", disabled: true }),
      codigo: new CMInputConfig({
        form: this,
        caption: "Código",
        disabled: true
      }),
      datamaximauso: new CMInputConfig({
        form: this,
        caption: "Data máxima de uso",
        mask: "99/99/9999"
      }),
      qtdeusuariosimultaneo: new CMInputConfig({
        form: this,
        caption: "Qtde usuários simultâneos"
      }),
      cnpj: new CMInputConfig({
        form: this,
        caption: "CNPJ",
        requiredFunc: () => this.idDataModel.toString() !== "1",
        mask: "99.999.999/9999-99",
        customvalidators: [new CMValidatorCNPJCPF()]
      }),
      nome: new CMInputConfig({
        form: this,
        caption: "Razão Social",
        required: true,
        maxlength: 60
      }),
      ie: new CMInputConfig({ form: this, caption: "IE", maxlength: 100 }),
      cadastro: new CMInputConfig({
        form: this,
        caption: "Data de Cadastro",
        mask: "99/99/9999",
        required: true,
        readonly: true
      }),
      email: new CMInputConfig({
        form: this,
        caption: "e-Mail",
        maxlength: 100,
        customvalidators: [new CMValidatorEmail()]
      }),
      endereco: {
        logradouro: new CMInputConfig({
          form: this,
          caption: "Logradouro",
          required: true,
          maxlength: 60
        }),
        numero: new CMInputConfig({
          form: this,
          caption: "Número",
          required: true,
          maxlength: 10
        }),
        bairro: new CMInputConfig({
          form: this,
          caption: "Bairro",
          required: true,
          maxlength: 60
        }),
        complemento: new CMInputConfig({
          form: this,
          caption: "Complemento",
          maxlength: 60
        }),
        estado: new CMInputConfig({
          form: this,
          caption: "Estado",
          required: true,
          values: [
            { value: "AC" },
            { value: "AL" },
            { value: "AP" },
            { value: "AM" },
            { value: "BA" },
            { value: "CE" },
            { value: "DF" },
            { value: "ES" },
            { value: "GO" },
            { value: "MA" },
            { value: "MT" },
            { value: "MS" },
            { value: "MG" },
            { value: "PR" },
            { value: "PB" },
            { value: "PA" },
            { value: "PE" },
            { value: "PI" },
            { value: "RJ" },
            { value: "RN" },
            { value: "RS" },
            { value: "RO" },
            { value: "RR" },
            { value: "SC" },
            { value: "SE" },
            { value: "SP" },
            { value: "TO" }
          ]
        }),
        ibge: new CMInputConfig({
          form: this,
          caption: "Código do Município no IBGE",
          required: true,
          pesqFunc: sender => {
            MunicipioBrowseComponent.pesquisa(
              this.model.endereco.estado,
              sender,
              this.model.endereco,
              "ibge"
            );
          }
        }),
        cep: new CMInputConfig({
          form: this,
          caption: "CEP",
          required: true,
          mask: "99999-999"
        })
      },
      telefones: new CMGridEditConfig({
        form: this,
        caption: "Telefones",
        onNewItem: model => {
          Object.assign(model, { tipo: "Residencial" });
        },
        fields: {
          numero: new CMInputConfig({
            form: this,
            caption: "Telefone",
            required: true,
            gridConfig: new CMGridEditConfigColumn(),
            maskFunc: row => {
              let m = "(99)9999-9999";
              if (row.tipo === "Celular") m = "(99)99999-9999";
              return m;
            }
          }),
          tipo: new CMInputConfig({
            form: this,
            caption: "Tipo",
            gridConfig: new CMGridEditConfigColumn({
              ordem: 1
            }),
            values: [
              { value: "Residencial" },
              { value: "Comercial" },
              { value: "Celular" },
              { value: "Fax" }
            ]
          }),
          contato: new CMInputConfig({
            form: this,
            caption: "Contato",
            gridConfig: new CMGridEditConfigColumn(),
            maxlength: 60
          }),
          observacao: new CMInputConfig({
            form: this,
            caption: "Observação",
            gridConfig: new CMGridEditConfigColumn(),
            maxlength: 100
          })
        }
      }),
      usuario: {
        login: new CMInputConfig({
          form: this,
          caption: "Login",
          required: true,
          maxlength: 50
        }),
        senha: new CMInputConfig({
          form: this,
          caption: "Senha",
          required: true,
          maxlength: 15,
          criptografado: true,
          minlength: 6
        }),
        ativo: new CMInputConfig({ form: this, caption: "Ativo" })
      },

      seguimentos: new CMGridEditConfig({
        form: this,
        caption: "Seguimentos",
        fields: {
          seguimento: new CMInputConfig({
            form: this,
            required: true,
            caption: "Seguimento",
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: row => {
                return this.getValueObject(row, "seguimento.codigo");
              },
              getBottomLabelColumn: row => {
                return this.getValueObject(row, "seguimento.descricao");
              }
            }),
            pesqFunc: sender =>
              SeguimentoBrowseComponent.pesquisaSeguimento(
                sender,
                this.modelConfig.seguimentos.grid.current
              )
          })
        }
      })
    };
  }

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }
}
