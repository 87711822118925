// tslint:disable
import {Injector, Component} from '@angular/core';

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMFormModalComponent
} from './../../@cmcore/component';
import {
  NFeEditComponent,
  NFeAcoesComponent,
  ClienteBrowseComponent,
  VendedorBrowseComponent,
  FormaPagamentoBrowseComponent,
  NFeRelatoriosComponent
} from './../index';

@Component({
  selector: 'nfe-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class NFeBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'nfe';
    this.formEditComponent = NFeEditComponent;
    this.buttonsExtra = 2;
    this.columns = [
      {
        field: 'nnf',
        caption: '#',
        filter: new CMInputConfig({
          dataType: 'integer'
        })
      },
      {
        field: 'dhemi',
        caption: 'Data',
        filter: new CMInputConfig({
          mask: '99/99/9999'
        })
      },
      {
        field: 'pessoa_nome',
        caption: 'Cliente',
        filterField: 'cliente.id',
        filterValue: 'cliente.codigo',
        fieldBottom: 'cliente.nome',
        filter: new CMInputConfig({
          dataType: 'integer',
          pesqFunc: (sender) => {
            ClienteBrowseComponent.pesquisa(sender, this.valuesFilters);
          }
        })
      },
      {field: 'statusstr', caption: 'Status'},
      {
        field: 'vendedor_nome',
        caption: 'Vendedor',
        filterField: 'vendedor.id',
        filterValue: 'vendedor.codigo',
        fieldBottom: 'vendedor.nome',
        filter: new CMInputConfig({
          dataType: 'integer',
          pesqFunc: (sender) => {
            VendedorBrowseComponent.pesquisa(sender, this.valuesFilters);
          }
        })
      },
      {
        field: 'formapag_descricao',
        caption: 'Forma de Pagamento',
        filterField: 'formapagamento.id',
        fieldBottom: 'formapagamento.descricao',
        filter: new CMInputConfig({
          dataType: 'integer',
          pesqFunc: (sender) => {
            FormaPagamentoBrowseComponent.pesquisa("todos", sender, this.valuesFilters);
          }
        })
      }
    ];
    if (NFeRelatoriosComponent.getRelatorioCustomizados().length === 0){
      this.getRelatoriosCustomizados();
    }
    console.log(NFeRelatoriosComponent.getRelatorioCustomizados());
  }

  
  _print() {
    console.log('method override')
    if (this.diretoImprimir) this.print();
  }
  
  print() {
    console.log('method overriden');
    if (NFeRelatoriosComponent.getRelatorioCustomizados().length > 0){
      this.openFormRelatoriosNFe();
    }
    else{
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat("Print", data, this.controllerName);
    }
  }

  
  getRelatoriosCustomizados(){
    this.postSubscribeT<Array<Object>>(
      "/api/nfe/GetRelatoriosCustomizados",
      JSON.stringify({}),
      (result) => {
        result.forEach(element => {
          if (element['tipo'] === 'NFe'){
            NFeRelatoriosComponent.addRelatorioCustomizado({value: element['id'], text: element['descricao']});
          }
        });
      },
    );
  }
  
  openFormRelatoriosNFe() {
    this.beforeComponent = NFeRelatoriosComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formRelatoriosNFe");
    }, 100);
  }

  buttonsExtraClick(index: number) {
    if (index === 0) {
      if (this.grid.fieldByName('impostocalculado') === 'Sim')
        this.openFormAcoesNFe(this.grid.fieldByName('id'));
      else
        CMFormGlobaisComponent.showMessageAviso(
          'Calcule os impostos da NF-e antes de abrir o gerenciamento'
        );
    } 
    else if (index === 1) {
      let data = { id: this.grid.fieldByName(this.fieldNameId()), origem: 'NF-e' };
      data = this.getDataPrint(data);
      this.showRelat(
        "/ImprimirBoleto/FuncaoExtra",
        data,
        this.controllerName
      );
    }
  }

  idFormAcoesNFe: number = 0;
  openFormAcoesNFe(id) {
    this.idFormAcoesNFe = id;
    this.beforeComponent = NFeAcoesComponent;
    setTimeout(() => {
      CMFormModalComponent.open('formAcoesNFe');
    }, 100);
  }

  loadDireitoButtonExtra(index: number) {
    if (index === 0) {
      this.getDireitosMenuBrowseButtonsExtra(index, 'GetDadosAcoesNFe');
    } else if (index === 1) {
      this.getDireitosMenuBrowseButtonsExtra(index, "ImprimirBoleto");
    }
  }

  buttonsExtraVisible(index: number) {
    let r: boolean = super.buttonsExtraVisible(index);
    if (r) {
      let isCurrent: boolean = false;
      if (this.grid) {
        isCurrent = this.grid.isCurrent;
      }
      if (index === 0 || index === 1) r = isCurrent;
    }
    return r;
  }

  buttonsExtraIcon(index: number) {
    let r: string = '';
    if (index === 0) r = 'build';
    if (index === 1) r = 'view_column';
    return r;
  }

  buttonsExtraHint(index: number) {
    let r: string = '';
    if (index === 0) r = 'Gerenciamento';
    if (index === 1) r = 'Imprimir Boleto';
    return r;
  }

  getTitle() {
    return 'Nota Fiscal Eletrônica';
  }

  static pesquisa(
    sender: any,
    target: any,
    targetProp?: string,
    onAfterSelect?: Function,
    idCliente?: number,
    tipoPagamento?: string
  ) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'nfe',
        'Pesquisa de NF-e',
        [
          {field: 'nnf', caption: '#'},
          {field: 'pessoa_nome', caption: 'Cliente', filter: true},
          {field: 'dhemi', caption: 'Data', filter: true, mask: '99/99/9999'}
        ],
        target,
        targetProp,
        'infnfe.ide.nnf',
        onAfterSelect,
        undefined,
        undefined,
        {
          idcliente: idCliente,
          tipopagamento: tipoPagamento,
          status: 'Autorizado'
        }
      );
    }
  }
}
