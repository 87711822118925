// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent, CMInputConfig } from './../../../@cmcore/component';
import { GrupoUsuarioEditComponent } from './grupo-usuario-edit.component';

@Component({
  selector: 'grupo-usuario-browse',
  templateUrl: './../../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class GrupoUsuarioBrowseComponent extends CMFormBrowse2Component {

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'grupousuario';
    this.formEditComponent = GrupoUsuarioEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = [{field: 'id', caption: '#', visible: false},
                    {field: 'nome',  caption: 'Nome',
                     filter: new CMInputConfig({
                       maxlength: 255
                     })
                    },
                    {field: 'ativo', caption: 'Ativo', width: 100}
                   ];
  }

  getTitle() {
    return 'Grupo de Usuário';
  }

  static pesquisa(sender: any, target: any, targetProp?: string) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'grupousuario',
        'Pesquisa de Grupo de Usuário',
        [{field: 'id',    caption: '#', visible: false},
         {field: 'nome',  caption: 'Nome', filter: true},
         {field: 'ativo', visible: false, value: 'Sim'}],
        target,
        targetProp);
    }
  }

}