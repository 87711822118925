// tslint:disable

//
// Code originally taken from http://stackoverflow.com/a/36325468/48651
//

// Imports
import {
  Component,
  ComponentRef,
  Input,
  ViewContainerRef,
  ComponentFactoryResolver,
  ViewChild,
  OnChanges,
  OnInit,
  OnDestroy,
} from '@angular/core';

//
// Dynamic component loader component
//
@Component({
  selector: 'cmdynamic-creator-component',
  template: `
    <div #dynamicTarget></div>
  `,
})
export class CMDynamicCreatorComponent implements OnChanges, OnInit, OnDestroy {
  // Private properties
  public componentReference: ComponentRef<any>;
  private isViewInitialized = false;

  // Component input
  @ViewChild('dynamicTarget', {read: ViewContainerRef, static: true})
  private dynamicTarget: any;

  private _componentType: any = undefined;
  @Input()
  get componentType() {
    return this._componentType;
  }
  set componentType(value) {
    if (this._componentType !== value) {
      if (this._componentType !== undefined) {
        this.componentReferenceDestroy();
      }
      this._componentType = value;
      if (this._componentType !== undefined) { 
        this.updateComponent();  
      }
    }
  }

  @Input()
  private owner: any = undefined;

  @Input()
  private componentCreatedCallback: (component: Component) => void;

  @Input()
  private componentDestroyedCallback: () => void;

  //
  // Constructor
  //
  constructor(private resolver: ComponentFactoryResolver) {}

  //
  // Called when the view changes
  //
  ngOnChanges() {
    this.updateComponent();
  }

  //
  // Called after the view has been initialised
  //
  ngOnInit() {
    this.isViewInitialized = true;
    this.updateComponent();
  }

  //
  // Called when we need to destroy the view
  //
  ngOnDestroy() {
    this.componentReferenceDestroy();
  }

  componentReferenceDestroy() {
    if (this.componentReference) {
      this.componentReference['_component']['owner'] = undefined;
      this.componentReference.destroy();
      // Call the callback if needed
      if (
        this.componentDestroyedCallback !== null &&
        this.componentDestroyedCallback !== undefined
      ) {
        this.componentDestroyedCallback();
      }
      this.componentReference = undefined;
    }
  }

  //
  // Updates the view with the dynamically loaded component
  //
  private updateComponent() {
    if (!this.isViewInitialized || this.componentType === undefined) {
      return;
    }

    this.componentReferenceDestroy();

    // Create an instance of this component
    let componentFactory = this.resolver.resolveComponentFactory(this.componentType);
    this.componentReference = this.dynamicTarget.createComponent(componentFactory);
    if (this.owner !== undefined) this.componentReference['_component']['owner'] = this.owner;

    // If we need to, pass through the component we created
    if (this.componentCreatedCallback !== null && this.componentCreatedCallback !== undefined) {
      this.componentCreatedCallback(this.componentReference.instance);
    }
  }
}
