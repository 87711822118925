// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormModalComponent,
  CMFormPesquisaComponent,
  CMInputConfig,
} from "./../../@cmcore/component";
import { CupomDescontoEditComponent } from "./cupom-desconto-edit.component";
import { ClienteBrowseComponent } from "./../cliente/cliente-browse.component";
import { CupomDescontoRelatoriosComponent } from "../cupom-desconto-relatorios/cupom-desconto-relatorios.component";

@Component({
  selector: "cupom-desconto-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class CupomDescontoBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "CupomDesconto";
    this.formEditComponent = CupomDescontoEditComponent;
    this.fixedFooterFormEdit = false;
    this.buttonsExtra = 1;
    this.showPrintButton = true;
    this.columns = [
      {
        field: "codigo",
        caption: "Código",
        width: 100,
        filter: new CMInputConfig({
          maxlength: 20,
        }),
      },
      {
        field: "datacadastro",
        caption: "Cadastro",
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {
        field: "datavalidade",
        caption: "Data Validade",
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {
        field: "valor",
        caption: "Valor",
        filter: new CMInputConfig({
          dataType: "double(2)",
        }),
      },
      {
        field: "cliente_nome",
        caption: "Cliente",
        filterField: "cliente.id",
        filterValue: "cliente.codigo",
        fieldBottom: "cliente.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            ClienteBrowseComponent.pesquisa(sender, this.valuesFilters);
          },
        }),
      },
      {
        field: "datautilizado",
        caption: "Data Utilizado",
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {
        field: "tipo",
        caption: "Tipo",
        filter: new CMInputConfig({
          values: [
            { value: "Desconto" },
            { value: "ValePresente", text: "Vale-Presente" },
            { value: "TrocaProduto", text: "Troca de Produto" },
            { value: "Outros" },
          ],
        }),
      },
      { field: "nnf", caption: "Doc Troca" },
      {
        field: "utilizado",
        caption: "Utilizado",
        dataType: "boolean",
      },
    ];
    if (CupomDescontoRelatoriosComponent.getRelatorioCustomizados().length === 0){
      this.getRelatoriosCustomizados();
    }
    console.log(CupomDescontoRelatoriosComponent.getRelatorioCustomizados());
  }

  
  _print() {
    console.log('method override')
    if (this.diretoImprimir) this.print();
  }
  
  print() {
    console.log('method overriden');
    if (CupomDescontoRelatoriosComponent.getRelatorioCustomizados().length > 0){
      this.openFormRelatoriosCupomDesconto();
    }
    else{
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat("Print", data, this.controllerName);
    }
  }

  
  getRelatoriosCustomizados(){
    this.postSubscribeT<Array<Object>>(
      "/api/cupomdesconto/GetRelatoriosCustomizados",
      JSON.stringify({}),
      (result) => {
        result.forEach(element => {
          if (element['tipo'] === 'CupomDesconto'){
            CupomDescontoRelatoriosComponent.addRelatorioCustomizado({value: element['id'], text: element['descricao']});
          }
        });
      },
    );
  }
  
  openFormRelatoriosCupomDesconto() {
    this.beforeComponent = CupomDescontoRelatoriosComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formRelatoriosCupomDesconto");
    }, 100);
  }

  getTitle() {
    return "Cupom de Desconto";
  }

  static pesquisa(
    ativos: string,
    sender: any,
    target: any,
    targetProp?: string,
    onAfterSelect?: Function
  ) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "cupomdesconto",
        "Pesquisa de Cupom de Desconto",
        [
          { field: "codigo", caption: "Código" },
          {
            field: "datacadastro",
            caption: "Cadastro",
            filter: true,
            mask: "99/99/9999",
          },
          {
            field: "datavalidade",
            caption: "Validade",
            filter: true,
            mask: "99/99/9999",
          },
          { field: "cliente_nome", caption: "Cliente", filter: true },
          { field: "valor", caption: "Valor", filter: true },
          {
            field: "utilizado",
            caption: "Utilizado",
            value: ativos,
            filter: true,
            visible: false,
            filterVisible: false,
          },
        ],
        target,
        targetProp,
        "codigo",
        onAfterSelect,
        false
      );
    }
  }

  getDataPrint(data) {
    data.numero = this.grid.fieldByName("id");
    return data;
  }

  loadDireitoButtonExtra(index: number) {
    if (index === 0) {
      this.getDireitosMenuBrowseButtonsExtra(index, "ImpressaoCupomReduzido");
    }
  }

  buttonsExtraVisible(index: number) {
    let r: boolean = super.buttonsExtraVisible(index);
    if (r) {
      let isCurrent: boolean = false;
      if (this.grid) {
        isCurrent = this.grid.isCurrent;
      }
      if (index === 0) r = isCurrent;
    }
    return r;
  }

  buttonsExtraIcon(index: number) {
    let r: string = "";
    if (index === 0) r = "receipt";
    return r;
  }

  buttonsExtraHint(index: number) {
    let r: string = "";
    if (index === 0) r = "Imprimir Reduzido";
    return r;
  }

  buttonsExtraClick(index: number) {
    if (index === 0) {
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat("/ImpressaoCupom/FuncaoExtra", data, this.controllerName);
    }
  }
}
