// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
  CMFormGlobaisComponent,
} from "./../../@cmcore/component";
import {
  CFOPBrowseComponent,
  GrupoDeOperacaoBrowseComponent,
} from "./../index";

@Component({
  selector: "padraodeoperacao-edit",
  templateUrl: "padraodeoperacao-edit.component.html",
})
export class PadraoDeOperacaoEditComponent extends CMFormEditComponent {
  static CST_ICMS = [
    { value: "0", text: "00" },
    { value: "10" },
    { value: "20" },
    { value: "30" },
    { value: "40" },
    { value: "41" },
    { value: "50" },
    { value: "51" },
    { value: "60" },
    { value: "70" },
    { value: "90" },
  ];

  static CST_PIS_COFINS = [
    { value: "0", text: "00" },
    { value: "1", text: "01" },
    { value: "2", text: "02" },
    { value: "3", text: "03" },
    { value: "4", text: "04" },
    { value: "5", text: "05" },
    { value: "6", text: "06" },
    { value: "7", text: "07" },
    { value: "8", text: "08" },
    { value: "9", text: "09" },
    { value: "49" },
    { value: "50" },
    { value: "51" },
    { value: "52" },
    { value: "53" },
    { value: "54" },
    { value: "55" },
    { value: "56" },
    { value: "60" },
    { value: "61" },
    { value: "62" },
    { value: "63" },
    { value: "64" },
    { value: "65" },
    { value: "66" },
    { value: "67" },
    { value: "70" },
    { value: "71" },
    { value: "72" },
    { value: "73" },
    { value: "74" },
    { value: "75" },
    { value: "98" },
    { value: "99" },
  ];
  static CSOSN = [
    { value: "101" },
    { value: "102" },
    { value: "103" },
    { value: "201" },
    { value: "202" },
    { value: "203" },
    { value: "300" },
    { value: "400" },
    { value: "500" },
    { value: "900" },
  ];

  getFirstComponentName() {
    return "model_nome";
  }

  getTitle() {
    return "Padrão de Operação";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "padraodeoperacao";
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      codigo: new CMInputConfig({ form: this, caption: "#", disabled: true }),
      nome: new CMInputConfig({
        form: this,
        caption: "Nome",
        maxlength: 200,
        required: true,
      }),
      grupos: new CMGridEditConfig({
        form: this,
        required: true,
        customValidItem: (item) => {
          return this.validItem(item);
        },
        fields: {
          grupooperacao: new CMInputConfig({
            form: this,
            caption: "Grupo",
            required: true,
            upper: true,
            gridConfig: new CMGridEditConfigColumn({
              ordem: 1,
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "grupooperacao.codigo");
              },
              getBottomLabelColumn: (row) => {
                return this.getValueObject(row, "grupooperacao.descricao");
              },
            }),
            pesqFunc: (sender) => {
              GrupoDeOperacaoBrowseComponent.pesquisa(
                sender,
                this.modelConfig.grupos.current,
                "grupooperacao"
              );
            },
          }),
          operacoes: new CMGridEditConfig({
            form: this,
            captionFunc: (row) =>
              "Operações do grupo - " +
              this.getValueObject(
                this.modelConfig.grupos.current,
                "grupooperacao.codigo"
              ),
            onNewItem: (model) => {
              Object.assign(model, {
                cst: "0",
                cstpis: "99",
                cstcofins: "99",
                iva: "0,00",
                reducao: "0,00",
                aliqicmsst: "0,00",
                estado: "",
              });
            },
            required: true,
            moreActions: [
              new CMGridEditConfigMore({
                hint: "Mais detalhes",
                icone: () => {
                  if (this.disabledAll) return "visibility";
                  else return "edit";
                },
              }),
            ],
            fields: {
              cfop: new CMInputConfig({
                form: this,
                caption: "CFOP",
                mask: "9.999",
                required: true,
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 2,
                  tipo: "input-pesquisa",
                  width: "300px",
                  getValue: (row) => {
                    return this.getValueObject(row, "cfop.codigo");
                  },
                  getBottomLabelColumn: (row) => {
                    return this.getValueObject(row, "cfop.descricao");
                  },
                }),
                pesqFunc: (sender) => {
                  CFOPBrowseComponent.pesquisa(
                    sender,
                    this.modelConfig.grupos.fields.operacoes.current,
                    "cfop"
                  );
                },
              }),
              cst: new CMInputConfig({
                form: this,
                caption: "CST",
                dataType: "integer",
                disabled: true,
                values: PadraoDeOperacaoEditComponent.CST_ICMS,
              }),
              csosn: new CMInputConfig({
                form: this,
                caption: "CSOSN",
                dataType: "integer",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 3,
                }),
                values: PadraoDeOperacaoEditComponent.CSOSN,
              }),
              cstpis: new CMInputConfig({
                form: this,
                caption: "CST PIS",
                dataType: "integer",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 4,
                }),
                values: PadraoDeOperacaoEditComponent.CST_PIS_COFINS,
              }),
              cstcofins: new CMInputConfig({
                form: this,
                caption: "CST COFINS",
                dataType: "integer",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 5,
                }),
                values: PadraoDeOperacaoEditComponent.CST_PIS_COFINS,
              }),
              iva: new CMInputConfig({
                form: this,
                caption: "IVA",
                dataType: "double(2)",
              }),
              reducao: new CMInputConfig({
                form: this,
                caption: "Redução ICMS ST",
                dataType: "double(2)",
              }),
              aliqicmsst: new CMInputConfig({
                form: this,
                caption: "Aliq. ICMS ST",
                dataType: "double(2)",
              }),
              estado: new CMInputConfig({
                form: this,
                caption: "Estado",
                required: true,
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 1,
                }),
                values: [
                  { value: "AC" },
                  { value: "AL" },
                  { value: "AP" },
                  { value: "AM" },
                  { value: "BA" },
                  { value: "CE" },
                  { value: "DF" },
                  { value: "ES" },
                  { value: "GO" },
                  { value: "MA" },
                  { value: "MT" },
                  { value: "MS" },
                  { value: "MG" },
                  { value: "PR" },
                  { value: "PB" },
                  { value: "PA" },
                  { value: "PE" },
                  { value: "PI" },
                  { value: "RJ" },
                  { value: "RN" },
                  { value: "RS" },
                  { value: "RO" },
                  { value: "RR" },
                  { value: "SC" },
                  { value: "SE" },
                  { value: "SP" },
                  { value: "TO" },
                ],
              }),
              obssimples: new CMInputConfig({
                form: this,
                caption: "Observação simples",
              }),
            },
          }),
        },
      }),
    };
  }

  validItem(item: any) {

    let r: String = "";

    let hash = {};
    item.operacoes.forEach(function(item) {
      if (hash[item]) {
        r = "Já existe um cadastro de estado para esse mesmo grupo de operação";
      }
      hash[item] = true;
    });
    return r;

  }

  showInfo() {
    let msg = '<div style="padding-left: 20px;">';
    msg += "<p><strong>CSOSN</strong></p>";
    msg += "<p>";
    msg += "101- Tributada pelo Simples Nacional com permissão de crédito.<BR>";
    msg +=
      "CALCULAR crédito de icms na observação de acordo com aliquota no Parâmetro.<BR>";
    msg += "NÃO permite substituição tributária.<BR>";
    msg += "</p>";

    msg += "<p>";
    msg += "102- Tributada pelo Simples Nacional sem permissão de crédito.<BR>";
    msg +=
      "NÃO calcular crédito de icms na observação de acordo com aliquota no Parâmetro.<BR>";
    msg += "NÃO permite substituição tributária.<BR>";
    msg += "</p>";

    msg += "<p>";
    msg +=
      "103 – Isenção do ICMS no Simples Nacional para faixa de receita bruta.<BR>";
    msg +=
      "NÃO calcular crédito de icms na observação de acordo com aliquota no Parâmetro.<BR>";
    msg += "NÃO permite substituição tributária<BR>";
    msg += "</p>";

    msg += "<p>";
    msg +=
      "201- Tributada pelo Simples Nacional com permissão de crédito e com cobrança do ICMS por Substituição Tributária<BR>";
    msg +=
      "CALCULAR crédito de icms na observação de acordo com aliquota no Parâmetro.<BR>";
    msg += "DEVE TER Substituição Tributária<BR>";
    msg += "</p>";

    msg += "<p>";
    msg +=
      "202- Tributada pelo Simples Nacional sem permissão de crédito e com cobrança do ICMS por Substituição Tributária<BR>";
    msg +=
      "NÃO calcular crédito de icms na observação de acordo com aliquota no Parâmetro.<BR>";
    msg += "DEVE TER Substituição Tributária<BR>";
    msg += "</p>";

    msg += "<p>";
    msg +=
      "203- Isenção do ICMS nos Simples Nacional para faixa de receita bruta e com cobrança do ICMS por Substituição Tributária<BR>";
    msg +=
      "NÃO calcular crédito de icms na observação de acordo com aliquota no Parâmetro.<BR>";
    msg += "DEVE TER Substituição Tributária<BR>";
    msg += "</p>";

    msg += "<p>";
    msg += "300 – Imune.<BR>";
    msg +=
      "NÃO calcular crédito de icms na observação de acordo com aliquota no Parâmetro.<BR>";
    msg += "NÃO permite substituição tributária<BR>";
    msg += "</p>";

    msg += "<p>";
    msg += "400 – Não tributada pelo Simples Nacional<BR>";
    msg +=
      "NÃO calcular crédito de icms na observação de acordo com aliquota no Parâmetro.<BR>";
    msg += "NÃO permite substituição tributária<BR>";
    msg += "</p>";

    msg += "<p>";
    msg +=
      "500 – ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação<BR>";
    msg +=
      "NÃO calcular crédito de icms na observação de acordo com aliquota no Parâmetro.<BR>";
    msg += "NÃO permite substituição tributária<BR>";
    msg += "</p>";

    msg += "<p>";
    msg += "900 - Outros<BR>";
    msg +=
      "NÃO calcular crédito de icms na observação de acordo com aliquota no Parâmetro.<BR>";
    msg += "PODE TER Substituição Tributária<BR>";
    msg += "</p>";

    msg += "</div>";

    CMFormGlobaisComponent.showMessage("Informações", msg);
  }
}
