// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormEditComponent, CMInputConfig
       } from './../../@cmcore/component';

@Component({
    selector: 'municipio-edit',
    templateUrl: 'municipio-edit.component.html',
})
export class MunicipioEditComponent extends CMFormEditComponent {

  getFirstComponentName() {
    return 'model_codigomunicipio';
  }

  getTitle() {
    return 'Município';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'municipio';
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      codigomunicipio: new CMInputConfig({form: this,
                                          caption: 'Código do Município no IBGE',
                                          dataType: 'integer',
                                          required: true}),
      estado: new CMInputConfig({form: this,
                                 caption: 'Estado',
                                 required: true,
                                 maxlength: 2,
                                 values: [{'value': "AC"},
                                          {'value': "AL"},
                                          {'value': "AP"},
                                          {'value': "AM"},
                                          {'value': "BA"},
                                          {'value': "CE"},
                                          {'value': "DF"},
                                          {'value': "ES"},
                                          {'value': "GO"},
                                          {'value': "MA"},
                                          {'value': "MT"},
                                          {'value': "MS"},
                                          {'value': "MG"},
                                          {'value': "PR"},
                                          {'value': "PB"},
                                          {'value': "PA"},
                                          {'value': "PE"},
                                          {'value': "PI"},
                                          {'value': "RJ"},
                                          {'value': "RN"},
                                          {'value': "RS"},
                                          {'value': "RO"},
                                          {'value': "RR"},
                                          {'value': "SC"},
                                          {'value': "SE"},
                                          {'value': "SP"},
                                          {'value': "TO"}]}),
      municipio: new CMInputConfig({form: this,
                                    caption: 'Município',
                                    required: true,
                                    maxlength: 200})
    };
  }

}