// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormGlobaisComponent, CMFormRelatComponent, CMInputConfig
       } from './../../../@cmcore/component';

@Component({
    selector: 'dre-liquidacao-vertical',
    templateUrl: 'dre-liquidacao-vertical.component.html'
})
export class RelatDRELiquidacaoVerticalComponent extends CMFormRelatComponent {

  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = 'ImpressaoDRELiquidacaoVertical';
    this.filterConfig = {
      dataini: new CMInputConfig({
        form: this,
        caption: "Data Inicial",
        mask: "99/99/9999",
      }),
      datafim: new CMInputConfig({
        form: this,
        caption: "Data Final",
        mask: "99/99/9999",
      }),
    };
    this.filter.dataini = CMFormRelatComponent.firstDate();
    this.filter.datafim = CMFormRelatComponent.lastDate();
  }

  /*
  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.dataini);
      if (!r) CMFormGlobaisComponent.toastErro("Data inicial não informada");
    }
    if (r) {
      r = !this.isEmpty(this.filter.datafim);
      if (!r) CMFormGlobaisComponent.toastErro("Data final não informada");
    }
    return r;
  }*/

}