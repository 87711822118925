// tslint:disable
import { Component, Input, ViewChildren, QueryList, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms'

import { CMInput } from '../../decorator';
import { CMCheckBoxComponent, CMInputComponent } from '../../component';
import { CMFormComponent } from '../../component/cmform/cmform.component';

export class CMListCheckBoxConfig {
  public required: boolean = true;
  public caption: string = '';
  public form: CMFormComponent = null;
  public list = [];
  public listFunc: Function = undefined;
  public propId: string = 'id';
  public propValue: string = '';
  public subProp: string = '';
  public columns: number = 3;
  public onSelect: Function = undefined;
  public onUnSelect: Function = undefined;
  public nodeList: CMListCheckBoxConfig = undefined;
  public visibleFunc: Function = undefined;
  public onGetValue: Function = undefined;
  public onSetValue: Function = undefined;
  public showBorder: boolean = true;
  public striping: boolean = false;
  public listCheckBox: CMListCheckBoxComponent = null;

  private InnerDisabled: boolean = false;
  get disabled() {
    let r: boolean = this.InnerDisabled;
    if (!r) {
      if (this.form)
      {
        if (this.form.disabledAll !== undefined)
          r = this.form.disabledAll;
      }
    }
    return r;
  }
  set disabled(value: boolean) {
    this.InnerDisabled = value;
  }

  isSelected(item) {
    if (this.listCheckBox)
      return this.listCheckBox.isSelected(item);
    else
      return '';
  }

  constructor (data?: any) {
    if (data) {
      if (data.form !== undefined)
        this.form = data.form;
      if (data.required !== undefined)
        this.required = data.required;
      if (data.caption !== undefined)
        this.caption = data.caption;
      if (data.disabled !== undefined)
        this.disabled = data.disabled;
      if (data.list !== undefined)
        this.list = data.list;
      if (data.listFunc !== undefined)
        this.listFunc = data.listFunc;
      if (data.propId !== undefined)
        this.propId = data.propId;
      if (data.propValue !== undefined)
        this.propValue = data.propValue;
      if (data.subProp !== undefined)
        this.subProp = data.subProp;
      if (data.columns !== undefined)
        this.columns = data.columns;
      if (data.onSelect !== undefined)
        this.onSelect = data.onSelect;
      if (data.onUnSelect !== undefined)
        this.onUnSelect = data.onUnSelect;
      if (data.nodeList !== undefined)
        this.nodeList = data.nodeList;
      if (data.visibleFunc !== undefined)
        this.visibleFunc = data.visibleFunc;
      if (data.onGetValue !== undefined)
        this.onGetValue = data.onGetValue;
      if (data.onSetValue !== undefined)
        this.onSetValue = data.onSetValue;
      if (data.showBorder !== undefined)
        this.showBorder = data.showBorder;
      if (data.striping !== undefined)
        this.striping = data.striping;
    }
  }

}

export const CMLISTCHECKBOX_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CMListCheckBoxComponent),
    multi: true
};

@Component({
  selector: 'cmlistcheckbox',
  templateUrl: './cmlistcheckbox.component.html',
  styleUrls: ['./cmlistcheckbox.component.scss'],
  providers: [CMLISTCHECKBOX_CONTROL_VALUE_ACCESSOR],
})
export class CMListCheckBoxComponent implements ControlValueAccessor {

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  private _listConfig: CMListCheckBoxConfig = null;
  @Input()
  get listConfig() {
    return this._listConfig;
  };
  set listConfig(value) {
    if (this._listConfig)
      this._listConfig.listCheckBox = null;
    this._listConfig = value;
    if (this._listConfig)
      this._listConfig.listCheckBox = this;
  };

  @ViewChildren(CMCheckBoxComponent)
  items: QueryList<CMCheckBoxComponent>;

  @ViewChildren(CMListCheckBoxComponent)
  nodes: QueryList<CMListCheckBoxComponent>;

  @Input()
  parentList: CMListCheckBoxConfig = null;

  @Input()
  nodeItem: any;
  nodeValue: any;

  innerList = [];
  @Input()
  get list() {
    if (this.listConfig) {
      if (this.listConfig.listFunc !== undefined)
        return this.listConfig.listFunc(this.nodeItem, this.nodeValue);
      else
        return this.listConfig.list;
    }
    else
      return this.innerList;
  }
  set list(value: any) {
    if (this.listConfig) {
      if (this.listConfig.listFunc === undefined)
        this.listConfig.list = value;
    }
    else
      this.innerList = value;
  }
  @Input() propId: string = 'id';
  @Input() propValue: string = '';
  @Input() subProp: string = '';
  @Input() columns: number = 3;
  @Input()
  @CMInput()
  disabled: boolean = false;
  getDisabled() {
    if (this.listConfig)
      return this.listConfig.disabled;
    else
      return this.disabled;
  }

  _innerValue: any;

  get value() {
    if ((this.listConfig) && (this.listConfig.onGetValue !== undefined))
      return this.listConfig.onGetValue(this.nodeItem, this.nodeValue);
    else
      return this._innerValue;
  }
  @Input()
  set value(value: any) {
    if ((this.listConfig) && (this.listConfig.onSetValue !== undefined))
      this.listConfig.onSetValue(this.nodeItem, this.nodeValue, value);
    else
      this._innerValue = value;
  }

  isSelected(item) {
    let v = undefined;
    if (this.value !== undefined) {
      for (let i = 0; i < this.value.length; i++) {
        if (this.getSubPropId(this.value[i]) == this.getId(item)) {
          v = this.value[i];
          break;
        }
      }
      this.checkNodeValues(item, v);
      if (v !== undefined)
        return 'Sim';
      else
        return 'Não';
    }
    else
      return '';
  }

  indexItem(value: any) {
    let i = -1;
    if (this.value) {
      this.value.forEach(item => {
        if (this.getSubPropId(item) === value) {
          i = this.value.indexOf(item);
        }
      });
    }
    return i;
  }

  selectItem(_item, value) {
    let i = this.indexItem(this.getId(_item));
    const v = (value+"").toLowerCase();
    if (v !== '') {
      let item: any;
      if (this.subProp === '')
        item = _item;
      else {
        item = {};
        item[this.subProp] = _item;
      }
      if (v === 'Sim'.toLowerCase()) {
        if (i === -1) {
          this.value.push(item);
          if (this.listConfig !== null) {
            if (this.listConfig.onSelect !== undefined)
              this.listConfig.onSelect(item);
          }
          this.checkNodeValues(_item, item);
        }
      }
      else {
        if (i === -1){
          this.value.splice(i, 1);
          if (this.listConfig !== null) {
            if (this.listConfig.onUnSelect !== undefined)
              this.listConfig.onUnSelect(item);
          }
        }
        this.checkNodeValues(_item, undefined);
      }
    }
  }

  checkNodeValues(nodeItem, nodeValue) {
    if (this.nodes) {
      this.nodes.forEach(n => {
        n.checkNodeValue(nodeItem, nodeValue);
      })
    }
  }

  checkNodeValue(nodeItem, nodeValue) {
    if (this.nodeItem == nodeItem)
      this.nodeValue = nodeValue;
  }

  getCaption(item: any) {
    if ((this.propValue !== '') && (item[this.propValue]))
      return item[this.propValue];
    else
      return item;
  }

  getId(item: any) {
    if (item && item[this.propId])
      return item[this.propId];
    else
      return item;
  }

  getSubPropId(item: any) {
    if (item && item[this.subProp])
      return this.getId(item[this.subProp]);
    else
      return this.getId(item);
  }

  getColumns() {
    return Math.floor(12 / this.columns);
  }

  ngOnInit() {
    if (this.listConfig !== null) {
      this.disabled = this.listConfig.disabled;
      this.propId = this.listConfig.propId;
      this.propValue = this.listConfig.propValue;
      this.subProp = this.listConfig.subProp;
      this.columns = this.listConfig.columns;
    }
  }

  get nodeList() {
    let r: CMListCheckBoxConfig = undefined;
    if (this.listConfig !== null)
      r = this.listConfig.nodeList;
    return r;
  }

  get visible() {
    let r = true;
    if ((this.listConfig !== null) && (this.listConfig.visibleFunc !== undefined))
      r = this.listConfig.visibleFunc(this.nodeItem, this.nodeValue);
    return r;
  }

  get showBorder() {
    let r = true;
    if (this.listConfig !== null)
      r = this.listConfig.showBorder;
    return r;
  }

  get striping() {
    let r = false;
    if (this.listConfig !== null)
      r = this.listConfig.striping;
    return r;
  }

}