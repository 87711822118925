// tslint:disable
import { Component, Input, EventEmitter, Output } from "@angular/core";

import { CMInput } from "../../../decorator";

@Component({
  selector: "cmform-buttons-edit",
  templateUrl: "./cmform-buttons-edit.component.html",
  styleUrls: ["./cmform-buttons-edit.component.scss"],
})
export class CMFormButtonsEditComponent {
  @Input()
  @CMInput()
  showButtonEdit: boolean = true;
  @Input()
  @CMInput()
  showButtonCancel: boolean = true;
  @Input()
  @CMInput()
  showButtonExtra: boolean = false;
  @Input()
  @CMInput()
  hintButtonExtra: string = "";
  @Input()
  @CMInput()
  iconButtonExtra: string = "";

  @Input()
  @CMInput()
  leftText: string = "";

  @Output()
  clickButtonSave = new EventEmitter();
  _clickButtonSave(event: any) {
    this.clickButtonSave.emit(event);
  }

  @Output()
  clickButtonCancel = new EventEmitter();
  _clickButtonCancel(event: any) {
    this.clickButtonCancel.emit(event);
  }

  @Output()
  clickButtonExtra = new EventEmitter();
  _clickButtonExtra(event: any) {
    this.clickButtonExtra.emit(event);
  }

  isLeftText() {
    return this.leftText !== "";
  }
}
