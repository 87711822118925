// tslint:disable
import { Injector, Component } from "@angular/core";

import { CMFormBrowse2Component } from "./../../@cmcore/component";
import { RelatorioCustomizadoEditComponent } from "./relatorio-customizado-edit.component";

@Component({
  selector: "relatorio-customizado-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class RelatorioCustomizadoBrowseComponent extends CMFormBrowse2Component {
  static defaultColumns = [
    { field: "codigo", caption: "Código", width: "100" },
    { field: "descricao", caption: "Descrição", filter: true },
  ];

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "RelatorioCustomizado";
    this.fixedFooterFormEdit = false;
    this.formEditComponent = RelatorioCustomizadoEditComponent;
    this.columns = [
      { field: "nomeassociado", caption: "Associado" },
      { field: "nomeempresa", caption: "Empresa" },
      { field: "descricao", caption: "Descrição" },
      { field: "tipo", caption: "Relatório" },
    ];
  }

  getTitle() {
    return "Relatórios Customizados";
  }
}
