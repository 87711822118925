// tslint:disable
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent } from './../../../@cmcore/component';
import { UsuarioEditComponent } from './usuario-edit.component';

@Component({
  selector: 'usuario-browse',
  templateUrl: './../../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class UsuarioBrowseComponent extends CMFormBrowse2Component {

  getTitle() {
    return 'Usuário';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'usuario';
    this.formEditComponent = UsuarioEditComponent;
    this.columns = [{field: 'nome', caption: 'Nome', filter: true},
                    {field: 'login', caption: 'Login', filter: true}
                   ];
  }

  static pesquisa(sender: any, target: any, targetProp?: string, onAfterSelect?: Function) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'usuario',
        'Pesquisa de Usuário',
        [{field: 'id', caption: '#', width: 100},
         {field: 'nome', caption: 'Nome', filter: true},
         {field: 'login', caption: 'Login', filter: true}],
        target,
        targetProp,
        'id',
        onAfterSelect);
    }
  }

}