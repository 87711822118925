export enum CMGridV2EditType {
  INPUT = 0,
  CHECKBOX = 1,
}

export enum CMGridV2EditDataType {
  STRING = 0,
  BOOLEAN = 1,
  DOUBLE = 2,
}

export type CMGridV2ColumnButtonOnClick = (row: any) => void;

export type CMGridV2ColumnFuncRowBoolean = (row: any) => boolean;
export const getValueFuncRowBoolean = (
  value: boolean | CMGridV2ColumnFuncRowBoolean,
  row: any
) => {
  if (typeof value == "boolean") {
    return value;
  } else {
    return value(row);
  }
};

export class CMGridV2ColumnButton {
  icon: string;
  hint?: string;
  onClick: CMGridV2ColumnButtonOnClick;
  className?: string;
  enabled?: boolean | CMGridV2ColumnFuncRowBoolean;
  visible?: boolean | CMGridV2ColumnFuncRowBoolean;
}

export class CMGridV2Column {
  field: string;
  header: string;
  editType?: CMGridV2EditType;
  dataType?: CMGridV2EditDataType;
  decimals?: number;
  visible?: boolean;
  enabled?: boolean | CMGridV2ColumnFuncRowBoolean;
  buttons?: Array<CMGridV2ColumnButton>;
}

export class CMGridV2ListColumns {
  constructor(aParams?: { columns?: Array<CMGridV2Column> }) {
    if (aParams) {
      if (aParams.columns) {
        this.columns = aParams.columns;
      }
    }
  }

  get columns() {
    return this.fColumns;
  }
  set columns(aColumns) {
    this.fColumns = aColumns;
    this.changeColumns();
  }

  fColumns: Array<CMGridV2Column> = [];
  displayedColumns: string[] = [];

  changeColumns() {
    setTimeout(() => {
      this.changeColumns();
    }, 5000);
    this.displayedColumns = [];
    this.fColumns.forEach((column) => {
      if (column.dataType == undefined) {
        column.dataType = CMGridV2EditDataType.STRING;
      }
      if (column.decimals == undefined) {
        column.decimals = 0;
      }
      if (column.editType == undefined) {
        column.editType = CMGridV2EditType.INPUT;
        if (column.enabled == undefined) {
          column.enabled = false;
        }
      }
      if (column.visible == undefined) {
        column.visible = true;
      }
      if (column.enabled == undefined) {
        column.enabled = true;
      }
      if (column.visible) {
        this.displayedColumns.push(column.field);
      }
      if (column.buttons == undefined) {
        column.buttons = [];
      }
      column.buttons.forEach((button) => {
        if (button.hint == undefined) {
          button.hint = "";
        }
        if (button.className == undefined) {
          button.className = "";
        }
        if (button.enabled == undefined) {
          button.enabled = true;
        }
        if (button.visible == undefined) {
          button.visible = true;
        }
      });
    });
  }
}
