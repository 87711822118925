// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "./../../@cmcore/component/cmform/cmform.component";

import {
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMGridEditConfig,
  CMInputConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
} from "./../../@cmcore/component";
import {
  NFeRecebimentoBrowseComponent,
  ProdutoBrowseComponent,
  ProdutoEditComponent,
} from "./../index";
@Component({
  selector: "nfe-recebimento-alterar-data-duplicata",
  templateUrl: "nfe-recebimento-alterar-data-duplicata.component.html",
  styleUrls: ["nfe-recebimento-alterar-data-duplicata.component.scss"],
})
export class NFeRecebimentoAlterarDataDuplicataComponent extends CMFormComponent {
  _owner: NFeRecebimentoBrowseComponent = undefined;
  get owner() {
    return this._owner;
  }

  config = {
    duplicatas: new CMGridEditConfig({
      form: this,
      caption: "Duplicatas",
      fields: {
        nduplicata: new CMInputConfig({
          form: this,
          caption: "Número",
          disabled: true,
          gridConfig: new CMGridEditConfigColumn(),
        }),
        dvenc: new CMInputConfig({
          form: this,
          caption: "Data de Vencimento",
          mask: "99/99/9999",
          gridConfig: new CMGridEditConfigColumn(),
        }),
        valor: new CMInputConfig({
          form: this,
          caption: "Valor",
          dataType: "double(2)",
          disabled: true,
          gridConfig: new CMGridEditConfigColumn(),
        }),
      },
    }),
  };

  // podeCadastrarProduto: boolean = false;
  set owner(value) {
    this._owner = value;
  }

  constructor(_injector: Injector) {
    super(_injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.buscaDados();
  }

  close(formName) {
    if (this.owner) this.owner.beforeComponent = undefined;
    CMFormModalComponent.close(formName);
  }

  duplicatas = [];
  buscaDados() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/NFeRecebimento/AlteraVencimentoDuplicata/FuncaoExtra",
      JSON.stringify({
        buscar: "Sim",
        id: this.owner.idFormNFeRecebimentoAlterarDataDuplicata,
      }),
      (ret) => {
        if (ret["result"] == "ok") {
          this.duplicatas = ret["duplicatas"];
        } else {
          CMFormGlobaisComponent.showMessageAviso(ret["msg"]);
          this.close("formNFeRecebimentoAlterarDataDuplicata");
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

  salvarDados() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/NFeRecebimento/AlteraVencimentoDuplicata/FuncaoExtra",
      JSON.stringify({
        salvar: "Sim",
        id: this.owner.idFormNFeRecebimentoAlterarDataDuplicata,
        duplicatas: this.duplicatas,
      }),
      (ret) => {
        if (ret["result"] == "ok") {
          CMFormGlobaisComponent.toastSucesso(ret["msg"]);
          this.close("formNFeRecebimentoAlterarDataDuplicata");
        } else {
          CMFormGlobaisComponent.showMessageAviso(ret["msg"]);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }
}
