// tslint:disable
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent } from './../../@cmcore/component';
import { FormaPagamentoEditComponent } from './formapagamento-edit.component';
import { HttpClient } from '@angular/common/http';
import { FuncsGlobais } from '../funcs.globais';

@Component({
  selector: 'formapagamento-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class FormaPagamentoBrowseComponent extends CMFormBrowse2Component {

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'formapagamento';
    this.formEditComponent = FormaPagamentoEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = [{field: 'codigo', caption: '#'},
                    {field: 'descricao',   caption: 'Descrição', filter: true},
                    {field: 'numeroparcelas', caption: 'Número de parcelas'},
                    {field: 'geraboleto', caption: 'Gera Boleto'},
                    {field: 'contabancaria_descricao', caption: 'Conta Bancária'}];
  }

  getTitle() {
    return 'Forma de Pagamento';
  }

  static pesquisa(origemformapagamento: string, sender: any, target: any, targetProp?: string) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'formapagamento',
        'Pesquisa de Forma de Pagamento',
        [{field: 'codigo', caption: '#'},
         {field: 'descricao',   caption: 'Descrição', filter: true},
         //{field: 'numeroparcelas', caption: 'Número de parcelas'},
         {field: 'geraboleto', caption: 'Gera Boleto'},
         {field: 'contabancaria_descricao', caption: 'Conta Bancaria'},
         {
          field: 'origemformapagamento', caption: 'Conta Bancaria', 
          filter: true,
          visible: false,
          filterVisible: false,
          value: origemformapagamento
        },
        ],
        target,
        targetProp,
        'codigo');
    }
  }

  private static _valuesSelect = [];
  private static _valuesSelectBoleto = [];
  private static _valuesSelectCheque = [];
  private static _valuesSelectBaixaCreditoLoja = [];
  private static _valuesSelectCartao = [];
  private static _valuesSelectDinheiro = [];
  private static _inLoadValuesSelect = false;

  private static _valuesObjectSelect = {};
  private static _valuesObjectSelectBoleto = {};
  private static _valuesObjectSelectCheque = {};
  private static _valuesObjectSelectBaixaCreditoLoja = {};
  private static _valuesObjectSelectCartao = {};
  private static _valuesObjectSelectDinheiro = {};

  private static loadValuesSelect(http: HttpClient) {
    if (!FormaPagamentoBrowseComponent._inLoadValuesSelect) {
      FormaPagamentoBrowseComponent._inLoadValuesSelect = true;
      CMFormBrowse2Component.postSubscribeT<Array<Object>>(http, '/api/formapagamento/GetAllObject',
                    JSON.stringify({}),
              (condpag) => {
                if ((condpag.length) && (condpag.length > 0)) {
                  condpag.sort(function (a, b) {
                    if (a['descricao'] > b['descricao']) {
                      return 1;
                    }
                    if (a['descricao'] < b['descricao']) {
                      return -1;
                    }
                    return 0;
                  });
                  FormaPagamentoBrowseComponent._valuesSelect.push({
                    value: 0,
                    text: ' '
                  });
                  FormaPagamentoBrowseComponent._valuesSelectBoleto.push({
                    value: 0,
                    text: ' '
                  });
                  condpag.forEach(element => {
                    if ((element['tpagnfe'] !== 'Outros') && (element['tpagnfe'] !== 'Sempagamento')){
                      FormaPagamentoBrowseComponent._valuesObjectSelect[element['id']] = element;
                      FormaPagamentoBrowseComponent._valuesSelect.push({
                        value: element['id'],
                        text: element['descricao']
                      });
                    }
                    if ((element['tpagnfe'] !== 'Outros') && (element['tpagnfe'] !== 'Sempagamento') && (element['tpagnfe'] !== 'CreditoLoja')){
                      FormaPagamentoBrowseComponent._valuesObjectSelectBaixaCreditoLoja[element['id']] = element;
                      FormaPagamentoBrowseComponent._valuesSelectBaixaCreditoLoja.push({
                        value: element['id'],
                        text: element['descricao']
                      });
                    }
                    if ((element['tpagnfe'] === 'Outros') || (element['tpagnfe'] === 'BoletoBancario') || element['tpagnfe'] === 'CreditoLoja'){
                      FormaPagamentoBrowseComponent._valuesObjectSelectBoleto[element['id']] = element;
                      FormaPagamentoBrowseComponent._valuesSelectBoleto.push({
                        value: element['id'],
                        text: element['descricao']
                      });
                    }
                    if (element['tpagnfe'] === 'Cheque'){
                      FormaPagamentoBrowseComponent._valuesObjectSelectCheque[element['id']] = element;
                      FormaPagamentoBrowseComponent._valuesSelectCheque.push({
                        value: element['id'],
                        text: element['descricao']
                      });
                    }
                    if (element['tpagnfe'] === 'Dinheiro'){
                      FormaPagamentoBrowseComponent._valuesObjectSelectDinheiro[element['id']] = element;
                      FormaPagamentoBrowseComponent._valuesSelectDinheiro.push({
                        value: element['id'],
                        text: element['descricao']
                      });
                    }
                    if (
                        (element['tpagnfe'] === 'CartaoCredito')   ||
                        (element['tpagnfe'] === 'CartaoDebito')    || 
                        (element['tpagnfe'] === 'ValeAlimentacao') ||
                        (element['tpagnfe'] === 'ValeRefeicao')    ||
                        (element['tpagnfe'] === 'ValePresente')    ||
                        (element['tpagnfe'] === 'ValeCombustivel')
                      ){
                      FormaPagamentoBrowseComponent._valuesObjectSelectCartao[element['id']] = element;
                      FormaPagamentoBrowseComponent._valuesSelectCartao.push({
                        value: element['id'],
                        text: element['descricao']
                      });
                    }
                  });
                }
                FormaPagamentoBrowseComponent._inLoadValuesSelect = false;
                return true;
              });
    }
  }

  static valuesSelect(http: HttpClient) {
    if (FormaPagamentoBrowseComponent._valuesSelect.length == 0)
    FormaPagamentoBrowseComponent.loadValuesSelect(http);
    return FormaPagamentoBrowseComponent._valuesSelect;
  }
  
  static valuesSelectBoleto(http: HttpClient) {
    if (FormaPagamentoBrowseComponent._valuesSelect.length == 0)
    FormaPagamentoBrowseComponent.loadValuesSelect(http);
    return FormaPagamentoBrowseComponent._valuesSelectBoleto;
  }

  static valuesSelectCheque(http: HttpClient) {
    if (FormaPagamentoBrowseComponent._valuesSelect.length == 0)
    FormaPagamentoBrowseComponent.loadValuesSelect(http);
    return FormaPagamentoBrowseComponent._valuesSelectCheque;
  }
  
  static valuesSelectBaixaCreditoLoja(http: HttpClient) {
    if (FormaPagamentoBrowseComponent._valuesSelect.length == 0)
    FormaPagamentoBrowseComponent.loadValuesSelect(http);
    return FormaPagamentoBrowseComponent._valuesSelectBaixaCreditoLoja;
  }

  static valuesSelectCartao(http: HttpClient) {
    if (FormaPagamentoBrowseComponent._valuesSelect.length == 0)
    FormaPagamentoBrowseComponent.loadValuesSelect(http);
    return FormaPagamentoBrowseComponent._valuesSelectCartao;
  }
  static valuesSelectDinheiro(http: HttpClient) {
    if (FormaPagamentoBrowseComponent._valuesSelect.length == 0)
    FormaPagamentoBrowseComponent.loadValuesSelect(http);
    return FormaPagamentoBrowseComponent._valuesSelectDinheiro;
  }

  static valuesObjectSelect() {
    return FormaPagamentoBrowseComponent._valuesObjectSelect;
  }

  static valuesObjectSelectBoleto() {
    return FormaPagamentoBrowseComponent._valuesObjectSelectBoleto;
  }

  static valuesObjectSelectCheque() {
    return FormaPagamentoBrowseComponent._valuesObjectSelectCheque;
  }
  
  static valuesObjectSelectDinheiro() {
    return FormaPagamentoBrowseComponent._valuesObjectSelectDinheiro;
  }
  
  static valuesObjecSelectBaixaCreditoLoja() {
    return FormaPagamentoBrowseComponent._valuesObjectSelectBaixaCreditoLoja;
  }

  static valuesObjectSelectCartao() {
    return FormaPagamentoBrowseComponent._valuesObjectSelectCartao;
  }

  static clearValuesSelect() {
    FormaPagamentoBrowseComponent._valuesSelect = [];
    FormaPagamentoBrowseComponent._valuesObjectSelect = [];
    FormaPagamentoBrowseComponent._valuesSelectBoleto = [];
    FormaPagamentoBrowseComponent._valuesObjectSelectBoleto = [];
    FormaPagamentoBrowseComponent._valuesSelectCheque = [];
    FormaPagamentoBrowseComponent._valuesObjectSelectCheque = [];
    FormaPagamentoBrowseComponent._valuesSelectBaixaCreditoLoja = [];
    FormaPagamentoBrowseComponent._valuesObjectSelectBaixaCreditoLoja = [];
    FormaPagamentoBrowseComponent._valuesObjectSelectDinheiro = [];
  }

  private static _valuesQtdeParcelas = [];
  static valuesQtdeParcelas() {
    if (FormaPagamentoBrowseComponent._valuesQtdeParcelas.length == 0) {  
      FormaPagamentoBrowseComponent._valuesQtdeParcelas.push({value: "AVista", text: "À Vista"});
      for (let i = 0; i < FuncsGlobais.paramEmpresa.qtdeMaximaParcelas; i++) {
        const item = {
          value: (i+1).toString(),
          text: (i+1).toString()+'x'
        };
        FormaPagamentoBrowseComponent._valuesQtdeParcelas.push(item);
      }
    }
    return FormaPagamentoBrowseComponent._valuesQtdeParcelas;
  }

}