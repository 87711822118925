// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormRelatComponent, CMInputConfig,
         CMFormGlobaisComponent
       } from './../../../@cmcore/component';

@Component({
    selector: 'ultimo-login',
    templateUrl: 'ultimo-login.component.html'
})
export class RelatUltimoLoginComponent extends CMFormRelatComponent {

  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = 'ImpressaoUltimoLogin';
    this.filterConfig = {
      diassemacesso: new CMInputConfig({form: this,
                                  caption: 'Dias para considerar inativo',
                                  dataType: 'integer'
                                 }),
    }
    this.filter.diassemacesso = '30';
  }

}