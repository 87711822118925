// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormEditComponent, CMInputConfig,
         CMGridEditConfig, CMGridEditConfigColumn
       } from './../../@cmcore/component';
import { FuncsGlobais } from '../funcs.globais';
import { GradeBrowseComponent } from './grade-browse.component';

@Component({
    selector: 'grade-edit',
    templateUrl: 'grade-edit.component.html',
})
export class GradeEditComponent extends CMFormEditComponent {

  private sub: any;
  private indice: string;

  getFirstComponentName() {
    return 'model_codigo';
  }

  getTitle() {
    return FuncsGlobais.paramEmpresa['nomeGrade'+this.indice];
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'grade';
    this.setModelConfig();
    this.sub = this.route.params.subscribe(params => {
      if (params['indice'])
        this.indice = params['indice'];
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  setModelConfig() {
    this.modelConfig = {
      codigo: new CMInputConfig({form: this,
                                 caption: 'Código',
                                 dataType: 'string',
                                 upper: true,
                                 maxlength: 10,
                                 required: true,
                                 onExit: () => {
                                  console.log(this.model);
                                 }
                                }),
    };
  }

  beforeSave(procSave: Function) {
    console.log('procSave: ', procSave);
    this.model.indice = this.indice;
    super.beforeSave(procSave);
  }

}