// tslint:disable
import {Injector, Component} from '@angular/core';

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
  CMInputConfig,
} from './../../@cmcore/component';
import {NecessidadeCompraEditComponent} from './necessidade-compra-edit.component';

@Component({
  selector: 'necessidade-compra-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss'],
})
export class NecessidadeCompraBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'NecessidadeCompra';
    this.formEditComponent = NecessidadeCompraEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = [
      {
        field: 'numero',
        caption: '#',
        width: 100,
        filter: new CMInputConfig({
          dataType: 'integer',
        }),
      },
      {
        field: 'data',
        caption: 'Data',
        filter: new CMInputConfig({
          mask: '99/99/9999',
        }),
      },
      {
        field: 'status',
        caption: 'Status',
        filter: new CMInputConfig({
          values: [{value: 'Aberta'}, {value: 'Atendida'}, {value: 'Cancelada'}],
        }),
      },
    ];
  }

  getTitle() {
    return 'Solicitação de Compra';
  }

  static pesquisa(sender: any, target: any, targetProp?: string, onAfterSelect?: Function) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'NecessidadeCompra',
        'Pesquisa de Solicitação de Compra',
        [
          {field: 'numero', caption: '#'},
          {field: 'data', caption: 'Data', filter: true, mask: '99/99/9999'},
        ],
        target,
        targetProp,
        'numero',
        onAfterSelect,
      );
    }
  }

  static pesquisaItens(onAfterSelect: Function) {
    CMFormPesquisaComponent.defaultPesquisaMuiltSelect(
      'NecessidadeCompra',
      'Pesquisa de Solicitação de Compra',
      [
        {field: 'numero', caption: '#', width: '150px', filter: true},
        {
          field: 'data',
          caption: 'Data',
          filter: true,
          mask: '99/99/9999',
          width: '150px',
        },
        {field: 'produto', caption: 'Produto'},
        {field: 'quantidade', caption: 'Quantidade', width: '150px'},
      ],
      onAfterSelect,
      undefined,
      {pesqitens: true, status: 'Aberta'},
    );
  }
}
