// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMInputConfig, CMFormExecComponent,
         CMGridEditConfig, CMGridEditConfigColumn,
         CMFormGlobaisComponent } from './../../../@cmcore/component';

@Component({
  templateUrl: 'cotacao-acesso-fornecedor-component.html'
})
export class CotacaoAcessoFornecedorComponent extends CMFormExecComponent {

  private sub: any;
  chaveacesso: string;
  dadosNaoTransmitidos: boolean = false;

  constructor(_injector: Injector) {
    super(_injector);
    this.setModelConfig();
    this.sub = this.route.params.subscribe(params => {
      if (params['chaveacesso'])
        this.chaveacesso = params['chaveacesso'];
        this.loadProdutos();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  setModelConfig() {
    this.filterConfig = {
      condicoesproposta: new CMInputConfig({
        form: this,
        caption: 'Condições da Proposta',
        required: true,
        onExit: () => {
          this.dadosNaoTransmitidos = true
        },
      }),
      produtos: new CMGridEditConfig({form: this,
                                      fields: {produto: new CMInputConfig({form: this,
                                                                           caption: 'Produto',
                                                                           disabled: true,
                                                                           gridConfig: new CMGridEditConfigColumn()
                                                                        }),
                                              produtounidade: new CMInputConfig({form: this,
                                                                                 caption: 'Unidade',
                                                                                 disabled: true,
                                                                                 gridConfig: new CMGridEditConfigColumn({
                                                                                   width: "70px"
                                                                                 })
                                                                                }),
                                              quantidade: new CMInputConfig({form: this,
                                                                             caption: 'Quantidade',
                                                                             dataType: 'double(2)',
                                                                             disabled: true,
                                                                             gridConfig: new CMGridEditConfigColumn({
                                                                               width: "150px"
                                                                             })
                                                                            }),
                                              valorunitario: new CMInputConfig({form: this,
                                                                                caption: 'Valor Unitário',
                                                                                dataType: 'double(2)',
                                                                                onExit: () => {this.calcTotalItem();},
                                                                                gridConfig: new CMGridEditConfigColumn({
                                                                                  width: "150px",
                                                                                  disabledFunc: (row) => this.getValueObject(row, 'tipo') !== 'item',
                                                                                })
                                                                               }),
                                              valortotal: new CMInputConfig({form: this,
                                                                             caption: 'Valor Total',
                                                                             dataType: 'double(2)',
                                                                             disabled: true,
                                                                             gridConfig: new CMGridEditConfigColumn({
                                                                              width: "150px",
                                                                              background: (row) => {
                                                                                if (this.getValueObject(row, 'tipo') == 'item') {
                                                                                  if (this.valueToFloat(this.getValueObject(row, 'valorunitario')) == 0) {
                                                                                    return 'rgb(255, 232, 235)';
                                                                                  }
                                                                                  else {
                                                                                    return 'rgb(232, 255, 236)';
                                                                                  }
                                                                                }
                                                                              },
                                                                             })
                                                                            }),
                                              }
                                })
    }
  }

  innerExec() {
    super.innerExec();
    if (this.valid() && this.cotacaoCarregada()) {
      if (this.dadosNaoTransmitidos)
        this.enviaDadosCotacao();
      else
        CMFormGlobaisComponent.toastInformacao('Dados não alterados');
    }
  }

  loadProdutos() {
    CMFormGlobaisComponent.showSpinner();

    this.postSubscribe('/api/Cotacao/GetProdutosCotacao',
                  JSON.stringify({
                    chaveacesso: this.chaveacesso
                  }),
            (response) => {
              this.filter = response;
              CMFormGlobaisComponent.hideSpinner();
            },
            error => {
              CMFormGlobaisComponent.hideSpinner();
              this.showMessageErrorHTTP('loadProdutos', error);
            });
  }

  cotacaoCarregada() {
    return this.filter.status == "EmAndamento"
  }

  calcTotalItem() {
    this.dadosNaoTransmitidos = true;
    this.calcTotalItem2(this.filterConfig.produtos.current);
    const idproduto = this.filterConfig.produtos.current.idproduto;
    const valorunitario = this.filterConfig.produtos.current.valorunitario;
    setTimeout(() => {
      this.calcTotalItemProd(idproduto, valorunitario);
    }, 10);
  }

  calcTotalItem2(item) {
    item.valortotal = this.floatToValue(this.valueToFloat(item.quantidade, 0) *
                                        this.valueToFloat(item.valorunitario, 0));
  }

  calcTotalItemProd(idproduto, valorunitario) {
    this.filter.produtos.forEach(produto => {
      if (produto.idproduto == idproduto) {
        produto.valorunitario = valorunitario;
        this.calcTotalItem2(produto);
      }
    });
  }

  enviaDadosCotacao() {
    let dados = Object.assign(this.filter);
    dados.chaveacesso = this.chaveacesso;
    this.postSubscribe('/api/Cotacao/TrasmiteProdutosCotacao',
                  JSON.stringify(dados),
            (result) => {
              if (result['result'] === 'ok') {
                this.dadosNaoTransmitidos = false;
                CMFormGlobaisComponent.toastSucesso('Dados recedidos com sucesso.');
              }
              else {
                CMFormGlobaisComponent.toastErro(result['msg']);
              }
              CMFormGlobaisComponent.hideSpinner();
            },
            error => {
              CMFormGlobaisComponent.hideSpinner();
              this.showMessageErrorHTTP('enviaDadosCotacao', error);
            });
  }

}