// tslint:disable
import { Component, Injector } from "@angular/core";
import { ContaBancariaBrowseComponent } from "../../conta-bancaria";
import { ContaContabilBrowseComponent } from "../../conta-contabil";
import { FormaPagamentoBrowseComponent } from "../../formapagamento";

import {
  CMFormRelatComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
} from "./../../../@cmcore/component";
import { ClienteBrowseComponent } from "./../../cliente/cliente-browse.component";

@Component({
  selector: "conta-receber",
  templateUrl: "conta-receber.component.html",
})
export class RelatContaReceberComponent extends CMFormRelatComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = "ImpressaoContaReceber";
    this.filterConfig = {
      cliente: new CMInputConfig({
        form: this,
        caption: "Cliente",
        pesqFunc: (sender) => {
          ClienteBrowseComponent.pesquisa(sender, this.filter);
        },
      }),
      formapagamento: new CMInputConfig({
        form: this,
        caption: "Forma de Pagamento",
        disabledFunc: () => {
          return this.filter.tipopagamento == 'AVista';
        },
        pesqFunc: (sender) => {
          FormaPagamentoBrowseComponent.pesquisa("todos", sender, this.filter);
        },
      }),
      dataini: new CMInputConfig({
        form: this,
        caption: "Data Inicial",
        mask: "99/99/9999",
      }),
      datafim: new CMInputConfig({
        form: this,
        caption: "Data Final",
        mask: "99/99/9999",
      }),
      somentecontasemaberto: new CMInputConfig({
        form: this,
        caption: "Somente contas em aberto",
        disabledFunc: () => {
          return this.filter.tipopagamento == 'AVista';
        },
      }),
      demonstrarpagamentosavista: new CMInputConfig({
        form: this,
        caption: "Demonstra pagamentos a vista",
        disabledFunc: () => {
          return this.filter.tipopagamento !== 'Todos';
        },
      }),
      mostraendereco: new CMInputConfig({
        form: this,
        caption: "Demonstra endereço do cliente",
      }),
      tipopagamento: new CMInputConfig({
        form: this,
        caption: "Tipo de Recebimento",
        values: [
          { value: "Todos", text: "Todos" },
          { value: "AVista", text: "A Vista" },
          { value: "FormaPagamento", text: "Por forma de pagamento" },
        ],
      }),
      tipodata: new CMInputConfig({
        form: this,
        caption: "Pesquisar por",
        values: [
          { value: "Vencimento", text: "Vencimento" },
          { value: "Liquidacao", text: "Liquidação" },
          { value: "VencimentoAberto", text: "Vencimento - Liquidação Aberta" },
          { value: "Emissao", text: "Emissão" },
          { value: "VencimentoRecebimentoAberto", text: "Vencimento - Recebimento Aberto" },
          //{ value: "BomPara", text: "Bom Para" },
          { value: "EmissaoAberto", text: "Emissão - Liquidação Aberta" },
        ],
      }),
      entsai: new CMInputConfig({
        form: this,
        caption: "Entrada/Saída",
        values: [
          { value: "Todos", text: "Todos" },
          { value: "Entrada", text: "Recebimentos" },
          { value: "Saida", text: "Pagamentos" },
        ],
      }),
      contacontabil: new CMInputConfig({
                  form: this,
                  caption: "Conta Contabil",
                  pesqFunc: (sender) => {
                    ContaContabilBrowseComponent.pesquisa(sender, this.filter, "Categoria de Conta Contábil");
                  },
      }),
      outroscampos: new CMInputConfig({
        form: this,
        caption: "Outros Campos",
        values: [
          { value: "", text: "" },
          { value: "NrDuplicata", text: "Número do Documento" },
          { value: "NrVenda", text: "Número da Venda" },
          { value: "NrCompra", text: "Número da Compra" },
          { value: "NrNFe", text: "Número da NFe" },
          { value: "ValorLancamento", text: "Valor do Lançamento" },
          { value: "ValorBruto", text: "Valor Bruto" },
        ],
      }),
      observacao: new CMInputConfig({
        form: this,
        caption: "Observação",
      }),
      outroscamposdescstring: new CMInputConfig({
        form: this,
        caption: "Valor",
        maxlenght: 100,
      }),
      outroscamposdescnumber: new CMInputConfig({
        form: this,
        caption: 'Valor',
        maxlength: 100,
        dataType: "double(2)",
      }),
      contabancaria: new CMInputConfig({
        form: this,
        caption: "Conta Bancária",
        pesqFunc: (sender) => {
          ContaBancariaBrowseComponent.pesquisa(sender, this.filter, "Conta Bancária");
        },
      }),
      tipodre: new CMInputConfig({
                                      form: this,
                                      caption: 'Tipo de Conta - DRE',
                                      values: [
                                        {value: "", text: ""},
                                        {value: "ReceitaRecorrente", text: "Receita Recorrente"},
                                        {value: "ReceitaFinanceira", text: "Receita Financeira"},
                                        {value: "ReceitaComProdutos", text: "Receita Com Produtos"},
                                        {value: "NaoAplicavel", text: "Não aplicável"},
                                      ],
                                    }),
    };
    this.filter.dataini = this.firstDate();
    this.filter.datafim = this.lastDate();
    this.filter.somentecontasemaberto = "Sim";
    this.filter.demonstrarpagamentosavista = "Não";
    this.filter.mostraendereco = "Não";
    this.filter.tipopagamento = "Todos";
    this.filter.tipodata = "Vencimento";
    this.filter.entsai = "Todos";
    
  }
  
  limparFiltros(){
    this.filter.dataini = "";
    this.filter.datafim = "";
    this.filter.tipopagamento = "";
    this.filter.tipodata = "";
    this.filter.entsai = "";
    this.filter.tipodre = "";
    this.filter.contabancaria = "";
    this.filter.outroscampos = "";
    this.filter.outroscamposdescnumber = "";
    this.filter.outroscamposdescstring = "";
    this.filter.contabancaria = "";
    this.filter.observacao = "";
    this.filter.contacontabil = "";
    this.filter.formapagamento = "";
    this.filter.cliente = "";
  }
  
  tipoInput(){
    if (
        this.filter.outroscampos === 'ValorLancamento' ||
        this.filter.outroscampos === 'ValorBruto' ){
      return "number"          
    }
    else {
      return "string"
    }
  }

  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.dataini);
      if (!r) CMFormGlobaisComponent.toastErro("Data inicial não informada");
    }
    if (r) {
      r = !this.isEmpty(this.filter.datafim);
      if (!r) CMFormGlobaisComponent.toastErro("Data final não informada");
    }
    return r;
  }
}
