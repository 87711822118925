// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormEditComponent, CMInputConfig,
         CMGridEditConfig, CMGridEditConfigColumn
       } from './../../@cmcore/component';

@Component({
    selector: 'empresapreco-edit',
    templateUrl: 'empresapreco-edit.component.html',
})
export class EmpresaPrecoEditComponent extends CMFormEditComponent {

  getFirstComponentName() {
    return 'model_codigo';
  }

  getTitle() {
    return 'Empresa Preço';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'EmpresaPreco';
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      nome: new CMInputConfig({form: this,
                                    caption: 'Nome',
                                    required: true}),
    };
  }

}