// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMInputConfig,
} from "./../../@cmcore/component";
import {
  NFeEstoqueEditComponent,
  PessoaBrowseComponent,
  ProdutoBrowseComponent,
} from "./../index";

@Component({
  selector: "nfe-estoque-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class NFeEstoqueBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "nfeestoque";
    this.formEditComponent = NFeEstoqueEditComponent;
    this.columns = [
      {
        field: "nnf",
        caption: "#",
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "dhemi",
        caption: "Data",
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {
        field: "pessoa_nome",
        caption: "Fornecedor",
        filterField: "pessoa.id",
        filterValue: "pessoa.codigo",
        fieldBottom: "pessoa.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            PessoaBrowseComponent.pesquisa(
              sender,
              this.valuesFilters,
              "Fornecedor"
            );
          },
        }),
      },
      {
        field: "produto_descr",
        caption: "Produto",
        filterField: "produto.id",
        filterValue: "produto.codigo",
        fieldBottom: "produto.descr",
        visible: false,
        filter: new CMInputConfig({
          dataType: "integer",
          maxlength: 30,
          padInfo: { size: 30 },
          pesqFunc: (sender) => {
            ProdutoBrowseComponent.pesquisa(sender, this.valuesFilters, "Ativos");
          },
        }),
      },
      { field: "tpnf", caption: "Tipo de Operação" },
    ];
  }

  getTitle() {
    return "Entrada / Saída Manual de Estoque";
  }
}
