// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
} from "./../../@cmcore/component";
import { SubGrupoDeProdutoEditComponent } from "./subgrupodeproduto-edit.component";

@Component({
  selector: "subgrupodeproduto-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class SubGrupoDeProdutoBrowseComponent extends CMFormBrowse2Component {
  static defaultColumns = [
    { field: "codigo", caption: "Código", width: "100" },
    { field: "descricao", caption: "Descrição", filter: true },
  ];

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "subgrupodeproduto";
    this.fixedFooterFormEdit = false;
    this.formEditComponent = SubGrupoDeProdutoEditComponent;
    this.columns = SubGrupoDeProdutoBrowseComponent.defaultColumns;
  }

  getTitle() {
    return "Sub-Grupo de Produto";
  }

  static pesquisa(sender: any, target: any, targetProp: any = undefined) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "subgrupodeproduto",
        "Pesquisa de Sub-Grupo de Produto",
        SubGrupoDeProdutoBrowseComponent.defaultColumns,
        target,
        targetProp,
        "codigo",
        undefined,
        false
      );
    }
  }
}
