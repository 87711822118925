// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMInputConfig, CMValidatorEmail,
         CMFormGlobaisComponent,
         CMGridEditConfig,
         CMGridEditConfigColumn} from './../../../@cmcore/component';
import { CMFormComponent } from './../../../@cmcore/component/cmform/cmform.component';

@Component({
  selector: 'cotacao-email',
  templateUrl: './cotacao-email.component.html',
  styleUrls: ['./cotacao-email.component.scss']
})
export class CotacaoEmailComponent extends CMFormComponent {

  static open(urlPost: string, caption: string, data: any = {}) {
    let f = CMFormGlobaisComponent.createComponent(CotacaoEmailComponent);
    f.setData(urlPost, caption, data);
    f.openModal();
  }

  caption: string = '';
  urlPost: string = '';
  modelConfig: any = {};
  model: any = {};

  constructor(_injector: Injector) {
    super(_injector);
    this.setModelConfig();
  }

  setData(urlPost: string, caption: string, data: any = {}) {
    this.caption = caption;
    this.urlPost = urlPost;
    if (data) {
      if (data.assunto !== undefined) {
        this.model.assunto = data.assunto;
        delete data.assunto;
      }
      if (data.mensagem !== undefined) {
        this.model.mensagem = data.mensagem;
        delete data.mensagem;
      }
      if (data.listacontatos !== undefined) {
        this.model.listacontatos = data.listacontatos;
        delete data.listacontatos;
      }
    }
    let props = Object.getOwnPropertyNames(data);
    props.forEach(prop => {
      this.model[prop] = data[prop];
    });
  }

  setModelConfig() {
    this.modelConfig = {
      assunto: new CMInputConfig({
        form: this,
        caption: 'Assunto',
        required: true,
      }),
      mensagem: new CMInputConfig({
        form: this,
        caption: 'Mensagem',
        required: true,
      }),
      listacontatos: new CMGridEditConfig({
        form: this,
        caption: 'Fornecedores',
        required: true,
        fields: {
          enviar: new CMInputConfig({
            form: this,
            caption: 'Enviar',
            gridConfig: new CMGridEditConfigColumn({
              tipo: 'checkbox',
            }),
          }),
          nome: new CMInputConfig({
            form: this,
            caption: 'Nome',
            readonly: true,
            gridConfig: new CMGridEditConfigColumn(),
          }),
          email: new CMInputConfig({
            form: this,
            caption: 'email',
            readonly: true,
            gridConfig: new CMGridEditConfigColumn(),
          })
        }
      }),
    };
    this.generateEmptyModel(this.modelConfig, this.model);
  }

  enviarEmail() {
    if (this.valid(this.modelConfig, this.model)) {
      CMFormGlobaisComponent.showSpinner();
      this.model.baseurl = window.location.origin;
      this.postSubscribe(this.urlPost,
                    JSON.stringify(this.model),
              (r) => {
                this.model.result = r;
                if (r['result'] === 'true') {
                  CMFormGlobaisComponent.toastSucesso(r['msg']);
                  this.closeModal();
                }
                else {
                  let msg: string = r['msg'];
                  if (msg.startsWith('{') && msg.endsWith('}')) {
                    msg = JSON.parse(r['msg']);
                    CMFormGlobaisComponent.showMessageAviso(
                      '<pre style="padding-left: 30px;">'+JSON.stringify(msg, null, 2).replace(/\\r\\n/g, "<br>")+'</pre>'
                    );
                  }
                  else {
                    CMFormGlobaisComponent.showMessageAviso(
                      '<p style="padding-left: 30px;">'+msg+'</p>'
                    );
                  }
                }
                CMFormGlobaisComponent.hideSpinner();
              },
              error => {
                CMFormGlobaisComponent.hideSpinner();
                CMFormGlobaisComponent.showMessageError(error);
              });
    }
  }

}