// tslint:disable
import { Injector } from "@angular/core";

import { CMFormComponent } from "../../../component/cmform/cmform.component";

export class CMFormRelatComponent extends CMFormComponent {
  constructor(_injector: Injector) {
    super(_injector);
  }

  public relatName: string = "";
  public filter: any = {};
  private innerFilterConfig = {};
  public get filterConfig() {
    return this.innerFilterConfig;
  }
  public set filterConfig(value: any) {
    this.innerFilterConfig = value;
    this.filter = {};
    this.generateEmptyModel(value, this.filter);
  }

  valid() {
    return true;
  }

  print() {
    if (this.valid()) this.showRelat(this.relatName, this.filter);
  }
  
  exportCSV() {
    if (this.valid()) this.downloadCSV(this.relatName, this.filter);
  }
}
