// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormEditComponent, CMInputConfig, CMFormGlobaisComponent
       } from './../../@cmcore/component';
import { CategoriaContaContabilBrowse } from './categoria-conta-contabil-browse.component';
@Component({
    selector: 'categoria-conta-contabil-edit',
    templateUrl: 'categoria-conta-contabil.component.html',
})
export class CategoriaContaContabilEditComponent extends CMFormEditComponent {

    getFirstComponentName() {
      return 'model_descricao';
    }
  
    getTitle() {
      return 'Categoria de Conta Contabil';
    }
  
    constructor(_injector: Injector) {
      super(_injector);
      this.controllerName = 'CategoriaContaContabil';
      this.setModelConfig();
    }

    setModelConfig() {
      this.modelConfig = {
        codigo: new CMInputConfig({form: this,
                                   caption: '#',
                                   disabled: true}),
        descricao: new CMInputConfig({form: this,
                                      caption: 'Descrição',
                                      maxlength: 100,
                                      required: true}),
      };
    }
  
    /*
    afterSave() {
      CategoriaContaContabilBrowse.clearValuesSelect();
    }
    */
  
}  
