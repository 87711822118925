// tslint:disable

import { Component, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
} from "./../../@cmcore/component";
import { PessoaBrowseComponent, ProdutoBrowseComponent } from "./../index";

@Component({
  selector: "nfe-estoque-edit",
  templateUrl: "nfe-estoque-edit.component.html",
})
export class NFeEstoqueEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_infnfe_dest_pessoa";
  }

  getTitle() {
    let r: string = "Entrada / Saída Manual de Estoque";
    let tmp: string = this.fieldByName("infnfe.ide.nnf");
    if (tmp !== "0") r += " - #" + tmp;
    return r;
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "nfeestoque";
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      infnfe: {
        ide: {
          tpnf: new CMInputConfig({
            form: this,
            caption: "Tipo de Operação",
            disabledFunc: () => {
              return this.model.infnfe.det.length !== 0;
            },
            values: [{ value: "Entrada" }, { value: "Saida", text: "Saída" }],
          }),

          nnf: new CMInputConfig({ form: this, caption: "#", disabled: true }),
          dhemi: new CMInputConfig({
            form: this,
            caption: "Data emissão",
            mask: "99/99/9999",
            required: true,
          }),
        },
        dest: {
          pessoa: new CMInputConfig({
            form: this,
            caption: "Fornecedor",
            pesqFunc: (sender) => {
              PessoaBrowseComponent.pesquisa(
                sender,
                this.model.infnfe.dest,
                this.model.infnfe.dest.tipopessoa
              );
            },
          }),
        },
        det: new CMGridEditConfig({
          form: this,
          objName: "det",
          caption: "Produtos",
          onNewItem: (model) => {
            Object.assign(model, {
              qcom: "0,00",
              estoque: [],
            });
          },
          extraColumns: {
            produto_descr: new CMInputConfig({
              form: this,
              caption: "Descrição",
              gridConfig: new CMGridEditConfigColumn({
                ordem: 2,
                getValue: (row) =>
                  this.getValueObject(row, "prod.produto.descr"),
              }),
            }),
          },
          fields: {
            prod: {
              produto: new CMInputConfig({
                form: this,
                caption: "Produto",
                required: true,
                maxlength: 30,
                padInfo: { size: 30 },
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  ordem: 1,
                  width: "350px",
                  getValue: (row) => {
                    return this.getValueObject(row, "prod.produto.codigo");
                  },
                }),
                pesqFunc: (sender) => {
                  ProdutoBrowseComponent.pesquisa(
                    sender,
                    this.modelConfig.infnfe.det.current.prod,
                    "Ativos"
                  );
                },
              }),
              qcom: new CMInputConfig({
                form: this,
                caption: "Quantidade",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 3,
                  width: "200px",
                }),
                hintButton: "Editar",
                iconButton: () => {
                  if (this.disabledAll) return "visibility";
                  else return "edit";
                },
                showButtonClear: false,
                pesqOnlyFunc: (row) => this.mostraDetalhesQuantidade(row),
                pesqFunc: (sender) => {
                  if (
                    this.mostraDetalhesQuantidade(
                      this.modelConfig.infnfe.det.current
                    )
                  )
                    this.modelConfig.infnfe.det.extraClick(
                      new CMGridEditConfigMore({
                        index: 0,
                        title: "Detalhes da Quantidade",
                      })
                    );
                },
                showButtonFunc: (show, row) =>
                  this.mostraDetalhesQuantidade(row),
                required: true,
              }),
            },
            estoque: new CMGridEditConfig({
              form: this,
              required: false,
              fields: {
                grade: new CMInputConfig({
                  form: this,
                  caption: "Grade",
                  required: true,
                  pesqOnly: true,
                  gridConfig: new CMGridEditConfigColumn({
                    tipo: "input-pesquisa",
                    getValue: (row) => {
                      return this.getValueObject(row, "grade.descricao");
                    },
                  }),
                  pesqFunc: (sender) => {
                    ProdutoBrowseComponent.pesquisaGrade(
                      sender,
                      this.modelConfig.infnfe.det.fields.estoque.current,
                      this.modelConfig.infnfe.det.current.prod.produto.id
                    );
                  },
                  visibleFunc: () => {
                    return this.currentProdutoUsaGrade();
                  },
                }),
                numerolote: new CMInputConfig({
                  form: this,
                  caption: "Número Lote",
                  dataType: "string",
                  maxlength: 20,
                  gridConfig: new CMGridEditConfigColumn(),
                  requiredFunc: () => {
                    return this.currentProdutoUsaLote();
                  },
                  visibleFunc: () => {
                    return this.currentProdutoUsaLote();
                  },
                  pesqFunc: (sender) => {
                    ProdutoBrowseComponent.pesquisaLote(
                      sender,
                      this.modelConfig.infnfe.det.fields.estoque.current,
                      this.modelConfig.infnfe.det.current.prod.produto.id,
                      this.modelConfig.infnfe.det.fields.estoque.current.grade.id,
                      undefined,
                      (lote) => {
                        this.modelConfig.infnfe.det.fields.estoque.current.numerolote = lote.numerolote;
                        this.modelConfig.infnfe.det.fields.estoque.current.datavalidadelote = lote.datavalidadelote;
                      }
                    );
                  },
                }),
                datavalidadelote: new CMInputConfig({
                  form: this,
                  caption: "Data Validade Lote",
                  mask: "99/99/9999",
                  gridConfig: new CMGridEditConfigColumn(),
                  requiredFunc: () => {
                    return this.currentProdutoUsaValidadeLote();
                  },
                  visibleFunc: () => {
                    return this.currentProdutoUsaValidadeLote();
                  },
                }),
                qcom: new CMInputConfig({
                  form: this,
                  caption: "Quantidade",
                  dataType: "double(2)",
                  gridConfig: new CMGridEditConfigColumn(),
                  onExit: () => {
                    this.calcTotalItemEstoque();
                  },
                  required: true,
                }),
              },
            }),
          },
        }),
        infadic: {
          infcpl: new CMInputConfig({ form: this, caption: "Observação" }),
        },
      },
    };
  }

  mostraDetalhesQuantidade(row) {
    let r = false;
    if (row && row.prod && row.prod.produto) {
      r =
        row.prod.produto.usagrade1 === "Sim" ||
        row.prod.produto.usagrade2 === "Sim" ||
        row.prod.produto.usalote === "Sim";
    }
    return r;
  }

  currentProdutoUsaLote() {
    return (
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usalote"
      ) === "Sim"
    );
  }

  currentProdutoUsaValidadeLote() {
    if (this.getValueObject(this.model, "infnfe.ide.tpnf") == "Entrada")
      return (
        this.getValueObject(
          this.modelConfig.infnfe.det.current,
          "prod.produto.usavalidadelote"
        ) === "Sim"
      );
    else return false;
  }

  currentProdutoUsaGrade() {
    return (
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usagrade1"
      ) === "Sim" ||
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usagrade2"
      ) === "Sim"
    );
  }

  calcTotalItemEstoque() {
    let item = this.modelConfig.infnfe.det.current;
    let qtotal = 0;
    item.estoque.forEach((est) => {
      qtotal += this.valueToFloat(est.qcom);
    });
    item.prod.qcom = this.floatToValue(qtotal);
  }
}
