// tslint:disable
import { Component, Injector } from "@angular/core";
import { CMFormGlobaisComponent, CMInputConfig } from "src/app/@cmcore/component";
import { CMFormComponent } from "src/app/@cmcore/component/cmform/cmform.component";

@Component({
  selector: "solicita-senha-supervisor",
  templateUrl: "solicita-senha-supervisor.component.html",
})
export class SolicitaSenhaSupervisor extends CMFormComponent {
  modelConfig = {
    login: new CMInputConfig({
      form: this,
      caption: "Usuário",
      required: true,
      maxlength: 50,
    }),
    senha: new CMInputConfig({
      form: this,
      caption: "Senha",
      required: true,
      maxlength: 15,
      criptografado: true,
    }),
  };

  model = {
    login: "",
    senha: "",
  };

  constructor(_injector: Injector) {
    super(_injector);
  }

  validate(callYes) {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/cliente/LiberaVendaContaVencida",
      JSON.stringify(this.model),
      (res) => {
        CMFormGlobaisComponent.hideSpinner();
        if (res["ret"] == "ok") {
          callYes(); 
        } else {
          CMFormGlobaisComponent.showMessageAviso('<p>Usuário não possui a permissão para liberação.</p>');
        }
      },
      (error) => {
        this.showMessageErrorHTTP("LiberaVendaContaVencida", error);
        CMFormGlobaisComponent.hideSpinner();
      }
    );    
  }
}
