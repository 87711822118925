// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormEditComponent, CMInputConfig, CMFormGlobaisComponent
       } from './../../@cmcore/component';
import { FormaPagamentoBrowseComponent } from './formapagamento-browse.component';
import { ContaBancariaBrowseComponent } from '../conta-bancaria';

@Component({
    selector: 'formapagamento-edit',
    templateUrl: 'formapagamento-edit.component.html',
})
export class FormaPagamentoEditComponent extends CMFormEditComponent {

  getFirstComponentName() {
    return 'model_descricao';
  }

  getTitle() {
    return 'Forma de Pagamento';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'formapagamento';
    this.setModelConfig();
  }


  afterGetModelData() {
    super.afterGetModelData();
    console.log("this.model: ", this.model);
  }

  setModelConfig() {
    this.modelConfig = {
      codigo: new CMInputConfig({form: this,
                                 caption: '#',
                                 disabled: true}),
      descricao: new CMInputConfig({form: this,
                                    caption: 'Descrição',
                                    maxlength: 100,
                                    required: true}),
      tpagnfe: new CMInputConfig({form: this,
                                  caption: 'Tipo de Pagamento na NF-e / SAT',
                                  required: true,
                                  values: [
                                    {'value': "Dinheiro", "text": "Dinheiro"},
                                    {'value': "Cheque", "text": "Cheque"},
                                    {'value': "CartaoCredito", "text": "Cartão de Crédito"},
                                    {'value': "CartaoDebito", "text": "Cartão de Débito"},
                                    {'value': "CreditoLoja", "text": "Crédito Loja"},
                                    {'value': "ValeAlimentacao", "text": "Vale Alimentação"},
                                    {'value': "ValeRefeicao", "text": "Vale Refeição"},
                                    {'value': "ValePresente", "text": "Vale Presente"},
                                    {'value': "ValeCombustivel", "text": "Vale Combustível"},
                                    {'value': "BoletoBancario", "text": "Boleto Bancário"},
                                    {'value': "DepBancario", "text": "Depósito Bancário"},
                                    {'value': "PIX", "text": "PIX"},
                                    {'value': "Transferencia", "text": "Transferência bancária, Carteira Digital"},
                                    //{'value': "ProgFidelidade", "text": "Programa de fidelidade, Cashback, Crédito Virtual"},
                                    //{'value': "Sempagamento", "text": "Sem pagamento"},
                                    //{'value': "Outros", "text": "Outros"}
                                  ],
                                }),
      geraboleto: new CMInputConfig({form: this,
                                     caption: 'Gera Boleto'}),
      usaapipix: new CMInputConfig({form: this,
                                    caption: 'PIX: Usa API'}),
      taxa: new CMInputConfig({
        form: this,
        caption: "Taxa (%)",
        dataType: "double(2)",
      }),
                                     
      contabancaria: new CMInputConfig({
        form: this,
        caption: "Conta Bancária",
        required: true,
        pesqFunc: (sender) => {
          ContaBancariaBrowseComponent.pesquisa(sender, this.model, "Conta Bancária");
          console.log('thismodel: ', this.model);
        },
      }),
    };
  }

  afterSave() {
    FormaPagamentoBrowseComponent.clearValuesSelect();
  }

}