// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormEditComponent, CMInputConfig, CMGridEditConfig, CMGridEditConfigColumn
       } from './../../@cmcore/component';

@Component({
    selector: 'ibpt-edit',
    templateUrl: 'ibpt-edit.component.html',
})
export class IBPTEditComponent extends CMFormEditComponent {

  getFirstComponentName() {
    return 'model_codigo';
  }

  getTitle() {
    return 'IBPT (NCM/SH) - '+this.fieldByName('descricao');
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'ibpt';
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      codigo: new CMInputConfig({form: this,
                                 caption: 'Código (NCM/SH)',
                                 dataType: 'integer',
                                 maxlength: 9,
                                 disabledFunc: () => {return !this.isCustom()},
                                 required: true}),
      ex: new CMInputConfig({form: this,
                             caption: 'EX (Código da exceção à regra aplicada na NCM)',
                             dataType: 'integer',
                             disabledFunc: () => {return !this.isCustom()}}),
      tipo: new CMInputConfig({form: this,
                               caption: 'Tipo',
                               values: [{'value': "Mercadoria"},
                                        {'value': "ServicoNBS", 'text': "Serviço NBS"},
                                        {'value': "ServicoLC116", 'text': "Serviço LC 116"}],
                               disabledFunc: () => {return !this.isCustom()}}),
      descricao: new CMInputConfig({form: this,
                                    caption: 'Descrição',
                                    disabledFunc: () => {return !this.isCustom()},
                                    required: true}),
      vigenciainicio: new CMInputConfig({form: this,
                                         caption: 'Inicio da vigência',
                                         disabled: true}),
      vigenciafim: new CMInputConfig({form: this,
                                      caption: 'Fim da vigência',
                                      disabled: true}),
      versao: new CMInputConfig({form: this,
                                 caption: 'Versão',
                                 disabled: true}),
      custom: new CMInputConfig({form: this,
                                 caption: 'Alterado pelo usuário',
                                 maxlength: 10}),
      estados: new CMGridEditConfig({form: this,
                                   caption: 'Estados',
                                   onNewItem: model => {
                                     Object.assign(model, {
                                       estado: 'SP',
                                       aliquotanacional: '0,00',
                                       aliquotaimportada: '0,00',
                                       aliquotaicms: '0,00',
                                       aliquotamunicipal: '0,00',
                                      });
                                   },
                                   fields: {estado: new CMInputConfig({form: this,
                                                                       caption: 'Estado',
                                                                       required: true,
                                                                       disabledFunc: () => {return !this.isCustom()},
                                                                       maxlength: 2,
                                                                       gridConfig: new CMGridEditConfigColumn(),
                                                                       values: [{'value': "AC"},
                                                                                {'value': "AL"},
                                                                                {'value': "AP"},
                                                                                {'value': "AM"},
                                                                                {'value': "BA"},
                                                                                {'value': "CE"},
                                                                                {'value': "DF"},
                                                                                {'value': "ES"},
                                                                                {'value': "GO"},
                                                                                {'value': "MA"},
                                                                                {'value': "MT"},
                                                                                {'value': "MS"},
                                                                                {'value': "MG"},
                                                                                {'value': "PR"},
                                                                                {'value': "PB"},
                                                                                {'value': "PA"},
                                                                                {'value': "PE"},
                                                                                {'value': "PI"},
                                                                                {'value': "RJ"},
                                                                                {'value': "RN"},
                                                                                {'value': "RS"},
                                                                                {'value': "RO"},
                                                                                {'value': "RR"},
                                                                                {'value': "SC"},
                                                                                {'value': "SE"},
                                                                                {'value': "SP"},
                                                                                {'value': "TO"}]}),
                                            aliquotanacional: new CMInputConfig({form: this,
                                                                                 caption: 'Alíquota nacional',
                                                                                 dataType: 'double(2)',
                                                                                 gridConfig: new CMGridEditConfigColumn(),
                                                                                 disabledFunc: () => {return !this.isCustom()}}),
                                            aliquotaimportada: new CMInputConfig({form: this,
                                                                                  caption: 'Alíquota importada',
                                                                                  dataType: 'double(2)',
                                                                                  gridConfig: new CMGridEditConfigColumn(),
                                                                                  disabledFunc: () => {return !this.isCustom()}}),
                                            aliquotaicms: new CMInputConfig({form: this,
                                                                             caption: 'Alíquota ICMS',
                                                                             dataType: 'double(2)',
                                                                             gridConfig: new CMGridEditConfigColumn(),
                                                                             disabledFunc: () => {return !this.isCustom()}}),
                                            aliquotamunicipal: new CMInputConfig({form: this,
                                                                                  caption: 'Alíquota municipal',
                                                                                  dataType: 'double(2)',
                                                                                  gridConfig: new CMGridEditConfigColumn(),
                                                                                  disabledFunc: () => {return !this.isCustom()}}),
                                           }}),
    };
  }

  isCustom() {
    let r: boolean = false;
    if (this.model)
      r = (this.model.custom === 'Sim');
    return r;
  }

}