// tslint:disable
import {Component, OnInit, Input, EventEmitter, Output, ViewChild} from '@angular/core';

import {CMGridColumnComponent} from './cmgrid-column.component';
import {CMInput} from '../../decorator';
import {CMFormGlobaisComponent, CMInputConfig, CMGridEditConfig} from '../../component';
import {CMFormComponent} from '../../component/cmform/cmform.component';
//import { DatatableComponent } from 'angular2-data-table';

declare var $: any;

export class CMGridConfig {
  public fields = {};
  private innerRequired: boolean = true;
  get required() {
    let r = this.innerRequired;
    if (this.requiredFunc !== undefined) r = this.requiredFunc();
    return r;
  }
  set required(value: boolean) {
    this.innerRequired = value;
  }
  public requiredFunc: Function = undefined;
  public gridName: string = '';
  public onNewItem: Function = undefined;
  public onRemoveItem: Function = undefined;
  public onAllowRemoveItem: Function = undefined;
  public customValidItem: Function = undefined;
  public caption: string = '';
  public form: CMFormComponent = null;
  public focusComponentOnSelect: string = '';
  public objName: string = '';

  constructor(data?: any) {
    if (data) {
      if (data.fields !== undefined) this.fields = data.fields;
      if (data.required !== undefined) this.required = data.required;
      if (data.requiredFunc !== undefined) this.requiredFunc = data.requiredFunc;
      if (data.grid !== undefined) this.gridName = data.grid;
      if (data.form !== undefined) this.form = data.form;
      if (data.onNewItem !== undefined) this.onNewItem = data.onNewItem;
      if (data.onRemoveItem !== undefined) this.onRemoveItem = data.onRemoveItem;
      if (data.onAllowRemoveItem !== undefined) this.onAllowRemoveItem = data.onAllowRemoveItem;
      if (data.caption !== undefined) this.caption = data.caption;
      if (data.focusComponentOnSelect !== undefined)
        this.focusComponentOnSelect = data.focusComponentOnSelect;
      if (data.customValidItem !== undefined) this.customValidItem = data.customValidItem;
      if (data.objName !== undefined) this.objName = data.objName;
    }
  }

  static getDataFields(fields) {
    let propFields = Object.getOwnPropertyNames(fields);
    let ret = {};
    for (var i = 0; i < propFields.length; ++i) {
      let field = propFields[i];
      if (fields[field] instanceof CMInputConfig) ret[field] = fields[field].clone();
      else if (fields[field] instanceof CMGridConfig) ret[field] = fields[field].clone();
      else if (fields[field] instanceof CMGridEditConfig) ret[field] = fields[field].clone();
      //else if (this.fields[field] instanceof CMListCheckBoxConfig)
      //  newFields[field] = this.fields[field].clone();
      else if (CMFormComponent.isBaseObject(fields[field])) {
        ret[field] = CMGridConfig.getDataFields(fields[field]);
      }
    }
    return ret;
  }

  getData() {
    let data: any = {};
    if (this.fields !== undefined) data.fields = CMGridConfig.getDataFields(this.fields);
    if (this.required !== undefined) data.required = this.required;
    if (this.requiredFunc !== undefined) data.requiredFunc = this.requiredFunc;
    if (this.gridName !== undefined) data.gridName = this.gridName;
    if (this.form !== undefined) data.form = this.form;
    if (this.onNewItem !== undefined) data.onNewItem = this.onNewItem;
    if (this.onRemoveItem !== undefined) data.onRemoveItem = this.onRemoveItem;
    if (this.onAllowRemoveItem !== undefined) data.onAllowRemoveItem = this.onAllowRemoveItem;
    if (this.caption !== undefined) data.caption = this.caption;
    if (this.focusComponentOnSelect !== undefined)
      data.focusComponentOnSelect = this.focusComponentOnSelect;
    if (this.customValidItem !== undefined) data.customValidItem = this.customValidItem;
    if (this.objName !== undefined) data.objName = this.objName;
    return data;
  }

  public grid: CMGridComponent;

  static newId() {
    return CMGridComponent.newId();
  }

  private emptyModel: any = undefined;
  public get current() {
    let r = {};
    let g = this.grid;
    if (g) r = g.current;
    else {
      if (!this.emptyModel) {
        this.emptyModel = {};
        CMFormComponent.generateEmptyModel(this.fields, r);
      }
      r = this.emptyModel;
    }
    return r;
  }

  get count() {
    let g = this.grid;
    if (g) return g.count;
    else return 0;
  }

  unSelect() {
    let g = this.grid;
    if (g) g.unSelect();
  }

  public unSelectFields() {
    let g = this.grid;
    if (g) g.unSelectFields();
  }

  validItem(item: any) {
    let r: string = '';
    if (this.customValidItem !== undefined) r = this.customValidItem(item);
    if (r !== '') CMFormGlobaisComponent.toastErro(r, this.caption);
    return r === '';
  }

  get isCurrent() {
    let g = this.grid;
    if (g) return g.isCurrent;
    else return false;
  }

  public fieldByName(aFieldName: string) {
    return CMFormComponent.getValueObject(this.current, aFieldName);
  }

  clone() {
    return new CMGridConfig(this.getData());
  }

  public selectFirst(setFocus: boolean = true) {
    let g = this.grid;
    if (g) g.selectFirst(setFocus);
  }
}

@Component({
  selector: 'cmgrid-nao-usar',
  templateUrl: './cmgrid.component.html',
})
export class CMGridComponent implements OnInit {
  @ViewChild('datatable', {static: true}) datatable: any; //DatatableComponent;

  @Input() caption: string = '';
  private internalGridConfig: CMGridConfig = null;
  @Input()
  get gridConfig() {
    return this.internalGridConfig;
  }
  set gridConfig(value: CMGridConfig) {
    if (this.internalGridConfig) this.internalGridConfig.grid = null;
    this.internalGridConfig = value;
    if (this.internalGridConfig) this.internalGridConfig.grid = this;
  }
  private messagesDataTable = {emptyMessage: 'Sem dados para exibir', totalMessage: 'total'};
  private processUpdateRows() {
    this.unSelect();
    const start = this.page * this.limit;
    let rows = [];
    let max = this.innerRows.length - start;
    if (max > this.limit) max = this.limit;
    for (let i = 0; i < max; i++) {
      rows[i] = this.innerRows[i + start];
      rows[i].$$index = i;
    }
    this.setPageData(rows, this.innerRows.length);
  }
  private innerRows = [];
  private usingRows: boolean = false;
  get rows() {
    return this.innerRows;
  }
  @Input()
  set rows(value: any) {
    this.usingRows = true;
    if (!value) value = [];
    this.innerRows = value;
    this.limit = 5;
    this.page = 0;
    this.activeRows = [];
    this.processUpdateRows();
  }

  private activeRows = [];
  public selected = [];
  public getActiveRows() {
    return this.activeRows;
  }
  @Input() @CMInput() public count: number = 0;
  @Input() @CMInput() public page: number = 0;
  @Input() @CMInput() public limit: number = 10;
  @Input() public rowHeight: string = '40px';
  @Input() public selectionType: string = 'single';
  @Output() public onPage: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onNewItem: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onDblClick: EventEmitter<any> = new EventEmitter<any>();
  @Input() @CMInput() public defaultAddItem: boolean = true;
  @Input() @CMInput() public defaultRemoveItem: boolean = true;
  @Output() public onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onRemove: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onRefresh: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onUnSelect: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onSelect: EventEmitter<any> = new EventEmitter<any>();
  @Input() public focusComponentOnSelect: string = '';
  @Input() @CMInput() public showActionAdd: boolean = true;
  @Input() @CMInput() public showActionEdit: boolean = false;
  @Input() @CMInput() public showActionRemove: boolean = true;
  @Input() @CMInput() public showActionRefresh: boolean = true;
  getShowActionAdd() {
    let r: boolean = true;
    if (this.gridConfig && this.gridConfig.form) {
      if (this.gridConfig.form.disabledAll !== undefined) r = !this.gridConfig.form.disabledAll;
    }
    if (r) r = this.showActionAdd;
    return r;
  }
  getShowActionEdit() {
    let r: boolean = true;
    if (this.gridConfig && this.gridConfig.form) {
      if (this.gridConfig.form.disabledAll !== undefined) r = !this.gridConfig.form.disabledAll;
    }
    if (r) r = this.showActionEdit && this.selected.length > 0;
    return r;
  }
  getShowActionRemove() {
    let r: boolean = true;
    if (this.gridConfig && this.gridConfig.form) {
      if (this.gridConfig.form.disabledAll !== undefined) r = !this.gridConfig.form.disabledAll;
    }
    if (r) r = this.showActionRemove && this.selected.length > 0;
    return r;
  }
  showActions() {
    return (
      this.getShowActionAdd() ||
      this.getShowActionEdit() ||
      this.getShowActionRemove() ||
      this.showActionRefresh ||
      this.getTitle() !== ''
    );
  }

  private loading: boolean = false;
  public columns: CMGridColumnComponent[] = [];
  public visibleColumns: CMGridColumnComponent[] = [];

  public setPageData(rowsData: any, totalCount: number) {
    this.unSelect();
    const start = this.page * this.limit;
    let rows = [...this.activeRows];
    for (let i = 0; i < rowsData.length; i++) {
      rows[i + start] = rowsData[i];
      rows[i + start].$$index = i;
    }
    this.activeRows = rows;
    this.count = totalCount;
    this.loading = false;
    if (this.usingRows) {
      setTimeout(() => {
        this.selectFirst(false);
      }, 100);
    }
  }

  selectIndex(index: number, setFocus: boolean = true) {
    let p = index / this.limit;
    p = Math.floor(p);
    this.page = p;
    this.internalSelect(this.activeRows[index], setFocus);
  }

  public get firstSelected() {
    if (this.selected.length > 0) return this.selected[0];
    else return {};
  }

  public get isCurrent() {
    if (this.count > 0 && this.selected && this.selected.length > 0) return true;
    else return false;
  }

  private emptyModel: any = undefined;
  public get current() {
    let r = {};
    if (this.isCurrent && this.selected[0] !== undefined) r = this.selected[0];
    else if (this.gridConfig) {
      if (!this.emptyModel) {
        this.emptyModel = {};
        CMFormComponent.generateEmptyModel(this.gridConfig.fields, r);
      }
      r = this.emptyModel;
    }
    return r;
  }

  public fieldByName(aFieldName: string) {
    return CMFormComponent.getValueObject(this.current, aFieldName);
  }

  public addColumn(column: CMGridColumnComponent) {
    this.columns.push(column);
    if (column.visible) this.visibleColumns.push(column);
  }

  public changeVisibleColumns() {
    this.visibleColumns = [];
    this.columns.forEach((column) => {
      if (column.visible) this.visibleColumns.push(column);
    });
    this.recalculateColumns();
  }

  private doOnPage(page: number, limit: number) {
    this.loading = true;
    this.page = page;
    this.limit = limit;
    const start = this.page * this.limit;
    let rows = [...this.activeRows];
    for (let i = 0; i < limit; i++) {
      rows[i + start] = undefined;
    }
    this.activeRows = rows;
    this.onPage.emit(this);
    if (this.usingRows) this.processUpdateRows();
  }

  private _onPage(event) {
    this.doOnPage(event.offset, event.limit);
  }

  public Add(onAdd?: Function) {
    this._onAdd(null, onAdd);
  }

  static newId() {
    let id: number = CMInputConfig.getRandomInt(1, 9999) * -1;
    return id;
  }

  public _onAdd(event, onAdd?: Function) {
    this.unSelect();
    setTimeout(() => {
      this.onAdd.emit(this);
      if (this.defaultAddItem) {
        let newId: number = CMGridComponent.newId();
        let item = {};
        CMFormComponent.generateEmptyModel(this.gridConfig.fields, item);
        item['$$index'] = '';
        item['id'] = newId;
        let r = this.rows;
        r.splice(0, 0, item);
        this.onNewItem.emit(item);
        this.rows = r;
        this.selectFirst();
        if (onAdd !== undefined) onAdd();
      }
    }, 50);
  }

  public _onEdit(event) {
    this.onEdit.emit(this);
  }

  public _onRemove(event) {
    if (this.isCurrent) {
      CMFormGlobaisComponent.showConfirmation(
        'Confirmação',
        '<div class="container"><cmrow><p>Deseja apagar o registro selecionado?</p></cmrow></div>',
        () => {
          let allow: boolean = true;
          if (this.gridConfig) {
            if (this.gridConfig.onAllowRemoveItem !== undefined) {
              let s: string = this.gridConfig.onAllowRemoveItem(this.current);
              allow = s === '';
              if (!allow)
                CMFormGlobaisComponent.showMessageAviso(
                  '<div class="container"><p>' + s + '</p></div>',
                );
            }
          }
          if (allow) {
            if (this.gridConfig) {
              if (this.gridConfig.onRemoveItem !== undefined)
                this.gridConfig.onRemoveItem(this.current);
            }
            this.onRemove.emit(this);
            if (this.defaultRemoveItem) {
              let r: any = this.rows;
              r.splice(this.selected[0].$$index, 1);
              this.rows = r;
            }
          }
        },
      );
    }
  }

  public _onRefresh(event) {
    this.refreshCurrentPage();
    this.onRefresh.emit(this);
  }

  public unSelectFields() {
    if (this.gridConfig && this.gridConfig.fields) {
      let fields = this.gridConfig.fields;
      let props = Object.getOwnPropertyNames(fields);
      for (var i = 0; i < props.length; ++i) {
        let prop = props[i];
        if (fields[prop] instanceof CMGridConfig) {
          let gridConfig: CMGridConfig = fields[prop];
          gridConfig.unSelect();
        }
      }
    }
  }

  public unSelect() {
    this.unSelectFields();
    this.selected.splice(0, 0);
    this.selected = [];
    this.onUnSelect.emit(this);
  }

  private internalSelect(selected, setFocus: boolean) {
    this.unSelect();
    this.selected.splice(0, this.selected.length);
    this.selected.push(selected);
    if (setFocus) {
      if (this.focusComponentOnSelect !== '') {
        setTimeout(() => {
          $('#' + this.focusComponentOnSelect).focus();
        }, 10);
      }
    }
    this.onSelect.emit(this.current);
  }

  private _onSelect({selected}: any) {
    this.unSelect();
    setTimeout(() => {
      this.selected.splice(0, this.selected.length);
      this.selected.push(...selected);
      if (this.focusComponentOnSelect !== '') {
        setTimeout(() => {
          $('#' + this.focusComponentOnSelect).focus();
        }, 10);
      }
      this.onSelect.emit(this.current);
    }, 10);
  }

  public ngOnInit() {
    if (this.gridConfig) {
      this.focusComponentOnSelect = this.gridConfig.focusComponentOnSelect;
      if (this.gridConfig.onNewItem !== undefined)
        this.onNewItem.subscribe(this.gridConfig.onNewItem);
    }
    this.doOnPage(this.page, this.limit);
  }

  public refreshCurrentPage() {
    this.unSelect();
    this.doOnPage(this.page, this.limit);
  }

  public selectFirst(setFocus: boolean = true) {
    let i = this.page * this.limit;
    if (this.activeRows.length - 1 >= i) {
      if (this.activeRows[i] !== undefined && JSON.stringify(this.activeRows[i]) !== '{}')
        this.internalSelect(this.activeRows[i], setFocus);
      else this.unSelect();
    } else this.unSelect();
  }

  getTitle() {
    if (this.gridConfig) return this.gridConfig.caption;
    else return this.caption;
  }

  public recalculateColumns() {
    if (this.datatable) this.datatable.recalculate();
  }

  public clearColumns(ignoreColumnsName: string[] = []) {
    let new_columns = [];
    let new_visibleColumns = [];
    if (ignoreColumnsName.length > 0) {
      this.columns.forEach((column) => {
        if (ignoreColumnsName.indexOf(column.name) > -1) new_columns.push(column);
      });
      this.visibleColumns.forEach((column) => {
        if (ignoreColumnsName.indexOf(column.name) > -1) new_visibleColumns.push(column);
      });
    }
    this.columns = new_columns;
    this.visibleColumns = new_visibleColumns;
    if (this.datatable) this.datatable.columns = [];
  }

  _dblClick(event) {
    this.onDblClick.emit(this);
  }
}
