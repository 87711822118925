// tslint:disable
import { Injector, Input } from "@angular/core";

import { CMFormBrowseComponent } from "./";

import { CMFormGlobaisComponent } from "../../../component";

export class CMFormBrowse2Component extends CMFormBrowseComponent {
  constructor(_injector: Injector) {
    super(_injector);
    let g: any = this;
    this.grid = g;
  }

  page = 0;
  limit = 10;
  rowsData = [];
  selected = [];
  totalCount: number = 0;
  totalPage: number = 0;
  pages = [];
  depthPages = [];
  public setPageData(rowsData: any, totalCount: number) {
    this.rowsData = rowsData;
    this.totalCount = totalCount;
    this.totalPage = Math.ceil(this.totalCount / this.limit);
    this.pages = [];
    for (let i = 0; i < this.totalPage; i++) this.pages.push(i);
    let iniPage = 0;
    if (this.page > 5) iniPage = this.page - 4;
    let fimPage = iniPage + 9;
    if (fimPage > this.totalPage) {
      fimPage = this.totalPage;
      iniPage = fimPage - 9;
      if (iniPage < 0) iniPage = 0;
    }
    this.depthPages = [];
    if (this.totalPage > 0) {
      for (let i = iniPage; i < fimPage; i++) {
        this.depthPages.push(i);
      }
    }
    setTimeout(() => {
      this.config_dropdown_button();
    }, 1000);
  }

  config_dropdown_button() {
    let d: any = $(".dropdown-trigger");
    d.dropdown({
      inDuration: 300,
      outDuration: 225,
      constrainWidth: false, // Does not change width of dropdown to that of the activator
      hover: false, // Activate on hover
      gutter: 0, // Spacing from edge
      coverTrigger: true, // Displays dropdown below the button
      alignment: "left", // Displays dropdown with edge aligned to the left of button
    });
  }

  selectFirst() {
    if (this.count > 0) this.selected = [this.rowsData[0]];
  }

  incPage() {
    this.setPage(this.page + 1);
  }

  decPage() {
    this.setPage(this.page - 1);
  }

  firstPage() {
    this.setPage(0);
  }

  lastPage() {
    this.setPage(this.totalPage);
  }

  setPage(page) {
    if (page < 0) page = 0;
    if (page > this.totalPage - 1) page = this.totalPage - 1;
    if (this.page != page) {
      this.page = page;
      let g: any = this;
      this.onPage(g);
    }
  }

  showAcoes(item) {
    this.selected = [item];
  }

  row_click(item, formEditDynamic, column) {
    this.selected = [item];
    if (column.iconClick === undefined) {
      if (this.getShowMenuActions()) {
        if (this.getShowActionEdit()) {
          this.edit(formEditDynamic);
        } else {
          this.view(formEditDynamic);
        }
      }
    } else {
      column.iconClick(item, column);
    }
  }

  refreshCurrentPage() {
    this.selected = [];
    let g: any = this;
    this.onPage(g);
  }

  buttonsExtraGridClick(index: number, formEditDynamic) {
    this.__formEditDynamic = formEditDynamic;
    this.buttonsExtraClick(index);
  }

  private emptyModel: any = undefined;
  public get current() {
    let r = {};
    if (this.isCurrent && this.selected[0] !== undefined) r = this.selected[0];
    else {
      if (!this.emptyModel) {
        this.emptyModel = {};
        this.generateEmptyModel(this.columns, r);
      }
      r = this.emptyModel;
    }
    return r;
  }

  public fieldByName(aFieldName: string) {
    return this.getValueObject(this.current, aFieldName);
  }

  public _onRemove(event) {
    if (this.isCurrent) {
      CMFormGlobaisComponent.showConfirmation(
        "Confirmação",
        '<div class="container"><cmrow><p style="text-align: center; font-size: 1.5em;">Deseja apagar o registro selecionado?</p></cmrow></div>',
        () => {
          if (this.diretoApagar) {
            let id = this.grid.selected[0][this.fieldNameId()];
            this.grid.selected = [];
            this.deleteModel(id);
          }
        }
      );
    }
  }

  public get count() {
    if (this.rowsData) return this.rowsData.length;
    else return 0;
  }

  public get isCurrent() {
    if (this.count > 0 && this.selected && this.selected.length > 0)
      return true;
    else return false;
  }

  heightGrid() {
    if (this._hideShowFilters) {
      let height = "0";
      const PN_Pesquisa = $("#PN_Pesquisa");
      if (PN_Pesquisa && PN_Pesquisa.height) height = PN_Pesquisa.height() + "";
      height = "calc(100% - " + height + "px)";
      return height;
    } else return "100%";
  }

  modalFormEditClose() {
    const callback = () => {
      if ($(".warpper-form-edit")[0].scrollTo)
        $(".warpper-form-edit")[0].scrollTo(0, 0);
      super.modalFormEditClose();
    };

    if (!this.formEdit.disabledAll && this.formEdit.modified()) {
      CMFormGlobaisComponent.showConfirmation(
        "ATENÇÃO",
        '<p style="text-align: center; font-size: 1.5em;">As informações foram modificadas. Deseja mesmo cancelar a operação?</p>',
        callback
      );
    } else {
      callback();
    }
  }

  permiteFiltros() {
    return true;
  }

  getcolor(item) {
    return undefined;
  }
}
