// tslint:disable
import { Input, forwardRef, Component } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import {
  CMInputComponent,
  CMInputComponent_props,
  CMOutputComponent_props,
} from "./cminput.component";
import { CMInput } from "../../decorator";
import * as M from "materialize-css/dist/js/materialize";

export const CMMEMO_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CMMemoComponent),
  multi: true,
};

@Component({
  selector: "cmmemo",
  templateUrl: "cmmemo.component.html",
  styleUrls: ["./cminput.component.scss"],
  providers: [CMMEMO_CONTROL_VALUE_ACCESSOR],
  inputs: CMInputComponent_props,
  outputs: CMOutputComponent_props,
})
export class CMMemoComponent extends CMInputComponent {
  @Input()
  @CMInput()
  autoSizeOnLoad: boolean = true;

  protected onSetValue(oldValue: any, newValue: any) {
    super.onSetValue(oldValue, newValue);
    if (this.autoSizeOnLoad) {
      if (oldValue !== newValue) this.setCheckAutoSize();
    }
  }

  checkAutoSize: any = undefined;
  clearCheckAutoSize() {
    if (this.checkAutoSize) {
      clearInterval(this.checkAutoSize);
      this.checkAutoSize = undefined;
    }
  }
  setCheckAutoSize() {
    if (!this.checkAutoSize) {
      this.checkAutoSize = setInterval(() => {
        this.checkAutoSizeFn();
      }, 1000);
    }
  }

  ngOnDestroy() {
    this.clearCheckAutoSize();
  }

  checkAutoSizeFn() {
    let textarea = $("#" + this.name);
    if (textarea) {
      if (textarea.width() > 0) {
        this.clearCheckAutoSize();
        M.textareaAutoResize(textarea);
      }
    }
  }
}
