// tslint:disable
import { Injector } from '@angular/core';

import { 
  CMFormComponent 
} from '../../../component/cmform/cmform.component';

export class CMFormExecComponent extends CMFormComponent {

  constructor(_injector: Injector) {
    super(_injector);
  }

  public functionName: string = "";
  public controllerName: string ="";
  public downloadFiles: boolean = false;
  public filter: any = {};
  private innerFilterConfig = {};
  public beforeComponent: any = undefined;
  public get filterConfig() {
    return this.innerFilterConfig;
  }
  public set filterConfig(value: any) {
    this.innerFilterConfig = value;
    this.filter = {};
    this.generateEmptyModel(value, this.filter);
  }

  getFirstComponentName() {
    return "";
  }

  focusFirstComponentEdit() {
    if (this.getFirstComponentName() !== "")
      $("#" + this.getFirstComponentName()).focus();
  }

  valid() {
    return super.valid(this.filterConfig, this.filter);
	}

	innerExec() {
    if (this.downloadFiles)
      this.downloadFile(this.functionName, this.filter, this.controllerName);
	}

  exec() {
    if (this.valid())
      this.innerExec();
  }

}