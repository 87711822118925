// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormRelatComponent, CMInputConfig
       } from './../../../@cmcore/component';

@Component({
    selector: 'clientes-aniversariantes',
    templateUrl: 'clientes-aniversariantes.component.html'
})
export class RelatClientesAniversariantesComponent extends CMFormRelatComponent {

  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = 'ImpressaoClientesAniversariantes';
    this.filterConfig = {
      mes: new CMInputConfig({form: this,
                              caption: 'Mês',
                              values: [
                                {value: '1', text: 'Janeiro'},
                                {value: '2', text: 'Fevereiro'},
                                {value: '3', text: 'Março'},
                                {value: '4', text: 'Abril'},
                                {value: '5', text: 'Maio'},
                                {value: '6', text: 'Junho'},
                                {value: '7', text: 'Julho'},
                                {value: '8', text: 'Agosto'},
                                {value: '9', text: 'Setembro'},
                                {value: '10', text: 'Outubro'},
                                {value: '11', text: 'Novembro'},
                                {value: '12', text: 'Dezembro'},
                              ],
                             })
    }
    this.filter.mes = this.mes;
  }

}