// tslint:disable
import { Component, Injector } from '@angular/core';
import { FormaPagamentoBrowseComponent } from '../../formapagamento';

import { CMFormRelatComponent, CMInputConfig,
         CMFormGlobaisComponent
       } from './../../../@cmcore/component';
import { FornecedorBrowseComponent } from './../../fornecedor/fornecedor-browse.component';

@Component({
    selector: 'conta-pagar',
    templateUrl: 'conta-pagar.component.html'
})
export class RelatContaPagarComponent extends CMFormRelatComponent {

  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = 'ImpressaoContaPagar';
    this.filterConfig = {
      destinatario: new CMInputConfig({form: this,
                                       caption: 'Fornecedor',
                                       pesqFunc: sender => {
                                         FornecedorBrowseComponent.pesquisa(sender, this.filter, 'destinatario');
                                       }
                                      }),
      dataini: new CMInputConfig({form: this,
                                  caption: 'Data Inicial',
                                  mask: '99/99/9999'
                                 }),
      datafim: new CMInputConfig({form: this,
                                  caption: 'Data Final',
                                  mask: '99/99/9999'
                                 }),
      somentecontasemaberto: new CMInputConfig({form: this,
                                                caption: 'Somente contas em aberto',
                                                disabledFunc: () => {
                                                  return this.filter.tipopagamento == 'AVista';
                                                }
                                 }),
      formapagamento: new CMInputConfig({
        form: this,
        caption: "Forma de Pagamento",
        disabledFunc: () => {
          return this.filter.tipopagamento == 'AVista';
        },
        pesqFunc: (sender) => {
          FormaPagamentoBrowseComponent.pesquisa("todos", sender, this.filter);
        },
      }),
      tipopagamento: new CMInputConfig({
        form: this,
        caption: "Tipo de Recebimento",
        values: [
          { value: "Todos", text: "Todos" },
          { value: "AVista", text: "A Vista" },
          { value: "FormaPagamento", text: "Por forma de pagamento" },
        ],
      }),
    }
    this.filter.dataini = this.firstDate();
    this.filter.datafim = this.lastDate();
    this.filter.somentecontasemaberto = 'Sim';
    this.filter.tipopagamento = "Todos";
  }

  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.dataini);
      if (!r)
        CMFormGlobaisComponent.toastErro('Data inicial não informada');
    }
    if (r) {
      r = !this.isEmpty(this.filter.datafim);
      if (!r)
        CMFormGlobaisComponent.toastErro('Data final não informada');
    }
    return r;
  }

}