// tslint:disable
import {Component, Injector, HostListener} from '@angular/core';
import {AuthService} from './../../@cmcore/guard';

import {CMFormComponent} from './../../@cmcore/component/cmform/cmform.component';
import {FuncsGlobais} from './../funcs.globais';
import {MenuLateralComponent} from '../index';

@Component({
  selector: 'menu-principal',
  templateUrl: './menu-principal.component.html',
  styleUrls: ['./menu-principal.component.scss'],
})
export class MenuPrincipalComponent extends CMFormComponent {
  public constructor(public authService: AuthService, _injector: Injector) {
    super(_injector);
  }

  toggleDropdown(dropdown) {
    document.getElementById(dropdown).classList.toggle('menu-dropdown-show');
  }

  ngOnInit() {
    super.ngOnInit();
    if (MenuLateralComponent.defaultInstance)
      this.searchResult = MenuLateralComponent.defaultInstance.atalhos;
    this.loadNotificacoes();
  }

  showHideMenu() {
    MenuLateralComponent.defaultInstance.showHideMenu();
  }

  inHome() {
    return this.router.url.toLowerCase().startsWith('/home');
  }

  getNomeUsuario() {
    return CMFormComponent.currentUser().nome;
  }

  searchResult = [];
  searchEngine(event) {
    let input: any = document.getElementById('search-input');
    let matchingResults = [];
    let heading = document.querySelector('.search-heading');
    if (input.value === '') {
      heading.textContent = '';
      matchingResults = MenuLateralComponent.defaultInstance.atalhos;
    } else {
      heading.textContent = 'Pesquisa';
      MenuLateralComponent.defaultInstance.menus.forEach(function(menu) {
        if (menu.rotas) {
          menu.rotas.forEach((rota) => {
            if (rota.captionPesquisa.toUpperCase().includes(input.value.toUpperCase())) {
              matchingResults.push({
                caption: menu.grupo + ' -> ' + rota.caption,
                routerLink: rota.routerLink,
              });
            }
          });
        }
      });
    }
    this.searchResult = matchingResults;
  }

  empresaAtual() {
    return FuncsGlobais.titleEmpresaAtual;
  }

  public get empresas() {
    return FuncsGlobais.empresas;
  }

  notificacoes = [];
  loadNotificacoes() {
    this.notificacoes.length = 0;
    Object.assign(this.notificacoes, this.currentUserStorage.avisos);
  }

  getNotificationsIcon() {
    if (this.notificacoes.length === 0) return 'notifications_none';
    else return 'notifications_active';
  }

  public setIdEmpresa(id: string) {
    FuncsGlobais.setIdEmpresa(this.http, id, () => {
      this.loadNotificacoes();
    });
  }

  idServerEmpresa() {
    return FuncsGlobais.idServerEmpresa;
  }

  empresaDefinida() {
    return FuncsGlobais.idServerEmpresa !== '';
  }

  @HostListener('window:click', ['$event', '$event.target'])
  public _window_click(event: MouseEvent, targetElement: HTMLElement) {
    if (targetElement && !targetElement.matches('.menu-dropdown-button-click')) {
      let dropdowns = document.getElementsByClassName('menu-dropdown-content');
      for (let i = 0; i < dropdowns.length; i++) {
        let openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('menu-dropdown-show')) {
          openDropdown.classList.remove('menu-dropdown-show');
        }
      }
    }
  }
}
