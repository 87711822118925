// tslint:disable
import { Component } from '@angular/core';
import { AuthService } from './../../@cmcore/guard';

import { FuncsGlobais } from './../funcs.globais';

@Component({
  selector: 'footer-page',
  templateUrl: './footer-page.component.html'
})
export class FooterPageComponent {

  public constructor(public authService: AuthService) {}

  getCurrentYear() {
    return new Date().getFullYear();
  }

  empresaAtual() {
    return FuncsGlobais.titleEmpresaAtual;
  }

}
