// tslint:disable
import { Component, Input } from '@angular/core';

import { CMInput } from '../../decorator';
import { CMMenuComponent } from './cmmenu.component';

@Component({
  selector: 'cmmenu-item',
  template: '<ng-content></ng-content>'
})
export class CMMenuItemComponent {

  @Input() text: string = '';
  @Input() ordem: number = 0;
  @Input() mobileText: string = undefined;
  @Input() href: string = '';
  @Input() routerLink: string = '';
  @Input() icone: string = '';
  @Input() mobileIcone: string = undefined;
  @Input() icone_class: string = '';

  @Input()
  @CMInput()
  visible: boolean = true;

  @Input()
  @CMInput()
  showDesktop: boolean = true;

  @Input()
  @CMInput()
  showMobile: boolean = true;

  constructor(private menu: CMMenuComponent) {}

  ngOnInit() {
    this.menu.addItem(this);
  }

  getMobileText() {
    if (this.mobileText !== undefined) {
      return this.mobileText;
    }
    else {
      return this.text;
    }
  }

  getMobileIcone() {
    if (this.mobileIcone !== undefined) {
      return this.mobileIcone;
    }
    else {
      return this.icone;
    }
  }

}
