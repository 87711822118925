// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "./../../@cmcore/component/cmform/cmform.component";
import { AppComponent } from "../../app.component";

@Component({
  selector: "menu-lateral",
  templateUrl: "menu-lateral.component.html",
  styleUrls: ["menu-lateral.component.scss"],
})
export class MenuLateralComponent extends CMFormComponent {
  menus = [];

  atalhos = [];

  public static defaultInstance: MenuLateralComponent = undefined;

  constructor(_injector: Injector) {
    super(_injector);
    this.closeMenu();
    MenuLateralComponent.defaultInstance = this;
  }

  loadAtalhos() {
    let atalhos = this.atalhos;
    atalhos.length = 0;
    this.menus.forEach(function (menu) {
      if (menu.rotas) {
        menu.rotas.forEach((rota) => {
          if (rota.favorito) {
            atalhos.push({
              caption: menu.grupo + " -> " + rota.caption,
              routerLink: rota.routerLink,
            });
          }
        });
      }
      if (menu.subgrupos) {
        menu.subgrupos.forEach((subgrupo) => {
          if (subgrupo.rotas) {
            subgrupo.rotas.forEach((rota) => {
              if (rota.favorito) {
                atalhos.push({
                  caption:
                    menu.grupo +
                    " -> " +
                    subgrupo.grupo +
                    " -> " +
                    rota.caption,
                  routerLink: rota.routerLink,
                });
              }
            });
          }
        });
      }
    });
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadMenus();
  }

  loadMenus() {
    this.menus = this.currentUserStorage.menus;
    if (!this.menus) this.menus = [];
    this.loadAtalhos();
    setTimeout(() => {
      let c: any = $(".collapsible");
      c.collapsible();
    }, 100);
  }

  showHideMenu() {
    if (this.isShowMenuPrincipal()) this.closeMenu();
    else this.showMenuPrincipal({});
  }

  closeMenu() {
    this._isShowMenuPrincipal = false;
    this._lastMenuClick = undefined;
    setTimeout(() => {
      $(".li-collapsible-header").removeClass("active");
      $(".collapsible-body").hide();
    }, 10);
  }

  _isShowMenuPrincipal: boolean = false;
  _lastMenuClick: any = undefined;
  showMenuPrincipal(menu) {
    let b = true;
    if (menu && menu.rotas) {
      b = menu.rotas.length > 0;
    }
    if (b) {
      this._isShowMenuPrincipal = this._lastMenuClick !== menu;
      if (this._isShowMenuPrincipal) this._lastMenuClick = menu;
      else this.closeMenu();
    } else this.closeMenu();
  }

  isShowMenuPrincipal() {
    return this._isShowMenuPrincipal;
  }

  heightScreen() {
    AppComponent.heightMenuLateral2 = this.windowHeight + "px";
    return AppComponent.heightMenuLateral2;
  }

  getIconFavorito(rota) {
    if (rota.favorito) return "star";
    else return "star_border";
  }

  toggleFavorito(rota) {
    this.postSubscribe(
      "/api/MinhaConta/SaveFavorito",
      JSON.stringify(rota),
      (res) => {
        if (res["result"] == "ok") {
          rota.favorito = !rota.favorito;
          let user = JSON.parse(localStorage.getItem("user"));
          user.menus = this.menus;
          localStorage.setItem("user", JSON.stringify(user));
          this.loadAtalhos();
          return true;
        } else return false;
      },
      (error) => {
        this.showMessageErrorHTTP("toggleFavorito", error);
      }
    );
  }

  getUrlLogoLateral() {
    if (this._isShowMenuPrincipal) {
      return "/logo-lateral-open.png";
    } else {
      return "/logo-lateral-close.png";
    }
  }
}
