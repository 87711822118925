// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormRelatComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
} from "./../../../@cmcore/component";
import { VendedorBrowseComponent } from "../../vendedor";

@Component({
  selector: "vendas-por-dia",
  templateUrl: "vendas-por-dia.component.html",
})
export class RelatVendasPorDiaComponent extends CMFormRelatComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = "ImpressaoVendasPorDia";
    this.filterConfig = {
      vendedor: new CMInputConfig({
        form: this,
        caption: "Vendedor",
        pesqFunc: (sender) => {
          VendedorBrowseComponent.pesquisa(sender, this.filter);
        },
      }),
      dataini: new CMInputConfig({
        form: this,
        caption: "Data Inicial",
        mask: "99/99/9999",
      }),
      datafim: new CMInputConfig({
        form: this,
        caption: "Data Final",
        mask: "99/99/9999",
      }),
      tipoquebra1: new CMInputConfig({
        form: this,
        caption: "Quebra",
        values: [{ value: "Geral" }, { value: "Vendedor" }],
      }),
      consideraprevenda: new CMInputConfig({
        form: this,
        caption: "Considera Pré-Venda em aberto",
        disabledFunc: () => {
          if (this.filter.somenteprevenda === "Sim"){
            this.filter.consideraprevenda = "Não";
            return true;
          }
          else{
            return false;
          }

        }
      }),
      somenteprevenda: new CMInputConfig({
        form: this,
        caption: "Somente Pré-Venda em aberto",
        onSelect: (elm) => {
          console.log("onSelect");
        }
      }),
    };
    this.filter.dataini = CMFormRelatComponent.firstDate();
    this.filter.datafim = CMFormRelatComponent.lastDate();
    this.filter.tipoquebra1 = "Geral";
    this.filter.consideraprevenda = "Não";
  }

  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.dataini);
      if (!r) CMFormGlobaisComponent.toastErro("Data inicial não informada");
    }
    if (r) {
      r = !this.isEmpty(this.filter.datafim);
      if (!r) CMFormGlobaisComponent.toastErro("Data final não informada");
    }
    return r;
  }
}
