// tslint:disable
import { Component, Injector, ViewChild } from "@angular/core";
import { FuncsGlobais } from "../funcs.globais";

import {
  CMInputConfig,
  CMFormExecComponent,
  CMUploadFileComponent,
  CMFormGlobaisComponent,
} from "./../../@cmcore/component";
import {
  PessoaBrowseComponent,
  EmpresaAssociadoBrowseComponent,
} from "./../index";

@Component({
  templateUrl: "importacoes-component.html",
})
export class ImportacoesCNABComponent extends CMFormExecComponent {
  @ViewChild("fileUpLoad", { static: true }) fileUpLoad: CMUploadFileComponent;

  valuesTipoImportacao = [];
  setValuesTipoImportacao() {
    if (this.paramEmpresa.modoOperacao === "Nivel1") {
      this.valuesTipoImportacao = [
        { value: "Clientes", text: "Clientes / Fornecedores" },
        { value: "Produtos" },
        { value: "IBPT" },
      ];
    } else {
      this.valuesTipoImportacao = [
        { value: "Clientes", text: "Clientes / Fornecedores" },
        { value: "Produtos" },
        { value: "Grade", text: "Grade de Produtos" },
        { value: "IBPT" },
      ];
    }
  }

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.setValuesTipoImportacao();
    this.filterConfig = {
      tipo: new CMInputConfig({
        form: this,
        caption: "Tipo",
        values: this.valuesTipoImportacao,
      }),
      associado: new CMInputConfig({
        form: this,
        caption: "Associado",
        required: true,
        disabledFunc: () => this.filter.tipo === "IBPT",
        pesqFunc: (sender) => {
          PessoaBrowseComponent.pesquisa(
            sender,
            this.filter,
            "Empresa",
            "associado",
            () => this.getEmpresasAssociado()
          );
        },
      }),
      empresa: new CMInputConfig({
        form: this,
        caption: "Empresa",
        requiredFunc: () => this.exigeEmpresa(),
        disabledFunc: () => !this.exigeEmpresa(),
        values: this.empresasAssociado,
      }),
    };
    this.filter.tipo = "Clientes";
  }

  exigeEmpresa() {
    return this.filter.tipo === "Produtos" || this.filter.tipo === "Grade";
  }

  onCompleteUploadFile(sender: any) {
    CMFormGlobaisComponent.hideSpinner();
    sender.form.filter.fileuploadkeyserver = sender.keyserver;
    sender.form.filter.fileuploadname = sender.file.name;
    sender.form.importar();
  }

  innerExec() {
    this.importou = false;
    this.mostraResultado = false;
    CMFormGlobaisComponent.showConfirmation(
      "Importação",
      "Confirma a importação com os dados selecionados. <br>" +
        "<h5>Essa ação não poderá ser desfeita.</h5>",
      () => {
        super.innerExec();
        if (this.fileUpLoad.IsFileSelect) {
          CMFormGlobaisComponent.showSpinner();
          this.fileUpLoad.enviar();
        } else CMFormGlobaisComponent.toastErro("Selecione um arquivo");
      }
    );
  }

  objectKeys = Object.keys;
  importados: any = {};
  naoimportados: any = {};
  jacadastrados: any = {};
  importou: boolean = false;
  mostraResultado: boolean = false;

  importar() {
    this.fileUpLoad.setMsg("Processamento arquivo no servidor");
    let filter = this.filter;
    CMFormGlobaisComponent.showSpinner();

    this.postSubscribe(
      "/api/Importacoes/Importar/FuncaoExtra",
      JSON.stringify(filter),
      (result) => {
        if (result["result"] === "ok") {
          this.fileUpLoad.setMsg(result["msg"]);
          CMFormGlobaisComponent.toastSucesso(result["msg"]);
          this.mostraResultado = result["importados"] != undefined;
          if (this.mostraResultado) {
            this.importados = result["importados"];
            this.naoimportados = result["naoimportados"];
            this.jacadastrados = result["jacadastrados"];
          } else {
            this.importados = {};
            this.naoimportados = {};
            this.jacadastrados = {};
          }
          this.importou = true;
        } else {
          CMFormGlobaisComponent.toastErro(result["msg"]);
          this.fileUpLoad.setMsg(result["msg"]);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("importar", error);
      }
    );
  }

  empresasAssociado = [];

  getEmpresasAssociado() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/Importacoes/GetEmpresasAssociado",
      JSON.stringify({ associado: this.filter.associado.id }),
      (result) => {
        if (result instanceof Array) {
          this.empresasAssociado.length = 0;
          Object.assign(this.empresasAssociado, result);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("getEmpresasAssociado", error);
      }
    );
  }
}
