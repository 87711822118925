// tslint:disable
import { Component, Injector } from '@angular/core';
import { FuncsGlobais } from '../funcs.globais';
import { ConfiguracaoBoletoBrowseComponent } from '../configuracao-boleto';
import {
    CMInputConfig, CMFormExecComponent,
    CMFormGlobaisComponent
} from './../../@cmcore/component';

@Component({
    templateUrl: 'retorno-processamento.component.html'
})
export class RetornoProcessamentoComponent extends CMFormExecComponent {
    constructor(_injector: Injector) {
        super(_injector);
        this.filterConfig = {
            configuracaoboleto: new CMInputConfig({
                form: this,
                caption: "Configuração de Boleto",
                pesqFunc: (sender) => {
                    ConfiguracaoBoletoBrowseComponent.pesquisa(sender, this.filter);
                    console.log('this.filter: ', this.filter)
                },
            }),
        }
        this.filter.dataini = this.firstDate();
        this.filter.datafim = this.lastDate();
    }

    innerExec() {
        super.innerExec();
        this.ProcessarRetorno();
    }

    ProcessarRetorno() {
        let filter = this.filter;
        CMFormGlobaisComponent.showSpinner();
        let date = new Date;
        let dia = date.getDate();
        let diaformatado;
        let mes = date.getMonth() + 1;
        let mesformatado;

        if (dia < 10) {
            diaformatado = "0" + dia;
        }
        else {
            diaformatado = dia;
        }

        if (mes === 10) {
            mesformatado = "O";
        }
        else if (mes === 11) {
            mesformatado = "N";
        }
        else if (mes === 12) {
            mesformatado = "D";
        }
        else {
            mesformatado = mes;
        }

        this.postSubscribeT<Array<string>>('/api/ConfiguracaoBoleto/ProcessarRetorno',
            JSON.stringify(filter),
            (result) => {
                let result_str = result.pop();
                if (result_str === 'ok') {
                    let contentFile = result.pop();
                    let fileName = result.pop();
                    //this.downloadContent(contentFile, fileName);
                }
                else {
                    CMFormGlobaisComponent.toastErro(result_str);
                }
                CMFormGlobaisComponent.hideSpinner();
            },
            error => {
                CMFormGlobaisComponent.hideSpinner();
                this.showMessageErrorHTTP('geraArquivoRemessa', error);
            });
    }
}