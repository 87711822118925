// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "./../../../@cmcore/component/cmform/cmform.component";
import {
  CMFormGlobaisComponent,
  CMPanelConfigMore,
} from "../../../@cmcore/component";
import * as shape from "d3-shape";

@Component({
  templateUrl: "home-dashboard.component.html",
  styleUrls: ["home-dashboard.component.scss"],
})
export class HomeDashboardComponent extends CMFormComponent {
  constructor(_injector: Injector) {
    super(_injector);
  }

  valoresFixos = {
    getCursor: (item) => {
      if (this.trimValue(item.relatName) !== "") {
        return "pointer";
      } else {
        return "auto";
      }
    },
    data: [
      [
        {
          name: "CARREGANDO AGUARDE...",
          value: "R$ 0,00",
          relatName: "",
          filtroRelat: undefined,
        },
      ],
    ],
  };

  graficoVendas = {
    data: [
      {
        name: "CARREGANDO AGUARDE...",
        series: [
          {
            name: "...",
            value: 0,
          },
        ],
      },
    ],
    yScaleMax: 0,
    curve: shape.curveCardinal.tension(0),
    colorScheme: {
      domain: [],
    },
    xAxisTickFormatting: (value) => {
      if (value) {
        return value.substr(0, 5);
      } else {
        return value;
      }
    },
  };

  graficoInadimplencia = {
    colorScheme: {
      domain: ["#697279", "#5ba1de"],
    },
    data: [],
    tooltipText: (data) => {
      if (data && data.value) {
        return this.floatToValue(data.value) + "%";
      }
    },
    getValueLegend: (index, tipo) => {
      if (this.graficoInadimplencia.data.length > index) {
        if (tipo == 0) {
          return this.graficoInadimplencia.data[index].name;
        } else {
          return (
            this.floatToValue(this.graficoInadimplencia.data[index].value) + "%"
          );
        }
      } else {
        return "Carregando";
      }
    },
  };

  onSelect(tipo, valor) {
    if (valor.relatName && valor.filtroRelat) {
      this.showRelat(valor.relatName, valor.filtroRelat);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.loadData();
  }

  setValues(response) {
    this.valoresFixos.data = [];
    if (response["valoresFixos"]) {
      this.valoresFixos.data = response["valoresFixos"];
    }
    this.graficoVendas.yScaleMax = 0;
    if (response["graficoVendas"]) {
      const newSerie = response["graficoVendas"];
      this.graficoVendas.data = [
        {
          name: "Vendas",
          series: newSerie,
        },
      ];
      newSerie.forEach((item) => {
        if (item.value > this.graficoVendas.yScaleMax) {
          this.graficoVendas.yScaleMax = item.value;
        }
      });
      this.graficoVendas.yScaleMax = this.graficoVendas.yScaleMax * 1.1;
    }
    let inadinplencia = 100;
    let relatName = "";
    let filtroRelat = {};
    if (response["inadinplencia"]) {
      inadinplencia = response["inadinplencia"].value;
      relatName = response["inadinplencia"].relatName;
      filtroRelat = response["inadinplencia"].filtroRelat;
    }
    this.graficoInadimplencia.data = [
      {
        name: "Inadinplência",
        value: inadinplencia,
        relatName: relatName,
        filtroRelat: filtroRelat
      },
      {
        name: "Outros",
        value: 100 - inadinplencia,
        relatName: relatName,
        filtroRelat: filtroRelat
      },
    ];
  }

  dias: string = "7";
  loadData() {
    CMFormGlobaisComponent.showSpinner();

    this.postSubscribe(
      "/api/EmpresaAssociado/dashboard/FuncaoExtra",
      JSON.stringify({
        dias: this.dias,
      }),
      (response) => {
        this.setValues(response);
        setTimeout(() => {
          this.graficoVendas.colorScheme = {
            domain: ["#5ba1de"],
          }
          CMFormGlobaisComponent.hideSpinner();
        }, 100);
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("loadData", error);
      }
    );
  }
}
