// tslint:disable
import { Routes, RouterModule } from "@angular/router";

import {
  LoginFullScreenComponent,
  LoginApiComponent,
  AuthGuard,
  UsuarioBrowseComponent,
  RecursoBrowseComponent,
  GrupoUsuarioBrowseComponent,
  RecursoHelpBrowseComponent,
  MaxUsersComponent,
  IPsBloqueadosComponent,
  EsqueceuSenhaComponent,
} from "./guard";

const appRoutes: Routes = [
  { path: "login", component: LoginFullScreenComponent },
  { path: "router/semchave/:routerok", component: LoginApiComponent },
  { path: "router/:chaveacesso/:routerok", component: LoginApiComponent },
  { path: "esqueceu-senha", component: EsqueceuSenhaComponent },
  { path: "esqueceu-senha/:token", component: EsqueceuSenhaComponent },
  {
    path: "usuario",
    component: UsuarioBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "recurso",
    component: RecursoBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "grupousuario",
    component: GrupoUsuarioBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "recurso-help",
    component: RecursoHelpBrowseComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "maxusers",
    component: MaxUsersComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
  {
    path: "ips-bloqueados",
    component: IPsBloqueadosComponent,
    canActivate: [AuthGuard],
    canDeactivate: [AuthGuard],
  },
];

export const CMRouting = RouterModule.forRoot(appRoutes);
