// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormEditComponent, CMInputConfig } from "./../../@cmcore/component";

@Component({
  selector: "marketplace-edit",
  templateUrl: "marketplace-edit.component.html",
})
export class MarketplaceEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_codigo";
  }

  getTitle() {
    return "Marketplace";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "marketplace";
    this.modelConfig = {
      codigo: new CMInputConfig({
        form: this,
        caption: "Código",
        disabled: true,
      }),
      nome: new CMInputConfig({
        form: this,
        caption: "Nome",
        required: true,
        maxlength: 60,
      }),
      ativo: new CMInputConfig({
        form: this,
        caption: "Ativo",
      }),
      tipo: new CMInputConfig({
        form: this,
        caption: "Tipo",
      }),
      appkey: new CMInputConfig({
        form: this,
        caption: "ID do Aplicativo", // ver nome conforme marketplace
        required: true,
        maxlength: 255,
      }),
      accountmanagerkey: new CMInputConfig({
        form: this,
        caption: "Secret Key", // ver nome conforme marketplace
        required: true,
        maxlength: 255,
      }),
      importarapartirde: new CMInputConfig({
        form: this,
        caption: "Importar apartir de",
        required: true,
        mask: "99/99/9999",
      }),
      ultimaimportacao: new CMInputConfig({
        form: this,
        caption: "Última Importação",
        mask: "99/99/9999",
      }),
    };
  }
}
