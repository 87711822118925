// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormEditComponent, CMInputConfig } from "./../../@cmcore/component";

@Component({
  selector: "grupodeproduto-edit",
  templateUrl: "grupodeproduto-edit.component.html",
})
export class GrupoDeProdutoEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_codigo";
  }

  getTitle() {
    return "Grupo de Produto";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "grupodeproduto";
    this.modelConfig = {
      codigo: new CMInputConfig({
        form: this,
        caption: "Código",
        maxlength: 5,
        required: true,
        upper: true,
        disabledFunc: () => {
          return this.model.codigo == "GERAL" || this.model.codigo == "SAT";
        },
      }),
      descricao: new CMInputConfig({
        form: this,
        caption: "Descrição",
        required: true,
        maxlength: 60,
      }),
    };
  }
}
