// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormRelatComponent,
  CMInputConfig
} from "./../../../@cmcore/component";

@Component({
  selector: "produtos-sem-venda",
  templateUrl: "produtos-sem-venda.component.html"
})
export class RelatProdutosSemVendaComponent extends CMFormRelatComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = "ImpressaoProdutosSemVenda";
    this.filterConfig = {
      diassemvenda: new CMInputConfig({
        form: this,
        caption: "Dias sem Venda",
        required: true,
        dataType: "integer"
      }),
      consideraprevenda: new CMInputConfig({
        form: this,
        caption: "Considere Pré-Venda em aberto"
      })
    };
    this.filter.consideraprevenda = "Não";
    this.filter.diassemvenda = "30";
  }
}
