// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormRelatComponent,
  CMInputConfig,
} from "./../../../@cmcore/component";
import {
  GrupoDeProdutoBrowseComponent,
  ProdutoBrowseComponent,
  SubGrupoDeProdutoBrowseComponent,
} from "../..";
import { FuncsGlobais } from "../../funcs.globais";

@Component({
  selector: "posicao-estoque",
  templateUrl: "posicao-estoque.component.html",
})
export class RelatPosicaoEstoqueComponent extends CMFormRelatComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = "ImpressaoPosicaoEstoque";
    this.filterConfig = {
      produto: new CMInputConfig({
        form: this,
        caption: "Produto",
        maxlength: 30,
        padInfo: { size: 30 },
        pesqFunc: (sender) => {
          ProdutoBrowseComponent.pesquisa(sender, this.filter, "Todos");
        },
      }),
      grupo: new CMInputConfig({
        form: this,
        caption: "Grupo",
        upper: true,
        visibleFunc: () => FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
        pesqFunc: (sender) => {
          GrupoDeProdutoBrowseComponent.pesquisa(sender, this.filter, "grupo");
        },
      }),
      subgrupo: new CMInputConfig({
        form: this,
        caption: "Sub-Grupo",
        upper: true,
        visibleFunc: () => FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
        pesqFunc: (sender) => {
          SubGrupoDeProdutoBrowseComponent.pesquisa(
            sender,
            this.filter,
            "subgrupo"
          );
        },
      }),
      apenasprodutoscomsaldo: new CMInputConfig({
        form: this,
        caption: "Apenas produtos com saldo",
      }),
      ativo: new CMInputConfig({
        form: this,
        caption: "Apenas produtos ativos",
      }),
    };
    this.filter.apenasprodutoscomsaldo = "Não";
    this.filter.ativo = "Sim";
  }
}
