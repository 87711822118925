// tslint:disable
import {Injector, Component} from '@angular/core';

import {CMFormBrowse2Component} from './../../@cmcore/component';
import {CaixaSATEditComponent} from './caixa-sat-edit.component';

@Component({
  selector: 'caixa-sat-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class CaixaSATBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'caixasat';
    this.formEditComponent = CaixaSATEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = [
      {field: 'numerocaixa', caption: 'Número do Caixa'},
      {field: 'identificacaodispositivocomplemento', caption: 'Identificação do Dispositivo'}
    ];
  }

  getTitle() {
    return 'Caixa SAT';
  }
}
