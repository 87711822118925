// tslint:disable
import { Component } from '@angular/core';

import { CMFormComponent } from './../../../@cmcore/component/cmform/cmform.component';

@Component({
    templateUrl: 'home-consultas.component.html',
    styleUrls: ['home-consultas.component.scss']
})
export class HomeConsultasComponent extends CMFormComponent {

  segundaViaBoleto() {
    window.open('https://www.sicoob.com.br/web/sicoob/segunda-via-boleto', '_blank');
  }

}