// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMValidatorEmail,
  CMListCheckBoxConfig,
  CMFormGlobaisComponent,
} from "./../../@cmcore/component";

import { CMValidatorCNPJCPF } from "./../../@cmcore/validator";
import { MunicipioBrowseComponent } from "./../municipio/municipio-browse.component";
import { GrupoDeOperacaoBrowseComponent } from "../grupodeoperacao";

@Component({
  selector: "cliente-edit",
  templateUrl: "cliente-edit.component.html",
})
export class ClienteEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_cpf";
  }

  getTitle() {
    return "Pessoa - " + this.fieldByName("nome");
  }

  afterGetModelData() {
    super.afterGetModelData();
    if (this.cpfurl !== "") {
      if (this.fieldIsEmpty("cpf") && this.fieldIsEmpty("cnpj")) {
        this.model.cpf = this.cpfurl;
      }
    }
  }

  cpfurl = "";
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "cliente";
    this.setModelConfig();
    this.route.params.subscribe((params: any) => {
      if (params.cpf) {
        this.cpfurl = params.cpf;
      }
    });
  }

  setModelConfig() {
    this.modelConfig = {
      codigo: new CMInputConfig({ form: this, caption: "#", disabled: true }),
      cpf: new CMInputConfig({
        form: this,
        caption: "CPF",
        mask: "999.999.999-99",
        customvalidators: [new CMValidatorCNPJCPF()],
      }),
      cnpj: new CMInputConfig({
        form: this,
        caption: "CNPJ",
        mask: "99.999.999/9999-99",
        customvalidators: [new CMValidatorCNPJCPF()],
        iconButton: "file_download",
        hintButton: "Buscar dados por CNPJ",
        pesqFunc: (sender) => {
          if (!sender) {
            this.buscaCNPJ( 
              this.model.cnpj, this.modelConfig.endereco, this.model.endereco
            );
          }
        }        
      }),
      ie: new CMInputConfig({ form: this, caption: "IE", maxlength: 18 }),
      rg: new CMInputConfig({ form: this, caption: "RG", maxlength: 14 }),
      cadastro: new CMInputConfig({
        form: this,
        caption: "Data de Cadastro",
        mask: "99/99/9999",
        required: true,
        disabled: true,
      }),
      datanascimento: new CMInputConfig({
        form: this,
        caption: "Data de nascimento",
        mask: "99/99/9999",
      }),
      consumidorfinal: new CMInputConfig({
        form: this,
        caption: "Consumidor Final",
      }),
      nome: new CMInputConfig({
        form: this,
        caption: "Nome / Razão Social",
        required: true,
        maxlength: 60,
      }),
      fantasia: new CMInputConfig({
        form: this,
        caption: "Nome Fantasia",
        maxlength: 100,
      }),
      email: new CMInputConfig({
        form: this,
        caption: "e-mail",
        maxlength: 100,
        customvalidators: [new CMValidatorEmail()],
      }),
      emailnfe: new CMInputConfig({
        form: this,
        caption: "e-mail NF-e",
        maxlength: 100,
        customvalidators: [new CMValidatorEmail()],
      }),
      observacao: new CMInputConfig({ form: this, caption: "Observação" }),
      indiedest: new CMInputConfig({
        form: this,
        caption: "Indicação da I.E.",
        values: [
          { value: "Auto", text: "Automático" },
          { value: "Contribuinte" },
          { value: "Isento" },
          { value: "NaoContribuinte", text: "Não Contribuinte" },
        ],
      }),
      tipo: new CMListCheckBoxConfig({
        form: this,
        caption: "Tipo",
        columns: 1,
        list: ["Cliente", "Fornecedor", "Transportadora", "Vendedor"],
      }),
      grupodeoperacao: new CMInputConfig({
        form: this,
        caption: "Grupo de Operação",
        upper: true,
        pesqFunc: (sender) => {
          GrupoDeOperacaoBrowseComponent.pesquisa(
            sender,
            this.model,
            "grupodeoperacao"
          );
        },
      }),
      crc: new CMInputConfig({
        form: this,
        caption: "CRC",
        maxlength: 15
      }),
      perccomissao: new CMInputConfig({
        form: this,
        caption: "Percentual de Comissão",
        dataType: "double(2)",
        disabledFunc: () => {
          return this.model.tipo.indexOf("Vendedor") == -1;
        },
      }),
      endereco: {
        logradouro: new CMInputConfig({
          form: this,
          caption: "Logradouro",
          maxlength: 60,
        }),
        numero: new CMInputConfig({
          form: this,
          caption: "Número",
          maxlength: 10,
        }),
        bairro: new CMInputConfig({
          form: this,
          caption: "Bairro",
          maxlength: 60,
        }),
        complemento: new CMInputConfig({
          form: this,
          caption: "Complemento",
          maxlength: 60,
        }),
        estado: new CMInputConfig({
          form: this,
          caption: "Estado",
          values: [
            { value: "AC" },
            { value: "AL" },
            { value: "AP" },
            { value: "AM" },
            { value: "BA" },
            { value: "CE" },
            { value: "DF" },
            { value: "ES" },
            { value: "GO" },
            { value: "MA" },
            { value: "MT" },
            { value: "MS" },
            { value: "MG" },
            { value: "PR" },
            { value: "PB" },
            { value: "PA" },
            { value: "PE" },
            { value: "PI" },
            { value: "RJ" },
            { value: "RN" },
            { value: "RS" },
            { value: "RO" },
            { value: "RR" },
            { value: "SC" },
            { value: "SE" },
            { value: "SP" },
            { value: "TO" },
          ],
        }),
        ibge: new CMInputConfig({
          form: this,
          caption: "Código do Município no IBGE",
          pesqFunc: (sender) => {
            MunicipioBrowseComponent.pesquisa(
              this.model.endereco.estado,
              sender,
              this.model.endereco,
              "ibge"
            );
          },
        }),
        cep: new CMInputConfig({
          form: this,
          caption: "CEP",
          mask: "99999-999",
          iconButton: "file_download",
          hintButton: "Buscar endereço por CEP",
          pesqFunc: (sender) => {
            if (!sender) {
              this.buscaCEP(this.modelConfig.endereco, this.model.endereco);
            }
          },
        }),
      },
      telefones: new CMGridEditConfig({
        form: this,
        caption: "Telefones",
        required: false,
        onNewItem: (model) => {
          Object.assign(model, { tipo: "Residencial" });
        },
        fields: {
          numero: new CMInputConfig({
            form: this,
            caption: "Telefone",
            required: true,
            gridConfig: new CMGridEditConfigColumn(),
            maskFunc: (row) => {
              let m = "(99)9999-9999";
              if (row.tipo === "Celular") m = "(99)99999-9999";
              return m;
            },
          }),
          tipo: new CMInputConfig({
            form: this,
            caption: "Tipo",
            gridConfig: new CMGridEditConfigColumn({
              ordem: 1,
            }),
            values: [
              { value: "Residencial" },
              { value: "Comercial" },
              { value: "Celular" },
              { value: "Fax" },
            ],
          }),
          contato: new CMInputConfig({
            form: this,
            caption: "Contato",
            gridConfig: new CMGridEditConfigColumn(),
            maxlength: 60,
          }),
          observacao: new CMInputConfig({
            form: this,
            caption: "Observação",
            gridConfig: new CMGridEditConfigColumn(),
            maxlength: 100,
          }),
        },
      }),
      enderecos: new CMGridEditConfig({
        form: this,
        caption: "Endereços de Entrega",
        required: false,
        onNewItem: (model) => {
          Object.assign(model.endereco, { estado: "SP" });
        },
        fields: {
          identificacao: new CMInputConfig({
            form: this,
            caption: "Identificação",
            required: true,
            gridConfig: new CMGridEditConfigColumn(),
            maxlength: 30,
          }),
          endereco: {
            logradouro: new CMInputConfig({
              form: this,
              caption: "Logradouro",
              maxlength: 60,
            }),
            numero: new CMInputConfig({
              form: this,
              caption: "Número",
              maxlength: 10,
            }),
            bairro: new CMInputConfig({
              form: this,
              caption: "Bairro",
              maxlength: 60,
            }),
            complemento: new CMInputConfig({
              form: this,
              caption: "Complemento",
              maxlength: 60,
            }),
            estado: new CMInputConfig({
              form: this,
              caption: "Estado",
              values: [
                { value: "AC" },
                { value: "AL" },
                { value: "AP" },
                { value: "AM" },
                { value: "BA" },
                { value: "CE" },
                { value: "DF" },
                { value: "ES" },
                { value: "GO" },
                { value: "MA" },
                { value: "MT" },
                { value: "MS" },
                { value: "MG" },
                { value: "PR" },
                { value: "PB" },
                { value: "PA" },
                { value: "PE" },
                { value: "PI" },
                { value: "RJ" },
                { value: "RN" },
                { value: "RS" },
                { value: "RO" },
                { value: "RR" },
                { value: "SC" },
                { value: "SE" },
                { value: "SP" },
                { value: "TO" },
              ],
            }),
            ibge: new CMInputConfig({
              form: this,
              caption: "Código do Município no IBGE",
              pesqFunc: (sender) => {
                MunicipioBrowseComponent.pesquisa(
                  this.modelConfig.enderecos.current.endereco.estado,
                  sender,
                  this.modelConfig.enderecos.current.endereco,
                  "ibge"
                );
              },
            }),
            cep: new CMInputConfig({
              form: this,
              caption: "CEP",
              mask: "99999-999",
              iconButton: "file_download",
              hintButton: "Buscar endereço por CEP",
              pesqFunc: (sender) => {
                if (!sender) {
                  this.buscaCEP(
                    this.modelConfig.enderecos.fields.endereco,
                    this.modelConfig.enderecos.current.endereco
                  );
                }
              },
            }),
          },
        },
      }),
    };
  }

  buscaCEP(configEndereco, endereco) {
    if (this.trimValue(endereco.cep).length == 9) {
      CMFormGlobaisComponent.showSpinner();
      const s = this.http
        .get("https://viacep.com.br/ws/" + endereco.cep + "/json/")
        .subscribe({
          next: (value: any) => {
            if (value.bairro) {
              endereco.bairro = value.bairro;
            }
            if (value.complemento) {
              endereco.complemento = value.complemento;
            }
            if (value.logradouro) {
              endereco.logradouro = value.logradouro;
            }
            if (value.uf) {
              endereco.estado = value.uf;
            }
            if (value.ibge) {
              configEndereco.ibge.value = value.ibge;
              MunicipioBrowseComponent.pesquisa(
                endereco.estado,
                configEndereco.ibge,
                endereco,
                "ibge"
              );
            }
          },
          error: (e) => {
            console.error(e);
            CMFormGlobaisComponent.toastErro(
              "Erro ao consultar servidor de CEP"
            );
            s.unsubscribe();
            CMFormGlobaisComponent.hideSpinner();
          },
          complete: () => {
            s.unsubscribe();
            CMFormGlobaisComponent.hideSpinner();
          },
        });
    }
  }

  buscaCNPJ(cnpj, configEndereco, endereco) {
    cnpj = cnpj.replace(/[^0-9]/g, '');
    if (this.trimValue(cnpj).length == 14) {
      CMFormGlobaisComponent.showSpinner();
      const s = this.http
        .get("https://api-publica.speedio.com.br/buscarcnpj?cnpj=" + cnpj)
        .subscribe({
          next: (value: any) => {
            if (value['RAZAO SOCIAL']) {
              this.model.nome = this.trimValue(value['RAZAO SOCIAL']);
            }
            if (value['NOME FANTASIA']) {
              this.model.fantasia = this.trimValue(value['NOME FANTASIA']);
            }            
            if (value['CEP']) {
              endereco.cep = value['CEP'].slice(0, 5) + '-' + value['CEP'].slice(5);
              this.buscaCEP(configEndereco, endereco);
            }
          },
          error: (e) => {
            console.error(e);
            CMFormGlobaisComponent.toastErro(
              "Erro ao consultar servidor de CNPJ"
            );
            s.unsubscribe();
            CMFormGlobaisComponent.hideSpinner();
          },
          complete: () => {
            s.unsubscribe();
            CMFormGlobaisComponent.hideSpinner();
          },
        });
    }
  }

  buscaCPF(cpf, dataNascimento, pCaptcha) {
    CMFormGlobaisComponent.showSpinner();
    console.log('ok');
    let json = {
      cpf: cpf,
      data_nascimento: dataNascimento,
      captcha: pCaptcha
    };
    console.log('json: ', json)
    this.postSubscribe(
      "/api/cliente/BuscarCPF",
      JSON.stringify(json),
      (result) => {
        console.log('result: ', result);
        if (result["result"] === "ok") {
          let nome = result["nome"];
          console.log('nome: ' + nome);
          this.model.nome = result["nome"];
        }
        else {
          CMFormGlobaisComponent.showMessageError(result["result"]);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      },
    );
  }  
  
  geraCaptcha(sender) {
    CMFormGlobaisComponent.showSpinner();
    console.log('ok');
    let json = {};
    console.log('json: ', json)
    this.postSubscribe(
      "/api/cliente/getCaptcha",
      JSON.stringify(json),
      (result) => {
        console.log('result: ', result);
        if (result["result"] === "ok") {
          let img = result["png"];
          console.log('png: ' + img);
          //CaptchaComponent.openCaptcha();
        }
        else {
          CMFormGlobaisComponent.showMessageError(result["result"]);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      },
    );
  } 

  customValid() {
    let r = super.customValid();
    if (r === "") {
      if (!this.fieldIsEmpty("cpf") && !this.fieldIsEmpty("cnpj"))
        r = "CPF e CNPJ não podem ser informados juntos";
    }
    return r;
  }
}
