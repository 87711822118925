// tslint:disable
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { SimpleNotificationsModule } from "angular2-notifications";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { CMRouting } from "./cmcore.routing";
import {
  CMButtonComponent,
  CMMenuComponent,
  CMMenuItemComponent,
  CMIconeComponent,
  CMInputComponent,
  CMCheckBoxComponent,
  CMSelectComponent,
  CMGridComponent,
  CMGridColumnComponent,
  CMFormModalComponent,
  CMFormButtonsEditComponent,
  CMDynamicCreatorComponent,
  CMFormGlobaisComponent,
  CMListCheckBoxComponent,
  CMPanelComponent,
  CMRowComponent,
  CMDividerComponent,
  CMContainerComponent,
  CMImageComponent,
  CMTabComponent,
  CMTabsComponent,
  CMFormPesquisaComponent,
  CMMemoComponent,
  CMLayoutComponent,
  CMFormRelatDefaultComponent,
  CMUploadFileComponent,
  CMFormEmailComponent,
  CMFormExecDefaultComponent,
  GridEditComponent,
  CMGridV2Component,
  CMFormPesquisaV2Component,
} from "./component";
import {
  CMMaskedInputDirective,
  CMColumnDirective,
  CMNumericDirective,
} from "./directive";
import {
  LoginComponent,
  LoginApiComponent,
  LoginFullScreenComponent,
  AuthGuard,
  AuthService,
  UsuarioEditComponent,
  UsuarioBrowseComponent,
  RecursoBrowseComponent,
  RecursoEditComponent,
  GrupoUsuarioBrowseComponent,
  GrupoUsuarioEditComponent,
  RecursoHelpBrowseComponent,
  RecursoHelpEditComponent,
  MaxUsersComponent,
  MaxUsersConfirmComponent,
  IPsBloqueadosComponent,
  EsqueceuSenhaComponent,
} from "./guard";
import { CMSafePipe } from "./pipe";

import { AngularMaterialModule } from "./../../angular-material.module";

import * as $ from "jquery";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SimpleNotificationsModule.forRoot(),
    RouterModule,
    AngularMaterialModule,
    CMRouting,
    NgxChartsModule,
  ],
  declarations: [
    CMButtonComponent,
    CMMenuComponent,
    CMMenuItemComponent,
    CMIconeComponent,
    CMInputComponent,
    CMMaskedInputDirective,
    CMNumericDirective,
    CMCheckBoxComponent,
    CMSelectComponent,
    CMFormButtonsEditComponent,
    LoginComponent,
    LoginApiComponent,
    LoginFullScreenComponent,
    CMFormGlobaisComponent,
    CMFormModalComponent,
    CMGridComponent,
    CMGridColumnComponent,
    CMDynamicCreatorComponent,
    CMListCheckBoxComponent,
    CMPanelComponent,
    CMRowComponent,
    CMColumnDirective,
    CMDividerComponent,
    CMContainerComponent,
    CMImageComponent,
    CMTabComponent,
    CMTabsComponent,
    CMFormPesquisaComponent,
    CMMemoComponent,
    CMSafePipe,
    CMLayoutComponent,
    CMFormRelatDefaultComponent,
    CMUploadFileComponent,
    CMFormEmailComponent,
    CMFormExecDefaultComponent,
    UsuarioEditComponent,
    UsuarioBrowseComponent,
    RecursoBrowseComponent,
    RecursoEditComponent,
    GrupoUsuarioBrowseComponent,
    GrupoUsuarioEditComponent,
    GridEditComponent,
    RecursoHelpBrowseComponent,
    RecursoHelpEditComponent,
    MaxUsersComponent,
    MaxUsersConfirmComponent,
    CMGridV2Component,
    IPsBloqueadosComponent,
    EsqueceuSenhaComponent,
    CMFormPesquisaV2Component,
  ],
  entryComponents: [
    CMFormEmailComponent,
    UsuarioEditComponent,
    RecursoEditComponent,
    GrupoUsuarioEditComponent,
    RecursoHelpEditComponent,
    MaxUsersConfirmComponent,
  ],
  providers: [AuthGuard, AuthService],
  exports: [
    CMButtonComponent,
    CMMenuComponent,
    CMMenuItemComponent,
    CMIconeComponent,
    CMInputComponent,
    CMCheckBoxComponent,
    CMSelectComponent,
    CMFormButtonsEditComponent,
    LoginComponent,
    LoginApiComponent,
    LoginFullScreenComponent,
    CMFormGlobaisComponent,
    CMFormModalComponent,
    CMGridComponent,
    CMGridColumnComponent,
    CMDynamicCreatorComponent,
    CMListCheckBoxComponent,
    CMPanelComponent,
    CMRowComponent,
    CMColumnDirective,
    CMNumericDirective,
    CMDividerComponent,
    CMContainerComponent,
    CMImageComponent,
    CMTabComponent,
    CMTabsComponent,
    CMFormPesquisaComponent,
    CMMemoComponent,
    CMSafePipe,
    CMLayoutComponent,
    CMFormRelatDefaultComponent,
    CMUploadFileComponent,
    CMFormEmailComponent,
    CMFormExecDefaultComponent,
    UsuarioEditComponent,
    UsuarioBrowseComponent,
    RecursoBrowseComponent,
    RecursoEditComponent,
    GrupoUsuarioBrowseComponent,
    GrupoUsuarioEditComponent,
    GridEditComponent,
    NgxChartsModule,
    CMGridV2Component,
    CMFormPesquisaV2Component,
  ],
})
export class CMCoreModule {}
