// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormComponent } from './../../@cmcore/component/cmform/cmform.component';

import { CMInputConfig, CMValidatorEmail, CMFormGlobaisComponent,
         CMFormModalComponent
       } from './../../@cmcore/component';
import { NFeInutilizacaoBrowseComponent,
       } from './../index';
@Component({
    selector: 'nfeinutilizacao-acoes',
    templateUrl: 'nfeinutilizacao-acoes.component.html',
  styleUrls: ['nfeinutilizacao-acoes.component.scss']
})
export class NFeInutilizacaoAcoesComponent extends CMFormComponent {

  _owner: NFeInutilizacaoBrowseComponent = undefined;
  get owner() {
    return this._owner;
  }
  set owner(value) {
    this._owner = value;
    if (this.owner) {
      this.loadDadosNFeInutilizacao();
    }
  }

  configNFeInutilizacao: any = {
    email: {
      para: new CMInputConfig({form: this,
                               caption: 'Para',
                               required: true,
                               customvalidators: [new CMValidatorEmail()],
      }),
      cc: new CMInputConfig({form: this,
                             caption: 'CC',
                             customvalidators: [new CMValidatorEmail()],
      }),
      assunto: new CMInputConfig({form: this,
                                  caption: 'Assunto',
                                  required: true,
      }),
      mensagem: new CMInputConfig({form: this,
                                   caption: 'Mensagem',
                                   required: true,
      }),
    }
  };

  modelNFeInutilizacao: any = {
		email: {
			para: '',
			cc: '',
			assunto: '',
			mensagem: '',
		}
	};
  loadDadosNFeInutilizacao() {
    this.getObjectData('nfeinutilizacao',
      [{'field': 'id', 'value': this.owner.idFormAcoesNFeInutilizacao}],
      object => {
				this.modelNFeInutilizacao = object;
      });
  }

  constructor(_injector: Injector) {
    super(_injector);
	}

	transmitir() {
    let ok: boolean = true;
    if (this.trimValue(this.modelNFeInutilizacao.xjust).length < 15) {
      ok = false;
      CMFormGlobaisComponent.toastErro('Justificativa deve ter pelo menos 15 caracteres');
    }
    if (ok) {
      if (this.modelNFeInutilizacao.cstat !== '102') {
        CMFormGlobaisComponent.showSpinner();
        this.postSubscribe('/api/NFeInutilizacao/Transmitir',
                      JSON.stringify({id: this.modelNFeInutilizacao.id}),
                (result) => {
                  this.processaRetorno('Retorno da Inutilização', result);
                  CMFormGlobaisComponent.hideSpinner();
                },
                error => {
                  CMFormGlobaisComponent.hideSpinner();
                  CMFormGlobaisComponent.showMessageError(error);
                });
      }
    }
	}

  titleEmail: string = '';
  openEmail() {
    this.titleEmail = '';
		this.titleEmail = 'e-Mail do XML da inutilização';
    CMFormModalComponent.open('formAcoesNFeInutilizacaoEmail');
  }

  enviarEmail() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe('/api/NFeInutilizacao/EnviarEmail',
                  JSON.stringify({
                    id: this.modelNFeInutilizacao.id,
                    para: this.modelNFeInutilizacao.email.para,
                    cc: this.modelNFeInutilizacao.email.cc,
                    assunto: this.modelNFeInutilizacao.email.assunto,
                    mensagem: this.modelNFeInutilizacao.email.mensagem,
                  }),
            (result) => {
              this.processaRetorno(this.titleEmail, result, 'formAcoesNFeInutilizacaoEmail');
              CMFormGlobaisComponent.hideSpinner();
            },
            error => {
              CMFormGlobaisComponent.hideSpinner();
              CMFormGlobaisComponent.showMessageError(error);
            });
  }

  close(formName) {
    if (this.owner)
      this.owner.beforeComponent = undefined;
    CMFormModalComponent.close(formName);
  }

  retorno: any = {
    title: '',
    result: '',
    tipo: '',
    msg: '',
    cstat: '',
    xml: '',
    xml_conferencia: '',
    result_str: '',
    json: '',
  }

  setRetorno(title, retorno) {
    this.retorno.title           = title;
    this.retorno.result          = this.trimValue(retorno.result);
    this.retorno.tipo            = this.trimValue(retorno.tipo);
    this.retorno.msg             = this.trimValue(retorno.msg);
    this.retorno.cstat           = this.trimValue(retorno.cstat);
    this.retorno.xml             = this.trimValue(retorno.xml);
    this.retorno.xml_conferencia = this.trimValue(retorno.xml_conferencia);
    this.retorno.result_str      = this.trimValue(retorno.result_str);

    delete retorno.result;
    delete retorno.tipo;
    delete retorno.msg;
    delete retorno.cstat;
    delete retorno.xml;
    delete retorno.xml_conferencia;
    delete retorno.result_str;

    this.retorno.json = retorno;
  }

  processaRetorno(title, retorno, closeFormOK = undefined) {
    this.setRetorno(title, retorno);
    if (this.retorno.result === 'True') {
      this.loadDadosNFeInutilizacao();
      CMFormGlobaisComponent.toastSucesso(this.retorno.result_str);
      if (closeFormOK)
        this.close(closeFormOK);
    }
    else {
      if (this.retorno.tipo !== 'str') {
        let myNode = document.getElementById("div_json");
        while (myNode.firstChild)
          myNode.removeChild(myNode.firstChild);
        let ui = this.tree(this.retorno.json);
        ui.className = 'tree';
        myNode.appendChild(ui);
      }
      CMFormModalComponent.open('formAcoesNFeInutilizacaoRetorno');
    }
  }

  tree(data) {
    let r: HTMLElement = undefined;
    let ul = document.createElement('ul');
    for (var i in data) {
      let li = document.createElement('li');
      ul.appendChild(li);
      var t = document.createTextNode(i);
      li.appendChild(t);
      if (data[i] instanceof Object)
        li.appendChild(this.tree(data[i]));
      else {
        let textNode = document.createTextNode(' - '+data[i]);
        li.appendChild(textNode);
      }
    }
    r = ul;
    return r;
	}

	clickEmail() {
		this.openEmail();
	}

}