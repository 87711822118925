// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormEditComponent, CMInputConfig
       } from './../../@cmcore/component';

@Component({
    selector: 'seguimento-edit',
    templateUrl: 'seguimento-edit.component.html',
})
export class SeguimentoEditComponent extends CMFormEditComponent {

  getFirstComponentName() {
    return 'model_descricao';
  }

  getTitle() {
    return 'Seguimento';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'seguimento';
    this.modelConfig = {
      codigo: new CMInputConfig({form: this,
                                 caption: '#',
                                 disabled: true}),
      descricao: new CMInputConfig({form: this,
                                    caption: 'Descrição',
                                    required: true,
                                    maxlength: 60}),
    };
  }

}