// tslint:disable
import {
  Component,
  Injector,
  ViewChild,
  ViewContainerRef,
  Type,
  ComponentFactory,
} from "@angular/core";

import { CMFormComponent } from "../../../component/cmform/cmform.component";
import { CMFormModalComponent } from "../../../component";
import { environment } from "./../../../../../environments/environment";

@Component({
  selector: "cmform-globais",
  templateUrl: "./cmform-globais.component.html",
  styleUrls: ["./cmform-globais.component.scss"],
})
export class CMFormGlobaisComponent extends CMFormComponent {
  @ViewChild("dynamicTargetGlobal", { read: ViewContainerRef, static: true })
  private dynamicTargetGlobal: ViewContainerRef;

  static createComponent<T>(component: Type<T>, owner?: any): T {
    let componentFactory: ComponentFactory<T> = CMFormGlobaisComponent.defaultInstance.resolver.resolveComponentFactory(
      component
    );
    let componentReference = CMFormGlobaisComponent.defaultInstance.dynamicTargetGlobal.createComponent(
      componentFactory
    );
    componentReference["_component"]["owner"] = owner;
    let r = componentReference.instance;
    r["componentReference"] = componentReference;
    return r;
  }

  static freeComponent<T>(instance: T) {
    if (instance) {
      let componentReference = instance["componentReference"];
      if (componentReference) {
        componentReference["_component"]["owner"] = undefined;
        componentReference.destroy();
      }
    }
  }

  constructor(_injector: Injector) {
    super(_injector);
    CMFormGlobaisComponent.defaultInstance = this;
  }

  public simpleNotificationsOptions = {
    timeOut: 2000,
    lastOnBottom: true,
    clickToClose: true,
    maxLength: 0,
    maxStack: 7,
    showProgressBar: false,
    pauseOnHover: true,
    preventDuplicates: false,
    preventLastDuplicates: "visible",
    rtl: false,
    animate: "scale",
    position: ["right", "top"],
  };

  public showModalComponent: any = undefined;
  public showModalTitle: string = "";
  private showModalCallbackClose: Function = undefined;
  private showModalCallbackCreate: Function = undefined;
  private showModalComponentInstance: any = undefined;
  public messageErrorStr: string = "";
  public messageAvisoStr: string = "";
  public messageStr: string = "";
  public titleMessage: string = "";
  public fixedFooterMessageURL: boolean = true;
  public showPrintMessageURL: boolean = true;
  public urlCSVMessageURL: string = "";
  public showMessageURL_nameDownload: string = "";
  static messageConfirmationStr: string = "";
  static titleConfirmationStr: string = "";
  static confirmationCallbackYes: Function;
  static messageConfirmationDynamicValidator: any = undefined;
  static messageConfirmationDynamicValidatorInstance: any = undefined;
  public messageHtml: string = "";
  public messageHtmlTitle: string = "";

  static showSpinner() {
    $("#globalSpinner").show();
  }

  static hideSpinner() {
    $("#globalSpinner").hide();
  }

  static showMessageError(messageErrorStr: string) {
    CMFormGlobaisComponent.hideSpinner();
    CMFormGlobaisComponent.defaultInstance.messageErrorStr = messageErrorStr;
    if (CMFormGlobaisComponent.defaultInstance.messageErrorStr !== undefined)
      CMFormModalComponent.open("globalMessageError");
  }

  closeMessageError(name) {
    CMFormModalComponent.close(name);
    if (environment.production) this.openNewURL(this.router.url, "_self");
  }

  static showMessageAviso(messageAvisoStr: string) {
    CMFormGlobaisComponent.defaultInstance.messageAvisoStr = messageAvisoStr.substring(
      0
    );
    CMFormModalComponent.open("globalMessageAviso");
  }

  static showMessage(titleMessage: string, messageStr: string) {
    CMFormGlobaisComponent.defaultInstance.titleMessage = titleMessage.substring(
      0
    );
    CMFormGlobaisComponent.defaultInstance.messageStr = messageStr.substring(0);
    CMFormModalComponent.open("globalMessage");
  }

  static showMessageHtml(messageHtml: string, title: string) {
    CMFormGlobaisComponent.defaultInstance.messageHtmlTitle = title.substring(
      0
    );
    CMFormGlobaisComponent.defaultInstance.messageHtml = messageHtml.substring(
      0
    );
    CMFormModalComponent.open("globalMessageHTML");
  }

  static showModalComponent(
    caption: string,
    component: any,
    callbackCreate?: Function,
    callbackClose?: Function
  ) {
    CMFormGlobaisComponent.defaultInstance.showModalCallbackCreate = callbackCreate;
    CMFormGlobaisComponent.defaultInstance.showModalCallbackClose = callbackClose;
    CMFormGlobaisComponent.defaultInstance.showModalTitle = caption;
    CMFormGlobaisComponent.defaultInstance.showModalComponent = component;
    setTimeout(() => {
      CMFormModalComponent.open("globalModalComponent");
    }, 1000);
  }

  static closeModalComponent() {
    CMFormModalComponent.close("globalModalComponent");
    if (
      CMFormGlobaisComponent.defaultInstance.showModalCallbackClose !==
      undefined
    )
      CMFormGlobaisComponent.defaultInstance.showModalCallbackClose(
        CMFormGlobaisComponent.defaultInstance.showModalComponentInstance
      );
    CMFormGlobaisComponent.defaultInstance.showModalComponent = undefined;
    CMFormGlobaisComponent.defaultInstance.showModalCallbackClose = undefined;
    CMFormGlobaisComponent.defaultInstance.showModalCallbackCreate = undefined;
  }

  showModalComponentCreatedCallback(component: Component) {
    CMFormGlobaisComponent.defaultInstance.showModalComponentInstance = component;
    if (
      CMFormGlobaisComponent.defaultInstance.showModalCallbackCreate !==
      undefined
    )
      CMFormGlobaisComponent.defaultInstance.showModalCallbackCreate(component);
  }

  closeModalComponent() {
    CMFormGlobaisComponent.closeModalComponent();
  }

  static showMessageURL(
    _url: any,
    fixedFooter?: boolean,
    showPrint?: boolean,
    _urlcsv?: any,
    _nameDownload?: string
  ) {
    if (_nameDownload)
      CMFormGlobaisComponent.defaultInstance.showMessageURL_nameDownload = _nameDownload;
    if (fixedFooter !== undefined)
      CMFormGlobaisComponent.defaultInstance.fixedFooterMessageURL = fixedFooter;
    else CMFormGlobaisComponent.defaultInstance.fixedFooterMessageURL = true;
    if (CMFormComponent.isMSIE() || CMFormComponent.isiPad()) showPrint = false;
    if (showPrint === undefined) showPrint = true;
    CMFormGlobaisComponent.defaultInstance.showPrintMessageURL = showPrint;
    CMFormGlobaisComponent.defaultInstance.urlCSVMessageURL = "";
    if (_urlcsv !== undefined)
      CMFormGlobaisComponent.defaultInstance.urlCSVMessageURL = _urlcsv;
    if (Object.getPrototypeOf(_url).constructor.name.toLowerCase() === "blob") {
      if (showPrint) {
        if (CMFormComponent.isFireFox()) {
          showPrint = false;
          CMFormGlobaisComponent.defaultInstance.showPrintMessageURL = showPrint;
        }
      }
      var newurl = window.URL.createObjectURL(_url);
      $("#iframe_globalMessageURL").attr("src", newurl);
    } else $("#iframe_globalMessageURL").attr("src", _url.substring(0));
    CMFormModalComponent.open("globalMessageURL");
  }

  closeMessageURL() {
    CMFormGlobaisComponent.defaultInstance.showMessageURL_nameDownload = "";
    $("#iframe_globalMessageURL").attr("src", "");
    this.closeMessage("globalMessageURL");
  }

  printMessageURL() {
    let iframe: any = document.getElementById("iframe_globalMessageURL");
    iframe.contentWindow.focus();
    iframe.contentWindow.print();
  }

  downloadCSV() {
    window.open(this.urlCSVMessageURL, "_self");
  }

  messageURL_nameDownload() {
    var a: any = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = $("#iframe_globalMessageURL").attr("src");
    a.download =
      CMFormGlobaisComponent.defaultInstance.showMessageURL_nameDownload;
    a.click();
  }

  static showConfirmation(
    caption: string,
    message: string,
    callbackYes: Function,
    dynamicValidator?: any
  ) {
    CMFormGlobaisComponent.titleConfirmationStr = caption;
    CMFormGlobaisComponent.messageConfirmationStr = message;
    CMFormGlobaisComponent.confirmationCallbackYes = callbackYes;
    CMFormGlobaisComponent.messageConfirmationDynamicValidator = dynamicValidator;
    CMFormModalComponent.open("globalConfirmation"); 
  }

  getMessageConfirmationDynamicValidator() {
    return CMFormGlobaisComponent.messageConfirmationDynamicValidator;
  }

  public messageConfirmationDynamicValidatorComponentCreatedCallback(component: Component) {
    CMFormGlobaisComponent.messageConfirmationDynamicValidatorInstance = component;
  }  

  public messageConfirmationDynamicValidatorComponentDestroyedCallback() {
    CMFormGlobaisComponent.messageConfirmationDynamicValidatorInstance = undefined;
  }

  getMessageConfirmationStr() {
    return CMFormGlobaisComponent.messageConfirmationStr;
  }

  getTitleConfirmationStr() {
    return CMFormGlobaisComponent.titleConfirmationStr;
  }

  globalMessageConfirmationYes() {
    const callYes = () => {
      CMFormGlobaisComponent.confirmationCallbackYes();
      CMFormModalComponent.close("globalConfirmation");
      CMFormGlobaisComponent.messageConfirmationDynamicValidator = undefined;  
    };
    if (CMFormGlobaisComponent.messageConfirmationDynamicValidatorInstance !== undefined) {
      CMFormGlobaisComponent.messageConfirmationDynamicValidatorInstance.validate(() => {
        callYes();
      });
    }
    else {
      callYes();
    }
  }

  globalMessageConfirmationNo() {
    CMFormGlobaisComponent.messageConfirmationDynamicValidator = undefined;  
    CMFormModalComponent.close("globalConfirmation");
  }

  closeGlobalMessageHTML() {
    CMFormGlobaisComponent.defaultInstance.messageHtml = "";
    this.closeMessage("globalMessageHTML");
  }

  closeMessage(name) {
    CMFormModalComponent.close(name);
  }

  static toastSucesso(content: string, caption?: string) {
    if (caption === undefined) caption = "Sucesso";
    CMFormGlobaisComponent.defaultInstance.notificationsService.success(
      caption,
      content
    );
  }

  static toastErro(content: string, caption?: string) {
    if (caption === undefined) caption = "Erro";
    CMFormGlobaisComponent.defaultInstance.notificationsService.error(
      caption,
      content
    );
  }

  static toastAlerta(content: string, caption?: string) {
    if (caption === undefined) caption = "Alerta";
    CMFormGlobaisComponent.defaultInstance.notificationsService.alert(
      caption,
      content
    );
  }

  static toastInformacao(content: string, caption?: string) {
    if (caption === undefined) caption = "Informação";
    CMFormGlobaisComponent.defaultInstance.notificationsService.info(
      caption,
      content
    );
  }

  static defaultInstance: CMFormGlobaisComponent = undefined;
}
