// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormEditComponent, CMInputConfig,
         CMGridEditConfig, CMGridEditConfigColumn
       } from './../../@cmcore/component';

@Component({
    selector: 'cest-edit',
    templateUrl: 'cest-edit.component.html',
})
export class CESTEditComponent extends CMFormEditComponent {

  getFirstComponentName() {
    return 'model_codigo';
  }

  getTitle() {
    return 'CEST';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'cest';
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      codigo: new CMInputConfig({form: this,
                                 caption: 'Código',
                                 dataType: 'integer',
                                 maxlength: 7,
                                 required: true}),
      descricao: new CMInputConfig({form: this,
                                    caption: 'Descrição',
                                    required: true}),
      ncms: new CMGridEditConfig({form: this,
                                   caption: '',
                                   required: false,
                                   focusComponentOnSelect: 'model_ncms_ncmsh',
                                   fields: {ncmsh: new CMInputConfig({form: this,
                                                                      caption: 'NCM / SH',
                                                                      dataType: 'integer',
                                                                      maxlength: 9,
                                                                      gridConfig: new CMGridEditConfigColumn()}),
                                           }}),
    };
  }

}