// tslint:disable
import { Component, Injector } from '@angular/core';
import { ConfiguracaoBoletoBrowseComponent } from './../../configuracao-boleto/configuracao-boleto-browse.component';
import {
    CMInputConfig, CMFormExecComponent,
    CMFormGlobaisComponent
} from './../../../@cmcore/component';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'boleto-impressao',
    templateUrl: 'boleto-impressao.component.html'
})
export class RelatBoletoImpressaoComponent extends CMFormExecComponent {

    constructor(_injector: Injector) {
        super(_injector);
        this.filterConfig = {
            dataini: new CMInputConfig({
                form: this,
                caption: 'Data Inicial',
                mask: '99/99/9999',
                required: true
            }),
            datafim: new CMInputConfig({
                form: this,
                caption: 'Data Final',
                mask: '99/99/9999',
                required: true
            }),
            configuracaoboleto: new CMInputConfig({
                form: this,
                caption: "Configuração de Boleto",
                pesqFunc: (sender) => {
                    ConfiguracaoBoletoBrowseComponent.pesquisa(sender, this.filter);
                    console.log('this.filter: ', this.filter)
                },
            }),        
        }
        this.filter.dataini = this.firstDate();
        this.filter.datafim = this.lastDate();        
    }

    private downloadFileBoleto(content: string, fileName: string): void {
        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${content}`;
        link.download = fileName;
        link.target = '_blank';
        link.style.display = 'none';

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    }    

    innerExec() {
        super.innerExec();
        this.ImprimeBoleto();
    }

    ImprimeBoleto () {
        let filter = this.filter;
        CMFormGlobaisComponent.showSpinner();
        let date = new Date;
        let dia = date.getDate();
        let diaformatado;
        let mes = date.getMonth() + 1;
        let mesformatado;

        if (dia < 10) {
            diaformatado = "0" + dia;
        }
        else {
            diaformatado = dia;
        }

        if (mes === 10) {
            mesformatado = "O";
        }
        else if (mes === 11) {
            mesformatado = "N";
        }
        else if (mes === 12) {
            mesformatado = "D";
        }
        else {
            mesformatado = mes;
        }

        this.postSubscribeT<Array<string>>('/api/ConfiguracaoBoleto/ImprimirBoleto',
            JSON.stringify(filter),
            (result) => {
                let result_str = result.pop();
                if (result_str === 'ok') {
                    let fileName = result.pop();                    
                    let base64pdf = result.pop();
                    this.downloadFileBoleto(base64pdf, fileName);                 
                }
                else {
                    CMFormGlobaisComponent.toastErro(result_str);
                }
                CMFormGlobaisComponent.hideSpinner();
            },
            error => {
                CMFormGlobaisComponent.hideSpinner();
                this.showMessageErrorHTTP('ImprimirBoleto', error);
            });
        
    }

}