// tslint:disable
import {Component, OnInit, Input, ViewChild, TemplateRef, ContentChild} from '@angular/core';

import {CMGridComponent} from './cmgrid.component';
import {CMInput, BooleanConverter} from '../../decorator';

@Component({
  selector: 'cmgrid-column-nao-usar',
  templateUrl: 'cmgrid-column.component.html',
})
export class CMGridColumnComponent implements OnInit {
  @Input() caption: string = '';
  @Input() field: string = '';
  @Input() name: string = '';
  @Input()
  @CMInput()
  sortable: boolean = false;
  @Input()
  @CMInput()
  width: number = 1;
  get canAutoResize() {
    return this.width === 1;
  }
  @Input()
  @CMInput()
  frozenRight: boolean = false;

  private _visible: boolean = true;
  @Input()
  @CMInput(BooleanConverter)
  get visible() {
    return this._visible;
  }
  set visible(value: boolean) {
    if (this._visible !== value) {
      this._visible = value;
      this.grid.changeVisibleColumns();
    }
  }

  @ViewChild('cellTemplate', {static: true}) cellTemplate: TemplateRef<any>;
  @ContentChild(TemplateRef, {static: true}) cellTemplateChild: TemplateRef<any>;

  constructor(private grid: CMGridComponent) {}

  ngOnInit() {
    this.grid.addColumn(this);
  }

  getTitle() {
    let r = this.caption;
    return r;
  }
}
