// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowseComponent,
  CMFormPesquisaComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
} from "./../../../@cmcore/component";
import { RecursoEditComponent } from "./recurso-edit.component";
import { HttpClient } from "@angular/common/http";

@Component({
  selector: "recurso-browse",
  templateUrl:
    "./../../../@cmcore/component/cmform/cmform-browse/cmform-browse.component.html",
  styleUrls: [
    "./../../../@cmcore/component/cmform/cmform-browse/cmform-browse.component.scss",
  ],
})
export class RecursoBrowseComponent extends CMFormBrowseComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "recurso";
    this.formEditComponent = RecursoEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = [
      { field: "id", caption: "#", width: 100 },
      { field: "tipo", caption: "Tipo", width: 200 },
      {
        field: "descricao",
        caption: "Descrição",
        filter: new CMInputConfig({
          maxlength: 500,
        }),
      },
      { field: "ativo", caption: "Ativo", width: 100 },
    ];
  }

  getTitle() {
    return "Recurso";
  }

  static pesquisa(sender: any, target: any, tipo?: string | Array<string>) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "recurso",
        "Pesquisa de Recursos",
        [
          { field: "id", caption: "#", visible: false },
          { field: "descricao", caption: "Descrição", filter: true },
        ],
        target,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        { tipo: tipo }
      );
    }
  }

  static getTodosRecursos(http: HttpClient, onResult: Function) {
    CMFormGlobaisComponent.showSpinner();

    CMFormBrowseComponent.postSubscribeT<Array<Object>>(
      http,
      "/api/recurso/GetAll",
      JSON.stringify({}),
      (rec) => {
        onResult(
          rec.sort((a, b) => {
            let da = a["descricao"].toLowerCase();
            let db = b["descricao"].toLowerCase();
            return da > db ? 1 : db > da ? -1 : 0;
          })
        );
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }
}
