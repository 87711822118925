// tslint:disable
import { Component, Injector, ViewChild } from "@angular/core";
import { FuncsGlobais } from "../funcs.globais";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
} from "./../../@cmcore/component";
import {
  MunicipioBrowseComponent,
  PessoaBrowseComponent,
  ProdutoBrowseComponent,
  VendedorBrowseComponent,
} from "./../index";

@Component({
  selector: "nfe-importacao-marketplace-edit",
  templateUrl: "nfe-importacao-marketplace-edit.component.html",
})
export class NFeImportacaoMarketplaceEditComponent extends CMFormEditComponent {
  getTitle() {
    let r: string = "Importação de Marketplace";
    let tmp: string = this.fieldByName("infnfe.chaveacesso");
    if (tmp !== "0") r += " - " + tmp;
    return r;
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "nfeimportacaomarketplace";
    this.setModelConfig();
  }

  afterGetModelData() {
    super.afterGetModelData();
    if (!this.disabledAll)
      this.disabledAll = this.model.infnfe.podeeditar !== "Sim";
  }

  setModelConfig() {
    this.modelConfig = {
      marketplace: new CMInputConfig({
        form: this,
        caption: "Marketplace",
        disabled: true,
      }),
      infnfe: {
        chaveacesso: new CMInputConfig({
          form: this,
          caption: "Número no Marketplace",
          disabled: true,
        }),
        ide: {
          nnf: new CMInputConfig({
            form: this,
            caption: "#",
            disabled: true,
          }),
          dhemi: new CMInputConfig({
            form: this,
            caption: "Data emissão",
            mask: "99/99/9999",
            disabled: true,
          }),
        },
        dest: {
          pessoa: new CMInputConfig({
            form: this,
            caption: "Cliente",
            disabled: true,
          }),
          enderecoentrega: {
            logradouro: new CMInputConfig({
              form: this,
              caption: "Logradouro",
              maxlength: 60,
              disabled: true,
            }),
            numero: new CMInputConfig({
              form: this,
              caption: "Número",
              maxlength: 10,
              disabled: true,
            }),
            bairro: new CMInputConfig({
              form: this,
              caption: "Bairro",
              maxlength: 60,
              disabled: true,
            }),
            complemento: new CMInputConfig({
              form: this,
              caption: "Complemento",
              maxlength: 60,
              disabled: true,
            }),
            estado: new CMInputConfig({
              form: this,
              caption: "Estado",
              disabled: true,
              values: [
                { value: "AC" },
                { value: "AL" },
                { value: "AP" },
                { value: "AM" },
                { value: "BA" },
                { value: "CE" },
                { value: "DF" },
                { value: "ES" },
                { value: "GO" },
                { value: "MA" },
                { value: "MT" },
                { value: "MS" },
                { value: "MG" },
                { value: "PR" },
                { value: "PB" },
                { value: "PA" },
                { value: "PE" },
                { value: "PI" },
                { value: "RJ" },
                { value: "RN" },
                { value: "RS" },
                { value: "RO" },
                { value: "RR" },
                { value: "SC" },
                { value: "SE" },
                { value: "SP" },
                { value: "TO" },
              ],
            }),
            ibge: new CMInputConfig({
              form: this,
              caption: "Código do Município no IBGE",
              pesqFunc: (sender) => {
                MunicipioBrowseComponent.pesquisa(
                  this.model.infnfe.dest.enderecoentrega.estado,
                  sender,
                  this.model.infnfe.dest.enderecoentrega,
                  "ibge"
                );
              },
              required: true,
            }),
            cep: new CMInputConfig({
              form: this,
              caption: "CEP",
              mask: "99999-999",
              disabled: true,
            }),
          },
        },
        det: new CMGridEditConfig({
          form: this,
          objName: "det",
          caption: "Produtos",
          extraColumns: {
            grade: new CMInputConfig({
              form: this,
              caption: "Grade",
              required: true,
              pesqOnly: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: "input-pesquisa",
                ordem: 2,
                width: "350px",
                disabledFunc: (row) => {
                  return !this.produtoUsaGrade(row);
                },
                getValue: (row) => {
                  let r = "";
                  if (row && row.estoque && row.estoque.length > 0) {
                    const item = row.estoque[0];
                    if (item.grade) {
                      r = item.grade.descricao;
                    }
                  }
                  return r;
                },
              }),
              pesqFunc: (sender) => {
                let itemEstoque = {};
                const current = this.modelConfig.infnfe.det.current;
                if (current && current.estoque) {
                  if (current.estoque.length == 0) {
                    current.estoque.push({});
                  }
                  itemEstoque = current.estoque[0];
                }
                this.modelConfig.infnfe.det.current.prod.produto.id;
                ProdutoBrowseComponent.pesquisaGrade(
                  sender,
                  itemEstoque,
                  this.modelConfig.infnfe.det.current.prod.produto.id
                );
              },
              visibleFunc: () => {
                return (
                  this.paramEmpresa.usaGrade1 || this.paramEmpresa.usaGrade2
                );
              },
            }),
          },
          fields: {
            prod: {
              produto: new CMInputConfig({
                form: this,
                caption: "Produto",
                maxlength: 30,
                padInfo: { size: 30 },
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 1,
                  width: "350px",
                  tipo: "input-pesquisa",
                  getValue: (row) => {
                    return this.getValueObject(row, "prod.produto.codigo");
                  },
                  getBottomLabelColumn: (row) => {
                    return this.getValueObject(row, "prod.produto.descr");
                  },
                }),
                pesqFunc: (sender) => {
                  ProdutoBrowseComponent.pesquisa(
                    sender,
                    this.modelConfig.infnfe.det.current.prod,
                    "Ativos"
                  );
                },
              }),
              cprod: new CMInputConfig({
                form: this,
                caption: "SKU",
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 3,
                }),
                disabled: true,
              }),
              xprod: new CMInputConfig({
                form: this,
                caption: "Descrição SKU",
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 4,
                }),
                disabled: true,
              }),
              qcom: new CMInputConfig({
                form: this,
                caption: "Quantidade",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 5,
                }),
                disabled: true,
              }),
              vuncom: new CMInputConfig({
                form: this,
                caption: "Valor unitário",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 6,
                }),
                disabled: true,
              }),
              vtotal: new CMInputConfig({
                form: this,
                caption: "Valor total",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 7,
                }),
                disabled: true,
              }),
            },
          },
        }),
        total: {
          icmstot: {
            vnf: new CMInputConfig({
              form: this,
              caption: "Valor total",
              dataType: "double(2)",
              disabled: true,
            }),
            vfrete: new CMInputConfig({
              form: this,
              caption: "Valor do Frete",
              dataType: "double(2)",
              disabled: true,
            }),
            vdesc: new CMInputConfig({
              form: this,
              caption: "Valor de Desconto",
              dataType: "double(2)",
              disabled: true,
            }),
          },
        },
        infadic: {
          infcpl: new CMInputConfig({
            form: this,
            caption: "Observação",
            disabled: true,
          }),
        },
      },
    };
  }

  produtoUsaGrade(row) {
    let r = false;
    if (row && row.prod && row.prod.produto) {
      r =
        row.prod.produto.usagrade1 === "Sim" ||
        row.prod.produto.usagrade2 === "Sim";
    }
    return r;
  }

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }
}
