// tslint:disable
import { Component, Injector } from '@angular/core';
import { FuncsGlobais } from '../funcs.globais';

import { CMInputConfig, CMFormExecComponent,
         CMFormGlobaisComponent } from './../../@cmcore/component';

@Component({
  templateUrl: "exportar-xmls-nfe.component.html",
})
export class ExportarXmlComponent extends CMFormExecComponent {
  private sub: any;

  constructor(_injector: Injector) {
    super(_injector);
    this.functionName = "ExportarXmlsNFe/FuncaoExtra";
    this.controllerName = "nfe";
    this.downloadFiles = true;
    this.filterConfig = {
      dataini: new CMInputConfig({form: this,
                                  caption: 'Data Inicial',
                                  mask: '99/99/9999',
																	required: true
                                 }),
      datafim: new CMInputConfig({form: this,
                                  caption: 'Data Final',
                                  mask: '99/99/9999',
																	required: true
                                 }),
		}
    this.filter.dataini = this.firstDate();
    this.filter.datafim = this.lastDate();
	}

	innerExec() {
    super.innerExec();
		this.geraXml();
	}

  geraXml() {

    let filter = this.filter;
    
    this.postSubscribeT<Array<string>>('/api/nfe/ExportarXmlsNFe/FuncaoExtra',
                  JSON.stringify(filter),
            (result) => {
              console.log("result: ", result);
              let result_str = result.pop();
              if (result_str === 'ok') {
                let contentFile = result.pop();

                let fileName = 'xmlsnfes-'+this.filter.filtro.dataini+'-'+this.filter.filtro.datafim+'.zip';
                //let fileName = 'SpedEFD-'+this.paramEmpresa.Convenio+mesformatado+diaformatado+'.crm';
                this.downloadContent(contentFile, fileName);
              }
              else {
                CMFormGlobaisComponent.toastErro("Não existem dados nessas condições");
              }
            },
            error => {
              this.showMessageErrorHTTP('exportarXMLsNFes', error);
            });
  }
  
}
