// tslint:disable
import { Component, Input } from "@angular/core";

import { CMInput } from "../../decorator";

@Component({
  selector: "cmicone",
  templateUrl: "./cmicone.component.html",
  styleUrls: ["./cmicone.component.scss"],
})
export class CMIconeComponent {
  @Input()
  @CMInput()
  fontSize: number = 0;

  @Input()
  color: string = "";

  @Input()
  lineHeight: string = "";

  _hint: string = "";
  @Input()
  get hint() {
    return this._hint;
  }
  set hint(value) {
    this._hint = value;
  }

  getStyle() {
    let r: string = "";
    if (this.fontSize > 0) r += "font-size: " + this.fontSize + "em;";
    if (this.color !== "") r += "color: " + this.color + ";";
    if (this.lineHeight !== "") r += "line-height: " + this.lineHeight + ";";
    return r;
  }

  getClass() {
    let r: string = "material-icons cmicone";
    return r;
  }
}
