// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "../../@cmcore/component/cmform/cmform.component";

import {
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMGridEditConfig,
  CMInputConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
} from "../../@cmcore/component";
import {
    NFeBrowseComponent,
} from "../index";
@Component({
  selector: "nfe-recebimento-relatorios",
  templateUrl: "nfe-recebimento-relatorios.component.html",
  styleUrls: ["nfe-recebimento-relatorios.component.scss"],
})
export class NFeRecebimentoRelatoriosComponent extends CMFormComponent {
  _owner: NFeBrowseComponent = undefined;

  get owner() {
    return this._owner;
  }

  private static relatoriosCustomizados: any = [];
  static isLista = false;

  static addRelatorioCustomizado(item: any){
    NFeRecebimentoRelatoriosComponent.relatoriosCustomizados.push(item);
  }

  static getRelatorioCustomizados(){
    return NFeRecebimentoRelatoriosComponent.relatoriosCustomizados;
  }


  dados = {
    tiporelatorio: "NFeRecebimentoEtiquetaPadrao",
  };

  config = {
    tiporelatorio: new CMInputConfig({
                                    form: this,
                                    values: [
                                      {value: "NFeRecebimentoEtiquetaPadrao", text: "Etiqueta Padrão"},
                                    ],
                                  }),
  };

  Imprimir(){
    
    if (this.dados.tiporelatorio === "NFeRecebimentoEtiquetaPadrao"){

      if (!NFeRecebimentoRelatoriosComponent.isLista){
        this.showRelat(
          "/ImprimeEtiquetas/FuncaoExtra",
          {
            idnferecebimento: this.owner.fieldByName("id"),
            nnf: this.owner.fieldByName("nnf"),
          },
          "NFeRecebimento"
        );
      }
      else{
        let pessoa;
        let produto;
        if (this.owner.valuesFilters.hasOwnProperty("pessoa")){
          pessoa = this.owner.valuesFilters.pessoa.id;
        }
        else {
          pessoa = 0;
        }
        if (this.owner.valuesFilters.hasOwnProperty("produto")){
          produto = this.owner.valuesFilters.produto.id;
        }
        else {
          produto = 0;
        }

        this.showRelat(
          "/ImprimeEtiquetas/FuncaoExtra",
          {
            idnferecebimento: this.owner.fieldByName("id"),
            nnf: this.owner.valuesFilters.nnf,
            listaetiqueta: 1,
            dhemi: this.owner.valuesFilters.dhemi,
            nnf_nferecebimento: this.owner.valuesFilters.nnf_nferecebimento,
            pessoa,
            produto,
            serie: this.owner.valuesFilters.serie
          },
          "NFeRecebimento"
        );
      }

    }
    else{

      if (!NFeRecebimentoRelatoriosComponent.isLista){
        let data = { 
          idnferecebimento: this.owner.fieldByName("id"),
          nnf: this.owner.fieldByName("nnf"),
          idrelatorio: this.dados.tiporelatorio
        };
        console.log(data);
        data = this.owner.getDataPrint(data);
        this.showRelat(
          "/ImpressaoNFeEtiquetaCustomizada",
          data,
          "nferecebimento"
        );
      }
      else {
        console.log('relatorio etiqueta customizada lista')
        let pessoa;
        let produto;
        if (this.owner.valuesFilters.hasOwnProperty("pessoa")){
          pessoa = this.owner.valuesFilters.pessoa.id;
        }
        else {
          pessoa = 0;
        }
        if (this.owner.valuesFilters.hasOwnProperty("produto")){
          produto = this.owner.valuesFilters.produto.id;
        }
        else {
          produto = 0;
        }

        this.showRelat(
          "/ImpressaoNFeEtiquetaCustomizada",
          {
            idnferecebimento: this.owner.fieldByName("id"),
            nnf: this.owner.valuesFilters.nnf,
            listaetiqueta: 1,
            dhemi: this.owner.valuesFilters.dhemi,
            nnf_nferecebimento: this.owner.valuesFilters.nnf_nferecebimento,
            pessoa,
            produto,
            serie: this.owner.valuesFilters.serie,
            idrelatorio: this.dados.tiporelatorio
          },
          "nferecebimento"
        );
      }
    }
  }

  podeCadastrarProduto: boolean = false;
  set owner(value) {
    this._owner = value;
  }

  constructor(_injector: Injector) {
    super(_injector);
    console.log('construtor');
    this.config.tiporelatorio.values.push.apply(this.config.tiporelatorio.values, NFeRecebimentoRelatoriosComponent.relatoriosCustomizados)
  }


  close(formName) {
    if (this.owner) this.owner.beforeComponent = undefined;
    CMFormModalComponent.close(formName);
  }
}
