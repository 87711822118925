// tslint:disable
import { Pipe } from "@angular/core";
import {
  DomSanitizer,
  SafeHtml,
  SafeStyle,
  SafeScript,
  SafeUrl,
  SafeResourceUrl,
} from "@angular/platform-browser";

@Pipe({
  name: "cmsafe",
})
export class CMSafePipe {
  constructor(protected _sanitizer: DomSanitizer) {}

  private sanitizerAutoImg(value: string) {
    let r: any = value;
    if (typeof value === "string") {
      if (value.substr(0, 5) === "data:")
        r = this._sanitizer.bypassSecurityTrustResourceUrl(value);
      else r = this._sanitizer.bypassSecurityTrustUrl(value);
    }
    return r;
  }

  public transform(
    value: string,
    type: string = "html"
  ): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    if (value === undefined) {
      value = "";
    }
    switch (type) {
      case "html":
        return this._sanitizer.bypassSecurityTrustHtml(value);
      case "style":
        return this._sanitizer.bypassSecurityTrustStyle(value);
      case "script":
        return this._sanitizer.bypassSecurityTrustScript(value);
      case "url":
        return this._sanitizer.bypassSecurityTrustUrl(value);
      case "resourceUrl":
        return this._sanitizer.bypassSecurityTrustResourceUrl(value);
      case "auto_img":
        return this.sanitizerAutoImg(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
