// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormEditComponent, CMInputConfig, CMGridComponent,
         CMInputComponent, CMGridConfig, CMListCheckBoxConfig
       } from './../../../@cmcore/component';

@Component({
    selector: 'recurso-edit',
    templateUrl: 'recurso-edit.component.html',
})
export class RecursoEditComponent extends CMFormEditComponent {

  getFirstComponentName() {
    return 'model_hash';
  }

  getTitle() {
    return 'Recurso';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'recurso';
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      id: new CMInputConfig({form: this,
                             caption: '#',
                             disabled: true}),
      hash: new CMInputConfig({form: this,
                               caption: 'Hash',
                               maxlength: 255,
                               required: true}),
      tipo: new CMInputConfig({form: this,
                               caption: 'Tipo',
                               values: [
                                 {'value': "MenuBrowse"},
                                 {'value': "MenuRelatorio"},
                                 {'value': "MenuBrowseFuncaoExtra"}
                               ],
                               required: true}),
      descricao: new CMInputConfig({form: this,
                                    caption: 'Descrição',
                                    maxlength: 500,
                                    required: true}),
      permissoes: new CMListCheckBoxConfig({form: this,
                                            caption: 'Permissões',
                                            list: ['Cadastrar',
                                                   'Editar',
                                                   'Apagar',
                                                   'Visualizar',
                                                   'Imprimir',
                                                   'Executar']}),
      ativo: new CMInputConfig({form: this,
                                caption: 'Ativo'}),
    };
  }

}