// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormRelatComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
} from "./../../../@cmcore/component";
import { VendedorBrowseComponent } from "../../vendedor";
import { ClienteBrowseComponent } from "../../cliente";

@Component({
  selector: "produtos-consignacao",
  templateUrl: "produtos-consignacao.component.html",
})
export class RelatProdutosConsignacaoComponent extends CMFormRelatComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = "ImpressaoProdutosConsignacao";
    this.filterConfig = {
      cliente: new CMInputConfig({
        form: this,
        caption: "Cliente",
        pesqFunc: (sender) => {
          ClienteBrowseComponent.pesquisa(sender, this.filter);
        },
      }),
      vendedor: new CMInputConfig({
        form: this,
        caption: "Vendedor",
        pesqFunc: (sender) => {
          VendedorBrowseComponent.pesquisa(sender, this.filter);
        },
      }),
      dataini: new CMInputConfig({
        form: this,
        caption: "Data Inicial",
        mask: "99/99/9999",
      }),
      datafim: new CMInputConfig({
        form: this,
        caption: "Data Final",
        mask: "99/99/9999",
      }),
    };
    this.filter.dataini = CMFormRelatComponent.firstDate();
    this.filter.datafim = CMFormRelatComponent.lastDate();
  }

  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.dataini);
      if (!r) CMFormGlobaisComponent.toastErro("Data inicial não informada");
    }
    if (r) {
      r = !this.isEmpty(this.filter.datafim);
      if (!r) CMFormGlobaisComponent.toastErro("Data final não informada");
    }
    return r;
  }
}
