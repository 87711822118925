// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMInputConfig,
} from "./../../@cmcore/component";
import { PromocaoEditComponent } from "./promocao-edit.component";

@Component({
  selector: "promocao-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class PromocaoBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "promocao";
    this.formEditComponent = PromocaoEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = [
      {
        field: "nome",
        caption: "Nome",
        filter: new CMInputConfig({
          maxlength: 10,
        }),
      },
      {
        field: "datainicio",
        caption: "Data de Início",
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {
        field: "datatermino",
        caption: "Data de Término",
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      { field: "vuncom", caption: "Preço de Venda" },
    ];
  }

  getTitle() {
    return "Promoção de Produtos";
  }
}
