// tslint:disable
import { Component, Injector } from "@angular/core";
import { MarketplaceBrowseComponent } from "../marketplace";
import { ProdutoBrowseComponent } from "../produto";

import {
  CMFormEditComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMInputConfig,
} from "./../../@cmcore/component";

@Component({
  selector: "produto-marketplace-edit",
  templateUrl: "produto-marketplace-edit.component.html",
})
export class ProdutoMarketplaceEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_produto";
  }

  getTitle() {
    return "Marketplace -> Produto";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "ProdutoMarketplace";
    this.modelConfig = {
      produto: new CMInputConfig({
        form: this,
        caption: "Produto",
        required: true,
        maxlength: 30,
        padInfo: { size: 30 },
        disabledFunc: () => {
          return this.model.grades.length !== 0;
        },
        pesqFunc: (sender) => {
          ProdutoBrowseComponent.pesquisa(sender, this.model, "Ativos");
        },
      }),
      grades: new CMGridEditConfig({
        form: this,
        required: true,
        caption: "SKUs",
        fields: {
          grade: new CMInputConfig({
            form: this,
            caption: "Grade",
            required: true,
            pesqOnly: true,
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "grade.descricao");
              },
            }),
            pesqFunc: (sender) => {
              ProdutoBrowseComponent.pesquisaGrade(
                sender,
                this.modelConfig.grades.current,
                this.model.produto.id
              );
            },
            visibleFunc: () => {
              return this.produtoUsaGrade();
            },
          }),
          sku: new CMInputConfig({
            form: this,
            caption: "SKU",
            maxlength: 60,
            gridConfig: new CMGridEditConfigColumn(),
            required: true,
          }),
          marketplaces: new CMGridEditConfig({
            form: this,
            required: true,
            caption: "Marketplaces",
            fields: {
              marketplace: new CMInputConfig({
                form: this,
                caption: "Marketplace",
                required: true,
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  getValue: (row) => {
                    return this.getValueObject(row, "marketplace.codigo");
                  },
                  getBottomLabelColumn: (row) => {
                    return this.getValueObject(row, "marketplace.nome");
                  },
                }),
                pesqFunc: (sender) => {
                  MarketplaceBrowseComponent.pesquisa(
                    sender,
                    this.modelConfig.grades.fields.marketplaces.current
                  );
                },
              }),
            },
          }),
        },
      }),
    };
  }

  produtoUsaGrade() {
    return (
      this.getValueObject(this.model, "produto.usagrade1") === "Sim" ||
      this.getValueObject(this.model, "produto.usagrade2") === "Sim"
    );
  }
}
