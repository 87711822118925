// tslint:disable
import { Injector, Component } from "@angular/core";
import { FuncsGlobais } from "../funcs.globais";
import { SolicitaSenhaSupervisor } from "../solicita-senha-supervisor";

import {
  CMFormBrowse2Component,
  CMFormGlobaisComponent,
  CMFormPesquisaComponent,
  CMFormPesquisaV2Component,
  CMInputConfig,
} from "./../../@cmcore/component";

@Component({
  selector: "pessoa-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class PessoaBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "pessoa";
  }

  static pesquisa(
    sender: any,
    target: any,
    tipo: string,
    targetProp?: string,
    onAfterSelect?: Function,
    extraFilters?: Object,
    extraColumns?: Array<any>
  ) {
    if (target) {
      let columns = [
        { field: "codigo", caption: "#" },
        {
          field: "$tipopessoa",
          caption: "Tipo",
          filter: true,
          value: tipo,
          visible: false,
          filterVisible: false,
        },
        {
          field: "nome",
          caption: "Nome",
          filter: new CMInputConfig({
            maxlength: 60,
          }),
        },
        {
          field: "fantasia",
          caption: "Fantasia",
          filter: new CMInputConfig({
            maxlength: 100,
          }),
        },
        {
          field: "cpf",
          caption: "CPF",
          filter: true,
          mask: "999.999.999-99",
        },
        {
          field: "cnpj",
          caption: "CNPJ",
          filter: true,
          mask: "99.999.999/9999-99",
        },
      ];
      if (extraColumns) {
        extraColumns.forEach((column) => {
          columns.push(column);
        });
      }
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "pessoa",
        "Pesquisa de " + tipo,
        columns,
        target,
        targetProp,
        "codigo",
        onAfterSelect,
        undefined,
        undefined,
        extraFilters
      );
    }
  }

  
  static pesquisaPDV(
    sender: any,
    target: any,
    tipo: string,
    targetProp?: string,
    onAfterSelect?: Function,
    extraFilters?: Object,
    extraColumns?: Array<any>
  ) {
    if (target) {
      let columns = [
        { field: "codigo", caption: "#" },
        {
          field: "nome",
          caption: "Nome",
          filter: new CMInputConfig({
            maxlength: 60,
          }),
          onKeyPress: (sender) => {
            clearTimeout(CMFormPesquisaV2Component.timeoutid);
            CMFormPesquisaV2Component.timeoutid = setTimeout(() => {
              CMFormPesquisaV2Component.instance.filtrar();
              clearTimeout(CMFormPesquisaV2Component.timeoutid);
            }, 500);
          },
        },
        {
          field: "fantasia",
          caption: "Fantasia",
          filter: new CMInputConfig({
            maxlength: 100,
          }),
        },
        {
          field: "cpf",
          caption: "CPF",
          filter: true,
          mask: "999.999.999-99",
        },
        {
          field: "cnpj",
          caption: "CNPJ",
          filter: true,
          mask: "99.999.999/9999-99",
        },
      ];
      if (extraColumns) {
        extraColumns.forEach((column) => {
          columns.push(column);
        });
      }
      CMFormPesquisaV2Component.defaultPesquisa(
        sender,
        "pessoa",
        "Pesquisa de " + tipo,
        columns,
        target,
        targetProp,
        "codigo",
        onAfterSelect,
        undefined,
        undefined,
        extraFilters
      );
    }
  }
  
  static pesquisaContador(
    sender: any,
    target: any,
    targetProp?: string,
    onAfterSelect?: Function,
    extraFilters?: Object,
    extraColumns?: Array<any>
  ) {
    if (target) {
      let columns = [
        { field: "codigo", caption: "#" },
        {
          field: "$tipopessoa",
          caption: "Tipo",
          filter: true,
          visible: false,
          filterVisible: false,
        },
        {
          field: "nome",
          caption: "Nome",
          filter: new CMInputConfig({
            maxlength: 60,
          }),
        },
        {
          field: "fantasia",
          caption: "Fantasia",
          filter: new CMInputConfig({
            maxlength: 100,
          }),
        },
        {
          field: "cpf",
          caption: "CPF",
          filter: true,
          mask: "999.999.999-99",
        },
        {
          field: "cnpj",
          caption: "CNPJ",
          filter: true,
          mask: "99.999.999/9999-99",
        },
      ];
      if (extraColumns) {
        extraColumns.forEach((column) => {
          columns.push(column);
        });
      }
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "pessoa",
        "Pesquisa de Contador",
        columns,
        target,
        targetProp,
        "codigo",
        onAfterSelect,
        undefined,
        undefined,
        extraFilters
      );
    }
  }

  static pesquisaEnderecoEntrega(idPessoa, onAfterSelect: Function) {
    CMFormPesquisaComponent.defaultPesquisaSelect(
      "pessoa",
      "Pesquisa de Endereço de Entrega",
      [
        { field: "id", caption: "#", visible: false },
        {
          field: "identificacao",
          caption: "Identificação",
          filter: true,
          width: "140px",
        },
        {
          field: "endereco",
          caption: "Endereço",
        },
      ],
      onAfterSelect,
      undefined,
      { pesqenderecoentrega: "Sim", idpessoa: idPessoa },
      100
    );
  }

  static setValidProcSolicitaPermissaoVendaContaVencida(_this, getAvista) {
    if (FuncsGlobais.paramEmpresa.solicitaPermissaoVendaContaVencida) {
      _this.validProc = (procSave) => {
        if (!getAvista()) {
          CMFormGlobaisComponent.showSpinner();
          _this.postSubscribe(
            "/api/pessoa/TemContaVencida",
            JSON.stringify({
              idpessoa: _this.fieldByName('cliente.id')
            }),
            (result) => {
              CMFormGlobaisComponent.hideSpinner();
              if (result) {
                if (result['ok'] == false) {
                  procSave();
                }
                else {
                  if (_this.isRecurso(109)) {
                    CMFormGlobaisComponent.showConfirmation(
                      'AVISO', 
                      '<p style="padding: 0 0.75rem;">Cliente com contas em atraso deseja continuar?</p>', 
                      () => {
                        procSave();
                      });
                  }
                  else {
                    CMFormGlobaisComponent.showConfirmation(
                      'AVISO', 
                      '<p style="padding: 0 0.75rem;">Cliente com contas em atraso deseja continuar?</p>', 
                      () => {
                        procSave();
                      },
                      SolicitaSenhaSupervisor);
                  }
                }
              }
            },
            (error) => {
              CMFormGlobaisComponent.hideSpinner();
              CMFormGlobaisComponent.showMessageError(error);
            }
          );
        }
        else {
          procSave();
        }
      }
    }
  }
}
