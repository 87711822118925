// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMListCheckBoxConfig,
  CMGridEditConfigColumn,
  CMGridComponent,
} from "./../../../@cmcore/component";
import { GrupoUsuarioBrowseComponent } from "./../seguranca/grupo-usuario-browse.component";
import { RecursoBrowseComponent } from "..";

@Component({
  selector: "usuario-edit",
  templateUrl: "usuario-edit.component.html",
})
export class UsuarioEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_nome";
  }

  getTitle() {
    return "Usuário";
  }

  todosRecursos = [];

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "usuario";
    RecursoBrowseComponent.getTodosRecursos(this.http, (dados) => {
      this.todosRecursos = dados;
    });
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      nome: new CMInputConfig({
        form: this,
        caption: "Nome",
        required: true,
        maxlength: 255,
      }),
      ativo: new CMInputConfig({ form: this, caption: "Ativo" }),
      login: new CMInputConfig({
        form: this,
        caption: "Login",
        required: true,
        lower: true,
        minlength: 3,
        maxlength: 50,
      }),
      senha: new CMInputConfig({
        form: this,
        caption: "Senha",
        criptografado: true,
        required: true,
        minlength: 6,
        maxlength: 17,
      }),
      grupos: new CMGridEditConfig({
        form: this,
        caption: "Grupos",
        required: false,
        fields: {
          grupo: new CMInputConfig({
            form: this,
            caption: "Grupo",
            pesqOnly: true,
            required: true,
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "grupo.nome");
              },
            }),
            pesqFunc: (sender) => {
              GrupoUsuarioBrowseComponent.pesquisa(
                sender,
                this.modelConfig.grupos.current,
                "grupo"
              );
            },
          }),
        },
      }),
      recursos: new CMListCheckBoxConfig({
        form: this,
        listFunc: () => {
          return this.todosRecursos;
        },
        caption: "Recursos",
        propValue: "descricao",
        subProp: "recurso",
        columns: 1,
        striping: true,
        required: false,
        onSelect: (item) => {
          if (item.id == undefined) item.id = CMGridComponent.newId();
          item.permissoes = Object.assign([], item.recurso.permissoes);
        },
        nodeList: new CMListCheckBoxConfig({
          form: this,
          columns: 1,
          showBorder: false,
          listFunc: (nodeItem, nodeValue) => {
            if (nodeItem) return nodeItem.permissoes;
            else return [];
          },
          visibleFunc: (nodeItem, nodeValue) => {
            if (
              nodeValue &&
              nodeValue.permissoes &&
              nodeValue.permissoes.length > 1
            )
              return true;
            else return false;
          },
          onGetValue: (nodeItem, nodeValue) => {
            if (nodeValue) return nodeValue.permissoes;
            else return [];
          },
          onSetValue: (nodeItem, nodeValue, value) => {
            if (nodeValue) nodeValue.permissoes = value;
          },
        }),
      }),
    };
  }
}
