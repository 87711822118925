// tslint:disable
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

import { CMTabsComponent } from "./cmtabs.component";
import { CMInput } from "../../decorator";

@Component({
  selector: "cmtab",
  styles: [
    `
      .cmtab-padding {
        padding: 1em;
      }
      .cmtab-hide {
        position: fixed;
        top: -30000px;
        width: 10px;
        opacity: 0;
        z-index: -100;
      }
    `,
  ],
  template: `
    <div [class.cmtab-padding]="autoPadding" [class.cmtab-hide]="!active">
      <ng-content></ng-content>
    </div>
  `,
})
export class CMTabComponent implements OnInit {
  @Input()
  caption: string = "";

  @Input()
  @CMInput()
  visible: boolean = true;

  @Input()
  @CMInput()
  autoPadding: boolean = true;

  @Input()
  innerClass: string = "";

  @Output()
  onActive: EventEmitter<CMTabComponent> = new EventEmitter<CMTabComponent>();

  private innerActive = false;
  @Input()
  get active() {
    return this.innerActive;
  }
  set active(value: boolean) {
    if (value) this.owner.tabs.forEach((tab) => (tab.innerActive = false));
    this.innerActive = value;
    if (value) this.onActive.emit(this);
  }

  constructor(private owner: CMTabsComponent) {}

  ngOnInit() {
    this.owner.addTab(this);
  }
}
