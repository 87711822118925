// tslint:disable
import {CMValidator} from './cmvalidator';

export class CMValidatorCNPJCPF extends CMValidator {

  tipo_padrao: string = 'CPF ou CNPJ';
  constructor (tipo_padrao?: string) {
    super(null);
    if (tipo_padrao)
      this.tipo_padrao = tipo_padrao;
  }

  validate(value: string, gridRow) {
    let v = CMValidator.trimValue(value);
    if (v !== '') {
      let r = undefined;
      let tipo = this.verifica_cpf_cnpj(v);
      if (tipo === 'CNPJ') {
        if (!this.valida_cnpj(v))
            r = 'CNPJ inválido';
      }
      else if (tipo === 'CPF') {
        if (!this.valida_cpf(v))
            r = 'CPF inválido';
      }
      else if (v.replace(/[^0-9]/g, '').length > 0)
        r = this.tipo_padrao+' inválido';
      return r;
    }
  };

  verifica_cpf_cnpj(valor) {
    let r: string = '';
    valor = valor.replace(/[^0-9]/g, '');
    if (valor.length === 11)
      r = 'CPF';
    else if (valor.length === 14)
      r = 'CNPJ';
    return r;
  }

  calc_digitos_posicoes(digitos, posicoes = 10, soma_digitos = 0) {
    digitos = digitos.toString();
    for (let i = 0; i < digitos.length; i++) {
      soma_digitos = soma_digitos + (digitos[i] * posicoes);
      posicoes--;
      if (posicoes < 2)
        posicoes = 9;
    }
    soma_digitos = soma_digitos % 11;
    if (soma_digitos < 2)
      soma_digitos = 0;
    else
      soma_digitos = 11 - soma_digitos;
    let r = digitos + soma_digitos;
    return r;
  }

  valida_cnpj(valor) {
    let r: boolean = true;
    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, '');
    if ((valor == '00000000000000') ||
        (valor == '11111111111111') ||
        (valor == '22222222222222') ||
        (valor == '33333333333333') ||
        (valor == '44444444444444') ||
        (valor == '55555555555555') ||
        (valor == '66666666666666') ||
        (valor == '77777777777777') ||
        (valor == '88888888888888') ||
        (valor == '99999999999999'))
      r = false;
    if (r) {
      let cnpj_original = valor;
      let primeiros_numeros_cnpj = valor.substr(0, 12);
      let primeiro_calculo = this.calc_digitos_posicoes(primeiros_numeros_cnpj, 5);
      let segundo_calculo = this.calc_digitos_posicoes(primeiro_calculo, 6);
      let cnpj = segundo_calculo;
      if (cnpj === cnpj_original)
        r = true;
      else
        r = false;
    }
    return r;
  }

  valida_cpf(valor) {
    let r: boolean = true;
    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, '');
     if ((valor == '00000000000') ||
         (valor == '11111111111') ||
         (valor == '22222222222') ||
         (valor == '33333333333') ||
         (valor == '44444444444') ||
         (valor == '55555555555') ||
         (valor == '66666666666') ||
         (valor == '77777777777') ||
         (valor == '88888888888') ||
         (valor == '99999999999'))
      r = false;
    if (r) {
      let digitos = valor.substr(0, 9);
      let novo_cpf = this.calc_digitos_posicoes(digitos);
      novo_cpf = this.calc_digitos_posicoes(novo_cpf, 11);
      if (novo_cpf === valor)
        r = true;
      else
        r = false;
    }
    return r;
  }

}