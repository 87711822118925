// tslint:disable
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent, CMInputConfig } from './../../@cmcore/component';
import { CategoriaContaContabilEditComponent } from './categoria-conta-contabil-edit.component';
import { HttpClient } from '@angular/common/http';
import { FuncsGlobais } from '../funcs.globais';

@Component({
  selector: 'categoria-conta-contabil-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class CategoriaContaContabilBrowse extends CMFormBrowse2Component {

  static defaultColumns = [{field: 'codigo', caption: '#', filter: true},
  {field: 'descricao',   caption: 'Descrição', filter: true}
];

  constructor(_injector: Injector) {
      super(_injector);
      this.controllerName = 'CategoriaContaContabil';
      this.formEditComponent = CategoriaContaContabilEditComponent;
      this.fixedFooterFormEdit = false;
      this.columns = CategoriaContaContabilBrowse.defaultColumns;
    }
  
  getTitle() {
  return 'Categoria de Conta Contábil';
  }

  static pesquisa(
    sender: any,
    target: any,
    tipo: string,
    targetProp?: string,
    onAfterSelect?: Function,
    extraFilters?: Object,
    extraColumns?: Array<any>
  ) {
    if (target) {
      let columns = [
        { field: "codigo", caption: "#", filter: true},
        {
          field: "descricao",
          caption: "Descricao",
          filter: new CMInputConfig({
            maxlength: 60,
          }),
        }
      ];
      if (extraColumns) {
        extraColumns.forEach((column) => {
          columns.push(column);
        });
      }
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "categoriacontacontabil",
        "Pesquisa de " + tipo,
        columns,
        target,
        targetProp,
        "codigo",
        onAfterSelect,
        undefined,
        undefined,
        extraFilters
      );
    }
  }
}
