// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMInputConfig,
  CMFormExecComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMFormGlobaisComponent,
} from "./../../@cmcore/component";
import { PessoaBrowseComponent } from "../pessoa";
import { FormaPagamentoBrowseComponent } from "../formapagamento";
import { ContaBancariaBrowseComponent } from "../conta-bancaria";

@Component({
  templateUrl: "cartao-baixa-em-lote-component.html",
})
export class CartaoBaixaEmLoteComponent extends CMFormExecComponent {
  private sub: any;
  title: string = "";
  titleDestinatario: string = "";
  fieldGridDestinatario: string = "";

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.sub = this.route.params.subscribe((params) => {
      this.title = "Baixar Cartões";
      this.controllerName = "contapagarreceber";
      this.titleDestinatario = "Cliente";
      this.fieldGridDestinatario = "cliente_nome";
      this.setfilterConfig();
    });
  }

  setfilterConfig() {
    this.filterConfig = {
      filtro: {
        tipoduplicata: new CMInputConfig({
          form: this,
          caption: "Tipo de Documento",
          values: [
            { value: "Pagamento", text: "Pagamento"},
            { value: "Recebimento", text: "Recebimento"},
          ],
          onSetValue: () => {
            this.filter.filtro.destinatario = '';
          }
        }),
        dataini: new CMInputConfig({
          form: this,
          required: true,
          caption: "Data Inicial",
          mask: "99/99/9999",
        }),
        datafim: new CMInputConfig({
          form: this,
          required: true,
          caption: "Data Final",
          mask: "99/99/9999",
        }),
        tipopesquisa: new CMInputConfig({
          form: this,
          visible: false,
        }),
        destinatario: new CMInputConfig({
          form: this,
          caption: () => this.titleDestinatario,
          pesqFunc: (sender) => {
            PessoaBrowseComponent.pesquisa(
              sender,
              this.filter.filtro,
              this.titleDestinatario,
              "destinatario"
            );
          },
        }),
        cliente: new CMInputConfig({
          form: this,
          caption: () => 'Cliente',
          pesqFunc: (sender) => {
            PessoaBrowseComponent.pesquisa(
              sender,
              this.filter.filtro,
              "Cliente",
              "destinatario"
            );
          },
        }),
        fornecedor: new CMInputConfig({
          form: this,
          caption: () => 'Fornecedor',
          pesqFunc: (sender) => {
            PessoaBrowseComponent.pesquisa(
              sender,
              this.filter.filtro,
              "Fornecedor",
              "destinatario"
            );
          },
        }),
        filtroformapagto: new CMInputConfig({
          form: this,
          caption: "Forma de pagamento",
          values: FormaPagamentoBrowseComponent.valuesSelectCartao(this.http),
          valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelectCartao(),
        }),
        databaixa: new CMInputConfig({
          form: this,
          required: true,
          caption: "Data do Fluxo de Caixa",
          mask: "99/99/9999",
        }),
        tipocontapagarreceber: new CMInputConfig({
          form: this,
          visible: false,
        }),
      },
      dados: {
        duplicatas: new CMGridEditConfig({
          form: this,
          required: false,
          fields: {
            selecionado: new CMInputConfig({
              form: this,
              dataType: "boolean",
              onChange: () => {
                let row = this.filterConfig.dados.duplicatas.current;
                console.log('row onChange: ', row);
                console.log('this.dados: ', this.filter.dados);
                if (row.selecionado === 'Sim'){
                  row.baixa_valorbaixa = row.baixa_valordevido;
                  this.calcValorTotal();
                }
                else if (row.selecionado === 'Não'){
                  row.baixa_valorbaixa = this.floatToValue(0);
                  this.calcValorTotal();
                }
              },
              gridConfig: new CMGridEditConfigColumn({
                tipo: "checkbox",
                width: "0px",
              }),
            }),
            duplicata: new CMInputConfig({
              form: this,
              caption: "Origem - Fatura - Documento",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: "input-pesquisa",
                getValue: (row) => {
                  return (
                    this.getValueObject(row, "origem") +
                    " - " +
                    this.getValueObject(row, "nfatura") +
                    " - " +
                    this.getValueObject(row, "nduplicata")
                  );
                },
                getBottomLabelColumn: (row) => {
                  if (
                    this.getValueObject(
                      this.filter.filtro.destinatario,
                      "id"
                    ) === ""
                  )
                    return this.getValueObject(row, this.fieldGridDestinatario);
                  else return "";
                },
              }),
            }),
            dvenc: new CMInputConfig({
              form: this,
              caption: "Vencimento",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                tipo: "input-pesquisa",
                width: "120px",
                getValue: (row) => {
                  return this.getValueObject(row, 'dvenc');
                },
              }),
            }),
            baixa_valorbaixa: new CMInputConfig({
              form: this,
              caption: "Valor Baixa",
              dataType: "double(2)",
              disabled: true,
              onExit: () => this.calcValorTotal(),
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
                getValue: (row) => {
                  return this.getValueObject(row, 'baixa_valorbaixa');
                },
                getFooterLabelColumn: () => this.filter.dados.total_valorbaixa,
              }),
            }),
            vsaldo: new CMInputConfig({
              form: this,
              caption: "Saldo Devido",
              dataType: "double(2)",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
                getValue: (row) => {
                  return this.getValueObject(row, 'vsaldo');
                },
              }),
            }),
            baixa_databaixa: new CMInputConfig({
              form: this,
              caption: "Data da Baixa",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
                getValue: (row) => {
                  return this.getValueObject(row, 'baixa_databaixa');
                },
              }),
            }),
            baixa_valordevido: new CMInputConfig({
              form: this,
              disabled: true,
              caption: "Valor Devido",
              dataType: "double(2)",
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
                getValue: (row) => {
                  return this.getValueObject(row, 'baixa_valordevido');
                },
                getFooterLabelColumn: () => this.filter.dados.total_valordevido,
              }),
            }),
            valor: new CMInputConfig({
              form: this,
              caption: "Valor Original",
              dataType: "double(2)",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
                getValue: (row) => {
                  return this.getValueObject(row, 'valor');
                },
              }),
            }),
            baixa_observacao: new CMInputConfig({
              form: this,
              caption: "Observação",
              maxlength: 60,
              gridConfig: new CMGridEditConfigColumn({
                width: "120px",
                getValue: (row) => {
                  return this.getValueObject(row, 'baixa_observacao');
                },
              }),
            }),
          },
        }),
      },
    };
    this.filter.filtro.dataini = this.firstDateLastMonth();
    this.filter.filtro.datafim = this.lastDateNextMonth();
    this.filter.filtro.databaixa = this.date;
    this.filter.filtro.tipopesquisa = "cartao";
    this.filter.filtro.tipocontapagarreceber = "cartao";
    this.filter.dados.duplicatas = [];
    this.filter.dados.total_valordevido = "0,00";
    this.filter.dados.total_valorbaixa = "0,00";
    this.filter.filtro.tipoduplicata = "Recebimento";
  }

  innerExec() {
    super.innerExec();

    let soma = 0;

    this.filter.dados.duplicatas.forEach(element => {
      soma += this.valueToFloat(element.baixa_valorbaixa);
    });

    this.geraBaixas();

  }

  loadDuplicatas() {

    if (this.valid()) {
      console.log("this.filter.filtro: ", this.filter.filtro);
      CMFormGlobaisComponent.showSpinner();
      this.filter.filtro.status = "abertas";
      let filtro = {
        origem: "pesquisa",
        page: -1,
        limit: -1,
        filter: this.filter.filtro,
      };
      this.postSubscribe(
        "/api/" + this.controllerName + "/GetBrowseObjects",
        JSON.stringify(filtro),
        (ret) => {
          let duplicatas = [];
          if (ret["data"]) duplicatas = ret["data"];
          let total_valorbaixa = 0;
          let total_valordevido = 0;
          duplicatas.forEach((duplicata) => {
            let vsaldo = this.valueToFloat(duplicata.vsaldo);
            let vbaixa = 0;
            total_valordevido += vsaldo;
            total_valorbaixa += vbaixa;
            duplicata.baixa_valordevido = duplicata.vsaldo;
            duplicata.baixa_valorbaixa = this.floatToValue(vbaixa);
            duplicata.baixa_observacao = "";
            duplicata.selecionado = "Não";
            duplicata.baixa_databaixa = this.filter.filtro.databaixa;
          });
          this.filter.dados.duplicatas = duplicatas;
          this.filter.dados.total_valorbaixa =
            this.floatToValue(total_valorbaixa);
          this.filter.dados.total_valordevido =
            this.floatToValue(total_valordevido);
          CMFormGlobaisComponent.hideSpinner();
          this.filterConfig.dados.duplicatas.fields.baixa_valorbaixa.gridConfig.background = "lightyellow";
          this.filterConfig.dados.duplicatas.fields.baixa_databaixa.gridConfig.background = "lightyellow";
          //console.log(this.filterConfig.dados);
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("loadDuplicatas", error);
        }
      );
    }
  }

  calcValorDevido() {
    let row = this.filterConfig.dados.duplicatas.current;
    if (row) {
      let vsaldo = this.valueToFloat(row.vsaldo);
      let baixa_valordevido =
        vsaldo;
      

      console.log("this.valueToFloat(row.baixa_valorbaixa): ", this.valueToFloat(row.baixa_valorbaixa));
      console.log("this.valueToFloat(row.baixa_valorbaixa): ", this.valueToFloat(0));

      console.log("row.baixa_valorbaixa: ", this.valueToFloat(row.baixa_valorbaixa) > this.valueToFloat(0));
      if ((row.baixa_valorbaixa === row.baixa_valordevido) && (this.valueToFloat(row.baixa_valorbaixa) > this.valueToFloat(0))){
        row.baixa_valorbaixa = this.floatToValue(baixa_valordevido);
      }

      row.baixa_valordevido = this.floatToValue(baixa_valordevido);


      this.calcValorTotal();
    }
  }

  calcValorTotal() {
    let total_valordevido = 0;
    let total_valorbaixa = 0;
    this.filter.dados.duplicatas.forEach((duplicata) => {
      total_valordevido += this.valueToFloat(duplicata.baixa_valordevido);
      total_valorbaixa += this.valueToFloat(duplicata.baixa_valorbaixa);
    });
    this.filter.dados.total_valordevido = this.floatToValue(total_valordevido);
    this.filter.dados.total_valorbaixa = this.floatToValue(total_valorbaixa);
  }

  geraBaixas() {
    CMFormGlobaisComponent.showSpinner();
    let duplicatas = [];
      
    this.filter.dados.duplicatas.forEach((duplicata) => {
      if (
        this.valueToFloat(duplicata.baixa_valorbaixa) >
        this.valueToFloat(duplicata.baixa_valordevido)
      )
        duplicata.baixa_valorbaixa = duplicata.baixa_valordevido;
      if (this.valueToFloat(duplicata.baixa_valorbaixa) > 0) {

        duplicatas.push({
          id: duplicata.id_duplicata,
          vbaixa: duplicata.baixa_valorbaixa,
          observacao: duplicata.baixa_observacao,
          databaixa: duplicata.baixa_databaixa,
          tipobaixa: "cartao",
        });
      }
    });

    if (duplicatas.length == 0) {
      CMFormGlobaisComponent.hideSpinner();
      CMFormGlobaisComponent.toastErro(
        "Ao menos uma duplicata deve possuir valor de baixa"
      );
    } else {
      console.log("duplicatas: ", )
      this.postSubscribe(
        "/api/" + this.controllerName + "/BaixaEmLote/FuncaoExtra",
        JSON.stringify(duplicatas),
        (result) => {
          if (result["result"] === "ok") {
            this.filter.dados.duplicatas = [];
            this.filter.dados.total_valordevido = "0,00";
            this.filter.dados.total_valorbaixa = "0,00";
            CMFormGlobaisComponent.toastSucesso("Baixas geradas com sucesso.");
            this.filterConfig.dados.duplicatas.fields.baixa_valorbaixa.gridConfig.background = "unset";
          } else {
            CMFormGlobaisComponent.toastErro(result["msg"]);
          }
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("geraBaixas", error);
        }
      );
    }
  }
  
}
