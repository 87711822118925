// tslint:disable
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMInputConfig } from './../../@cmcore/component';

@Component({
  selector: 'curriculum-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class CurriculumBrowseComponent extends CMFormBrowse2Component {


  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'curriculum';
    this.columns = [{field: 'cpf', caption: 'CPF',
                    filter: new CMInputConfig({
                      mask: '999.999.999-99'
                      })
                    },
                    {field: 'nome', caption: 'Nome',
                    filter: true
                    },
                    {field: 'tipocnh', caption: 'Habilitação', visible: false,
                    filter: new CMInputConfig({
                      values: [{value: 'Todos'}, {value: 'A'}, {value: 'AB'}]
                      })
                    },
                    {field: 'necessidadesespeciais', caption: 'Portador Necessidades Especiais', visible: false,
                    filter: new CMInputConfig({
                      values: [{value: 'Todos'}, {value: 'Visual'}, {value: 'Auditivo'}, {value: 'Locomoção'}]
                      })
                    },
                    {field: 'veiculoproprio', caption: 'Possui Veículo Prórpio', visible: false,
                    filter: new CMInputConfig({
                      values: [{value: 'Todos'}, {value: 'Carro'}, {value: 'Moto'}, {value: 'Carro e Moto'}]
                      })
                    },
                    {field: 'primeiroemprego', caption: 'É o primeiro Emprego', visible: false,
                    filter: new CMInputConfig({
                      values: [{value: 'Todos'}, {value: 'Sim'}, {value: 'Não'}]
                      })
                    },
                    {field: 'escolaridade', caption: 'Escolaridade', visible: false,
                    filter: new CMInputConfig({
                      values: [{value: 'Todos'}, {value: 'Ensino Médio'}, {value: 'Graduação'}, {value: 'Pós-Graduação'}]
                      })
                    },
                    {field: 'disponivelparaviagens', caption: 'Disponibilidade para viagens', visible: false,
                    filter: new CMInputConfig({
                      values: [{value: 'Todos'}, {value: 'Sim'}, {value: 'Não'}]
                      })
                    },
                    {field: 'disponivelresidiremoutracidade', caption: 'Disponibilidade para residir em outra cidade', visible: false,
                    filter: new CMInputConfig({
                      values: [{value: 'Todos'}, {value: 'Sim'}, {value: 'Não'}]
                      })
                    },
                  ];
    this.showPrintButton = true;
  }

  getTitle() {
    return 'Curriculum';
  }

}