// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMInputConfig,
         CMGridEditConfig, CMGridEditConfigColumn,
         CMFormGlobaisComponent,
         CMFormModalComponent} from './../../../@cmcore/component';

import { CMFormComponent } from './../../../@cmcore/component/cmform/cmform.component';
import { CotacaoBrowseComponent } from '../cotacao-browse.component';

@Component({
  templateUrl: 'cotacao-analise-component.html',
  styleUrls: ['cotacao-analise-component.scss']
})
export class CotacaoAnaliseComponent extends CMFormComponent {

  _owner: CotacaoBrowseComponent = undefined;
  get owner() {
    return this._owner;
  }
  set owner(value) {
    this._owner = value;
    if (this.owner) {
      this.loadDados();
    }
  }

  close() {
    if (this.owner) {
      this.owner.beforeComponent = undefined;
      this.owner.refresh();
    }
    CMFormModalComponent.close('formAnalisarCotacao');
  }

  dados: any = {};
  dadosConfig: any = {};

  constructor(_injector: Injector) {
    super(_injector);
    this.setDadosConfig();
  }

  getTitle() {
    return "Cotação # "+this.dados.numero;
  }

  loadDados() {
    CMFormGlobaisComponent.showSpinner();

    this.postSubscribe('/api/Cotacao/GetAnaliseCotacao',
                  JSON.stringify({
                    idcotacao: this.owner.idFormAnalisarCotacao
                  }),
            (response) => {
              this.dados = response;
              this.sortRespostas();
              if (this.dados.status == "Encerrada") {
                setTimeout(() => {
                  this.demonstrar();
                }, 1000);
              }
              CMFormGlobaisComponent.hideSpinner();
            },
            error => {
              CMFormGlobaisComponent.hideSpinner();
              this.showMessageErrorHTTP('loadDados', error);
            });
  }

  cotacaoCarregada() {
    return (this.dados.status == "EmAndamento") || (this.dados.status == "Encerrada")
  }

  setDadosConfig() {
    this.dadosConfig = {
      condicoesproposta: new CMInputConfig({
        form: this,
        caption: 'Condições da Proposta',
        disabled: true,
      }),
      produtos: new CMGridEditConfig({
        form: this,
        fields: {
          produto: new CMInputConfig({
            form: this,
            caption: 'Produto',
            disabled: true,
            gridConfig: new CMGridEditConfigColumn({
              tipo: 'input-pesquisa',
              getValue: (row) => {
                return this.getValueObject(row, 'descricao_produto');
              },
              getBottomLabelColumn: (row) => {
                return this.getValueObject(row, 'codigo_produto');
              }
            })
          }),
          quantidade: new CMInputConfig({
            form: this,
            caption: 'Quantidade',
            dataType: 'double(2)',
            disabled: true,
            gridConfig: new CMGridEditConfigColumn({
              width: "150px"
            })
          }),
          respostas: new CMGridEditConfig({
            form: this,
            onSelect: (row) => {
              setTimeout(() => {
                this.onSelectResposta(row);
              }, 100);
            },
            fields: {
              fornecedor: new CMInputConfig({
                form: this,
                caption: 'Fornecedor',
                disabled: true,
                gridConfig: new CMGridEditConfigColumn()
              }),
              valorunitario: new CMInputConfig({
                form: this,
                caption: 'Valor Unitário',
                dataType: 'double(2)',
                disabled: true,
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px"
                })
              }),
              valortotal: new CMInputConfig({
                form: this,
                caption: 'Valor Total',
                dataType: 'double(2)',
                disabled: true,
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px"
                })
              }),
              vencedora: new CMInputConfig({
                form: this,
                caption: 'Vencedora',
                dataType: 'boolean',
                disabledFunc: () => this.dados.status !== "EmAndamento",
                onChange: () => {
                  this.unSelectAllRespostas();
                },
                gridConfig: new CMGridEditConfigColumn({
                  tipo: 'checkbox',
                  width: "70px"
                })
              }),
            }
          })
        }
      })
    }
  }

  condicoesProposta = '';
  lastIdFornec = '';
  findCondicoesProposta(idfornecedor) {
    let r = '';
    for (let i=0; i < this.dados.fornecedores.length; i++) {
      if (this.dados.fornecedores[i].idfornecedor == idfornecedor) {
        r = this.dados.fornecedores[i].condicoesproposta;
        break;
      }
    }
    return r;
  }

  onSelectResposta(row) {
    if ((row) && (row.idfornecedor !== this.lastIdFornec)) {
      this.lastIdFornec = row.idfornecedor;
      this.condicoesProposta = this.findCondicoesProposta(row.idfornecedor);
    }
  }

  demostrativos = [];

  demonstrar() {
    let demostrativos = [];
    let ordens_compra = {};
    this.dados.produtos.forEach(produto => {
      produto.respostas.forEach(resposta => {
        if (resposta.vencedora === 'Sim') {
          let ordem_compra = ordens_compra[resposta.idfornecedor];
          if (ordem_compra == undefined) {
            ordem_compra = {
              idfornecedor: resposta.idfornecedor,
              fornecedor: resposta.fornecedor,
              condicoesproposta: this.findCondicoesProposta(resposta.idfornecedor),
              produtos: [],
              totalgeral: '0'
            };
            ordens_compra[resposta.idfornecedor] = ordem_compra;
            demostrativos.push(ordem_compra);
          }
          ordem_compra.totalgeral = this.floatToValue(
            this.valueToFloat(ordem_compra.totalgeral) + this.valueToFloat(resposta.valortotal)
          );
          let p = {
            iditemestoque: produto.id,
            codigo_produto: produto.codigo_produto,
            descricao_produto: produto.descricao_produto,
            quantidade: produto.quantidade,
            valorunitario: resposta.valorunitario,
            valortotal: resposta.valortotal,
          };
          ordem_compra.produtos.push(p);
        }
      });
    });
    this.demostrativos = demostrativos;
  }

  unSelectAllRespostas() {
    if (this.demostrativos.length > 0) {
      setTimeout(() => {
        this.demostrativos = [];
      }, 10);
    }
    let respostas = this.dadosConfig.produtos.current.respostas;
    let current = this.dadosConfig.produtos.fields.respostas.current;
    if ((respostas) && (current)) {
      if (current.vencedora == 'Sim') {
        let id = current.id;
        respostas.forEach(resposta => {
          if (resposta.id !== id)
            resposta.vencedora = 'Não';
        });
      }
    }
  }

  sortRespostas() {
    if (this.dados.produtos) {
      this.dados.produtos.forEach(produto => {
        if (produto.respostas) {
          produto.respostas.sort(function (a, b) {
            let a_valorunitario = CMInputConfig.valueToFloat(a.valorunitario);
            let b_valorunitario = CMInputConfig.valueToFloat(b.valorunitario);
            if (a_valorunitario > b_valorunitario) {
              return 1;
            }
            if (a_valorunitario < b_valorunitario) {
              return -1;
            }
            return 0;
          });
        }
      });
    }
  }

  showGerarOrdemCompra() {
    return (this.demostrativos.length > 0) && (this.dados.status == "EmAndamento");
  }

  gerarOrdemCompra() {
    if (this.dados.status == "EmAndamento") {
      CMFormGlobaisComponent.showSpinner();

      this.postSubscribe('/api/Cotacao/AnaliseCotacao/FuncaoExtra',
                    JSON.stringify({
                      idcotacao: this.owner.idFormAnalisarCotacao,
                      aprovados: this.demostrativos
                    }),
              (r) => {
                if (r['result'] == 'ok') {
                  CMFormGlobaisComponent.toastSucesso('Ordens de Compra gerada com sucesso');
                  this.close();
                }
                else
                  CMFormGlobaisComponent.toastInformacao(r['msg']);
                CMFormGlobaisComponent.hideSpinner();
              },
              error => {
                CMFormGlobaisComponent.hideSpinner();
                this.showMessageErrorHTTP('loadDados', error);
              });
    }
  }

}