// tslint:disable
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMInputConfig,
         CMFormModalComponent } from './../../@cmcore/component';
import { NFeInutilizacaoEditComponent,
         NFeInutilizacaoAcoesComponent
       } from './../index';

@Component({
  selector: 'nfeinutilizacao-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class NFeInutilizacaoBrowseComponent extends CMFormBrowse2Component {

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'NFeInutilizacao';
    this.buttonsExtra = 1;
    this.fixedFooterFormEdit = false;
    this.showPrintButton = true;
    this.formEditComponent = NFeInutilizacaoEditComponent;
    this.columns = [{field: 'ano', caption: 'Ano',
                     filter: new CMInputConfig({
                       dataType: 'integer',
                       maxlength: 4,
                     })
                    },
                    {field: 'serie', caption: 'Série',
                     filter: new CMInputConfig({
                       dataType: 'integer',
                     })
                    },
                    {field: 'nnfini',  caption: 'Nota Inicial',
                     filter: new CMInputConfig({
                       dataType: 'integer',
                     })
                    },
                    {field: 'nnffin',  caption: 'Nota Final',
                     filter: new CMInputConfig({
                       dataType: 'integer',
                    })
                  }
                ];
  }

  buttonsExtraClick(index: number) {
    if (index === 0) {
      this.openFormAcoesNFeInutilizacao(this.grid.fieldByName('id'));
    }
  }

  idFormAcoesNFeInutilizacao: number = 0;
  openFormAcoesNFeInutilizacao(id) {
    this.idFormAcoesNFeInutilizacao = id;
    this.beforeComponent = NFeInutilizacaoAcoesComponent;
    setTimeout(() => {
      CMFormModalComponent.open('formAcoesNFeInutilizacao');
    }, 100);
  }

  buttonsExtraVisible(index: number) {
    let r: boolean = false;
    if (index === 0)
      r = this.selectedItem();
    return r;
  }

  buttonsExtraIcon(index: number) {
    let r: string = '';
    if (index === 0)
      r = 'build';
    return r;
  }

  buttonsExtraHint(index: number) {
    let r: string = '';
    if (index === 0)
      r = 'Gerenciamento';
    return r;
  }

  getTitle() {
    return 'NF-e Inutilização';
  }

}