// tslint:disable 
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent, CMInputConfig, CMFormModalComponent, CMFormGlobaisComponent } from './../../@cmcore/component';
import { CotacaoEditComponent } from './cotacao-edit.component';
import { CotacaoAnaliseComponent } from './analise';
import { CotacaoEmailComponent } from './email';

@Component({
  selector: 'cotacao-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class CotacaoBrowseComponent extends CMFormBrowse2Component {

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'Cotacao';
    this.formEditComponent = CotacaoEditComponent;
    this.fixedFooterFormEdit = false;
    this.buttonsExtra = 1;
    this.showEmailButton = true;
    this.columns = [{field: 'numero', caption: '#', width: 100,
                     filter: new CMInputConfig({
                       dataType: 'integer'
                     })
                    },
                    {field: 'data', caption: 'Data', width: 130,
                     filter: new CMInputConfig({
                       mask: '99/99/9999'
                     })
                    },
                    {field: 'datamaximaresposta', caption: 'Data Maxima Resposta',
                     filter: new CMInputConfig({
                       mask: '99/99/9999'
                     })
                    },
                    {field: 'status', caption: 'Status',
                     filter: new CMInputConfig({
                       values: [{'value': "Aberta"},
                                {'value': "EmAndamento", 'text': 'Em Andamento'},
                                {'value': "Encerrada"},
                                {'value': "Cancelada"},
                       ]
                     })
                    }
                  ];
  }

  buttonsExtraClick(index: number) {
    if (index === 0) {
      if ((this.grid.fieldByName('status') === 'EmAndamento') || (this.grid.fieldByName('status') === 'Encerrada'))
        this.openFormAnalisarCotacao(this.grid.fieldByName('id'));
      else
        CMFormGlobaisComponent.showMessageAviso('Somente cotações em andamento podem ser analisadas');
    }
  }

  idFormAnalisarCotacao: number = 0;
  openFormAnalisarCotacao(id) {
    this.idFormAnalisarCotacao = id;
    this.beforeComponent = CotacaoAnaliseComponent;
    setTimeout(() => {
      CMFormModalComponent.open('formAnalisarCotacao');
    }, 100);
  }

  loadDireitoButtonExtra(index: number) {
    if (index === 0) {
      this.getDireitosMenuBrowseButtonsExtra(index, 'AnaliseCotacao');
    }
  }

  buttonsExtraVisible(index: number) {
    let r: boolean = super.buttonsExtraVisible(index);
    if (r) {
      let isCurrent: boolean = false;
      if (this.grid) {
        isCurrent = this.grid.isCurrent;
      }
      if (index === 0)
        r = isCurrent;
    }
    return r;
  }

  buttonsExtraIcon(index: number) {
    let r: string = '';
    if (index === 0)
      r = 'gavel';
    return r;
  }

  buttonsExtraHint(index: number) {
    let r: string = '';
    if (index === 0)
      r = 'Analisar';
    return r;
  }

  getTitle() {
    return 'Cotação';
  }

  emailOpenForm(data) {
    let id = this.grid.fieldByName('id');
    data.id = id;
    data.tipo = 'SendMail';
    CotacaoEmailComponent.open(
      '/api/'+this.controllerName+'/Email',
      data.caption,
      data
    );
  }

  static pesquisa(sender: any, target: any, targetProp?: string, onAfterSelect?: Function) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'NecessidadeCompra',
        'Pesquisa de Necessidade de Compra',
        [{field: 'numero', caption: '#'},
         {field: 'data', caption: 'Data', filter: true, mask: '99/99/9999'}],
        target,
        targetProp,
        'numero',
        onAfterSelect);
    }
  }

}