// tslint:disable
import { Component, OnInit, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMValidatorEmail,
  CMFormModalComponent,
} from "./../../@cmcore/component";

import { CMValidatorCNPJCPF } from "./../../@cmcore/validator";
import {
  PadraoDeOperacaoBrowseComponent,
  MunicipioBrowseComponent,
  CFOPBrowseComponent,
  PessoaBrowseComponent,
} from "./../index";
import { UsuarioBrowseComponent } from "./../../@cmcore/guard";
import { EmpresaAssociadoBrowseComponent } from "./empresa-associado-browse.component";
import { FuncsGlobais } from "../funcs.globais";

@Component({
  selector: "empresa-associado-edit",
  templateUrl: "empresa-associado-edit.component.html",
})
export class EmpresaAssociadoEditComponent
  extends CMFormEditComponent
  implements OnInit {
  getFirstComponentName() {
    return "model_cnpj";
  }

  getTitle() {
    return "Empresa - " + this.fieldByName("nome");
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "empresaassociado";
    this.setModelConfig();

  }

  afterGetModelData() {
    super.afterGetModelData();
    this.imageSrc = this.getImageSrc();
  }

  getImageSrc(){
    return "/api/empresaassociado/GetDBFileGroupImage/Logos/EmpresaLogo_"+this.model.id+"/_self"
  }

  public imageSrc: String = "";

  AcabouHabilitarCopiaProdutos: boolean = false;

  setModelConfig() {
    this.modelConfig = {
      codigo: new CMInputConfig({ form: this, caption: "#", disabled: true }),
      nomefantasia: new CMInputConfig({
        form: this,
        caption: "Nome Fantasia",
        maxlength: 60,
        onExit: () => {
          console.log(this.model)
        }
      }),
      cnpj: new CMInputConfig({
        form: this,
        caption: "CNPJ",
        required: true,
        mask: "99.999.999/9999-99",
        customvalidators: [new CMValidatorCNPJCPF()],
      }),
      nome: new CMInputConfig({
        form: this,
        caption: "Razão Social",
        required: true,
        maxlength: 60,
      }),
      ie: new CMInputConfig({
        form: this,
        caption: "IE",
        maxlength: 100,
        requiredFunc: () => this.usaSAT(),
      }),
      im: new CMInputConfig({
        form: this,
        caption: "IM",
        maxlength: 20,
        requiredFunc: () => this.usaSAT(),
      }),
      cadastro: new CMInputConfig({
        form: this,
        caption: "Data de Cadastro",
        mask: "99/99/9999",
        required: true,
        readonly: true,
      }),
      email: new CMInputConfig({
        form: this,
        caption: "e-Mail",
        maxlength: 100,
        customvalidators: [new CMValidatorEmail()],
      }),
      emailnfe: new CMInputConfig({
        form: this,
        caption: "e-Mail NF-e",
        maxlength: 100,
        customvalidators: [new CMValidatorEmail()],
      }),
      contador: new CMInputConfig({
        form: this,
        caption: "Contador",
        pesqFunc: (sender) => {
          PessoaBrowseComponent.pesquisaContador(sender, this.model, "contador");
        },
      }),
      endereco: {
        logradouro: new CMInputConfig({
          form: this,
          caption: "Logradouro",
          required: true,
          maxlength: 60,
        }),
        numero: new CMInputConfig({
          form: this,
          caption: "Número",
          required: true,
          maxlength: 10,
        }),
        bairro: new CMInputConfig({
          form: this,
          caption: "Bairro",
          required: true,
          maxlength: 60,
        }),
        complemento: new CMInputConfig({
          form: this,
          caption: "Complemento",
          maxlength: 60,
        }),
        estado: new CMInputConfig({
          form: this,
          caption: "Estado",
          required: true,
          values: [
            { value: "AC" },
            { value: "AL" },
            { value: "AP" },
            { value: "AM" },
            { value: "BA" },
            { value: "CE" },
            { value: "DF" },
            { value: "ES" },
            { value: "GO" },
            { value: "MA" },
            { value: "MT" },
            { value: "MS" },
            { value: "MG" },
            { value: "PR" },
            { value: "PB" },
            { value: "PA" },
            { value: "PE" },
            { value: "PI" },
            { value: "RJ" },
            { value: "RN" },
            { value: "RS" },
            { value: "RO" },
            { value: "RR" },
            { value: "SC" },
            { value: "SE" },
            { value: "SP" },
            { value: "TO" },
          ],
        }),
        ibge: new CMInputConfig({
          form: this,
          caption: "Código do Município no IBGE",
          required: true,
          pesqFunc: (sender) => {
            MunicipioBrowseComponent.pesquisa(
              this.model.endereco.estado,
              sender,
              this.model.endereco,
              "ibge"
            );
          },
        }),
        cep: new CMInputConfig({
          form: this,
          caption: "CEP",
          required: true,
          mask: "99999-999",
        }),
      },
      telefones: new CMGridEditConfig({
        form: this,
        caption: "Telefones",
        onNewItem: (model) => {
          Object.assign(model, { tipo: "Residencial" });
        },
        fields: {
          numero: new CMInputConfig({
            form: this,
            caption: "Telefone",
            required: true,
            gridConfig: new CMGridEditConfigColumn(),
            maskFunc: (row) => {
              let m = "(99)9999-9999";
              if (row.tipo === "Celular") m = "(99)99999-9999";
              return m;
            },
          }),
          tipo: new CMInputConfig({
            form: this,
            caption: "Tipo",
            gridConfig: new CMGridEditConfigColumn({
              ordem: 1,
            }),
            values: [
              { value: "Residencial" },
              { value: "Comercial" },
              { value: "Celular" },
              { value: "Fax" },
            ],
          }),
          contato: new CMInputConfig({
            form: this,
            caption: "Contato",
            gridConfig: new CMGridEditConfigColumn(),
            maxlength: 60,
          }),
          observacao: new CMInputConfig({
            form: this,
            caption: "Observação",
            gridConfig: new CMGridEditConfigColumn(),
            maxlength: 100,
          }),
        },
      }),
      parametroemail: {
        email: new CMInputConfig({
          form: this,
          caption: "e-Mail",
          maxlength: 100,
          required: true,
          customvalidators: [new CMValidatorEmail()],
        }),
        smtp: new CMInputConfig({
          form: this,
          caption: "Servidor (SMTP)",
          maxlength: 50,
          required: true,
        }),
        portsmtp: new CMInputConfig({
          form: this,
          caption: "Porta",
          dataType: "integer",
          required: true,
        }),
        protocol: new CMInputConfig({
          form: this,
          caption: "Protocolo",
          required: true,
          values: [
            { value: "Nenhum" },
            { value: "Padrao", text: "Padrão" },
            { value: "SSL" },
            { value: "TLS" },
            { value: "TLSv1" },
            { value: "TLSv1R", text: "TSLv1 requerido" },
            { value: "STARTTLS" },
          ],
        }),
        usernamesmtp: new CMInputConfig({
          form: this,
          caption: "Usuário",
          maxlength: 100,
          required: true,
        }),
        passwordsmtp: new CMInputConfig({
          form: this,
          caption: "Senha",
          maxlength: 50,
          required: true,
          criptografado: true,
        }),
        mensagememailpadrao: new CMInputConfig({
          form: this,
          caption: "Mensagem padrão",
          required: true,
        }),
      },
      parametronfe: {
        regimetrib: new CMInputConfig({
          form: this,
          caption: "Regime tributário",
          values: [
            { value: "SimplesNacional", text: "Simples Nacional" },
            {
              value: "SimplesNacionalSubLimite",
              text: "Simples Nacional Sublimite",
            },
          ],
        }),
        tpamb: new CMInputConfig({
          form: this,
          caption: "Tipo de Ambiente",
          values: [
            { value: "Producao", text: "Produção" },
            { value: "Homologacao", text: "Homologação" },
          ],
        }),
        contingencia: new CMInputConfig({
          form: this,
          caption: "Ativar Contingência",
        }),
        serie: new CMInputConfig({
          form: this,
          caption: "Série",
          dataType: "integer",
          required: true,
        }),
        cfoppadrao: new CMInputConfig({
          form: this,
          caption: "CFOP Padrão",
          mask: "9.999",
          required: true,
          pesqFunc: (sender) => {
            CFOPBrowseComponent.pesquisa(
              sender,
              this.model.parametronfe,
              "cfoppadrao"
            );
          },
        }),
        cfoppadraodevolucao: new CMInputConfig({
          form: this,
          caption: "CFOP Padrão de Devolução",
          mask: "9.999",
          pesqFunc: (sender) => {
            CFOPBrowseComponent.pesquisa(
              sender,
              this.model.parametronfe,
              "cfoppadraodevolucao"
            );
          },
        }),
        nrultimanf: new CMInputConfig({
          form: this,
          caption: "Produção",
          dataType: "integer",
          disabledFunc: () => {
            return this.model.temnota === "true";
          },
        }),
        nrultimanfhomologacao: new CMInputConfig({
          form: this,
          caption: "Homologação",
          dataType: "integer",
          disabledFunc: () => {
            return this.model.temnotahomologacao === "true";
          },
        }),
        padraodeoperacao: new CMInputConfig({
          form: this,
          caption: "Padrão de Operação",
          pesqFunc: (sender) => {
            PadraoDeOperacaoBrowseComponent.pesquisa(
              sender,
              this.model.parametronfe
            );
          },
        }),
        pcredsn: new CMInputConfig({
          form: this,
          caption:
            "Alíquota aplicável de cálculo do crédito (Simples Nacional)",
          dataType: "double(2)",
        }),
        obssimples: new CMInputConfig({
          form: this,
          caption: "Observação do Simples",
          required: true,
        }),
        mensagememailnfe: new CMInputConfig({
          form: this,
          caption: "Mensagem padrão da NF-e",
          required: true,
        }),
        msgemailcancelamento: new CMInputConfig({
          form: this,
          caption: "Mensagem padrão do Cancelamento",
          required: true,
        }),
        msgemailcartacor: new CMInputConfig({
          form: this,
          caption: "Mensagem padrão da Carta de Correção",
          required: true,
        }),
        senhacertificadodigital: new CMInputConfig({
          form: this,
          caption: "Senha",
          required: true,
          criptografado: true,
        }),
        diasvalcertdigital: new CMInputConfig({
          form: this,
          caption: "Qtde. de dias para aviso do vencimento",
          required: true,
          dataType: "integer",
        }),
        datacertificadodigital: new CMInputConfig({
          form: this,
          caption: "Validade",
          disabled: true,
        }),
        infocertificadodigital: new CMInputConfig({ form: this }),
      },
      parametronfce: {
        usanfce: new CMInputConfig({
          form: this,
          caption: "Usa NFCe",
        }),
        regimetrib: new CMInputConfig({
          form: this,
          caption: "Regime tributário",
          values: [
            { value: "SimplesNacional", text: "Simples Nacional" },
            {
              value: "SimplesNacionalSubLimite",
              text: "Simples Nacional Sublimite",
            },
            // {
            //   value: "Normal",
            //   text: "Normal",
            // },
          ],
        }),
        tpamb: new CMInputConfig({
          form: this,
          caption: "Tipo de Ambiente",
          values: [
            { value: "Producao", text: "Produção" },
            { value: "Homologacao", text: "Homologação" },
          ],
        }),
        serie: new CMInputConfig({
          form: this,
          caption: "Série",
          dataType: "integer",
          requiredFunc: () => this.usaNFCe(),
        }),
        idcsc: new CMInputConfig({
          form: this,
          caption: "ID CSC",
          requiredFunc: () => this.usaNFCe(),
          maxlength: 6,
        }),
        idcschomologacao: new CMInputConfig({
          form: this,
          caption: "ID CSC Amb. Homologação",
          maxlength: 6,
        }),
        csc: new CMInputConfig({
          form: this,
          caption: "CSC",
          requiredFunc: () => this.usaNFCe(),
          maxlength: 40,
        }),
        cschomologacao: new CMInputConfig({
          form: this,
          caption: "CSC Amb. Homologação",
          maxlength: 40,
        }),
        cfoppadrao: new CMInputConfig({
          form: this,
          caption: "CFOP Padrão",
          mask: "9.999",
          requiredFunc: () => this.usaNFCe(),
          pesqFunc: (sender) => {
            CFOPBrowseComponent.pesquisa(
              sender,
              this.model.parametronfce,
              "cfoppadrao"
            );
          },
        }),
        nrultimanf: new CMInputConfig({
          form: this,
          caption: "Produção",
          dataType: "integer",
          disabledFunc: () => {
            return this.model.temnota === "true";
          },
        }),
        nrultimanfhomologacao: new CMInputConfig({
          form: this,
          caption: "Homologação",
          dataType: "integer",
          disabledFunc: () => {
            return this.model.temnotahomologacao === "true";
          },
        }),
        padraodeoperacao: new CMInputConfig({
          form: this,
          caption:
            "Configuração Fiscal de Venda padrão para ser utilizada na emissão da NFCe",
          disabledFunc: () => !this.isEmpresaDefinida(),
          pesqFunc: (sender) => {
            PadraoDeOperacaoBrowseComponent.pesquisa(
              sender,
              this.model.parametronfce
            );
          },
        }),
        obssimples: new CMInputConfig({
          form: this,
          caption: "Observação do Simples",
          requiredFunc: () => this.usaNFCe(),
        }),
      },
      parametrocnab: {
        codigobanco: new CMInputConfig({
          form: this,
          caption: "Código do Banco",
          maxlength: 3,
        }),
        digitobanco: new CMInputConfig({
          form: this,
          caption: "Dígito",
          maxlength: 1,
        }),
        agencia: new CMInputConfig({
          form: this,
          caption: "Agência",
          maxlength: 4,
        }),
        digitoagencia: new CMInputConfig({
          form: this,
          caption: "Dg",
          maxlength: 1,
        }),
        contacorrente: new CMInputConfig({
          form: this,
          caption: "Conta Corrente",
          maxlength: 10,
        }),
        digitocc: new CMInputConfig({
          form: this,
          caption: "Dg",
          maxlength: 1,
        }),
        carteira: new CMInputConfig({
          form: this,
          caption: "Carteira",
          maxlength: 1,
        }),
        convenio: new CMInputConfig({
          form: this,
          caption: "Convenio",
          maxlength: 6,
        }),
        modalidade: new CMInputConfig({
          form: this,
          caption: "Modalidade",
          maxlength: 2,
        }),
        codigocliente: new CMInputConfig({
          form: this,
          caption: "Código do Cliente no Banco",
          maxlength: 9,
        }),
        digitocodigocliente: new CMInputConfig({
          form: this,
          caption: "Dg",
          maxlength: 1,
        }),
        instrucao01: new CMInputConfig({
          form: this,
          caption: "Instrução de Protesto",
          maxlength: 2,
        }),
        instrucao02: new CMInputConfig({
          form: this,
          caption: "Dias para Protesto",
          maxlength: 2,
        }),
        seqnn: new CMInputConfig({
          form: this,
          caption: "Sequência Nosso Número",
          dataType: "integer",
        }),
        taxajuros: new CMInputConfig({
          form: this,
          caption: "Taxa de Juros Mensal",
          dataType: "double(2)",
        }),
        taxamulta: new CMInputConfig({
          form: this,
          caption: "Taxa de Multa",
          dataType: "double(2)",
        }),
        msg1: new CMInputConfig({
          form: this,
          caption: "Mensagem 1",
          maxlength: 40,
        }),
        msg2: new CMInputConfig({
          form: this,
          caption: "Mensagem 2",
          maxlength: 40,
        }),
        posto: new CMInputConfig({
          form: this,
          caption: "Posto",
          dataType: "integer",
          maxlength: 2
        }),
        numeroarquivo: new CMInputConfig({
          form: this,
          caption: "Número do Arquivo",
          dataType: "integer",
        }),
      },
      parametroestoque: {
        usagrade1: new CMInputConfig({
          form: this,
          caption: "Controla Grade 1",
          disabledFunc: () =>
            this.model.parametroestoque.copiaprodutograde === "Sim",
        }),
        usagrade2: new CMInputConfig({
          form: this,
          caption: "Controla Grade 2",
          disabledFunc: () =>
            this.model.parametroestoque.copiaprodutograde === "Sim",
        }),
        nomegrade1: new CMInputConfig({
          form: this,
          caption: "Nome Grade 1",
          requiredFunc: () => this.model.parametroestoque.usagrade1 === "Sim",
          disabledFunc: () =>
            this.model.parametroestoque.copiaprodutograde === "Sim" ||
            this.model.parametroestoque.usagrade1 !== "Sim",
          maxlength: 10,
        }),
        nomegrade2: new CMInputConfig({
          form: this,
          caption: "Nome Grade 2",
          requiredFunc: () => this.model.parametroestoque.usagrade2 === "Sim",
          disabledFunc: () =>
            this.model.parametroestoque.copiaprodutograde === "Sim" ||
            this.model.parametroestoque.usagrade2 !== "Sim",
          maxlength: 10,
        }),
        usalote: new CMInputConfig({
          form: this,
          caption: "Controla Lote",
        }),
        usacopiaproduto: new CMInputConfig({
          form: this,
          caption: "Usa cópia de produto",
        }),
        copiaprodutopreco: new CMInputConfig({
          form: this,
          caption: "Formação de Preço de Venda",
          disabledFunc: () => !this.AcabouHabilitarCopiaProdutos,
        }),
        copiaprodutofiscal: new CMInputConfig({
          form: this,
          caption: "Informações Fiscais",
          disabledFunc: () => !this.AcabouHabilitarCopiaProdutos,
        }),
        copiaprodutograde: new CMInputConfig({
          form: this,
          caption: "Informações de Grade",
          disabledFunc: () => !this.AcabouHabilitarCopiaProdutos,
        }),
        copiaprodutofornecedor: new CMInputConfig({
          form: this,
          caption: "Informações de Fornecedores",
          disabledFunc: () => !this.AcabouHabilitarCopiaProdutos,
        }),
        copiaprodutoempresa: new CMInputConfig({
          form: this,
          caption: "Empresa de Origem",
          requiredFunc: () =>
            this.model.parametroestoque.copiaprodutograde === "Sim",
          disabledFunc: () => !this.AcabouHabilitarCopiaProdutos,
          pesqFunc: (sender) => {
            EmpresaAssociadoBrowseComponent.pesquisa(
              sender,
              this.model.parametroestoque,
              "copiaprodutoempresa"
            );
          },
        }),
        codigoprodutosequencial: new CMInputConfig({
          form: this,
          caption: "Usa código do produto sequencial",
        }),
      },
      parametrofinanceiro: {
        qtdemaximaparcelas: new CMInputConfig({
          form: this,
          caption: "Quantidade máxima de parcelas",
        }),
        solicitapermissaovendacontavencida: new CMInputConfig({
          form: this,
          caption: "Solicita permissão na venda para conta a receber vencida",
          dataType: "boolean",
        }),
        textonotapromissoria: new CMInputConfig({
          form: this,
          caption: "Texto Padrão para Nota Promissória",
        }),
      },
      parametrocompras: {
        msgemailcotacao: new CMInputConfig({
          form: this,
          caption: "Mensagem padrão da Cotação",
          required: true,
        }),
        msgemailordemcompra: new CMInputConfig({
          form: this,
          caption: "Mensagem padrão da Ordem de Compra",
          required: true,
        }),
        msgemailorcamento: new CMInputConfig({
          form: this,
          caption: "Mensagem padrão do Orçamento",
          required: true,
        }),
      },
      parametrosat: {
        regtribissqn: new CMInputConfig({
          form: this,
          caption: "Regime Especial de Tributação do ISSQN",
          values: [
            { value: "MicroempresaMunicipal", text: "Micro Empresa Municipal" },
            { value: "Estimativa", text: "Estimativa" },
            {
              value: "SociedadeDeProfissionais",
              text: "Sociedade de Profissionais",
            },
            { value: "Cooperativa", text: "Cooperativa" },
            {
              value: "MicroempresarioIndividual",
              text: "Micro Empresário Individual (MEI)",
            },
          ],
        }),
        indratissqn: new CMInputConfig({
          form: this,
          caption:
            "Indicador de rateio do Desconto sobre subtotal entre itens sujeitos à tributação pelo ISSQN",
        }),
        razaosocial: new CMInputConfig({
          form: this,
          caption: "Razão Social",
          maxlength: 63,
          requiredFunc: () => this.usaSAT(),
        }),
        enderecolinha01: new CMInputConfig({
          form: this,
          caption: "Endereço Linha 1",
          maxlength: 63,
          requiredFunc: () => this.usaSAT(),
        }),
        enderecolinha02: new CMInputConfig({
          form: this,
          caption: "Endereço Linha 2",
          maxlength: 63,
        }),
        nomefantasia: new CMInputConfig({
          form: this,
          caption: "Nome Fantasia",
          maxlength: 63,
        }),
        usasat: new CMInputConfig({
          form: this,
          caption: "Usa SAT",
        }),
        msgimpressao: new CMInputConfig({
          form: this,
          caption: "Mensagem para Impressão",
        }),
        cfop: new CMInputConfig({
          form: this,
          caption: "CFOP",
          mask: "9.999",
          requiredFunc: () => this.usaSAT(),
          pesqFunc: (sender) => {
            CFOPBrowseComponent.pesquisa(
              sender,
              this.model.parametrosat,
              "cfop"
            );
          },
        }),
      },
      usuarios: new CMGridEditConfig({
        form: this,
        caption: "Usuários",
        required: false,
        fields: {
          usuario: new CMInputConfig({
            form: this,
            required: true,
            caption: "Usuário",
            pesqOnly: true,
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "usuario.nome");
              },
            }),
            pesqFunc: (sender) =>
              UsuarioBrowseComponent.pesquisa(
                sender,
                this.modelConfig.usuarios.grid.current
              ),
          }),
        },
      }),
      precos: new CMGridEditConfig({
        form: this,
        caption: "Preços",
        disabledFunc: () =>
          this.model.parametroestoque.copiaprodutopreco === "Sim",
        onAllowRemoveItemCallback: (callback, current) =>
          this.onAllowRemovePreco(callback, current),
        fields: {
          ordem: new CMInputConfig({
            form: this,
            required: true,
            caption: "Ordem",
            dataType: "integer",
            gridConfig: new CMGridEditConfigColumn(),
          }),
          nome: new CMInputConfig({
            form: this,
            required: true,
            caption: "Nome",
            maxlength: 10,
            gridConfig: new CMGridEditConfigColumn(),
          }),
        },
      }),
      filenamelogo: new CMInputConfig({
        form: this,
        disabled: true,
        maxlength: 255,
      }),
    };
  }

  testeEnvioEmail() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/empresaassociado/TesteEnvioEmail/FuncaoExtra",
      JSON.stringify(this.model.parametroemail),
      (result) => {
        if (result["result"] === "ok") {
          CMFormGlobaisComponent.toastSucesso(
            "Aguarde alguns minutos e verifique seu email"
          );
        } else {
          let str: string = '<p class="divider"></p><div class="container">';
          str += "<p><b>Verifique a mensagem abaixo</b></p>";
          str += "<p><b>Mensagem:</b> " + result["result"] + "</p>";
          str += '</div><p class="divider"></p>';
          CMFormGlobaisComponent.showMessageAviso(str);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

  usaSAT() {
    return this.model.parametrosat.usasat === "Sim";
  }

  carregarCertificadoDigital() {
    if (this.model.parametronfe.senhacertificadodigital === "")
      CMFormGlobaisComponent.toastErro(
        "Informe a senha do certificado digital"
      );
    else CMFormModalComponent.open("modalFormCertificado");
  }

  modalFormCertificadoClose() {
    CMFormModalComponent.close("modalFormCertificado");
  }

  onCompleteUpload(sender: any) {
    console.log(sender);
    CMFormGlobaisComponent.showSpinner();
    sender.form.postSubscribe(
      "/api/empresaassociado/UploadCertificadoDigital/FuncaoExtra",
      JSON.stringify({
        keyserver: sender.keyserver,
        senha: sender.form.model.parametronfe.senhacertificadodigital,
        idempresa: sender.form.model.id,
        filename: sender.file.name
      }),
      (result) => {
        if (result["result"] === "ok") {
          sender.form.model.parametronfe.datacertificadodigital =
            result["validade"];
          sender.form.model.parametronfe.infocertificadodigital =
            result["info"];
          CMFormGlobaisComponent.toastSucesso("Arquivo enviado com sucesso");
        } else {
          let str: string = '<p class="divider"></p><div class="container">';
          str += "<p><b>Mensagem:</b> " + result["result"] + "</p>";
          str += '</div><p class="divider"></p>';
          CMFormGlobaisComponent.showMessageAviso(str);
        }
        sender.form.modalFormCertificadoClose();
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        sender.form.modalFormCertificadoClose();
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }
  
  
  onCompleteUploadLogo(sender: any) {
    CMFormGlobaisComponent.showSpinner();
    sender.form.postSubscribe(
      "/api/empresaassociado/UploadLogo/FuncaoExtra",
      JSON.stringify({
        keyserver: sender.keyserver,
        idempresa: sender.form.model.id,
        filename: sender.file.name
      }),
      (result) => {
        if (result["result"] === "ok") {
          CMFormGlobaisComponent.toastSucesso("Arquivo enviado com sucesso");
        } else {
          let str: string = '<p class="divider"></p><div class="container">';
          str += "<p><b>Mensagem:</b> " + result["result"] + "</p>";
          str += '</div><p class="divider"></p>';
          CMFormGlobaisComponent.showMessageAviso(str);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

  afterSave() {
    this.AcabouHabilitarCopiaProdutos = false;
    if (this.model.id == FuncsGlobais.paramEmpresa.idEmpresa) {
      FuncsGlobais.setIdEmpresa(
        this.http,
        FuncsGlobais.idServerEmpresa,
        () => {}
      );
    }
    CMFormGlobaisComponent.showConfirmation(
      "Ir para tela de Login",
      "<p>Para que as alterações sejam aplicadas, é necessário realizar o login novamente.</p>" +
        "<p>Deseja realizar agora?</p>",
      () => {
        window.location.assign("/login");
      }
    );
  }

  showTagsTroca() {
    let html: string = "";
    html += '<div style="padding-left: 30px;">';
    html += "  <p><strong>#NumeroNota:</strong> Número da nota fiscal</p>";
    html += "  <p><strong>#NomeCliente:</strong> Nome do cliente</p>";
    html +=
      "  <p><strong>#DataEmissao:</strong> Data de emissão da nota fiscal</p>";
    html +=
      "  <p><strong>#ChaveAcesso:</strong> Chave de acesso da nota fiscal</p>";
    html +=
      "  <p><strong>#RazaoSocialAssociado:</strong> Razão social do associado</p>";
    html +=
      "  <p><strong>#DataCancelamento:</strong> Data do cancelamento da nota fiscal</p>";
    html +=
      "  <p><strong>#JustificativaCancelamento:</strong> Justificativa do cancelamento da nota fiscal</p>";
    html +=
      "  <p><strong>#DataCartaCorrecao:</strong> Data da carta de correção da nota fsical</p>";
    html +=
      "  <p><strong>#CartaCorrecao:</strong> Conteúdo da carta de correção da nota fiscal</p>";
    html += "<div>";
    html +=
      "  <p><strong>#DataVencimento: </strong> Data de vencimento da conta a receber</p > ";
    html += "<div>";

    html += "  <p><strong>#CNPJAssocialdo: </strong> CNPJ do Associado</p > ";
    html += "<div>";

    CMFormGlobaisComponent.showMessage(
      "Tags disponíveis para as mensagens",
      html
    );
  }

  showTagsNotaPromissoria() {
    let html: string = "";
    html += '<div style="padding-left: 30px;">';
    html +=
      "  <p><strong>#NumeroDocumento:</strong> Número do Documento que gerou a conta a receber</p>";
    html += "  <p><strong>#CodigoCliente:</strong> Código do cliente</p>";
    html += "  <p><strong>#CNPJCPFCliente:</strong> CNPJ/CPF do cliente</p>";
    html += "  <p><strong>#RGCliente:</strong> RG do cliente</p>";
    html += "  <p><strong>#EndereçoCliente:</strong> Endereço do cliente</p>";
    html += "  <p><strong>#BairroCliente:</strong> Bairro do cliente</p>";
    html += "  <p><strong>#CEPCliente:</strong> CEP do cliente</p>";
    html += "  <p><strong>#CidadeCliente:</strong> Cidade do cliente</p>";
    html +=
      "  <p><strong>#EndereçoAssociado:</strong> Endereço do Associado</p>";
    html +=
      "  <p><strong>#DataEmissao:</strong> Data de emissão do documento</p>";
    html +=
      "  <p><strong>#ValorDocumento: </strong> Valor do Documento a Receber</p > ";
    html +=
      "  <p><strong>#ValorDocExtenso: </strong> Valor do Documento a Receber por extenso</p > ";
    html +=
      "  <p><strong>#RazaoSocialAssociado:</strong> Razão social do associado</p>";
    html +=
      "  <p><strong>#DataVencimento: </strong> Data de vencimento da conta a receber</p > ";
    html +=
      "  <p><strong>#DataVencExtenso: </strong> Data de vencimento da conta a receber por extenso</p > ";
    html += "  <p><strong>#CNPJAssocialdo: </strong> CNPJ do Associado</p > ";
    html += "  <p><strong>#CidadeAssociado: </strong> CNPJ do Associado</p > ";
    html += "<div>";

    CMFormGlobaisComponent.showMessage(
      "Tags disponíveis para as mensagens",
      html
    );
  }

  checkCertificadoDigitalConfigurado() {
    if (this.valid()) {
      CMFormGlobaisComponent.showSpinner();
      this.postSubscribe(
        "/api/empresaassociado/CheckCertificadoDigitalConfigurado/FuncaoExtra",
        JSON.stringify({
          idempresa: this.model.id,
          senha: this.model.parametronfe.senhacertificadodigital,
        }),
        (result) => {
          if (result["result"] === "ok") {
            CMFormGlobaisComponent.toastSucesso(
              "Certificado digital configurado corretamente"
            );
          } else {
            let str: string = '<p class="divider"></p><div class="container">';
            str += "<p><b>Mensagem:</b> " + result["result"] + "</p>";
            str += '</div><p class="divider"></p>';
            CMFormGlobaisComponent.showMessageAviso(str);
          }
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          CMFormGlobaisComponent.showMessageError(error);
        }
      );
    }
  }

  onAllowRemovePreco(callback, current) {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/" + this.controllerName + "/PodeRemoverPreco",
      JSON.stringify({
        idempresapreco: current.id,
      }),
      (r) => {
        if (r["result"] == "ok") callback("");
        else callback(r["msg"]);
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

  showTagsTrocaCompras() {
    let html: string = "";
    html += '<div style="padding-left: 30px;">';
    html += "  <p><strong>#NomeFornecedor:</strong> Nome do forncedor</p>";
    html +=
      "  <p><strong>#RazaoSocialAssociado:</strong> Razão social do associado</p>";
    html +=
      "  <p><strong>#Cotacao_Link:</strong> Link para o fornecedor acessar a cotação</p>";
    html +=
      "  <p><strong>#Cotacao_DataMaximaResposta:</strong> Data de maxima para resposta da cotação</p>";
    html += "<div>";
    CMFormGlobaisComponent.showMessage(
      "Tags disponíveis para as mensagens",
      html
    );
  }

  habilitaCopiaProdutos() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/" + this.controllerName + "/PermiteHabilitarCopiaProdutos",
      JSON.stringify({
        id: this.idDataModel,
      }),
      (r) => {
        if (r["result"] == "ok") {
          this.AcabouHabilitarCopiaProdutos = true;
          this.model.parametroestoque.usacopiaproduto = "Sim";
          this.model.parametroestoque.copiaprodutopreco = "Sim";
          this.model.parametroestoque.copiaprodutofiscal = "Sim";
          this.model.parametroestoque.copiaprodutograde = "Sim";
          this.model.parametroestoque.copiaprodutofornecedor = "Sim";
        } else CMFormGlobaisComponent.toastErro(r["msg"]);
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

  desabilitaCopiaProdutos() {
    setTimeout(() => {
      this.model.parametroestoque.copiaprodutoempresa = {};
      this.model.parametroestoque.usacopiaproduto = "Não";
      this.model.parametroestoque.copiaprodutopreco = "Não";
      this.model.parametroestoque.copiaprodutofiscal = "Não";
      this.model.parametroestoque.copiaprodutograde = "Não";
      this.model.parametroestoque.copiaprodutofornecedor = "Não";
    }, 10);
  }

  customValid() {
    let r = super.customValid();
    if (r === "") {
      if (this.model.parametroestoque.usacopiaproduto === "Sim") {
        if (this.model.parametroestoque.copiaprodutoempresa) {
          if (
            this.model.parametroestoque.copiaprodutoempresa.id == this.model.id
          )
            r =
              "Empresa de origem de cópia de produtos não pode ser a mesma de destino.";
        }
      }
    }
    return r;
  }

  isEmpresaDefinida() {
    return this.model.id === FuncsGlobais.paramEmpresa.idEmpresa;
  }
  
  usaNFCe() {
    return this.model.parametronfce.usanfce === "Sim";
  }
  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

}
