// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMFormModalComponent,
} from "./../../@cmcore/component";
import { FluxoDeCaixaEditComponent } from "./fluxodecaixa-edit.component";
import { ContaBancariaBrowseComponent } from "../conta-bancaria";

@Component({
  selector: "fluxodecaixa-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class FluxoDeCaixaBrowseComponent extends CMFormBrowse2Component {

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "fluxodecaixa";
    this.formEditComponent = FluxoDeCaixaEditComponent;
    this.columns = [
      { field: "codigo", caption: "#" , filter: true},
      { field: "valor", caption: "Valor" , filter: true},
      {
        field: "data",
        caption: "Data",
      },
      {
        field: "dataini",
        caption: "Data de Inicial",
        visible: false,
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {
        field: "datafim",
        caption: "Data Final",
        visible: false,
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {field: 'contabancaria_descricao', caption: 'Conta Bancária',
       filterField: 'contabancaria.id',
       filterValue: 'contabancaria.codigo',
       fieldBottom: 'contabancaria.descricao',
       filter: new CMInputConfig({
         dataType: "integer",
         pesqFunc: (sender) => {
           ContaBancariaBrowseComponent.pesquisa(sender, this.valuesFilters, "Conta Bancária");
         },
       })
      },
    ]
  }

  
  _print() {
    console.log('method override')
    if (this.diretoImprimir) this.print();
  }
  

  getTitle() {
    return "Fluxo De Caixa";
  }

  getShowActionAdd() {
    return false;
  }

}
