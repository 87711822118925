// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
  CMInputConfig,
} from "./../../@cmcore/component";
import { IBPTEditComponent } from "./ibpt-edit.component";

@Component({
  selector: "ibpt-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class IBPTBrowseComponent extends CMFormBrowse2Component {
  
  static defaultColumns = [
    { field: "codigo", caption: "Código", width: "100", filter: true },
    { field: "descricao", caption: "Descrição", filter: true },
  ];


  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "ibpt";
    this.formEditComponent = IBPTEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = [
      {
        field: "tipo",
        caption: "Tipo",
        visible: false,
        value: "Mercadoria",
        filter: new CMInputConfig({
          values: [
            { value: "Todos" },
            { value: "Mercadoria" },
            { value: "ServicoNBS", text: "Serviço NBS" },
            { value: "ServicoLC116", text: "Serviço LC 116" },
          ],
        }),
      },
      {
        field: "codigo",
        caption: "Código (NCM/SH)",
        filter: true,
        width: "170",
      },
      { field: "ex", caption: "EX", width: "70", filter: true },
      { field: "descricao", caption: "Descrição", filter: true },
      {
        field: "custom",
        caption: "Alterado pelo usuário",
        visible: false,
        value: "Todos",
        filter: new CMInputConfig({
          values: [{ value: "Todos" }, { value: "Sim" }, { value: "Não" }],
        }),
      },
      {
        field: "validade",
        caption: "Validade",
        visible: false,
        value: "Todos",
        filter: new CMInputConfig({
          values: [{ value: "Todos" }, { value: "Vencidos" }],
        }),
      },
    ];
  }

  getTitle() {
    return "IBPT (NCM/SH)";
  }

  static pesquisa(sender: any, target: any) {
    if (target) {
      let caption: string = "Pesquisa de IBPT (NCM/SH)";
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "ibpt",
        caption,
        [
          {
            field: "codigo",
            caption: "Código (NCM/SH)",
            filter: true,
            width: "170",
          },
          { field: "ex", caption: "EX", width: "70", filter: true },
          { field: "descricao", caption: "Descrição", filter: true },
        ],
        target,
        undefined,
        "codigo",
        undefined,
        undefined,
        "auto"
      );
    }
  }

  static pesquisa2(sender: any, target: any, targetProp: any = undefined) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "ibpt",
        "Pesquisa de IBPT(NCM/SH)",
        IBPTBrowseComponent.defaultColumns,
        target,
        targetProp,
        "codigo",
        undefined,
        false
      );
    }
  }
}
