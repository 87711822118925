// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormEditComponent } from "./../../@cmcore/component";
import { AuthService } from "./auth.service";

@Component({
  selector: "login-api",
  template: "",
})
export class LoginApiComponent extends CMFormEditComponent {
  constructor(_injector: Injector, private authService: AuthService) {
    super(_injector);
  }

  ngOnInit() {
    super.ngOnInit();
    let sub = this.route.params.subscribe((params) => {
      if (params["chaveacesso"] !== undefined) {
        this.authService.login(params, "Token " + params["chaveacesso"]);
      } else {
        this.authService.login(params, undefined);
      }
    });
  }
}
