// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
  CMInputConfig,
} from "./../../../../@cmcore/component";
import { RecursoHelpEditComponent } from "./recurso-help-edit.component";
import { RecursoBrowseComponent } from "..";

@Component({
  selector: "recurso-help-browse",
  templateUrl:
    "./../../../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class RecursoHelpBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "RecursoHelp";
    this.formEditComponent = RecursoHelpEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = [
      { field: "id", caption: "#", visible: false },
      {
        field: "recurso_descricao",
        caption: "Recurso",
        filterField: "recurso.id",
        fieldBottom: "recurso.descricao",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            RecursoBrowseComponent.pesquisa(sender, this.valuesFilters, [
              "MenuBrowse",
              "MenuRelatorio",
            ]);
          },
        }),
      },
      {
        field: "titulo",
        caption: "Titulo",
        filter: new CMInputConfig({
          maxlength: 255,
        }),
      },
      { field: "ativo", caption: "Ativo", width: 100 },
    ];
  }

  getTitle() {
    return "Ajuda do Recurso";
  }
}
