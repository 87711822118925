// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
  CMInputConfig,
} from "./../../@cmcore/component";
import { LoteContaReceberEditComponent } from "./lote-conta-receber-edit.component";
import { ClienteBrowseComponent } from "../cliente";

@Component({
  selector: "lote-conta-receber-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class LoteContaReceberBrowseComponent extends CMFormBrowse2Component {
  getTitle() {
    return "Faturar Vendas em Carteira";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "LoteContaReceber";
    this.formEditComponent = LoteContaReceberEditComponent;
    this.columns = [
      {
        field: "numero",
        caption: "#",
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      { field: "datacadastro", caption: "Data de Cadastro" },
      {
        field: "cliente_nome",
        caption: "Cliente",
        filterField: "cliente.id",
        filterValue: "cliente.codigo",
        fieldBottom: "cliente.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            ClienteBrowseComponent.pesquisa(sender, this.valuesFilters);
          },
        }),
      },
    ];
  }

  static pesquisa(sender: any, target: any, targetProp: any = undefined) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "LoteContaReceber",
        "Pesquisa de Lote Conta a Receber",
        [
          {
            field: "numero",
            caption: "#",
            filter: new CMInputConfig({
              dataType: "integer",
            }),
          },
          { field: "datacadastro", caption: "Data de Cadastro" },
        ],
        target,
        targetProp,
        "numero"
      );
    }
  }
}
