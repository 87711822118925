import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "../../component/cmform/cmform.component";

@Component({
  selector: "app-ips-bloqueados",
  templateUrl: "ips-bloqueados.component.html",
  styleUrls: ["ips-bloqueados.component.css"],
})
export class IPsBloqueadosComponent extends CMFormComponent {
  ips = [];

  constructor(injector: Injector) {
    super(injector);
    this.getListIPBlock();
  }

  getListIPBlock() {
    CMFormComponent.postSubscribe(
      this.http,
      "/api/GetListIPBlock",
      JSON.stringify({}),
      (result) => {
        if (result["ips"]) {
          this.ips = result["ips"];
        }
      },
      (error) => {
        this.showMessageErrorHTTP("getListIPBlock", error);
      }
    );
  }

  liberarIP(ip) {
    CMFormComponent.postSubscribe(
      this.http,
      "/api/LiberaIPBlock",
      JSON.stringify({ ip: ip }),
      (result) => {
        if (result["result"] !== "ok") {
          console.error(result);
        }
        this.getListIPBlock();
      },
      (error) => {
        this.showMessageErrorHTTP("LiberaIPBlock", error);
      }
    );
  }
}
