// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMFormModalComponent,
} from "./../../@cmcore/component";
import {
  NFeRecebimentoEditComponent,
  PessoaBrowseComponent,
  NFeRecebimentoAcoesComponent,
  NFeRecebimentoAlterarDataDuplicataComponent,
  ProdutoBrowseComponent,
  NFeRecebimentoRelatoriosComponent,
} from "./../index";

@Component({
  selector: "nfe-recebimento-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class NFeRecebimentoBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "nferecebimento";
    this.buttonsExtra = 4;
    this.formEditComponent = NFeRecebimentoEditComponent;
    this.columns = [
      {
        field: "nnf",
        caption: "#",
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "serie",
        caption: "Série",
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "nnf_nferecebimento",
        caption: "Número",
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "dhemi",
        caption: "Data",
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {
        field: "pessoa_nome",
        caption: "Fornecedor",
        filterField: "pessoa.id",
        filterValue: "pessoa.codigo",
        fieldBottom: "pessoa.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            PessoaBrowseComponent.pesquisa(
              sender,
              this.valuesFilters,
              "Fornecedor"
            );
          },
        }),
      },
      {
        field: "produto_descr",
        caption: "Produto",
        filterField: "produto.id",
        filterValue: "produto.codigo",
        fieldBottom: "produto.descr",
        visible: false,
        filter: new CMInputConfig({
          maxlength: 30,
          padInfo: { size: 30 },
          pesqFunc: (sender) => {
            ProdutoBrowseComponent.pesquisa(sender, this.valuesFilters, "Todos");
          },
        }),
      },
    ];
    if (NFeRecebimentoRelatoriosComponent.getRelatorioCustomizados().length === 0){
      this.getRelatoriosCustomizados();
    }
    console.log(NFeRecebimentoRelatoriosComponent.getRelatorioCustomizados());
  }

  
  getRelatoriosCustomizados(){
    this.postSubscribeT<Array<Object>>(
      "/api/nferecebimento/GetRelatoriosCustomizados",
      JSON.stringify({}),
      (result) => {
        result.forEach(element => {
          if (element['tipo'] === 'EtiquetaNFeRecebimento'){
            NFeRecebimentoRelatoriosComponent.addRelatorioCustomizado({value: element['id'], text: element['descricao']});
          }
        });
      },
    );
  }

  
  openFormRelatoriosNFeRecebimento() {
    this.beforeComponent = NFeRecebimentoRelatoriosComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formRelatoriosNFeRecebimento");
    }, 100);
  }

  buttonsExtraClick(index: number) {
    if (index === 0) {
      this.openFormAcoesNFeRecebimento(this.grid.fieldByName("id"));
    } else if (index === 1) {
      if (NFeRecebimentoRelatoriosComponent.getRelatorioCustomizados().length <= 1 ){
        this.showRelat(
          "/ImprimeEtiquetas/FuncaoExtra",
          {
            idnferecebimento: this.fieldByName("id"),
            nnf: this.fieldByName("nnf"),
          },
          "nferecebimento"
        );
      }
      else{
        NFeRecebimentoRelatoriosComponent.isLista = false;
        this.openFormRelatoriosNFeRecebimento();
      }
    } else if (index === 2) {
      this.openFormNFeRecebimentoAlterarDataDuplicata(
        this.grid.fieldByName("id")
      );
    } else if (index === 3) {
      let pessoa;
      let produto;
      console.log(this.valuesFilters);
      if (this.valuesFilters.hasOwnProperty("pessoa")){
        pessoa = this.valuesFilters.pessoa.id;
        console.log('tilro ', pessoa);
      }
      else {
        pessoa = 0;
      }
      if (this.valuesFilters.hasOwnProperty("produto")){
        produto = this.valuesFilters.produto.id;
        console.log('filtro ',this.valuesFilters.produto.id);
      }
      else {
        produto = 0;
      }
      
      if (NFeRecebimentoRelatoriosComponent.getRelatorioCustomizados().length <= 1 ){
        this.showRelat(
          "/ImprimeEtiquetas/FuncaoExtra",
          {
            idnferecebimento: this.fieldByName("id"),
            nnf: this.valuesFilters.nnf,
            listaetiqueta: 1,
            dhemi: this.valuesFilters.dhemi,
            nnf_nferecebimento: this.valuesFilters.nnf_nferecebimento,
            pessoa,
            produto,
            serie: this.valuesFilters.serie
          },
          "nferecebimento"
        );
      } else {
        NFeRecebimentoRelatoriosComponent.isLista = true;
        this.openFormRelatoriosNFeRecebimento();
      }
    }
  }

  idFormAcoesNFeRecebimento: number = 0;
  openFormAcoesNFeRecebimento(id) {
    this.idFormAcoesNFeRecebimento = id;
    this.beforeComponent = NFeRecebimentoAcoesComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formAcoesNFeRecebimento");
    }, 100);
  }

  idFormNFeRecebimentoAlterarDataDuplicata: number = 0;
  openFormNFeRecebimentoAlterarDataDuplicata(id) {
    this.idFormNFeRecebimentoAlterarDataDuplicata = id;
    this.beforeComponent = NFeRecebimentoAlterarDataDuplicataComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formNFeRecebimentoAlterarDataDuplicata");
    }, 100);
  }

  loadDireitoButtonExtra(index: number) {
    if (index === 0) {
      this.getDireitosMenuBrowseButtonsExtra(index, "ImportNFeRecebimento");
    } else if ((index === 1) || (index === 3)) {
      this.getDireitosMenuBrowseButtonsExtra(index, "ImprimeEtiquetas");
    } else if (index === 2) {
      this.getDireitosMenuBrowseButtonsExtra(
        index,
        "AlteraVencimentoDuplicata"
      );
    }
  }

  buttonsExtraIcon(index: number) {
    let r: string = "";
    if (index === 0) r = "cloud_upload";
    else if (index === 1) r = "loyalty";
    else if (index === 2) r = "event";
    else if (index === 3) r = "print";
    return r;
  }

  buttonsExtraHint(index: number) {
    let r: string = "";
    if (index === 0) r = "Importar XML";
    else if (index === 1) r = "Imprimir etiquetas";
    else if (index === 2) r = "Alterar data de vencimento duplicata";
    else if (index === 3) r = "Imprimir etiquetas por lista";
    return r;
  }

  buttonsExtraPosicao(index: number) {
    let r: string = super.buttonsExtraPosicao(index);
    if (index === 0 || index === 3) r = "grid";
    return r;
  }

  getTitle() {
    return "NF-e de Recebimento";
  }

  static pesquisa(
    sender: any,
    target: any,
    targetProp?: string,
    onAfterSelect?: Function
  ) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "nferecebimento",
        "Pesquisa de NF-e de Recebimento",
        [
          { field: "nnf", caption: "#" },
          { field: "pessoa_nome", caption: "Fornecedor", filter: true },
          { field: "dhemi", caption: "Data", filter: true, mask: "99/99/9999" },
        ],
        target,
        targetProp,
        "infnfe.ide.nnf",
        onAfterSelect
      );
    }
  }
}
