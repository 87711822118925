// tslint:disable
import { Component, AfterContentInit, Input } from '@angular/core';

import { CMInput } from '../../decorator';
import { CMTabComponent } from './cmtab.component';

@Component({
  selector: 'cmtabs',
  styleUrls: ['cmtabs.component.scss'],
  templateUrl: 'cmtabs.component.html'
})
export class CMTabsComponent implements AfterContentInit {

  @Input()
  @CMInput()
  useBorder: boolean = true;

  public tabs: CMTabComponent[] = [];

  addTab(tab: CMTabComponent) {
    this.tabs.push(tab);
  }

  public activeFirstPage() {
    if (this.tabs.length > 0) {
      let i = 0;
      while (i > -1) {
        if (this.tabs[i].visible) {
          break;
        }
        else {
          i += 1;
          if (i >= this.tabs.length)
            i = -1;
        }
      }
      if (i > -1)
        this.tabs[i].active = true;
    }
  }

  public setActiveNextPage() {
    let r: boolean = false;
    if (this.tabs.length > 0) {
      let i = 0;
      while (i > -1) {
        if ((this.tabs[i].visible) && (this.tabs[i].active)) {
          break;
        }
        else {
          i += 1;
          if (i >= this.tabs.length)
            i = -1;
        }
      }
      if (i > -1) {
        i += 1;
        if (i < this.tabs.length) {
          this.tabs[i].active = true;
          r = true;
        }
      }
    }
    return r;
  }

  ngAfterContentInit() {
    if (this.tabs.length > 0) {
      let activeTabs = this.tabs.filter((tab)=>tab.active);
      if(activeTabs.length === 0) {
        setTimeout(() => {
          this.activeFirstPage();
        }, 1000);
      }
    }
  }

}
