// tslint:disable
import { Component } from '@angular/core';

import { CMFormComponent } from './../../@cmcore/component/cmform/cmform.component';
import { FuncsGlobais } from '../funcs.globais';

@Component({
    templateUrl: 'home.component.html',
    styleUrls: ['home.component.scss']
})
export class HomeComponent extends CMFormComponent {

    public get empresas() {
      return FuncsGlobais.empresas;
    }

}