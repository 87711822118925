// tslint:disable
import { Component, Injector } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { AuthService } from "./@cmcore/guard";

import { CMFormComponent } from "./@cmcore/component/cmform/cmform.component";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent extends CMFormComponent {
  static title = "Aguarde...";
  static useTimeOutSessao: boolean = true;
  static basePathApiVar: string = "";
  static useMenuLateral: boolean = false;
  static useMenuLateral2: boolean = false;
  static heightMenuLateral2: string = "unset";
  static useMenuPrincipalInRouterOutlet: boolean = false;
  static heightRouterOutlet: string = "100%";
  static classRouterOutlet: string = "";
  static defaultClassRowMain: string = "row";

  constructor(
    injector: Injector,
    private titleService: Title,
    public authService: AuthService
  ) {
    super(injector);
    CMFormComponent.funcBasePathApi = () => {
      return AppComponent.basePathApiVar;
    };
    this.titleService.setTitle(AppComponent.title);
    CMFormComponent.loadCSS_Color();
  }

  defaultClassRowMain() {
    return AppComponent.defaultClassRowMain;
  }

  checkTimeOutSessao() {
    if (AppComponent.useTimeOutSessao) {
      if (
        this.router.url === "/" ||
        this.router.url === "home" ||
        this.router.url.startsWith("/home")
      ) {
        let d = new Date();
        let n = d.getHours();
        let s = "APP" + n.toString();
        this.getDireitos("", s);
      }
    }
  }

  onActivate() {
    this.checkTimeOutSessao();
    window.scrollTo(0, 0);

    $("*").click(() => {
      $(".popup .show").removeClass("show");
    });
  }

  get useMenuPrincipalInRouterOutlet() {
    return AppComponent.useMenuPrincipalInRouterOutlet;
  }

  get heightRouterOutlet() {
    return AppComponent.heightRouterOutlet;
  }

  get classRouterOutlet() {
    return AppComponent.classRouterOutlet;
  }

  get useMenuLateral() {
    return AppComponent.useMenuLateral;
  }

  get useMenuLateral2() {
    return AppComponent.useMenuLateral2;
  }

  get heightMenuLateral2() {
    return AppComponent.heightMenuLateral2;
  }

  get overflowMenuLateral2() {
    if (AppComponent.useMenuLateral2) return "hidden";
    else return "unset";
  }

  get columnsRoute() {
    if (this.useMenuLateral2) return "0";
    else if (this.useMenuLateral && this.isLogged) return "10";
    else return "12";
  }

  getMainRouterOutletDisplay() {
    if (this.useMenuLateral2) return "flex";
    else return "block";
  }
}
