// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
} from "./../../@cmcore/component";
import { PessoaBrowseComponent } from "./../pessoa/pessoa-browse.component";
import { ProdutoBrowseComponent } from "./../produto/produto-browse.component";

@Component({
  selector: "ordem-compra-edit",
  templateUrl: "ordem-compra-edit.component.html",
})
export class OrdemCompraEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_fornecedor";
  }

  getTitle() {
    let r: string = "Ordem de Compra";
    let tmp: string = this.fieldByName("numero");
    if (tmp !== "0") r += " - #" + tmp;
    tmp = this.fieldByName("fornecedor.nome");
    if (tmp !== "") r += " - " + tmp;
    return r;
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "OrdemCompra";
    this.setModelConfig();
  }

  afterGetModelData() {
    super.afterGetModelData();
    if (!this.disabledAll) this.disabledAll = this.model.podeeditar !== "Sim";
  }

  setModelConfig() {
    this.modelConfig = {
      numero: new CMInputConfig({ form: this, caption: "#", disabled: true }),
      data: new CMInputConfig({
        form: this,
        caption: "Data emissão",
        mask: "99/99/9999",
        required: true,
      }),
      fornecedor: new CMInputConfig({
        form: this,
        caption: "Fornecedor",
        required: true,
        pesqFunc: (sender) => {
          PessoaBrowseComponent.pesquisa(
            sender,
            this.model,
            "Fornecedor",
            "fornecedor"
          );
        },
      }),
      status: new CMInputConfig({
        form: this,
        caption: "Status",
        values: [
          { value: "Aberta" },
          { value: "Recebida" },
          { value: "Cancelada" },
        ],
      }),
      observacao: new CMInputConfig({
        form: this,
        caption: "Observação",
      }),
      valortotal: new CMInputConfig({
        form: this,
        caption: "Valor total",
        dataType: "double(2)",
        disabled: true,
      }),
      itens: new CMGridEditConfig({
        form: this,
        onNewItem: (model) => {
          Object.assign(model, {
            quantidade: "1,00",
            valorunitario: "0,00",
            valortotal: "0,00",
            estoque: [],
          });
        },
        onRemoveItem: (item) => {
          this.onRemoveItem(item);
        },
        customValidItem: (item) => {
          return this.validItem(item);
        },
        fields: {
          produto: new CMInputConfig({
            form: this,
            caption: "Produto",
            required: true,
            maxlength: 30,
            padInfo: { size: 30 },
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              getValue: (row) => {
                return this.getValueObject(row, "produto.codigo");
              },
              getBottomLabelColumn: (row) => {
                return this.getValueObject(row, "produto.descr");
              },
            }),
            pesqFunc: (sender) => {
              ProdutoBrowseComponent.pesquisa(
                sender,
                this.modelConfig.itens.current,
                "Ativos",
                undefined,
                () => {
                  if(this.currentProdutoUsaGrade()){
                    console.log(this.modelConfig.itens.current);
                    this.modelConfig.itens.current.quantidade = "0,00";

                    let grades = this.modelConfig.itens.current.produto.grades;

                    grades.forEach((grade) => {
                      this.modelConfig.itens.current.estoque.push({
                        "datavalidadelote": "",
                        "grade": grade,
                        "id": CMInputConfig.getRandomInt(1, 9999) * -1,
                        "numerolote": "",
                        "quantidade": "0,00"
                      })
                    });
                  }
                  else{
                    this.modelConfig.itens.current.quantidade = "1,00";
                  };
                }
              );
            },
          }),
          quantidade: new CMInputConfig({
            form: this,
            caption: "Quantidade",
            dataType: "double(2)",
            gridConfig: new CMGridEditConfigColumn(),
            hintButton: "Editar",
            iconButton: () => {
              if (this.disabledAll) return "visibility";
              else return "edit";
            },
            showButtonClear: false,
            pesqOnlyFunc: (row) => this.mostraDetalhesQuantidade(row),
            pesqFunc: (sender) => {
              if (this.mostraDetalhesQuantidade(this.modelConfig.itens.current))
                this.modelConfig.itens.extraClick(
                  new CMGridEditConfigMore({
                    index: 0,
                    title: "Detalhes da Quantidade",
                  })
                );
            },
            showButtonFunc: (show, row) => this.mostraDetalhesQuantidade(row),
            onExit: () => {
              this.calcTotalItem();
            },
          }),
          valorunitario: new CMInputConfig({
            form: this,
            caption: "Valor unitário",
            dataType: "double(2)",
            gridConfig: new CMGridEditConfigColumn(),
            onExit: () => {
              this.calcTotalItem();
            },
            required: true,
          }),
          valortotal: new CMInputConfig({
            form: this,
            caption: "Valor total",
            dataType: "double(2)",
            disabled: true,
            gridConfig: new CMGridEditConfigColumn(),
            required: true,
          }),
          estoque: new CMGridEditConfig({
            form: this,
            required: false,
            onRemoveItem: (item) => {
              this.onRemoveItemEstoque(item);
            },
            fields: {
              grade: new CMInputConfig({
                form: this,
                caption: "Grade",
                required: true,
                pesqOnly: true,
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  getValue: (row) => {
                    return this.getValueObject(row, "grade.descricao");
                  },
                }),
                pesqFunc: (sender) => {
                  ProdutoBrowseComponent.pesquisaGrade(
                    sender,
                    this.modelConfig.itens.fields.estoque.current,
                    this.modelConfig.itens.current.produto.id
                  );
                },
                visibleFunc: () => {
                  return this.currentProdutoUsaGrade();
                },
              }),
              quantidade: new CMInputConfig({
                form: this,
                caption: "Quantidade",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn(),
                onExit: () => {
                  this.calcTotalItemEstoque();
                },
              }),
            },
          }),
        },
      }),
    };
  }
  onRemoveItemEstoque(item: any) {
    let itemsEstoque = this.modelConfig.itens.current;
    let qtotal = 0;
    
    itemsEstoque.estoque.forEach((est) => {
      if (item !== est){
        qtotal += this.valueToFloat(est.quantidade);
      }
    });
    itemsEstoque.quantidade = this.floatToValue(qtotal);
    this.calcTotalItem();
  }

  onRemoveItem(item) {
    this.add_ValorTotal(this.valueToFloat(item.valortotal, 0), 0);
  }

  calcTotalItem() {
    this.calcTotalItem2(this.modelConfig.itens.current);
  }

  calcTotalItem2(item) {
    let valortotal =
      this.valueToFloat(item.quantidade, 0) *
      this.valueToFloat(item.valorunitario, 0);
    this.add_ValorTotal(this.valueToFloat(item.valortotal, 0), valortotal);
    item.valortotal = this.floatToValue(valortotal);
  }

  add_ValorTotal(oldValue: number, newValue: number) {
    let valortotal = this.valueToFloat(this.model.valortotal, 0);
    valortotal += newValue - oldValue;
    this.model.valortotal = this.floatToValue(valortotal);
  }

  validItem(item: any) {
    let r: string = "";
    if (this.valueToFloat(item.valortotal, 0) <= 0)
      r = "Valor total deve ser maior que zero";
    if (this.valueToFloat(item.quantidade, 0) <= 0)
      r = "Valor total deve ser maior que zero";
    return r;
  }

  customValid() {
    let r = super.customValid();
    if (r === "") {
      if (this.valueToFloat(this.model.valortotal, 0) <= 0)
        r = "Valor total deve ser maior que zero";
    }
    return r;
  }

  mostraDetalhesQuantidade(row) {
    let r = false;
    if (row && row.produto) {
      r = row.produto.usagrade1 === "Sim" || row.produto.usagrade2 === "Sim";
    }
    return r;
  }

  currentProdutoUsaGrade() {
    return (
      this.getValueObject(
        this.modelConfig.itens.current,
        "produto.usagrade1"
      ) === "Sim" ||
      this.getValueObject(
        this.modelConfig.itens.current,
        "produto.usagrade2"
      ) === "Sim"
    );
  }

  calcTotalItemEstoque() {
    let item = this.modelConfig.itens.current;
    let qtotal = 0;
    item.estoque.forEach((est) => {
      qtotal += this.valueToFloat(est.quantidade);
    });
    item.quantidade = this.floatToValue(qtotal);
    this.calcTotalItem2(item);
  }
}
