// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormBrowse2Component } from './../../@cmcore/component';
import { ConfigGeralEditComponent } from './config-geral-edit.component';

@Component({
  selector: 'config-geral-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class ConfigGeralBrowseComponent extends CMFormBrowse2Component {

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'ConfigGeral';
    this.formEditComponent = ConfigGeralEditComponent;
    this.columns = [{ field: 'htmlatualizacoes', caption: 'O que há de novo' },
    ];
  }

  getTitle() {
    return 'Configuração Geral';
  }

}