// tslint:disable
import { forwardRef, Component, Input } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

import {
  CMInputComponent,
  CMInputComponent_props,
  CMOutputComponent_props,
} from "./cminput.component";
import { CMInput } from "../../decorator";

declare var $: any;

export const CMCHECKBOX_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CMCheckBoxComponent),
  multi: true,
};

@Component({
  selector: "cmcheckbox",
  templateUrl: "./cmcheckbox.component.html",
  styleUrls: ["./cmcheckbox.component.scss"],
  providers: [CMCHECKBOX_CONTROL_VALUE_ACCESSOR],
  inputs: CMInputComponent_props,
  outputs: CMOutputComponent_props,
})
export class CMCheckBoxComponent extends CMInputComponent {
  getType() {
    return "checkbox";
  }

  @Input()
  customStyleBase: string = "";

  getChecked() {
    if (this.value === "Sim") return "checked";
    else return "";
  }

  onClick(event: any) {
    let object = $("#" + this.name);
    if (object.is(":checked")) this.value = "Sim";
    else this.value = "Não";
  }

  getActive() {
    return null;
  }
}
