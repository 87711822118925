// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormRelatComponent, CMInputConfig,
         CMFormGlobaisComponent
       } from './../../../@cmcore/component';

@Component({
    selector: 'fluxo-caixa-futuro',
    templateUrl: 'fluxo-caixa-futuro.component.html'
})
export class RelatFluxoCaixaFuturoComponent extends CMFormRelatComponent {

  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = 'ImpressaoFluxoCaixaFuturo';
    this.filterConfig = {
      dataini: new CMInputConfig({form: this,
                                  caption: 'Data Inicial',
                                  mask: '99/99/9999'
                                 }),
      datafim: new CMInputConfig({form: this,
                                  caption: 'Data Final',
                                  mask: '99/99/9999'
                                 }),
      saldoinicial: new CMInputConfig({form: this,
                                       caption: 'Valor',
                                       dataType: 'double(2)'
                                     }),
      incluimovimentosdia: new CMInputConfig({form: this,
                                              caption: 'Inclui no saldo inicial contas recebidas ou pagas no dia'
                                            })
    }
    this.filter.dataini = CMFormRelatComponent.incDate(1);
    this.filter.datafim = this.lastDate();
    this.filter.saldoinicial = '0,00';
    this.filter.incluimovimentosdia = 'Não';
  }

  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.dataini);
      if (!r)
        CMFormGlobaisComponent.toastErro('Data inicial não informada');
    }
    if (r) {
      r = !this.isEmpty(this.filter.datafim);
      if (!r)
        CMFormGlobaisComponent.toastErro('Data final não informada');
    }
    return r;
  }

}