// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormBrowse2Component, CMInputConfig, CMFormPesquisaComponent } from './../../@cmcore/component';
import { EmpresaAssociadoEditComponent } from './empresa-associado-edit.component';

@Component({
  selector: 'empresa-associado-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class EmpresaAssociadoBrowseComponent extends CMFormBrowse2Component {

  getTitle() {
    return 'Empresa';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'empresaassociado';
    this.formEditComponent = EmpresaAssociadoEditComponent;
    this.columns = [{field: 'codigo', caption: '#', width: '100',
                     filter: new CMInputConfig({
                       dataType: 'integer'
                     })
                    },
                    {field: 'cnpj', caption: 'CNPJ',  width: '200',
                     filter: new CMInputConfig({
                       mask: '99.999.999/9999-99'
                     })
                    },
                    {field: 'nome', caption: 'Razão Social',
                     filter: new CMInputConfig({
                       maxlength: 100
                     })
                    }
                   ];
  }

  static pesquisa(sender: any, target: any, targetProp?: string, onAfterSelect?: Function) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'empresaassociado',
        'Pesquisa de Empresa',
        [{field: 'codigo', caption: '#'},
         {field: 'nome', caption: 'Nome'},
         {field: 'cnpj',   caption: 'CNPJ'}],
        target,
        targetProp,
        'codigo',
        onAfterSelect);
    }
  }

}