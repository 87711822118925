// tslint:disable
import { Component, Input, Output, OnInit, EventEmitter } from "@angular/core";

import { CMInput } from "../../../decorator";
import { CMFormGlobaisComponent } from "../../../component";

import * as M from "materialize-css";

@Component({
  selector: "cmform-modal",
  templateUrl: "./cmform-modal.component.html",
  styleUrls: ["./cmform-modal.component.scss"],
})
export class CMFormModalComponent {
  @Input() caption: string = "";
  @Input() classCaption: string = "default-color lighten-1";
  @Input() name: string = "";
  @Input() maxWidth: string = "inherit";
  @Input() overflowY: string = "auto";

  @CMInput()
  @Input()
  fixedFooter: boolean = true;
  @CMInput()
  @Input()
  bottomSheet: boolean = false;
  @CMInput()
  @Input()
  useFooter: boolean = true;
  @Input()
  @CMInput()
  showButtonCancel: boolean = false;

  @Output()
  clickButtonCancel = new EventEmitter();
  _clickButtonCancel(event: any) {
    this.clickButtonCancel.emit(event);
  }

  static onOpens = [];

  static close(name: string): void {
    let a: any = $("#" + name);
    a.modal("close");
  }
  
  static isClosed(name: string): boolean {
    let a: any = $("#" + name);
    return (a.css("display") === "none");
  }

  static closeForm<T>(form: T): void {
    let f: any = form;
    CMFormModalComponent.close(f.name);
    CMFormGlobaisComponent.freeComponent(form);
  }

  static onOpen() {
    while (CMFormModalComponent.onOpens.length > 0) {
      const ev = CMFormModalComponent.onOpens.pop();
      ev();
    }
  }

  static open(name: string, onOpen?: Function) {
    setTimeout(() => {
      if (onOpen !== undefined) {
        CMFormModalComponent.onOpens.push(onOpen);
      }
      let qIntance = document.querySelector("#" + name);
      if (qIntance) {
        let instance = M.Modal.getInstance(qIntance);
        if (!instance) {
          instance = M.Modal.init(qIntance, {
            dismissible: false,
            startingTop: "10%",
            endingTop: "10%",
            onOpenEnd: function (modal, trigger) {
              CMFormModalComponent.onOpen();
            },
          });
        }
        if (instance) {
          instance.open();
        }
      } else {
        CMFormModalComponent.onOpen();
      }
      let e = document.getElementById(name);
      if (e) {
        e.scrollTop = 0;
        e.scrollLeft = 0;
      }
    }, 100);
  }

  static openForm<T>(form: T, onOpen?: Function): void {
    let f: any = form;
    CMFormModalComponent.open(f.name, onOpen);
  }
}
