// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormRelatComponent,
  CMInputConfig,
} from "./../../../@cmcore/component";
import { FuncsGlobais } from "../../funcs.globais";
import {
  GradeBrowseComponent,
  PessoaBrowseComponent,
  FornecedorBrowseComponent,
  ProdutoBrowseComponent,
  GrupoDeProdutoBrowseComponent,
  SubGrupoDeProdutoBrowseComponent,
} from "../..";

@Component({
  selector: "listagem-produtos",
  templateUrl: "listagem-produtos.component.html",
})
export class RelatListagemProdutosComponent extends CMFormRelatComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = "ImpressaoListagemProdutos";
    this.filterConfig = {
      apenasprodutoscomsaldo: new CMInputConfig({
        form: this,
        caption: "Apenas produtos com saldo",
      }),
      ativo: new CMInputConfig({
        form: this,
        caption: "Apenas produtos ativos",
      }),
      ordem: new CMInputConfig({
        form: this,
        caption: "Ordenar por: ",
        values: [{value: 'A-Z'}, {value: 'Z-A'}, {value: 'Codigo'}]
      }),
      produto: new CMInputConfig({
        form: this,
        caption: "Produto",
        maxlength: 30,
        padInfo: { size: 30 },
        pesqFunc: (sender) => {
          ProdutoBrowseComponent.pesquisa(sender, this.filter, "Ativos");
        },
      }),
      descricaoproduto: new CMInputConfig({
        form: this,
        caption: "Descrição do produto",
        maxlength: 60,
      }),
      fornecedor: new CMInputConfig({
        form: this,
        caption: "Fornecedor",
        pesqFunc: (sender) => {
          FornecedorBrowseComponent.pesquisa(sender, this.filter);
        },
      }),
      grade1: new CMInputConfig({
        form: this,
        pesqOnly: true,
        caption: this.paramEmpresa.nomeGrade1,
        visibleFunc: () => {
          return this.paramEmpresa.usaGrade1;
        },
        pesqFunc: (sender) =>
          GradeBrowseComponent.pesquisa(sender, this.filter, "1"),
      }),
      grade2: new CMInputConfig({
        form: this,
        pesqOnly: true,
        caption: this.paramEmpresa.nomeGrade2,
        visibleFunc: () => {
          return this.paramEmpresa.usaGrade2;
        },
        pesqFunc: (sender) =>
          GradeBrowseComponent.pesquisa(sender, this.filter, "2"),
      }),
      grupo: new CMInputConfig({
        form: this,
        caption: "Grupo",
        upper: true,
        visibleFunc: () => FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
        pesqFunc: (sender) => {
          GrupoDeProdutoBrowseComponent.pesquisa(sender, this.filter, "grupo");
        },
      }),
      subgrupo: new CMInputConfig({
        form: this,
        caption: "Sub-Grupo",
        upper: true,
        visibleFunc: () => FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
        pesqFunc: (sender) => {
          SubGrupoDeProdutoBrowseComponent.pesquisa(
            sender,
            this.filter,
            "subgrupo"
          );
        },
      }),
    };
    this.filter.apenasprodutoscomsaldo = "Não";
    this.filter.ativo = "Sim";
    this.filter.ordem = "A-Z";
  }

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }
}
