// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormBrowse2Component, CMFormBrowseComponent, CMFormGlobaisComponent, CMFormPesquisaComponent } from './../../@cmcore/component';
import { GradeEditComponent } from './grade-edit.component';
import { FuncsGlobais } from './../funcs.globais';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'grade-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class GradeBrowseComponent extends CMFormBrowse2Component {

  private sub: any;
  private indice: string;

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'grade';
    this.formEditComponent = GradeEditComponent;
    this.fixedFooterFormEdit = false;
    this.sub = this.route.params.subscribe(params => {
      if (params['indice']) {
        this.indice = params['indice'];
        this.columns = [
          {field: 'codigo', caption: 'Código', filter: true},
          {field: 'indice', caption: 'Indice', filter: true,
           filterVisible: false, visible: false, value: params['indice']},
       ];
       this.internalInit();
       this.refresh();
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getTitle() {
    return FuncsGlobais.paramEmpresa['nomeGrade'+this.indice];
  }

  static pesquisa(sender: any, target: any, indice: string) {
    if (target) {
      let caption: string = 'Pesquisa de Grade';
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'grade',
        caption,
        [{field: 'codigo', caption: 'Código', filter: true},
         {field: '$indice', caption: 'Indice', filter: true, filterVisible: false, visible: false, value: indice}
        ],
        target,
        'grade'+indice,
        'codigo',
        undefined,
        false);
    }
  }

  
  private static _valuesSelect = [];
  private static _inLoadValuesSelect = false;

  private static _valuesObjectSelect = {};

  private static loadValuesSelect(http: HttpClient, tipoGrade, onResult: Function) {
    if (!GradeBrowseComponent._inLoadValuesSelect) {
      CMFormBrowse2Component.postSubscribeT<Array<Object>>(http, '/api/grade/GetAllObject',
                    JSON.stringify({}),
              (condpag) => {
                  if ((condpag.length) && (condpag.length > 0)) {

                    condpag.forEach(element => {
                        GradeBrowseComponent._valuesObjectSelect[element['id']] = element;
                        GradeBrowseComponent._valuesSelect.push(element);
                    });

                    onResult(
                      condpag.sort((a, b) => {
                        let da = a["codigo"].toLowerCase();
                        let db = b["codigo"].toLowerCase();
                        return da > db ? 1 : db > da ? -1 : 0;
                      })
                    );
                  }
                return true;
              });
    }
  }

  static carregaGrades(http: HttpClient, tipoGrade, onResult: Function) {
    if (GradeBrowseComponent._valuesSelect.length == 0)
    GradeBrowseComponent.loadValuesSelect(http, tipoGrade, onResult);
  }

  static getGrades(){
    return GradeBrowseComponent._valuesSelect;
  }

  static addGrade(novagrade){
    GradeBrowseComponent._valuesSelect.push(novagrade);
  }

  static valuesObjectSelect() {
    return GradeBrowseComponent._valuesObjectSelect;
  }

  static clearValuesSelect() {
    GradeBrowseComponent._valuesSelect = [];
    GradeBrowseComponent._valuesObjectSelect = [];
  }

}