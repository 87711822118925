// tslint:disable
import { Component, Injector, Input } from "@angular/core";

import { CMFormEditComponent, CMInputConfig } from "./../../@cmcore/component";
import { AuthService } from "./auth.service";

@Component({
  selector: "login",
  templateUrl: "login.component.html",
  styleUrls: ["login.component.scss"],
})
export class LoginComponent extends CMFormEditComponent {
  static captionUsuario: string = "Usuário";
  static useEsqueceuSenha: boolean = false;
  static useCadastreSe: boolean = false;

  constructor(_injector: Injector, public authService: AuthService) {
    super(_injector);
    this.modelConfig = {
      login: new CMInputConfig({
        form: this,
        caption: LoginComponent.captionUsuario,
        required: true,
        maxlength: 50,
      }),
      senha: new CMInputConfig({
        form: this,
        caption: "Senha",
        required: true,
        maxlength: 15,
        criptografado: true,
      }),
    };

    let sub2 = this.route.queryParams.subscribe((params) => {
      let p: any = {};
      Object.assign(p, params);
      if (p.usuario) {
        this.model.login = p.usuario;
        p.usuario = undefined;
      }
      this.model.queryParams = p;
    });
  }

  get useEsqueceuSenha() {
    return LoginComponent.useEsqueceuSenha;
  }

  get useCadastreSe() {
    return LoginComponent.useCadastreSe;
  }

  login() {
    if (this.valid()) {
      this.authService.login(this.model);
    }
  }

  ngOnInit() {
    super.ngOnInit();
    this.authService.logoff();
  }

  protected getKeyDownCodes() {
    let r = super.getKeyDownCodes();
    r.push(13);
    return r;
  }

  protected onKeydown(keyCode: number, event: KeyboardEvent) {
    super.onKeydown(keyCode, event);
    switch (keyCode) {
      case 13: // enter
        this.login();
        break;
    }
  }
}
