// tslint:disable
import { Directive, ElementRef, Input, OnInit } from '@angular/core'

@Directive({
  selector: '[cmcolumn]'
})
export class CMColumnDirective implements OnInit {

  private innerColumn: number = 12;
  get column() {
    return this.innerColumn;
  }
  @Input('cmcolumn') 
  set column(value: number) {
    this.innerColumn = value;
    this.chekcColmun();
  }

  constructor(private element: ElementRef) {}

  ngOnInit() {
    this.chekcColmun();
  }

  chekcColmun() {
    if ((this.element) && (this.column > 0)) {
      let className: string = this.element.nativeElement.className;
      for (let i = 12; i > 0; i--) {
        className = className.replace('col s'+i, '');
        className = className.replace('col m'+i, '');
        className = className.replace('col l'+i, '');
      }
      className += ' col s12';
      className += ' col m'+this.column;
      className += ' col l'+this.column;
      this.element.nativeElement.className = className;
    }
  }

}