// tslint:disable
import { Component, Injector, ViewChild } from "@angular/core";

import { CMFormComponent } from "./../../@cmcore/component/cmform/cmform.component";

import {
  CMInputConfig,
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMGridEditConfigColumn,
  CMGridEditConfig,
} from "./../../@cmcore/component";
import { NFeConsignacaoBrowseComponent } from "./../index";
@Component({
  selector: "nfe-consignacao-fechamento",
  templateUrl: "nfe-consignacao-fechamento.component.html",
  styleUrls: ["nfe-consignacao-fechamento.component.scss"],
})
export class NFeConsignacaoFechamentoComponent extends CMFormComponent {
  _owner: NFeConsignacaoBrowseComponent = undefined;
  get owner() {
    return this._owner;
  }
  set owner(value) {
    this._owner = value;
    if (this.owner) {
      this.loadDados();
    }
  }

  configNFeConsignacao: any;
  dadosNFeConsignacao: any = {};

  setConfigNFeConsignacao() {
    this.configNFeConsignacao = {
      infnfe: {
        det: new CMGridEditConfig({
          form: this,
          objName: "det",
          caption: "Produtos",
          extraColumns: {
            produto_descr: new CMInputConfig({
              form: this,
              caption: "Descrição",
              gridConfig: new CMGridEditConfigColumn({
                ordem: 2,
                getValue: (row) =>
                  this.getValueObject(row, "prod.produto.descr"),
              }),
            }),
          },
          fields: {
            prod: {
              produto: new CMInputConfig({
                form: this,
                caption: "Produto",
                disabled: true,
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 1,
                  width: "350px",
                  readOnly: true,
                  getValue: (row) => {
                    return this.getValueObject(row, "prod.produto.codigo");
                  },
                }),
              }),
              qcom: new CMInputConfig({
                form: this,
                caption: "Quantidade",
                dataType: "double(2)",
                disabled: true,
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 3,
                }),
              }),
              qret: new CMInputConfig({
                form: this,
                caption: "Qtde Retorno",
                dataType: "double(2)",
                onExit: () => {
                  this.calcQtdePreVenda();
                },
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 3,
                }),
              }),
              qprevenda: new CMInputConfig({
                form: this,
                caption: "Qtde Pré-Venda",
                dataType: "double(2)",
                disabled: true,
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 3,
                }),
              }),
            },
          },
        }),
      },
    };
  }

  loadDados() {
    this.getObjectData(
      "NFeConsignacao",
      [{ field: "id", value: this.owner.idFormFechamentoConsignacao }],
      (dados) => {
        this.dadosNFeConsignacao = dados;
        let det = dados.infnfe.det;
        let newDet = [];
        det.forEach((item) => {
          if (
            item.prod.produto.usagrade1 == "Sim" ||
            item.prod.produto.usagrade2 == "Sim"
          ) {
            item.estoque.forEach((itemEstoque) => {
              let newItem: any = {
                prod: {},
              };
              newItem.id = item.id;
              newItem.idgrade = itemEstoque.grade.id;
              newItem.prod.produto = { ...item.prod.produto };
              newItem.prod.produto.descr += " " + itemEstoque.grade.descricao;
              newItem.prod.qcom = itemEstoque.qcom;
              newItem.prod.qret = "0,00";
              newItem.prod.vuncom = item.prod.vuncom;
              newItem.prod.qprevenda = itemEstoque.qcom;
              newDet.push(newItem);
            });
          } else {
            let newItem: any = {
              prod: {},
            };
            newItem.id = item.id;
            newItem.prod.produto = { ...item.prod.produto };
            newItem.prod.qcom = item.prod.qcom;
            newItem.prod.qret = "0,00";
            newItem.prod.vuncom = item.prod.vuncom;
            newItem.prod.qprevenda = item.prod.qcom;
            newDet.push(newItem);
          }
        });
        dados.infnfe.det = newDet;
      }
    );
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.setConfigNFeConsignacao();
    this.generateEmptyModel(
      this.configNFeConsignacao,
      this.dadosNFeConsignacao
    );
  }

  calcQtdePreVenda() {
    let item = this.configNFeConsignacao.infnfe.det.current;
    let qcom = this.valueToFloat(item.prod.qcom);
    let qret = this.valueToFloat(item.prod.qret);
    if (qret < 0) {
      qret = 0;
      item.prod.qret = this.floatToValue(qret);
    }
    if (qret > qcom) {
      qret = qcom;
      item.prod.qret = this.floatToValue(qret);
    }
    item.prod.qprevenda = this.floatToValue(qcom - qret);
  }

  confirmaGeracao() {
    CMFormGlobaisComponent.showConfirmation(
      "CONFIRMAÇÃO",
      "<p>Confirma o retorno da quantidade informada e a geração da Pré-Venda com a quantidade restante?</p>",
      () => {
        this.enviarRetorno();
      }
    );
  }

  enviarRetorno() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/NFeConsignacao/FechamentoConsignacao/FuncaoExtra",
      JSON.stringify({
        id: this.dadosNFeConsignacao.id,
        itens: this.dadosNFeConsignacao.infnfe.det,
      }),
      (ret) => {
        if (ret["result"] == "ok") {
          this.close("formFechamentoConsignacao");
          CMFormGlobaisComponent.toastSucesso(ret["msg"]);
        } else CMFormGlobaisComponent.showMessageAviso(ret["msg"]);
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

  close(formName) {
    if (this.owner) {
      this.owner.refresh();
      this.owner.beforeComponent = undefined;
    }
    CMFormModalComponent.close(formName);
  }
}
