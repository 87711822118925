// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMInputConfig, CMValidatorEmail,
         CMFormGlobaisComponent } from '../../../component';
import { CMFormComponent } from '../../../component/cmform/cmform.component';

@Component({
  selector: 'cmform-email',
  templateUrl: './cmform-email.component.html',
  styleUrls: ['./cmform-email.component.scss']
})
export class CMFormEmailComponent extends CMFormComponent {

  static open(urlPost: string, caption: string, data: any = {}) {
    let f = CMFormGlobaisComponent.createComponent(CMFormEmailComponent);
    f.setData(urlPost, caption, data);
    f.openModal();
  }

  getColumnsEmail() {
    if (this.showContatos)
      return '9';
    else
      return '12';
  }
  clickShowContatos() {
    this.showContatos = !this.showContatos;
  }
  showContatos: boolean = false;
  temListaContatos() {
    let r: boolean = false;
    if ((this.model) && (this.model.listacontatos))
      r = (this.model.listacontatos.length > 0);
    return r;
  }

  caption: string = '';
  urlPost: string = '';
  modelConfig: any = {};
  model: any = {};
  onSend: Function = undefined;

  constructor(_injector: Injector) {
    super(_injector);
    this.setModelConfig();
  }

  Teste() {
    CMFormEmailComponent.open('', this.model.para);
  }

  setData(urlPost: string, caption: string, data: any = {}) {
    this.caption = caption;
    this.urlPost = urlPost;
    if (data) {
      if (data.para !== undefined) {
        this.model.para = data.para;
        delete data.para;
      }
      if (data.cc !== undefined) {
        this.model.cc = data.cc;
        delete data.cc;
      }
      if (data.cco !== undefined) {
        this.model.cco = data.cco;
        delete data.cco;
        if (CMInputConfig.trimValue(this.model.cco) !== '')
          this.model.exibecco = 'Sim';
      }
      if (data.assunto !== undefined) {
        this.model.assunto = data.assunto;
        delete data.assunto;
      }
      if (data.mensagem !== undefined) {
        this.model.mensagem = data.mensagem;
        delete data.mensagem;
      }
      if (data.listacontatos !== undefined) {
        this.model.listacontatos = data.listacontatos;
        delete data.listacontatos;
      }
      if (data.onsend !== undefined) {
        this.onSend = data.onsend;
        delete data.onsend;
      }
    }
    let props = Object.getOwnPropertyNames(data);
    props.forEach(prop => {
      this.model[prop] = data[prop];
    });
    this.showContatos = this.temListaContatos();
    if (this.showContatos) {
      if (this.model.listacontatos.length === 1)
      this.showContatos = (this.model.para !== this.model.listacontatos[0].email);
    }
  }

  setModelConfig() {
    this.modelConfig = {
      para: new CMInputConfig({form: this,
                               caption: 'Para',
                               required: true,
                               customvalidators: [new CMValidatorEmail(true)],
      }),
      cc: new CMInputConfig({form: this,
                             caption: 'Cc',
                             customvalidators: [new CMValidatorEmail(true)],
      }),
      exibecco: new CMInputConfig({form: this,
                                   caption: 'Com cópia oculta',
      }),
      cco: new CMInputConfig({form: this,
                             caption: 'Cco',
                             visibleFunc: () => {
                               return this.model.exibecco === 'Sim';
                             },
                             customvalidators: [new CMValidatorEmail(true)],
      }),
      assunto: new CMInputConfig({form: this,
                                  caption: 'Assunto',
                                  required: true,
      }),
      mensagem: new CMInputConfig({form: this,
                                   caption: 'Mensagem',
                                   required: true,
      }),
    };
    this.generateEmptyModel(this.modelConfig, this.model);
  }

  enviarEmail() {
    if (this.valid(this.modelConfig, this.model)) {
      CMFormGlobaisComponent.showSpinner();
      CMFormGlobaisComponent.toastErro('Não testado em angular 8');
      console.error('Não testado em angular 8');      
      this.postSubscribe(this.urlPost,
                    JSON.stringify(this.model),
              (r) => {
                this.model.result = r;
                if (r['result'] === 'true') {
                  CMFormGlobaisComponent.toastSucesso(r['msg']);
                  if (this.onSend !== undefined)
                    this.onSend(this.model);
                  this.closeModal();
                }
                else {
                  let msg: string = r['msg'];
                  if (msg.startsWith('{') && msg.endsWith('}')) {
                    msg = JSON.parse(r['msg']);
                    CMFormGlobaisComponent.showMessageAviso(
                      '<pre style="padding-left: 30px;">'+JSON.stringify(msg, null, 2).replace(/\\r\\n/g, "<br>")+'</pre>'
                    );
                  }
                  else {
                    CMFormGlobaisComponent.showMessageAviso(
                      '<p style="padding-left: 30px;">'+msg+'</p>'
                    );
                  }
                }
                CMFormGlobaisComponent.hideSpinner();
              },
              error => {
                CMFormGlobaisComponent.hideSpinner();
                CMFormGlobaisComponent.showMessageError(error);
              });
    }
  }

  showAcoesContato(contato) {
    if (contato.showAcoes === 'Sim')
      contato.showAcoes = 'Não';
    else {
      this.model.listacontatos.forEach(contato => {
        contato.showAcoes = 'Não';
      });
      contato.showAcoes = 'Sim';
    }
  }

  addDestinatario(contato, tipo) {
    contato.showAcoes = 'Não';
    let tmp: string = this.trimValue(this.model[tipo]);
    if (tmp.indexOf(contato.email) === -1) {
      if (tmp !== '')
        tmp += ';';
        tmp += contato.email;
      this.model[tipo] = tmp;
    }
  }

}