// tslint:disable
import { Component, EventEmitter, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Md5 } from "ts-md5/dist/md5";

import { CMInput } from "../../decorator";
import { CMFormComponent } from "../../component/cmform/cmform.component";
import { AppComponent } from "./../../../app.component";

export class CMUploadFile {
  constructor(protected http: HttpClient, file: Blob, filename: string) {
    this.file = file;
    this.filename = filename;
    this.eventUpload.subscribe(() => {
      this.upload();
    });
  }

  showButtonEnviar: boolean = true;
  onValidUpload: any = undefined;
  onCompleteUpload: any = undefined;
  onStartUpload: any = undefined;
  onErrorUpload: any = undefined;

  doOnErrorUpload() {
    setTimeout(() => {
      if (this.onErrorUpload !== undefined) {
        this.onErrorUpload(this);
      }
    }, 100);
  }

  public file: Blob;
  public msg: any;
  private buffer: ArrayBuffer = new ArrayBuffer(0);
  private offset = 0;
  private eventUpload = new EventEmitter();
  public keyserver: string = "";
  public status: string = "";
  private filename: string;

  setMsg(msg: any) {
    this.msg = msg;
  }

  clear(clearInput: boolean = true) {
    this.clearVars();
    this.status = "";
    this.buffer = new ArrayBuffer(0);
    this.file = undefined;
  }

  clearVars() {
    this.offset = 0;
    this.keyserver = "";
    this.msg = "";
    this._perc = 0;
  }

  _perc = 0;
  progressUpload(atual, max, perc) {
    this._perc = perc;
  }

  startUpload() {
    setTimeout(() => {
      if (this.onStartUpload !== undefined) {
        this.onStartUpload(this);
      }
    }, 100);
    this.clearVars();
    this.status = "enviando";
    

    CMFormComponent.postSubscribe(
      this.http,
      AppComponent.basePathApi + "/api/startuploadfile",
      {
        name: this.filename,
        size: this.file.size,
        type: this.file.type,
      },
      (response: string) => {
        let ret: boolean = false;
        if (this.status === "enviando") {
          this.keyserver = response;
          ret = this.keyserver !== "";
          if (!ret) {
            this.msg = "Erro ao enviar inicio do arquivo";
            this.status = "error";
            this.doOnErrorUpload();
          } else this.eventUpload.emit();
        }
      },
      (error) => {
        console.error(error);
        this.msg = error;
        this.status = "error";
        this.doOnErrorUpload();
      },
      undefined,
      "text" as "text"
    );
  }

  endUpload() {
    new Response(this.file).arrayBuffer().then((value) => {
      let md5 = new Md5();
      let ui = new Uint8Array(value);
      md5.appendByteArray(ui);
      this.postEndUpload(md5.end());
    });
  }

  postEndUpload(md5) {
    CMFormComponent.postSubscribe(
      this.http,
      AppComponent.basePathApi + "/api/enduploadfile",
      {
        keyserver: this.keyserver,
        name: this.filename,
        size: this.file.size,
        type: this.file.type,
        md5file: md5,
      },
      (response: string) => {
        let ret: boolean = false;
        ret = response === "1" || response === "True";
        if (!ret) {
          console.error(response);
          this.msg = "Erro ao concluir envio do arquivo";
          this.status = "error";
          this.doOnErrorUpload();
        } else {
          this.status = "concluido";
          setTimeout(() => {
            if (this.onCompleteUpload !== undefined)
              this.onCompleteUpload(this);
          }, 100);
        }
      },
      (error) => {
        this.msg = error;
        this.status = "error";
        this.doOnErrorUpload();
      },
      undefined,
      "text" as "text"
    );
  }

  cancelUpload() {}

  uploadPart() {
    let perc = (this.offset * 100) / this.file.size;
    this.progressUpload(this.offset, this.file.size, perc);
    let part = Math.min(1024 * 32, this.file.size - this.offset);
    let tmp = new Uint8Array(this.buffer, this.offset, part);

    let base64 = btoa(String.fromCharCode.apply(null, tmp));
    let data = {
      hash: Md5.hashStr(base64),
      keyserver: this.keyserver,
      data: base64,
    };
    CMFormComponent.postSubscribe(
      this.http,
      AppComponent.basePathApi + "/api/uploadfilepart",
      data,
      (response: string) => {
        let ret: boolean = false;
        ret = response === "1" || response === "True";
        if (ret) {
          this.offset += part;
          this.eventUpload.emit();
        } else {
          console.error(response);
          this.msg = "Erro ao enviar parte do arquivo";
          this.status = "error";
          this.doOnErrorUpload();
        }
      },
      (error) => {
        this.msg = error;
        this.status = "error";
        this.doOnErrorUpload();
      },
      undefined,
      "text" as "text"
    );
  }

  upload() {
    if (this.status === "cancel") this.cancelUpload();
    else if (this.status === "enviando") {
      if (this.offset < this.file.size) {
        setTimeout(() => {
          this.uploadPart();
        }, 10);
      } else {
        this.progressUpload(this.offset, this.file.size, 100);
        this.endUpload();
      }
    }
  }

  enviar() {
    if (this.file !== undefined) {
      let valid: boolean = true;
      if (this.onValidUpload !== undefined) valid = this.onValidUpload(this);
      if (valid) {
        var fileReader = new FileReader();
        let _this = this;
        fileReader.onloadend = function (this: FileReader, ev: ProgressEvent) {
          let tmp: any = this.result;
          _this.buffer = tmp;
          setTimeout(() => {
            _this.startUpload();
          }, 500);
        };
        this.status = "preparando";
        fileReader.readAsArrayBuffer(this.file);
      }
    }
  }

  cancelar(event) {
    this.status = "cancel";
  }

  getStatusStr() {
    let r: string = this.status;
    if (this.status === "preparando") r = "Preparando";
    else if (this.status === "enviando") r = "Enviando";
    else if (this.status === "cancel") r = "Cancelado";
    else if (this.status === "error") r = "Erro";
    else if (this.status === "concluido") r = "Concluído";
    return r;
  }
}

@Component({
  selector: "cmupload-file",
  templateUrl: "cmupload-file.component.html",
})
export class CMUploadFileComponent {
  private _imageSrc: String;
  constructor(protected http: HttpClient) {}

  static globalId: number = 0;
  static nextGlobalId() {
    CMUploadFileComponent.globalId += 1;
    return CMUploadFileComponent.globalId;
  }

  name: string =
    "cmupload_file_" + CMUploadFileComponent.nextGlobalId().toString();

  @CMInput()
  @Input()
  acceptAudio: boolean = false;

  @CMInput()
  @Input()
  acceptVideo: boolean = false;

  @CMInput()
  @Input()
  acceptImage: boolean = false;

  @CMInput()
  @Input()
  acceptOtherTypes: string = "";

  @Input()
  accept: string = undefined;

  @CMInput()
  @Input()
  showButtonEnviar: boolean = true;

  @Input()
  onValidUpload: any = undefined;

  @Input()
  onCompleteUpload: any = undefined;

  @Input()
  onStartUpload: any = undefined;

  @Input()
  onErrorUpload: any = undefined;

  doOnErrorUpload() {
    setTimeout(() => {
      if (this.onErrorUpload !== undefined) this.onErrorUpload(this);
    }, 100);
  }

  @Input()
  onChange: any = undefined;

  @Input()
  form: any = undefined;

  @Input()
  tag: any = undefined;

  @Input()
  tag2: any = undefined;

  private file: any;
  public msg: any;
  public isVideo: boolean = false;

  @Input()
  public isImagem: boolean = false;

  @Input()
  public getImageSrc: any = undefined;



  @Input('imageSrc')
  set imageSrc(val: String){
    this._imageSrc = val;
    let imageNode: any = document.getElementById("imageNode" + this.name);
    if (val !== "") imageNode.src = this._imageSrc;
    $(imageNode).on("error", function() {
      $(imageNode).hide();
    })
    
  }

  public isAudio: boolean = false;
  private buffer: ArrayBuffer = new ArrayBuffer(0);
  private offset = 0;
  private eventUpload = new EventEmitter();
  public keyserver: string = "";
  public status: string = "";

  setMsg(msg: any) {
    this.msg = msg;
  }

  clear(clearInput: boolean = true) {
    this.clearVars();
    let imageNode: any = document.getElementById("imageNode" + this.name);
    let videoNode: any = document.getElementById("videoNode" + this.name);
    let audioNode: any = document.getElementById("audioNode" + this.name);
    imageNode.src = "";
    videoNode.src = "";
    audioNode.src = "";
    this.status = "";
    this.buffer = new ArrayBuffer(0);
    this.isVideo = false;
    this.isImagem = false;
    this.isAudio = false;
    this.file = undefined;
    if (clearInput) $("#innerInputFile" + this.name).val("");
  }

  clearVars() {
    this.offset = 0;
    this.keyserver = "";
    this.msg = "";
    this._perc = 0;
  }

  change(event) {
    let imageNode: any = document.getElementById("imageNode" + this.name);
    let videoNode: any = document.getElementById("videoNode" + this.name);
    let audioNode: any = document.getElementById("audioNode" + this.name);
    this.clear(false);
    if (event.target.files.length > 0) {
      let URL = window.URL;
      this.file = event.target.files[0];
      let src = URL.createObjectURL(this.file);
      if (this.file.size === 0) {
        event.target.value = "";
        this.file = undefined;
        this.msg = "Tamanho de arquivo inválido";
      } else if (this.acceptImage && this.file.type.match(/image.*/)) {
        this.isImagem = true;
        imageNode.style.display = "inline-block";
        imageNode.src = src;
      } else if (this.acceptVideo && this.file.type.match(/video.*/)) {
        this.isVideo = true;
        videoNode.src = src;
      } else if (this.acceptAudio && this.file.type.match(/audio.*/)) {
        this.isAudio = true;
        audioNode.src = src;
      } else {
        let ok: boolean = true;
        if (this.acceptOtherTypes !== "") {
          ok = this.file.type !== "";
          if (ok) ok = this.acceptOtherTypes.indexOf(this.file.type) > -1;
        }
        if (!ok) {
          event.target.value = "";
          this.file = undefined;
          this.msg = "Formato de arquivo não suportado";
        }
      }
    }
    setTimeout(() => {
      if (this.onChange !== undefined) this.onChange(this);
    }, 100);
  }

  progressUpload(atual, max, perc) {
    this._perc = perc;
  }

  _perc = 0;
  getProgressStyle() {
    return "width: " + this._perc + "%";
  }

  ngOnInit() {
    this.eventUpload.subscribe(() => {
      this.upload();
    });

  }

  startUpload() {
    setTimeout(() => {
      if (this.onStartUpload !== undefined) this.onStartUpload(this);
    }, 100);
    this.clearVars();
    this.status = "enviando";
    CMFormComponent.postSubscribe(
      this.http,
      AppComponent.basePathApi + "/api/startuploadfile",
      {
        name: this.file.name,
        size: this.file.size,
        type: this.file.type,
      },
      (response: string) => {
        let ret: boolean = false;
        if (this.status === "enviando") {
          this.keyserver = response;
          ret = this.keyserver !== "";
          if (!ret) {
            this.msg = "Erro ao enviar inicio do arquivo";
            this.status = "error";
            this.doOnErrorUpload();
          } else this.eventUpload.emit();
        }
      },
      (error) => {
        console.error(error);
        this.msg = error;
        this.status = "error";
        this.doOnErrorUpload();
      },
      undefined,
      "text" as "text"
    );
  }

  endUpload() {
    CMFormComponent.postSubscribe(
      this.http,
      AppComponent.basePathApi + "/api/enduploadfile",
      {
        keyserver: this.keyserver,
        name: this.file.name,
        size: this.file.size,
        type: this.file.type,
      },
      (response: string) => {
        let ret: boolean = false;
        ret = response === "1" || response === "True";
        if (!ret) {
          this.msg = "Erro ao concluir envio do arquivo";
          this.status = "error";
          this.doOnErrorUpload();
        } else {
          this.status = "concluido";
          setTimeout(() => {
            if (this.onCompleteUpload !== undefined)
              this.onCompleteUpload(this);
          }, 100);
        }
      },
      (error) => {
        this.msg = error;
        this.status = "error";
        this.doOnErrorUpload();
      },
      undefined,
      "text" as "text"
    );
  }

  cancelUpload() {}

  uploadPart() {
    let perc = (this.offset * 100) / this.file.size;
    this.progressUpload(this.offset, this.file.size, perc);
    let part = Math.min(1024 * 32, this.file.size - this.offset);
    let tmp = new Uint8Array(this.buffer, this.offset, part);

    let base64 = btoa(String.fromCharCode.apply(null, tmp));
    let data = {
      hash: Md5.hashStr(base64),
      keyserver: this.keyserver,
      data: base64,
    };
    CMFormComponent.postSubscribe(
      this.http,
      AppComponent.basePathApi + "/api/uploadfilepart",
      data,
      (response: string) => {
        let ret: boolean = false;
        ret = response === "1" || response === "True";
        if (ret) {
          this.offset += part;
          this.eventUpload.emit();
        } else {
          console.error(response);
          this.msg = "Erro ao enviar parte do arquivo";
          this.status = "error";
          this.doOnErrorUpload();
        }
      },
      (error) => {
        this.msg = error;
        this.status = "error";
        this.doOnErrorUpload();
      },
      undefined,
      "text" as "text"
    );
  }

  upload() {
    if (this.status === "cancel") this.cancelUpload();
    else if (this.status === "enviando") {
      if (this.offset < this.file.size) {
        setTimeout(() => {
          this.uploadPart();
        }, 10);
      } else {
        this.progressUpload(this.offset, this.file.size, 100);
        this.endUpload();
      }
    }
  }

  enviar() {
    if (this.file !== undefined) {
      let valid: boolean = true;
      if (this.onValidUpload !== undefined) valid = this.onValidUpload(this);
      if (valid) {
        var fileReader = new FileReader();
        let _this = this;
        fileReader.onloadend = function (this: FileReader, ev: ProgressEvent) {
          let tmp: any = this.result;
          _this.buffer = tmp;
          setTimeout(() => {
            _this.startUpload();
          }, 500);
        };
        this.status = "preparando";
        fileReader.readAsArrayBuffer(this.file);
      }
    }
  }

  cancelar(event) {
    this.status = "cancel";
  }

  getVisibleButtonEnviar() {
    let r: boolean = false;
    if (this.showButtonEnviar)
      r = this.status !== "enviando" && this.status !== "preparando";
    return r;
  }

  statusNaoEnviando() {
    let r: boolean = false;
    r = this.status !== "enviando" && this.status !== "preparando";
    return r;
  }

  getStatusStr() {
    let r: string = this.status;
    if (this.status === "preparando") r = "Preparando";
    else if (this.status === "enviando") r = "Enviando";
    else if (this.status === "cancel") r = "Cancelado";
    else if (this.status === "error") r = "Erro";
    else if (this.status === "concluido") r = "Concluído";
    return r;
  }

  selectFile() {
    $("#innerInputFile" + this.name).click();
  }

  getFileName() {
    let r = "Selecione um arquivo";
    if (this.IsFileSelect) r = this.file.name;
    return r;
  }

  get IsFileSelect() {
    return this.file !== undefined;
  }
}
