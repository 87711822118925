// tslint:disable
import { Component, ViewChild, Injector } from "@angular/core";
import { FuncsGlobais } from "../funcs.globais";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridV2ListColumns,
} from "./../../@cmcore/component";

@Component({
  selector: "minha-conta",
  templateUrl: "minha-conta.component.html",
})
export class MinhaContaComponent extends CMFormEditComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "MinhaConta";
    this.modelConfig = {
      senhaatual: new CMInputConfig({
        form: this,
        caption: "Senha Atual",
        required: true,
        maxlength: 15,
        criptografado: true,
        minlength: 6,
      }),
      novasenha: new CMInputConfig({
        form: this,
        caption: "Nova Senha",
        required: true,
        maxlength: 15,
        criptografado: true,
        minlength: 6,
      }),
      novasenhaconfirmacao: new CMInputConfig({
        form: this,
        caption: "Confirmação de Senha",
        required: true,
        maxlength: 15,
        criptografado: true,
        minlength: 6,
      }),
    };
    this.getDadosAssinatura();
  }

  get modoOperacao() {
    return FuncsGlobais.paramEmpresa.modoOperacao;
  }

  dadosAssinatura: any = {};
  dadosAssinaturaModel = {
    numerocartao: new CMInputConfig({
      form: this,
      caption: "Numero do Cartão",
      maxlength: 16,
      required: true,
      dataType: "integer",
    }),
    validadecartao: new CMInputConfig({
      form: this,
      caption: "Validade (MM/AAAA)",
      mask: "99/9999",
      required: true,
    }),
    bandeiracartao: new CMInputConfig({
      form: this,
      caption: "Bandeira",
      values: [
        { value: " ", text: "Não Informada" },
        { value: "Visa" },
        { value: "Master", text: "Master Card" },
        { value: "Amex", text: "American Express" },
        { value: "Elo" },
        { value: "Aura" },
        { value: "JCB" },
        { value: "Diners", text: "Diners Club" },
        { value: "Discover" },
        { value: "Hipercard" },
        { value: "Hiper" },
      ],
      required: true,
    }),
    nomecartao: new CMInputConfig({
      form: this,
      caption: "Nome impresso no cartão",
      required: true,
    }),
    codigosegurancaocartaro: new CMInputConfig({
      form: this,
      caption: "Cód de Segurança",
      maxlength: 4,
      dataType: "integer",
      required: true,
    }),
    faturas: new CMGridV2ListColumns({
      columns: [
        {
          field: "dvencimento",
          header: "Data Vencimento",
        },
        {
          field: "valor",
          header: "Valor",
        },
        {
          field: "dhpagamento",
          header: "Pagamento",
        },
        {
          field: "tid",
          header: "TID",
        },
        {
          field: "displaynumber",
          header: "Cartão Utilizado",
          buttons: [
            {
              icon: "credit_card",
              hint: "Pagar agora",
              onClick: (row: any) => {
                this.pagarFatura(row);
              },
              visible: (row: any) => {
                return this.trimValue(row.dhpagamento) == "";
              },
            },
          ],
        },
      ],
    }),
  };

  getDadosAssinatura() {
    if (this.modoOperacao === "Nivel2") {
      CMFormGlobaisComponent.showSpinner();
      this.postSubscribe(
        "/api/minhaconta/DadosAssinatura",
        {},
        (result) => {
          if (result["result"] === "ok") {
            this.dadosAssinatura = result;
            this.dadosAssinatura.bandeiracartao = " ";
          }
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          CMFormGlobaisComponent.showMessageError(error);
        }
      );
    }
  }

  customValid() {
    let r: string = super.customValid();
    if (r === "") {
      if (this.model.novasenha !== this.model.novasenhaconfirmacao)
        r = "Senha e confirmação de senha não são iguais";
    }
    return r;
  }

  alterarSenha() {
    if (this.valid()) {
      CMFormGlobaisComponent.showSpinner();
      this.postSubscribe(
        "/api/minhaconta/alterarsenha",
        JSON.stringify(this.model),
        (result) => {
          if (result["result"] === "ok") {
            CMFormGlobaisComponent.toastSucesso("Senha alterada");
          } else {
            let str: string = '<p class="divider"></p><div class="container">';
            str += "<p><b>Mensagem:</b> " + result["result"] + "</p>";
            str += '</div><p class="divider"></p>';
            CMFormGlobaisComponent.showMessageAviso(str);
          }
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          CMFormGlobaisComponent.showMessageError(error);
        }
      );
    }
  }

  salvarCartao() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/minhaconta/salvarcartao",
      JSON.stringify({
        numerocartao: this.dadosAssinatura.numerocartao,
        validadecartao: this.dadosAssinatura.validadecartao,
        bandeiracartao: this.dadosAssinatura.bandeiracartao,
        nomecartao: this.dadosAssinatura.nomecartao,
        codigosegurancaocartaro: this.dadosAssinatura.codigosegurancaocartaro,
      }),
      (result) => {
        console.log(result);
        if (result["result"] === "ok") {
          window.location.reload();
        } else {
          let str: string = '<p class="divider"></p><div class="container">';
          str += "<p><b>Mensagem:</b> " + result["result"] + "</p>";
          str += '</div><p class="divider"></p>';
          CMFormGlobaisComponent.showMessageAviso(str);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

  inPagarFatura() {
    let r = this.router.url.indexOf('pagarfatura') > -1;
    return r;
  }

  pagarFatura(row) {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/minhaconta/pagarFatura",
      JSON.stringify({
        idfatura: row.id,
      }),
      (result) => {
        console.log(result);
        if (result["result"] === "ok") {
          CMFormGlobaisComponent.showConfirmation(
            "Ir para tela de Login",
            "<p>Para que as alterações sejam aplicadas, é necessário realizar o login novamente.</p>" +
              "<p>Deseja realizar agora?</p>",
            () => {
              window.location.assign("/login");
            }
          );
        } else {
          let str: string = '<p class="divider"></p><div class="container">';
          str += "<p><b>Mensagem:</b> " + result["result"] + "</p>";
          str += '</div><p class="divider"></p>';
          CMFormGlobaisComponent.showMessageAviso(str);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }
}
