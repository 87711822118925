// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "../../@cmcore/component/cmform/cmform.component";

import {
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMGridEditConfig,
  CMInputConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
} from "../../@cmcore/component";
import {
    PreVendaBrowseComponent,
} from "../index";
@Component({
  selector: "prevenda-relatorios",
  templateUrl: "prevenda-relatorios.component.html",
  styleUrls: ["prevenda-relatorios.component.scss"],
})
export class PreVendaRelatoriosComponent extends CMFormComponent {
  _owner: PreVendaBrowseComponent = undefined;

  get owner() {
    return this._owner;
  }

  private static relatoriosCustomizados: any = [];

  static addRelatorioCustomizado(item: any){
    PreVendaRelatoriosComponent.relatoriosCustomizados.push(item);
  }

  static getRelatorioCustomizados(){
    return PreVendaRelatoriosComponent.relatoriosCustomizados;
  }

  teste = 0;

  dados = {
    tiporelatorio: "PreVendaPadrao",
  };

  config = {
    tiporelatorio: new CMInputConfig({
                                    form: this,
                                    values: [
                                      {value: "PreVendaPadrao", text: "Pré-Venda Padrão"},
                                    ],
                                  }),
  };

  Imprimir(){
    
    if (this.dados.tiporelatorio === "PreVendaPadrao"){
        let data = { id: this.owner.grid.fieldByName(this.owner.fieldNameId()) };
        console.log(data);
        data = this.owner.getDataPrint(data);
        this.showRelat(
          "/Print",
          data,
          "prevenda"
        );
    }
    else{
      let data = { 
        id: this.owner.grid.fieldByName(this.owner.fieldNameId()),
        idrelatorio: this.dados.tiporelatorio
      };
      console.log(data);
      data = this.owner.getDataPrint(data);
      this.showRelat(
        "/ImpressaoPreVendaCustomizado",
        data,
        "prevenda"
      );
      
    }
  }

  podeCadastrarProduto: boolean = false;
  set owner(value) {
    this._owner = value;
  }

  constructor(_injector: Injector) {
    super(_injector);
    console.log('construtor');
    this.config.tiporelatorio.values.push.apply(this.config.tiporelatorio.values, PreVendaRelatoriosComponent.relatoriosCustomizados)
  }


  close(formName) {
    if (this.owner) this.owner.beforeComponent = undefined;
    CMFormModalComponent.close(formName);
  }
}
