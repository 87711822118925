// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "../../@cmcore/component/cmform/cmform.component";

import {
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMGridEditConfig,
  CMInputConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
} from "../../@cmcore/component";
import {
    NFeBrowseComponent,
} from "../index";
@Component({
  selector: "produto-relatorios",
  templateUrl: "produto-relatorios.component.html",
  styleUrls: ["produto-relatorios.component.scss"],
})
export class ProdutoRelatoriosComponent extends CMFormComponent {
  _owner: NFeBrowseComponent = undefined;

  get owner() {
    return this._owner;
  }

  private static relatoriosCustomizados: any = [];
  static isLista = false;

  static addRelatorioCustomizado(item: any){
    ProdutoRelatoriosComponent.relatoriosCustomizados.push(item);
  }

  static getRelatorioCustomizados(){
    return ProdutoRelatoriosComponent.relatoriosCustomizados;
  }


  dados = {
    tiporelatorio: "ProdutoEtiquetaPadrao",
  };

  config = {
    tiporelatorio: new CMInputConfig({
                                    form: this,
                                    values: [
                                      {value: "ProdutoEtiquetaPadrao", text: "Etiqueta Padrão"},
                                    ],
                                  }),
  };

  Imprimir(){
    
    if (this.dados.tiporelatorio === "ProdutoEtiquetaPadrao"){

      if (!ProdutoRelatoriosComponent.isLista){

      }
      else{

        let pessoa;
        //console.log(this.owner.valuesFilters);
  
        if (this.owner.valuesFilters.hasOwnProperty("fornecedor")){
        pessoa = this.owner.valuesFilters.fornecedor.id;
        }
        else {
        pessoa = 0;
        }
        
        this.showRelat(
        "ImprimeEtiquetas/FuncaoExtra",
        {
            listaetiqueta: 2,
            pessoa,
            codigoproduto: this.owner.valuesFilters.codigo,
            codigofabricante: this.owner.valuesFilters.codigofabricante,
            descricao: this.owner.valuesFilters.descr,
            ean: this.owner.valuesFilters.ean
        },
        "produto"
        );
      }

    }
    else{

      if (!ProdutoRelatoriosComponent.isLista){
        
      }
      else {
        let pessoa;
        //console.log(this.owner.valuesFilters);
  
        if (this.owner.valuesFilters.hasOwnProperty("fornecedor")){
        pessoa = this.owner.valuesFilters.fornecedor.id;
        }
        else {
        pessoa = 0;
        }
        
        this.showRelat(
        "ImpressaoProdutoEtiquetaCustomizada/FuncaoExtra",
        {
            listaetiqueta: 2,
            pessoa,
            codigoproduto: this.owner.valuesFilters.codigo,
            codigofabricante: this.owner.valuesFilters.codigofabricante,
            descricao: this.owner.valuesFilters.descr,
            ean: this.owner.valuesFilters.ean,
            idrelatorio: this.dados.tiporelatorio,
        },
        "produto"
        );
      }
    }
  }

  podeCadastrarProduto: boolean = false;
  set owner(value) {
    this._owner = value;
  }

  constructor(_injector: Injector) {
    super(_injector);
    console.log('construtor');
    this.config.tiporelatorio.values.push.apply(this.config.tiporelatorio.values, ProdutoRelatoriosComponent.relatoriosCustomizados)
  }


  close(formName) {
    if (this.owner) this.owner.beforeComponent = undefined;
    CMFormModalComponent.close(formName);
  }
}
