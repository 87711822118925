// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "./../../../@cmcore/component/cmform/cmform.component";
import {
  CMPanelConfigMore,
  CMFormGlobaisComponent,
  CMPanelComponent,
} from "./../../../@cmcore/component";
import { FuncsGlobais } from "../../funcs.globais";

@Component({
  templateUrl: "home-destaques.component.html",
  styleUrls: ["home-destaques.component.scss"],
})
export class HomeDestaquesComponent extends CMFormComponent {
  blogInfo = {
    img: "",
    href: "",
    titulo: "",
    texto: "",
  };

  currentAnotacao = {
    id: 0,
    titulo: "",
    descricao: "",
  };

  configShowAnotacao: CMPanelConfigMore;

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.configJSON.htmlatualizacoes = "";
    this.configJSONPath = "configgeral.json";
    this.configShowAnotacao = new CMPanelConfigMore({
      title: "Anotação",
      maxWidth: "300px",
      onSave: () => this.onSaveAnotacao(),
      onAction: (action) => this.onActionAnotacao(action),
      hintAction1: "Apagar",
      iconAction1: "delete",
    });
  }

  anotacoes = [];

  ngOnInit() {
    super.ngOnInit();
    this.loadAnotacoes();
    this.loadBlogInfo();
  }

  inLoadBlogInfo: boolean = true;
  loadBlogInfo() {
    
    this.inLoadBlogInfo = true;

    this.blogInfo.titulo = 'Todas as quintas-feiras às 17:00 realizamos um podcast sobre empreendedorismo sempre com um convidado especial!';
    this.blogInfo.texto = 'Todas as quintas-feiras às 17:00 realizamos um podcast sobre empreendedorismo sempre com um convidado especial!';
    this.blogInfo.href = 'https://www.youtube.com/@BessaniCast/featured';

    this.inLoadBlogInfo = false;
    /*
    this.http
      .get<Array<Object>>(
        "https://www.bessani.com.br/carousel_blog_bessani_last3.php"
      )
      .subscribe((datajson) => {
        if (datajson && datajson.length && datajson.length > 0) {
          this.blogInfo.img = datajson[0]["img"];
          this.blogInfo.href = datajson[0]["href"];
          this.blogInfo.titulo = datajson[0]["titulo"];
          this.blogInfo.texto = datajson[0]["texto"].replace(
            /\.|&hellip;/g,
            "..."
          );
        }
        this.inLoadBlogInfo = false;
      });
    */
  }

  inLoadAnotacoes: boolean = true;
  loadAnotacoes() {
    this.inLoadAnotacoes = true;
    this.postSubscribeT<Array<Object>>(
      "/api/MinhaConta/Anotacoes",
      JSON.stringify({}),
      (response) => {
        this.anotacoes = response;
        this.inLoadAnotacoes = false;
      },
      (error) => {
        this.showMessageErrorHTTP("loadAnotacoes", error);
        this.inLoadAnotacoes = false;
      }
    );
  }

  postAnotacao() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/MinhaConta/SaveAnotacao",
      JSON.stringify(this.currentAnotacao),
      (res) => {
        if (res["result"] == "ok") {
          if (this.currentAnotacao.id == 0) {
            this.currentAnotacao.id = res["id"];
            this.anotacoes.push(this.currentAnotacao);
          }
          this.currentAnotacao = {
            id: 0,
            titulo: "",
            descricao: "",
          };
          this.configShowAnotacao.allowCancel = true;
          this.configShowAnotacao.showAction1 = false;
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        this.showMessageErrorHTTP("postAnotacao", error);
        CMFormGlobaisComponent.hideSpinner();
      }
    );
  }

  postDeleteAnotacao() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/MinhaConta/DeleteAnotacao",
      JSON.stringify(this.currentAnotacao),
      (res) => {
        if (res["result"] == "ok") {
          const index = this.anotacoes.indexOf(this.currentAnotacao, 0);
          if (index > -1) {
            this.anotacoes.splice(index, 1);
          }
          this.currentAnotacao = {
            id: 0,
            titulo: "",
            descricao: "",
          };
          this.configShowAnotacao.allowCancel = true;
          this.configShowAnotacao.showAction1 = false;
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        this.showMessageErrorHTTP("postDeleteAnotacao", error);
        CMFormGlobaisComponent.hideSpinner();
      }
    );
  }

  onSaveAnotacao() {
    if (this.trimValue(this.currentAnotacao.titulo) == "") {
      CMFormGlobaisComponent.toastInformacao("Titulo não informado");
      return false;
    } else {
      this.postAnotacao();
      return true;
    }
  }

  onActionAnotacao(action) {
    if (action == "action1") {
      CMFormGlobaisComponent.showConfirmation(
        "Apagar Anotação",
        'Confirma a deleção da anotação "' + this.currentAnotacao.titulo + '"',
        () => {
          this.postDeleteAnotacao();
        }
      );
    }
    return true;
  }

  editAnotacao(anotacao, panel_anotacao: CMPanelComponent) {
    this.currentAnotacao = anotacao;
    this.configShowAnotacao.allowCancel = false;
    this.configShowAnotacao.showAction1 = true;
    panel_anotacao.clickHelpText();
  }

  getHeightAnotacoes() {
    if (this.isRole("AssociadoN2")) {
      return "calc(100% - 40% - 1rem)";
    } else {
      return "calc(100% - 20% - 150px - 2rem)";
    }
  }

  getHeightOqueHaNovo() {
    if (this.isRole("AssociadoN2")) {
      return "40%";
    } else {
      return "20%";
    }
  }
}
