import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "app-maxusers-confirm",
  templateUrl: "maxusers-confirm.component.html"
})
export class MaxUsersConfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<MaxUsersConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
