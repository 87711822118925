// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMInputConfig,
} from "./../../@cmcore/component";

@Component({
  selector: "fechamento-caixa-edit",
  templateUrl: "fechamento-caixa-edit.component.html",
})
export class FechamentoCaixaEditComponent extends CMFormEditComponent {
  getTitle() {
    return "Fechamento de Caixa";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "fechamentocaixa";
    this.setModelConfig();
  }
  setModelConfig() {
    this.modelConfig = {
      numerocaixa: new CMInputConfig({
        form: this,
        caption: "Número do Caixa",
        disabled: true,
      }),
      dataabertura: new CMInputConfig({
        form: this,
        caption: "Data",
        disabled: true,
      }),
      horaabertura: new CMInputConfig({
        form: this,
        caption: "Hora",
        disabled: true,
      }),
      usuarioabertura: new CMInputConfig({
        form: this,
        caption: "Usuário",
        disabled: true,
      }),
      tipofechamento: new CMInputConfig({
        form: this,
        caption: "Tipo",
        disabled: true,
      }),
      datafechamento: new CMInputConfig({
        form: this,
        caption: "Data",
        disabled: true,
      }),
      horafechamento: new CMInputConfig({
        form: this,
        caption: "Hora",
        disabled: true,
      }),
      usuariofechamento: new CMInputConfig({
        form: this,
        caption: "Usuário",
        disabled: true,
      }),
      movimentos: new CMGridEditConfig({
        form: this,
        caption: "Movimentos",
        fields: {
          dhmovimento: new CMInputConfig({
            form: this,
            caption: "Data e Hora",
            disabled: true,
            gridConfig: new CMGridEditConfigColumn(),
          }),
          tipo: new CMInputConfig({
            form: this,
            caption: "Tipo",
            disabled: true,
            values: [
              { value: "Sangria" },
              { value: "Suprimento" },
              { value: "ValorFechamento", text: "Valor de Fechamento" },
            ],
            gridConfig: new CMGridEditConfigColumn(),
          }),
          valor: new CMInputConfig({
            form: this,
            caption: "Valor",
            dataType: "double(2)",
            disabled: true,
            gridConfig: new CMGridEditConfigColumn(),
          }),
          usuario: new CMInputConfig({
            form: this,
            caption: "Usuário",
            disabled: true,
            gridConfig: new CMGridEditConfigColumn({
              getValue: (row) => {
                return this.getValueObject(row, "usuario.nome");
              },
            }),
          }),
          observacao: new CMInputConfig({
            form: this,
            caption: "Observação",
            disabled: true,
            gridConfig: new CMGridEditConfigColumn(),
          }),
        },
      }),
      customdata: {
        vtotalvenda: new CMInputConfig({
          form: this,
          caption: "Valor Total de Vendas",
          dataType: "double(2)",
          disabled: true,
        }),
        vsuprimento: new CMInputConfig({
          form: this,
          caption: "Valor Total de Suprimento",
          dataType: "double(2)",
          disabled: true,
        }),
        vsangria: new CMInputConfig({
          form: this,
          caption: "Valor Total de Sangria",
          dataType: "double(2)",
          disabled: true,
        }),
        vvalorfechamento: new CMInputConfig({
          form: this,
          caption: "Valor de Fechamento",
          dataType: "double(2)",
          disabled: true,
        }),
        pagamentos: new CMGridEditConfig({
          form: this,
          caption: "Pagamentos",
          fields: {
            descricao: new CMInputConfig({
              form: this,
              caption: "Descrição",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn(),
            }),
            vtotal: new CMInputConfig({
              form: this,
              caption: "Valor",
              dataType: "double(2)",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn(),
            }),
          },
        }),
      },
    };
  }
}
