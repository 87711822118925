// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent } from './../../@cmcore/component';
import { EmpresaPrecoEditComponent } from './empresapreco-edit.component';

@Component({
  selector: 'cest-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class EmpresaPrecoBrowseComponent extends CMFormBrowse2Component {

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'EmpresaPreco';
    this.formEditComponent = EmpresaPrecoEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = [{field: 'nome', caption: 'Nome', filter: true},
                   ];
  }

  getTitle() {
    return 'Preços';
  }

  static pesquisa(sender: any, target: any, targetProp: any = undefined) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "empresapreco",
        "Tabelas de Preços",
        [{field: 'nome', caption: 'Nome', filter: true},],
        target,
        targetProp,
        "ordem",
        undefined,
        false
      );
    }
  }

}