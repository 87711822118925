// tslint:disable
import { Injector, Component } from "@angular/core";
import { MarketplaceBrowseComponent } from "../marketplace";

import {
  CMFormBrowse2Component,
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMFormPesquisaComponent,
  CMInputConfig,
} from "./../../@cmcore/component";
import {
  NFeImportacaoMarketplaceEditComponent,
  PessoaBrowseComponent,
  PreVendaBrowseComponent,
} from "./../index";

@Component({
  selector: "nfe-importacao-marketplace-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class NFeImportacaoMarketplaceBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "nfeimportacaomarketplace";
    this.buttonsExtra = 2;
    this.formEditComponent = NFeImportacaoMarketplaceEditComponent;
    this.columns = [
      {
        field: "nnf",
        caption: "#",
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "dhemi",
        caption: "Data",
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {
        field: "pessoa_nome",
        caption: "Cliente",
        filterField: "pessoa.id",
        filterValue: "pessoa.codigo",
        fieldBottom: "pessoa.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            PessoaBrowseComponent.pesquisa(
              sender,
              this.valuesFilters,
              "Cliente"
            );
          },
        }),
      },
      {
        field: "marketplace_nome",
        caption: "Marketplace",
        filterField: "marketplace.id",
        filterValue: "marketplace.codigo",
        fieldBottom: "marketplace.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            MarketplaceBrowseComponent.pesquisa(sender, this.valuesFilters);
          },
        }),
      },
      {
        field: "chaveacesso",
        caption: "Número no Marketplace",
        filter: true,
      },
      {
        field: "prevenda_numero",
        caption: "Pré-Venda",
        filterField: "prevenda.id",
        filterValue: "prevenda.numero",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            PreVendaBrowseComponent.pesquisa(sender, this.valuesFilters);
          },
        }),
      },
    ];
  }

  getTitle() {
    return "Importação de Marketplace";
  }

  buttonsExtraClick(index: number) {
    if (index === 0) {
      this.importar();
    } else if (index === 1) {
      this.gerarPreVenda();
    }
  }

  loadDireitoButtonExtra(index: number) {
    if (index === 0) {
      this.getDireitosMenuBrowseButtonsExtra(index, "Importar");
    } else if (index === 1) {
      this.getDireitosMenuBrowseButtonsExtra(index, "GerarPreVenda");
    }
  }

  buttonsExtraIcon(index: number) {
    let r: string = "";
    if (index === 0) {
      r = "cloud_download";
    } else if (index === 1) {
      r = "account_balance";
    }
    return r;
  }

  buttonsExtraHint(index: number) {
    let r: string = "";
    if (index === 0) {
      r = "Importar";
    } else if (index === 1) {
      r = "Gerar Pré-Venda";
    }
    return r;
  }

  buttonsExtraPosicao(index: number) {
    let r: string = super.buttonsExtraPosicao(index);
    if (index === 0) {
      r = "grid";
    }
    return r;
  }

  importar() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/nfeimportacaomarketplace/Importar/FuncaoExtra",
      JSON.stringify({}),
      (ret) => {
        if (ret["result"]) {
          CMFormGlobaisComponent.showMessage(
            "Importação em andamento",
            ret["result"]
          );
        } else console.log(ret);
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("importar", error);
      }
    );
  }

  gerarPreVenda() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/nfeimportacaomarketplace/gerarPreVenda/FuncaoExtra",
      JSON.stringify({ id: this.grid.fieldByName("id") }),
      (ret) => {
        CMFormGlobaisComponent.hideSpinner();
        if (ret["result"] === "ok") {
          CMFormGlobaisComponent.toastSucesso(ret["msg"]);
          this.refresh();
        } else {
          CMFormGlobaisComponent.toastErro(ret["msg"]);
        }
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("importar", error);
      }
    );
  }
}
