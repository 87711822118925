// tslint:disable
import { Component, Injector } from '@angular/core';
import { PessoaBrowseComponent } from '../pessoa';

import { CMFormEditComponent, CMInputConfig, CMFormGlobaisComponent
       } from './../../@cmcore/component';
import { ContaBancariaBrowseComponent } from './conta-bancaria-browse.component';
@Component({
    selector: 'conta-bancaria-edit',
    templateUrl: 'conta-bancaria-edit.component.html',
})
export class ContaBancariaEditComponent extends CMFormEditComponent {

    getFirstComponentName() {
      return 'model_descricao';
    }
  
    getTitle() {
      return 'Conta Bancária';
    }
  
    constructor(_injector: Injector) {
      super(_injector);
      this.controllerName = 'ContaBancaria';
      this.setModelConfig();
    }

    setModelConfig() {
      this.modelConfig = {
        codigo: new CMInputConfig({form: this,
                                   caption: '#',
                                   disabled: true}),
        descricao: new CMInputConfig({form: this,
                                      caption: 'Descrição',
                                      maxlength: 100,
                                      required: true}),
        sped: new CMInputConfig({form: this,
                                      caption: 'Gerar Sped Fiscal - Registro 1601 - Boleto, Pix, Cart. Crédito, Cart. Débito'}),
        codigobanco: new CMInputConfig({
          form: this,
          required: true,
          caption: "Código do Banco",
          dataType: "integer",
        }),
        pessoa: new CMInputConfig({
          form: this,
          caption: "Pessoa",
          pesqFunc: (sender) => {
            PessoaBrowseComponent.pesquisaContador(sender, this.model, "pessoa");
          },
        }),
        ativo: new CMInputConfig({form: this,
                                  caption: 'Ativo',}),
      };
    }
  
}  
