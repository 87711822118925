// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormEditComponent, CMInputConfig, CMGridComponent,
         CMListCheckBoxConfig, CMFormGlobaisComponent
       } from './../../../@cmcore/component';
import { RecursoBrowseComponent } from '.';

@Component({
    selector: 'grupo-usuario-edit',
    templateUrl: 'grupo-usuario-edit.component.html',
})
export class GrupoUsuarioEditComponent extends CMFormEditComponent {

  getFirstComponentName() {
    return 'model_nome';
  }

  getTitle() {
    return 'Grupo de Usuário';
  }

  todosRecursos = [];

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'grupousuario';
    RecursoBrowseComponent.getTodosRecursos(this.http,
      (dados) => {
        this.todosRecursos = dados;
    });
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      id: new CMInputConfig({form: this,
                             caption: '#',
                             disabled: true}),
      nome: new CMInputConfig({form: this,
                               caption: 'Nome',
                               maxlength: 255,
                               required: true}),
      ativo: new CMInputConfig({form: this,
                                caption: 'Ativo'}),
      recursos: new CMListCheckBoxConfig({form: this,
                                          listFunc: () => {return this.todosRecursos},
                                          caption: "Recursos",
                                          propValue: 'descricao',
                                          subProp: "recurso",
                                          columns: 1,
                                          striping: true,
                                          onSelect: (item) => {
                                            if (item.id == undefined)
                                              item.id = CMGridComponent.newId();
                                            item.permissoes = Object.assign([], item.recurso.permissoes);
                                          },
                                          nodeList: new CMListCheckBoxConfig({
                                            form: this,
                                            columns: 1,
                                            showBorder: false,
                                            listFunc: (nodeItem, nodeValue) => {
                                              if (nodeItem)
                                                return nodeItem.permissoes;
                                              else
                                                return [];
                                            },
                                            visibleFunc: (nodeItem, nodeValue) => {
                                              if ((nodeValue) && (nodeValue.permissoes) && (nodeValue.permissoes.length > 1))
                                                return true;
                                              else
                                                return false;
                                            },
                                            onGetValue: (nodeItem, nodeValue) => {
                                              if (nodeValue)
                                                return nodeValue.permissoes;
                                              else
                                                return [];
                                            },
                                            onSetValue: (nodeItem, nodeValue, value) => {
                                              if (nodeValue)
                                                nodeValue.permissoes = value ;
                                            }
                                          })
                                        })
    };
  }

}