// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormRelatComponent, CMInputConfig,
         CMFormGlobaisComponent
       } from './../../../@cmcore/component';
import { PreVendaBrowseComponent } from './../../prevenda/prevenda-browse.component';
import { NFeBrowseComponent } from './../../nfe/nfe-browse.component';

@Component({
    selector: 'boleto',
    templateUrl: 'boleto.component.html'
})
export class RelatBoletoComponent extends CMFormRelatComponent {

  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = 'ImpressaoBoletoCarne';
    this.filterConfig = {
      dataini: new CMInputConfig({form: this,
                                  caption: 'Data Inicial',
                                  mask: '99/99/9999',
				    											disabledFunc: () => {
		    														return this.filter.origem !== 'Todos';
    															},
                                 }),
      datafim: new CMInputConfig({form: this,
                                  caption: 'Data Final',
                                  mask: '99/99/9999',
				    											disabledFunc: () => {
		    														return this.filter.origem !== 'Todos';
    															},
                                 }),

      prevenda: new CMInputConfig({form: this,
                                   caption: 'Pré-Venda',
				      										 disabledFunc: () => {
		     													   return this.filter.origem !== 'Pré-Venda';
     															 },
                                   pesqFunc: sender => {
                                     PreVendaBrowseComponent.pesquisa(sender, this.filter);
                                   }
                                 }),
      nfe: new CMInputConfig({form: this,
                              caption: 'NF-e',
															disabledFunc: () => {
																return this.filter.origem !== 'NF-e';
															},
                              pesqFunc: sender => {
                                NFeBrowseComponent.pesquisa(sender, this.filter);
                              }
                             }),
      origem: new CMInputConfig({form: this,
                                 caption: 'Origem',
  															 values: [{'value': 'Pré-Venda'}, {'value': 'NF-e'}, {'value': 'Todos'}]
                                })
    }
		this.filter.origem = 'Pré-Venda';
    this.filter.dataini = this.firstDate();
    this.filter.datafim = this.lastDate();
  }

  valid() {
	console.log(this.filter);
    let r = super.valid();
		if (this.filter.origem === 'Pré-Venda') {
			if (r) {
				r = !this.isEmpty(this.getValueObject(this.filter, 'prevenda.id'));
				if (!r)
					CMFormGlobaisComponent.toastErro('Pré-Venda não informada');
			}
		}
		else if (this.filter.origem === 'NF-e') {
			if (r) {
				r = !this.isEmpty(this.getValueObject(this.filter, 'nfe.id'));
				if (!r)
					CMFormGlobaisComponent.toastErro('NF-e não informada');
			}
		}
		else if (this.filter.origem === 'Todos') {
			if (r) {
				r = !this.isEmpty(this.filter.dataini);
				if (!r)
					CMFormGlobaisComponent.toastErro('Data inicial não informada');
			}
			if (r) {
				r = !this.isEmpty(this.filter.datafim);
				if (!r)
					CMFormGlobaisComponent.toastErro('Data final não informada');
			}
		}
    return r;
  }

}