// tslint:disable
import { Component, Injector, ViewChild } from "@angular/core";

import {
  CMInputConfig,
  CMFormExecComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMFormGlobaisComponent,
  CMUploadFileComponent,
  CMGridV2ListColumns,
  CMGridV2EditType,
  CMGridV2EditDataType,
} from "./../../@cmcore/component";
import { FuncsGlobais } from "../funcs.globais";
import { ProdutoBrowseComponent } from "../produto";
import {
  GrupoDeProdutoBrowseComponent,
  SubGrupoDeProdutoBrowseComponent,
} from "..";

@Component({
  templateUrl: "ajuste-saldo-component.html",
  styleUrls: ["ajuste-saldo-component.scss"],
})
export class AjusteSaldoComponent extends CMFormExecComponent {
  @ViewChild("fileUpLoad", { static: true }) fileUpLoad: CMUploadFileComponent;
  constructor(_injector: Injector) {
    super(_injector);
    this.filterConfig = {
      filtro: {
        produto: new CMInputConfig({
          form: this,
          caption: "Produto",
          maxlength: 30,
          padInfo: { size: 30 },
          pesqFunc: (sender) => {
            ProdutoBrowseComponent.pesquisa(sender, this.filter.filtro, "Ativos");
          },
        }),
        importar: new CMInputConfig({
          form: this,
          caption: "Importar Saldos de uma Planilha",
          dataType: "boolean",
        }),
        apenasprodutoscomsaldo: new CMInputConfig({
          form: this,
          caption: "Apenas produtos com saldo",
        }),
        grupo: new CMInputConfig({
          form: this,
          caption: "Grupo",
          upper: true,
          visibleFunc: () =>
            FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
          pesqFunc: (sender) => {
            GrupoDeProdutoBrowseComponent.pesquisa(
              sender,
              this.filter.filtro,
              "grupo"
            );
          },
        }),
        subgrupo: new CMInputConfig({
          form: this,
          caption: "Sub-Grupo",
          upper: true,
          visibleFunc: () =>
            FuncsGlobais.paramEmpresa.modoOperacao === "Nivel2",
          pesqFunc: (sender) => {
            SubGrupoDeProdutoBrowseComponent.pesquisa(
              sender,
              this.filter.filtro,
              "subgrupo"
            );
          },
        }),
      },
      dados: {
        produtos: new CMGridV2ListColumns({
          columns: [
            {
              field: "codigo",
              header: "Código",
            },
            {
              field: "descr",
              header: "Descrição",
            },
            {
              field: "descricaograde",
              header: "Grade",
              visible: FuncsGlobais.paramEmpresa.usaGrade,
            },
            {
              field: "datavalidadelote",
              header: "Validade do Lote",
              visible: FuncsGlobais.paramEmpresa.usaLote,
            },
            {
              field: "numerolote",
              header: "Lote",
              visible: FuncsGlobais.paramEmpresa.usaLote,
            },
            {
              field: "saldoatual",
              header: "Saldo Atual",
              dataType: CMGridV2EditDataType.DOUBLE,
            },
            {
              field: "ajustar",
              header: "Ajustar",
              editType: CMGridV2EditType.CHECKBOX,
            },
            {
              field: "novosaldo",
              header: "Novo Saldo",
              enabled: true,
              dataType: CMGridV2EditDataType.DOUBLE,
              decimals: 2,
            },
          ],
        }),
      },
    };
    this.filter.filtro.apenasprodutoscomsaldo = "Não";
    this.filter.dados.produtos = [];
  }

  innerExec() {
    super.innerExec();
    this.geraAjusteSaldo();
  }

  loadProdutos() {
    this.importou = false;
    this.mostraResultado = false;
    CMFormGlobaisComponent.showSpinner();

    this.postSubscribe(
      "/api/NFeEstoque/GetProdutosAjusteSaldo",
      JSON.stringify(this.filter.filtro),
      (response) => {
        this.filter.dados.produtos = response;
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("loadProdutos", error);
      }
    );
  }

  geraAjusteSaldo() {
    this.importou = false;
    this.mostraResultado = false;
    CMFormGlobaisComponent.showSpinner();
    let produtos = [];
    this.filter.dados.produtos.forEach((produto) => {
      if (produto.ajustar === "Sim") {
        produtos.push({
          idproduto: produto.idproduto,
          idgrade: produto.idgrade,
          numerolote: produto.numerolote,
          saldoatual: produto.saldoatual,
          novosaldo: produto.novosaldo,
        });
      }
    });

    if (produtos.length == 0) {
      CMFormGlobaisComponent.hideSpinner();
      CMFormGlobaisComponent.toastErro(
        "Ao menos um produto deve ser selecionado para ajustar"
      );
    } else {
      this.postSubscribe(
        "/api/NFeEstoque/AjusteSaldo/FuncaoExtra",
        JSON.stringify(produtos),
        (result) => {
          if (result["result"] === "ok") {
            this.filter.dados.produtos = [];
            CMFormGlobaisComponent.toastSucesso(
              "Movimento gerados com sucesso."
            );
          } else {
            CMFormGlobaisComponent.toastErro(result["msg"]);
          }
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("geraAjusteSaldo", error);
        }
      );
    }
  }

  onCompleteUploadFile(sender: any) {
    this.importou = false;
    this.mostraResultado = false;
    CMFormGlobaisComponent.hideSpinner();
    sender.form.filter.fileuploadkeyserver = sender.keyserver;
    sender.form.filter.fileuploadname = sender.file.name;
    sender.form.importar();
  }

  objectKeys = Object.keys;
  naoimportados: any = {};
  importou: boolean = false;
  mostraResultado: boolean = false;

  importar() {
    this.fileUpLoad.setMsg("Processamento arquivo no servidor");
    let filter = this.filter;
    CMFormGlobaisComponent.showSpinner();

    this.postSubscribe(
      "/api/NFeEstoque/ImportarAjusteSaldo/FuncaoExtra",
      JSON.stringify(filter),
      (result) => {
        if (result["result"] === "ok") {
          this.fileUpLoad.setMsg(result["msg"]);
          this.filter.dados.produtos = result["produtos"];
          //CMFormGlobaisComponent.toastSucesso(result["msg"]);
          this.mostraResultado = result["naoimportados"] !== undefined;
          if (this.mostraResultado) {
            this.naoimportados = result["naoimportados"];
          } else {
            this.naoimportados = {};
          }
          this.importou = true;
        } else {
          CMFormGlobaisComponent.toastErro(result["msg"]);
          this.fileUpLoad.setMsg(result["msg"]);
        }
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        this.showMessageErrorHTTP("importar", error);
      }
    );
  }
}
