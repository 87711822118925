// tslint:disable
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent } from './../../@cmcore/component';
import { MunicipioEditComponent } from './municipio-edit.component';

@Component({
  selector: 'municipio-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class MunicipioBrowseComponent extends CMFormBrowse2Component {

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'municipio';
    this.formEditComponent = MunicipioEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = [{field: 'codigomunicipio', caption: 'Cód. do IBGE', width: '120'},
                    {field: 'municipio',   caption: 'Município', filter: true},
                    {field: 'estado', caption: 'Estado',  width: '100'}];
  }

  getTitle() {
    return 'Município';
  }

  static pesquisa(estado: string, sender: any, target: any, targetProp?: string) {
    if (target) {
      let caption: string = 'Pesquisa de Municípios';
      estado = this.trimValue(estado);
      if (estado !== '')
        caption += ' do estado '+estado;

      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'municipio',
        caption,
        [{field: 'codigomunicipio', caption: 'Cód. do IBGE',  width: '120'},
         {field: 'municipio',   caption: 'Município', filter: true},
         {field: 'estado', caption: 'Estado', value: estado,  width: '100'}],
        target,
        targetProp,
        'codigomunicipio');
    }
  }

}