// tslint:disable
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent, CMInputConfig } from './../../@cmcore/component';
import { ConfiguracaoBoletoEditComponent } from './configuracao-boleto-edit.component';
import { HttpClient } from '@angular/common/http';
import { FuncsGlobais } from '../funcs.globais';

@Component({
  selector: 'configuracao-boleto-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class ConfiguracaoBoletoBrowseComponent extends CMFormBrowse2Component {

  static defaultColumns = [{ field: 'codigo', caption: '#', filter: true },
  { field: 'descricao', caption: 'Descrição', filter: true },
  { field: 'banco', caption: 'Banco', filter: true },
  { field: 'carteira', caption: 'Carteira', filter: true },
  { field: 'agencia', caption: 'Agência', filter: true },
  { field: 'digitoagencia', caption: 'DV', filter: true },
  { field: 'contacorrente', caption: 'Conta Corrente', filter: true },
  { field: 'digitocc', caption: 'DV', filter: true },
  { field: 'tiporemessa', caption: 'Tipo de Integração', filter: true },
  ];


  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'ConfiguracaoBoleto';
    this.formEditComponent = ConfiguracaoBoletoEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = ConfiguracaoBoletoBrowseComponent.defaultColumns;
  }

  getTitle() {
    return 'Configurações de Boletos Bancários';
  }

  private static _valuesBanco = [];
  private static _valuesTipoCobranca = [];
  private static _valuesResponEmissao = [];
  private static _valuesLayoutRemessa = [];

  private static _inLoadValuesBanco = false;
  private static _inLoadValuesTipoCobranca = false;
  private static _inLoadValuesResponEmissao = false;
  private static _inLoadValuesLayoutRemessa = false;

  private static _valuesObjectBanco = {};
  private static _valuesObjectTipoCobranca = {};
  private static _valuesObjectResponEmissao = {};
  private static _valuesObjectLayoutRemessa = {};

  private static loadValuesBanco(http: HttpClient) {
    if (!ConfiguracaoBoletoBrowseComponent._inLoadValuesBanco) {
      ConfiguracaoBoletoBrowseComponent._inLoadValuesBanco = true;
      CMFormBrowse2Component.postSubscribeT<Array<Object>>(
        http,
        '/api/ConfiguracaoBoleto/GetBanco',
        JSON.stringify({}),
        (codbanco) => {
          if ((codbanco.length) && (codbanco.length > 0)) {
            codbanco.sort(function (a, b) {
              if (a['descricao'] > b['descricao']) {
                return 1;
              }
              if (a['descricao'] < b['descricao']) {
                return -1;
              }
              return 0;
            });
            ConfiguracaoBoletoBrowseComponent._valuesBanco.push({
              value: 0,
              text: ' '
            });
            codbanco.forEach(element => {
              ConfiguracaoBoletoBrowseComponent._valuesObjectBanco[element['id']] = element;
              ConfiguracaoBoletoBrowseComponent._valuesBanco.push({
                value: element['id'],
                text: element['descricao']
              });
            });
          }
          ConfiguracaoBoletoBrowseComponent._inLoadValuesBanco = false;
          return true;
        });
    }
  }

  private static loadValuesTipoCobranca(http: HttpClient) {
    if (!ConfiguracaoBoletoBrowseComponent._inLoadValuesTipoCobranca) {
      ConfiguracaoBoletoBrowseComponent._inLoadValuesTipoCobranca = true;
      CMFormBrowse2Component.postSubscribeT<Array<Object>>(
        http,
        '/api/ConfiguracaoBoleto/GetTipoCobranca',
        JSON.stringify({}),
        (tipocobranca) => {
          if ((tipocobranca.length) && (tipocobranca.length > 0)) {
            tipocobranca.sort(function (a, b) {
              if (a['descricao'] > b['descricao']) {
                return 1;
              }
              if (a['descricao'] < b['descricao']) {
                return -1;
              }
              return 0;
            });
            ConfiguracaoBoletoBrowseComponent._valuesTipoCobranca.push({
              value: 0,
              text: ' '
            });
            tipocobranca.forEach(element => {
              ConfiguracaoBoletoBrowseComponent._valuesObjectTipoCobranca[element['id']] = element;
              ConfiguracaoBoletoBrowseComponent._valuesTipoCobranca.push({
                value: element['id'],
                text: element['descricao']
              });
            });
          }
          ConfiguracaoBoletoBrowseComponent._inLoadValuesTipoCobranca = false;
          return true;
        });
    }
  }

  private static loadValuesResponEmissao(http: HttpClient) {
    if (!ConfiguracaoBoletoBrowseComponent._inLoadValuesResponEmissao) {
      ConfiguracaoBoletoBrowseComponent._inLoadValuesResponEmissao = true;
      CMFormBrowse2Component.postSubscribeT<Array<Object>>(
        http,
        '/api/ConfiguracaoBoleto/GetResponEmissao',
        JSON.stringify({}),
        (codresponemissao) => {
          if ((codresponemissao.length) && (codresponemissao.length > 0)) {
            codresponemissao.sort(function (a, b) {
              if (a['descricao'] > b['descricao']) {
                return 1;
              }
              if (a['descricao'] < b['descricao']) {
                return -1;
              }
              return 0;
            });
            ConfiguracaoBoletoBrowseComponent._valuesResponEmissao.push({
              value: 0,
              text: ' '
            });
            codresponemissao.forEach(element => {
              ConfiguracaoBoletoBrowseComponent._valuesObjectResponEmissao[element['id']] = element;
              ConfiguracaoBoletoBrowseComponent._valuesResponEmissao.push({
                value: element['id'],
                text: element['descricao']
              });
            });
          }
          ConfiguracaoBoletoBrowseComponent._inLoadValuesResponEmissao = false;
          return true;
        });
    }
  }

  private static loadValuesLayoutRemessa(http: HttpClient) {
    if (!ConfiguracaoBoletoBrowseComponent._inLoadValuesLayoutRemessa) {
      ConfiguracaoBoletoBrowseComponent._inLoadValuesLayoutRemessa = true;
      CMFormBrowse2Component.postSubscribeT<Array<Object>>(
        http,
        '/api/ConfiguracaoBoleto/GetLayoutRemessa',
        JSON.stringify({}),
        (codlayoutremessa) => {
          if ((codlayoutremessa.length) && (codlayoutremessa.length > 0)) {
            codlayoutremessa.sort(function (a, b) {
              if (a['descricao'] > b['descricao']) {
                return 1;
              }
              if (a['descricao'] < b['descricao']) {
                return -1;
              }
              return 0;
            });
            ConfiguracaoBoletoBrowseComponent._valuesLayoutRemessa.push({
              value: 0,
              text: ' '
            });
            codlayoutremessa.forEach(element => {
              ConfiguracaoBoletoBrowseComponent._valuesObjectLayoutRemessa[element['id']] = element;
              ConfiguracaoBoletoBrowseComponent._valuesLayoutRemessa.push({
                value: element['id'],
                text: element['descricao']
              });
            });
          }
          ConfiguracaoBoletoBrowseComponent._inLoadValuesLayoutRemessa = false;
          return true;
        });
    }
  }

  static valuesBanco(http: HttpClient) {
    if (ConfiguracaoBoletoBrowseComponent._valuesBanco.length == 0)
      ConfiguracaoBoletoBrowseComponent.loadValuesBanco(http);
    return ConfiguracaoBoletoBrowseComponent._valuesBanco;
  }

  static valuesTipoCobranca(http: HttpClient) {
    if (ConfiguracaoBoletoBrowseComponent._valuesTipoCobranca.length == 0)
      ConfiguracaoBoletoBrowseComponent.loadValuesTipoCobranca(http);
    return ConfiguracaoBoletoBrowseComponent._valuesTipoCobranca;
  }

  static valuesResponEmissao(http: HttpClient) {
    if (ConfiguracaoBoletoBrowseComponent._valuesResponEmissao.length == 0)
      ConfiguracaoBoletoBrowseComponent.loadValuesResponEmissao(http);
    return ConfiguracaoBoletoBrowseComponent._valuesResponEmissao;
  }

  static valuesLayoutRemessa(http: HttpClient) {
    if (ConfiguracaoBoletoBrowseComponent._valuesLayoutRemessa.length == 0)
      ConfiguracaoBoletoBrowseComponent.loadValuesLayoutRemessa(http);
    return ConfiguracaoBoletoBrowseComponent._valuesLayoutRemessa;
  }

  static valuesObjectSelect(tipo: string) {
    if (tipo == 'banco')
      return ConfiguracaoBoletoBrowseComponent._valuesObjectBanco
    else if (tipo == 'tipocobranca')
      return ConfiguracaoBoletoBrowseComponent._valuesObjectTipoCobranca
    else if (tipo == 'responemissao')
      return ConfiguracaoBoletoBrowseComponent._valuesObjectResponEmissao
    else if (tipo == 'layoutremessa')
      return ConfiguracaoBoletoBrowseComponent._valuesObjectLayoutRemessa;
  }

  static pesquisa(
    sender: any,
    target: any,
    targetProp?: string,
    onAfterSelect?: Function,
    extraFilters?: Object,
    extraColumns?: Array<any>
  ) {
    if (target) {
      let columns = [
        { field: "codigo", caption: "#", filter: true },
        {
          field: "descricao",
          caption: "Descricao",
          filter: new CMInputConfig({
            maxlength: 60,
          }),
        },
        {
          field: "tiporemessa",
          caption: "Tipo de Integração",
          values: [
            { value: "0", text: "Arquivo Remessa" },
            { value: "1", text: "API- Online" },      
          ]
        },
       
      ];
      if (extraColumns) {
        extraColumns.forEach((column) => {
          columns.push(column);
        });
      }
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "configuracaoboleto",
        "Pesquisa de Configuração de Boleto",
        columns,
        target,
        targetProp,
        "codigo",
        onAfterSelect,
        undefined,
        undefined,
        extraFilters
      );
    }
  }
}
