// tslint:disable
import {Injector, Component} from '@angular/core';

import {CMFormBrowse2Component, CMFormModalComponent, CMFormPesquisaComponent, CMInputConfig} from './../../@cmcore/component';
import {OrdemCompraEditComponent} from './ordem-compra-edit.component';
import {PessoaBrowseComponent} from './../pessoa/pessoa-browse.component';
import { OrdemCompraRelatoriosComponent } from "../ordem-compra-relatorios/ordem-compra-relatorios.component";

@Component({
  selector: 'ordem-compra-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class OrdemCompraBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'OrdemCompra';
    this.formEditComponent = OrdemCompraEditComponent;
    this.fixedFooterFormEdit = false;
    this.showPrintButton = true;
    this.showEmailButton = true;
    this.columns = [
      {
        field: 'numero',
        caption: '#',
        width: 100,
        filter: new CMInputConfig({
          dataType: 'integer'
        })
      },
      {
        field: 'data',
        caption: 'Data',
        width: 130,
        filter: new CMInputConfig({
          mask: '99/99/9999'
        })
      },
      {
        field: 'fornecedor_nome',
        caption: 'Fornecedor',
        filterField: 'fornecedor.id',
        filterValue: 'fornecedor.codigo',
        fieldBottom: 'fornecedor.nome',
        filter: new CMInputConfig({
          dataType: 'integer',
          pesqFunc: (sender) => {
            PessoaBrowseComponent.pesquisa(sender, this.valuesFilters, 'Fornecedor');
          }
        })
      },
      {
        field: 'status',
        caption: 'Status',
        filter: new CMInputConfig({
          values: [{value: ''}, {value: 'Aberta'}, {value: 'Recebida'}, {value: 'Cancelada'}]
        })
      }
    ];
    if (OrdemCompraRelatoriosComponent.getRelatorioCustomizados().length === 0){
      this.getRelatoriosCustomizados();
    }
    console.log(OrdemCompraRelatoriosComponent.getRelatorioCustomizados());
  }

  
  _print() {
    console.log('method override')
    if (this.diretoImprimir) this.print();
  }
  
  print() {
    console.log('method overriden');
    if (OrdemCompraRelatoriosComponent.getRelatorioCustomizados().length > 0){
      this.openFormRelatoriosOrdemCompra();
    }
    else{
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat("Print", data, this.controllerName);
    }
  }

  
  getRelatoriosCustomizados(){
    this.postSubscribeT<Array<Object>>(
      "/api/ordemcompra/GetRelatoriosCustomizados",
      JSON.stringify({}),
      (result) => {
        result.forEach(element => {
          if (element['tipo'] === 'OrdemCompra'){
            OrdemCompraRelatoriosComponent.addRelatorioCustomizado({value: element['id'], text: element['descricao']});
          }
        });
      },
    );
  }
  
  openFormRelatoriosOrdemCompra() {
    this.beforeComponent = OrdemCompraRelatoriosComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formRelatoriosOrdemCompra");
    }, 100);
  }

  getTitle() {
    return 'Ordem de Compra';
  }

  getDataPrint(data) {
    data.numero = this.grid.fieldByName('numero');
    return data;
  }

  static pesquisa(sender: any, target: any, targetProp?: string, onAfterSelect?: Function) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'OrdemCompra',
        'Pesquisa de Ordem de Compra',
        [
          {field: 'numero', caption: '#'},
          {field: 'fornecedor_nome', caption: 'Fornecedor', filter: true},
          {field: 'data', caption: 'Data', filter: true, mask: '99/99/9999'}
        ],
        target,
        targetProp,
        'numero',
        onAfterSelect
      );
    }
  }
}
