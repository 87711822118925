// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMFormPesquisaComponent,
  CMInputConfig,
} from "./../../@cmcore/component";
import {
  NFeTrocaProdutoEditComponent,
  PessoaBrowseComponent,
} from "./../index";

@Component({
  selector: "nfe-troca-produto-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class NFeTrocaProdutoBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "nfetrocaproduto";
    this.showPrintButton = true;
    this.formEditComponent = NFeTrocaProdutoEditComponent;
    this.columns = [
      {
        field: "nnf",
        caption: "#",
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "dhemi",
        caption: "Data",
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {
        field: "pessoa_nome",
        caption: "Cliente",
        filterField: "pessoa.id",
        filterValue: "pessoa.codigo",
        fieldBottom: "pessoa.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            PessoaBrowseComponent.pesquisa(
              sender,
              this.valuesFilters,
              "Cliente"
            );
          },
        }),
      },
      {
        field: "vendedor_nome",
        caption: "Vendedor",
        filterField: "vendedor.id",
        filterValue: "vendedor.codigo",
        fieldBottom: "vendedor.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            PessoaBrowseComponent.pesquisa(
              sender,
              this.valuesFilters,
              "Vendedor",
              "vendedor"
            );
          },
        }),
      },
      {
        field: "cupomdesconto",
        caption: "Cupom de desconto",        
      }
    ];
  }

  getTitle() {
    return "Troca de Produtos";
  }
}
