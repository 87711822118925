// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormEditComponent, CMInputConfig, CMFormGlobaisComponent, CMINPUT_CONTROL_VALUE_ACCESSOR
       } from './../../@cmcore/component';
import { ConfiguracaoBoletoBrowseComponent } from './configuracao-boleto-browse.component';
import { ContaBancariaBrowseComponent } from "../conta-bancaria";
@Component({
    selector: 'configuracao-boleto-edit',
    templateUrl: 'configuracao-boleto-edit.component.html',
})
export class ConfiguracaoBoletoEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return 'model_descricao';
  }

  getTitle() {
    return 'Configurações de Boletos Bancário';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'ConfiguracaoBoleto';
    this.setValuesAceite();
    this.setValuesTipoCarteira();
    this.setValuesTipoDocumento();
    this.setValuesJurosRemessa();
    this.setValuesRetornoLiquidacao();
    this.setValuesTipoIntegracao();
    this.setValuesAmbienteDestino();
    this.setModelConfig();
  }

  valuesAceite = [];
  valuesTipoCarteira = [];
  valuesTipoDocumento = [];
  valuesJurosRemessa = [];
  valuesRetornoAlterarValor = [];
  valuesRetornoLiquidacao = [];
  valuesTipoIntegracao = [];
  valuesAmbienteDestino = [];

  setValuesAceite() {
    this.valuesAceite = [
      { value: "S", text: "Sim" },
      { value: "N", text: "Não" },
    ];
  } 

  setValuesTipoCarteira() {
    this.valuesTipoCarteira = [
      { value: "S", text: "Simples" },
      { value: "R", text: "Registrada" },
    ];
  }   

  setValuesTipoDocumento() {
    this.valuesTipoDocumento = [
      { value: "0", text: "Não Aplicável" },
      { value: "1", text: "Tradicional" },
      { value: "2", text: "Escritural" },
    ];
  }
  
  setValuesJurosRemessa() {
    this.valuesJurosRemessa = [
      { value: "1", text: "Valor por Dia" },
      { value: "2", text: "Taxa Mensal" },
      { value: "3", text: "Isento" },
    ];
  } 
  
  setValuesRetornoAlterarValor() {
    this.valuesRetornoAlterarValor = [
      { value: "S", text: "Sim" },
      { value: "N", text: "Não" },
    ]
  }

  setValuesRetornoLiquidacao() {
    this.valuesRetornoLiquidacao = [
      { value: "1", text: "Dt. Crédito" },
      { value: "2", text: "Dt. Ocorrência" },
      { value: "3", text: "Dt. Baixa" },
    ]
  }  

  setValuesTipoIntegracao() {
    this.valuesTipoIntegracao = [
      { value: "0", text: "Arquivo Remessa" },
      { value: "1", text: "API- Online" },      
    ];
  }
  
  setValuesAmbienteDestino() {
    this.valuesAmbienteDestino = [
      { value: "0", text: "Homologação" },
      { value: "1", text: "Produção" },
    ];
  } 
  
  onFolderSelected(event: any) {
    const fileList: FileList = event.target.files;
    console.log('fileList: ', fileList);

    if (fileList.length > 0) {
      const selectedFolder = fileList[0];
      console.log('Pasta Selecionada: ', selectedFolder);
    }
  }

  mostraModeloBoleto() {
    if (this.model.dirarqrelatorio && this.model.dirarqrelatorio.showbutton)
      return true;
    else return this.trimValue(this.model.filename) === "";
  }

  reenviarRelatorio() {
    this.model.dirarqrelatorio = {
      showbutton: true,
    };
  }

  getUrlRelat() {
    return (
      "/api/" +
      this.controllerName +
      "/GetDBFileGroup/" +
      this.model.id +
      "/" +
      this.model.filename +
      "/_self"
    );
  }

  onCompleteUploadFile(sender: any) {
    sender.form.model.dirarqrelatorio = {
      keyserver: sender.keyserver,
      filename: sender.file.name,
      showbutton: true,
    };
    CMFormGlobaisComponent.hideSpinner();
  }

  onStartUploadFile(sender: any) {
    CMFormGlobaisComponent.showSpinner();
  }

  onErrorUploadFile(sender: any) {
    CMFormGlobaisComponent.hideSpinner();
  }  

  setModelConfig() {
    this.modelConfig = {
      codigo: new CMInputConfig({
        form: this,
        caption: "#",
        disabled: true
      }),
      descricao: new CMInputConfig({
        form: this,
        caption: "Descrição",
        maxlength: 100,
      }),
      banco: new CMInputConfig({
        form: this,
        caption: "Banco",
        values: ConfiguracaoBoletoBrowseComponent.valuesBanco(this.http),
        valuesObject: ConfiguracaoBoletoBrowseComponent.valuesObjectSelect("banco"),
      }),
      agencia: new CMInputConfig({
        form: this,
        caption: "Agência",
        maxlength: 15,
        required: true
      }),
      digitoagencia: new CMInputConfig({
        form: this,
        caption: "Dígito",
        maxlength: 1,
      }), 
      contacorrente: new CMInputConfig({
        form: this,
        caption: "Conta Corrente",
        maxlength: 15,
        required: true
      }),
      digitocc: new CMInputConfig({
        form: this,
        caption: "Dígito",
        maxlength: 1,
        required: true
      }),
      agenciaconta: new CMInputConfig({
        form: this,
        caption: "Agência/Conta",
        maxlength: 4,
      }),
      convenio: new CMInputConfig({
        form: this,
        caption: "Código Convênio",
        maxlength: 15,
        required: true
      }), 
      codigocedente: new CMInputConfig({
        form: this,
        caption: "Código do Cedente",
        maxlength: 15,
        required: true
      }),                               
      carteira: new CMInputConfig({
        form: this,
        caption: "Carteira",
        maxlength: 5,
        required: true
      }), 
      modalidade: new CMInputConfig({
        form: this,
        caption: "Modalidade ou Variação Carteira",
        maxlength: 15,
      }),  
      numeroarquivo: new CMInputConfig({
        form: this,
        caption: "Número do Arquivo",
        dataType: "integer",
      }),              
      seqnn: new CMInputConfig({
        form: this,
        caption: "Nosso Número",
        dataType: "integer",
      }),         
      codigotransmissao: new CMInputConfig({
        form: this,
        caption: "Código Transmissão",
        maxlength: 30,
      }),        
      versaoarquivo: new CMInputConfig({
        form: this,
        caption: "Versão Arquivo",
        dataType: "integer",
      }),         
      versaolote: new CMInputConfig({
        form: this,
        caption: "Versão Lote",
        dataType: "integer",
      }),
      percmulta: new CMInputConfig({
        form: this,
        caption: "% Multa",
        dataType: "double(2)",
      }),                  
      diasmulta: new CMInputConfig({
        form: this,
        caption: "Dias Multa",
        dataType: "integer",
      }),
      percjuros: new CMInputConfig({
        form: this,
        caption: "% Juros por Mês",
        dataType: "double(2)",
      }),                 
      casasdecjuros: new CMInputConfig({
        form: this,
        caption: "Juros Casas Dec.",
        dataType: "integer",
      }),
      diasprotesto: new CMInputConfig({
        form: this,
        caption: "Dias Protesto",
        dataType: "integer",
      }),
      diasbaixaauto: new CMInputConfig({
        form: this,
        caption: "Dias Baixa Automática",
        dataType: "integer",
      }),                
      tipocobranca: new CMInputConfig({
        form: this,
        caption: "Carac. Título ou Tipo Cobrança",
        values: ConfiguracaoBoletoBrowseComponent.valuesTipoCobranca(this.http),
        valuesObject: ConfiguracaoBoletoBrowseComponent.valuesObjectSelect("tipocobranca"),
      }), 
      numerores: new CMInputConfig({
        form: this,
        caption: "Número Res",
        maxlength: 15,
      }),               
      especiemoeda: new CMInputConfig({
        form: this,
        caption: "Espécie Moeda",
        maxlength: 7,
      }),         
      especiedoc: new CMInputConfig({
        form: this,
        caption: "Espécie Docto",
        maxlength: 20,
        required: true
      }), 
      instrucao1: new CMInputConfig({
        form: this,
        caption: "Cód. Instrução 1",
        maxlength: 2,
      }),
      instrucao2: new CMInputConfig({
        form: this,
        caption: "Cód. Instrução 2",
        maxlength: 2,
      }),        
      aceite: new CMInputConfig({
        form: this,
        caption: "Aceite",
        values: this.valuesAceite,
      }),
      responemissao: new CMInputConfig({
        form: this,
        caption: "Responsável Emissão",
        values: ConfiguracaoBoletoBrowseComponent.valuesResponEmissao(this.http),
        valuesObject: ConfiguracaoBoletoBrowseComponent.valuesObjectSelect("responemissao"),
        required: true,
      }),
      tipocarteira: new CMInputConfig({
        form: this,
        caption: "Tipo Carteira",
        values: this.valuesTipoCarteira,
      }),      
      tipodocumento: new CMInputConfig({
        form: this,
        caption: "Tipo Documento",
        values: this.valuesTipoDocumento,
      }), 
      layoutremessa: new CMInputConfig({
        form: this,
        caption: "Layout Remessa",
        values: ConfiguracaoBoletoBrowseComponent.valuesLayoutRemessa(this.http),
        valuesObject: ConfiguracaoBoletoBrowseComponent.valuesObjectSelect("layoutremessa"),
        required: true,
      }),
      jurosremessa: new CMInputConfig({
        form: this,
        caption: "Juros na Remessa",
        values: this.valuesJurosRemessa,
      }),
      // dirarqrelatorio: new CMInputConfig({
      //   form: this,
      //   caption: "Arquivo Modelo do Boleto",
      //   maxlength: 500,
      //   required: true
      // }),
      dirarqremessa: new CMInputConfig({
        form: this,
        caption: "Pasta Arquivos de Remessa",
        maxlength: 500,
      }),      
      cedente: new CMInputConfig({
        form: this,
        caption: "Cedente",
        maxlength: 500,
        required: true,
      }),       
      desconto: new CMInputConfig({
        form: this,
        caption: "Desconto",
        values: this.valuesAceite,
      }),
      alterarvalorretorno: new CMInputConfig({
        form: this,
        caption: "Retorno: Alterar Valor?",
        values: this.valuesAceite,
      }),
      retornoliquidacao: new CMInputConfig({
        form: this,
        caption: "Retorno: Liquidação",
        values: this.valuesRetornoLiquidacao,
      }),
      retornorecebimento: new CMInputConfig({
        form: this,
        caption: "Retorno: Recebimento",
        values: this.valuesRetornoLiquidacao,
      }), 
      mensagem: new CMInputConfig({
        form: this,
        caption: "Mensagens / Instruções",
        maxlength: 500,
      }),
      localpagamento: new CMInputConfig({
        form: this,
        caption: "Mensagem para Local de Pagamento",
        maxlength: 100,
      }),
      clientid: new CMInputConfig({
        form: this,
        caption: "Cliente ID",
        maxlength: 350,
      }),
      clientsecret: new CMInputConfig({
        form: this,
        caption: "Cliente Secret",
        maxlength: 350,
      }),      
      keyuser: new CMInputConfig({
        form: this,
        caption: "Key User",
        maxlength: 60,
      }),      
      scope: new CMInputConfig({
        form: this,
        caption: "Scope",
        maxlength: 60,
      }),
      tiporemessa: new CMInputConfig({
        form: this,
        caption: "Tipo de Integração",
        values: this.valuesTipoIntegracao,
      }),
      apihomologacao: new CMInputConfig({
        form: this,
        caption: "Ambiente de Destino",
        values: this.valuesAmbienteDestino,
      }),
      contabancaria: new CMInputConfig({
        form: this,
        caption: "Conta Bancária",
        required: true,
        pesqFunc: (sender) => {
          ContaBancariaBrowseComponent.pesquisa(sender, this.model, "contabancaria");
          console.log("thismodel: ", this.model);
        },
      }),           
    };
  }
  
}  
