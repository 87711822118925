// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
} from "./../../@cmcore/component";
import { ProdutoBrowseComponent } from "./../produto/produto-browse.component";
import { PessoaBrowseComponent } from "./../pessoa/pessoa-browse.component";
import { NecessidadeCompraBrowseComponent } from "../necessidade-compra";
import { FornecedorBrowseComponent } from "../fornecedor";

@Component({
  selector: "cotacao-edit",
  templateUrl: "cotacao-edit.component.html",
})
export class CotacaoEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_data";
  }

  getTitle() {
    let r: string = "Cotação";
    let tmp: string = this.fieldByName("numero");
    if (tmp !== "0") r += " - #" + tmp;
    return r;
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "Cotacao";
    this.setModelConfig();
  }

  afterGetModelData() {
    super.afterGetModelData();
    if (!this.disabledAll) this.disabledAll = this.model.podeeditar !== "Sim";
  }

  setModelConfig() {
    this.modelConfig = {
      numero: new CMInputConfig({ form: this, caption: "#", disabled: true }),
      data: new CMInputConfig({
        form: this,
        caption: "Data emissão",
        mask: "99/99/9999",
        required: true,
      }),
      datamaximaresposta: new CMInputConfig({
        form: this,
        caption: "Data maxima resposta",
        mask: "99/99/9999",
        required: true,
      }),
      status: new CMInputConfig({
        form: this,
        caption: "Status",
        values: [
          { value: "Aberta" },
          { value: "EmAndamento", text: "Em Andamento" },
          { value: "Encerrada" },
          { value: "Cancelada" },
        ],
      }),
      itens: new CMGridEditConfig({
        form: this,
        customValidItem: (item) => {
          return this.validEstoque(item);
        },
        onNewItem: (model) => {
          Object.assign(model, {
            quantidade: "0,00",
            estoque: [],
          });
        },
        extraColumns: {
          produto_descr: new CMInputConfig({
            form: this,
            caption: "Descrição",
            gridConfig: new CMGridEditConfigColumn({
              ordem: 2,
              getValue: (row) => this.getValueObject(row, "produto.descr"),
            }),
          }),
        },
        fields: {
          produto: new CMInputConfig({
            form: this,
            caption: "Produto",
            required: true,
            padInfo: { size: 30 },
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              width: "300px",
              ordem: 1,
              getValue: (row) => {
                return this.getValueObject(row, "produto.codigo");
              },
            }),
            pesqFunc: (sender) => {
              ProdutoBrowseComponent.pesquisa(
                sender,
                this.modelConfig.itens.current,
                "Ativos",
                undefined,
                () => {
                  if(this.currentProdutoUsaGrade()){
                    console.log(this.modelConfig.itens.current);
                    this.modelConfig.itens.current.quantidade = "0,00";

                    let grades = this.modelConfig.itens.current.produto.grades;

                    grades.forEach((grade) => {
                      this.modelConfig.itens.current.estoque.push({
                        "grade": grade,
                        "id": CMInputConfig.getRandomInt(1, 9999) * -1,
                        "quantidade": "0,00"
                      })
                    });
                  }
                  else{
                    this.modelConfig.itens.current.quantidade = "1,00";
                  };
                }
              );
            },
          }),
          quantidade: new CMInputConfig({
            form: this,
            caption: "Quantidade",
            dataType: "double(2)",
            gridConfig: new CMGridEditConfigColumn({
              ordem: 3,
              width: "150px",
            }),
            hintButton: "Editar",
            iconButton: () => {
              if (this.disabledAll) return "visibility";
              else return "edit";
            },
            showButtonClear: false,
            pesqOnlyFunc: (row) => this.mostraDetalhesQuantidade(row),
            pesqFunc: (sender) => {
              if (this.mostraDetalhesQuantidade(this.modelConfig.itens.current))
                this.modelConfig.itens.extraClick(
                  new CMGridEditConfigMore({
                    index: 0,
                    title: "Detalhes da Quantidade",
                  })
                );
            },
            showButtonFunc: (show, row) => this.mostraDetalhesQuantidade(row),
          }),
          observacao: new CMInputConfig({
            form: this,
            maxlength: 60,
            caption: "Observação",
            gridConfig: new CMGridEditConfigColumn({
              ordem: 4,
            }),
          }),
          estoque: new CMGridEditConfig({
            form: this,
            required: false,
            onRemoveItem: (item) => {
              this.onRemoveItemEstoque(item);
            },
            fields: {
              grade: new CMInputConfig({
                form: this,
                caption: "Grade",
                required: true,
                pesqOnly: true,
                gridConfig: new CMGridEditConfigColumn({
                  tipo: "input-pesquisa",
                  getValue: (row) => {
                    return this.getValueObject(row, "grade.descricao");
                  },
                }),
                pesqFunc: (sender) => {
                  ProdutoBrowseComponent.pesquisaGrade(
                    sender,
                    this.modelConfig.itens.fields.estoque.current,
                    this.modelConfig.itens.current.produto.id
                  );
                },
                visibleFunc: () => {
                  return this.currentProdutoUsaGrade();
                },
              }),
              quantidade: new CMInputConfig({
                form: this,
                caption: "Quantidade",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn(),
                onExit: () => {
                  this.calcTotalItemEstoque();
                },
              }),
            },
          }),
        },
      }),
      fornecedores: new CMGridEditConfig({
        form: this,
        extraColumns: {
          produto_descr: new CMInputConfig({
            form: this,
            caption: "Nome",
            gridConfig: new CMGridEditConfigColumn({
              ordem: 2,
              getValue: (row) => this.getValueObject(row, "fornecedor.nome"),
            }),
          }),
        },
        fields: {
          fornecedor: new CMInputConfig({
            form: this,
            caption: "Fornecedor",
            required: true,
            gridConfig: new CMGridEditConfigColumn({
              tipo: "input-pesquisa",
              width: "300px",
              ordem: 1,
              getValue: (row) => {
                return this.getValueObject(row, "fornecedor.codigo");
              },
            }),
            pesqFunc: (sender) => {
              FornecedorBrowseComponent.pesquisa(
                sender,
                this.modelConfig.fornecedores.current,
                undefined,
                undefined,
                this.getIdsProdutos()
              );
            },
          }),
        },
      }),
    };
  }

  validEstoque(item: any) {
    let r: string = "";
    //console.log(item);
    console.log(this.valueToFloat(item.quantidade, 0));
    if (this.valueToFloat(item.quantidade, 0) <= 0)
      r = "Valor total deve ser maior que zero";
    return r;
  }

  onRemoveItemEstoque(item: any) {
    let qtotal = 0;
    qtotal = this.valueToFloat(this.modelConfig.itens.current.quantidade) - this.valueToFloat(item.quantidade);
    this.modelConfig.itens.current.quantidade = this.floatToValue(qtotal);
  }

  getIdsProdutos() {
    let r = [];
    for (let i = 0; i < this.model.itens.length; i++) {
      if (this.model.itens[i].produto) {
        r.push(this.model.itens[i].produto.id);
      }
    }
    return r;
  }

  mostraDetalhesQuantidade(row) {
    let r = false;
    if (row && row.produto) {
      r = row.produto.usagrade1 === "Sim" || row.produto.usagrade2 === "Sim";
    }
    return r;
  }

  currentProdutoUsaGrade() {
    return (
      this.getValueObject(
        this.modelConfig.itens.current,
        "produto.usagrade1"
      ) === "Sim" ||
      this.getValueObject(
        this.modelConfig.itens.current,
        "produto.usagrade2"
      ) === "Sim"
    );
  }

  calcTotalItemEstoque() {
    let item = this.modelConfig.itens.current;
    let qtotal = 0;
    item.estoque.forEach((est) => {
      qtotal += this.valueToFloat(est.quantidade);
    });
    item.quantidade = this.floatToValue(qtotal);
  }

  findItemProduto(idProduto) {
    let r = undefined;
    for (let i = 0; i < this.model.itens.length; i++) {
      if (
        this.model.itens[i].produto &&
        this.model.itens[i].produto.id == idProduto
      ) {
        r = this.model.itens[i];
        break;
      }
    }
    return r;
  }

  findGradeItem(item, idGrade) {
    let r = undefined;
    for (let i = 0; i < item.estoque.length; i++) {
      if (item.estoque[i].grade && item.estoque[i].grade.id == idGrade) {
        r = item.estoque[i];
        break;
      }
    }
    return r;
  }

  importNecessidadeCompra() {
    NecessidadeCompraBrowseComponent.pesquisaItens((rows) => {
      rows.forEach((row) => {
        let item = this.findItemProduto(row.idproduto);
        if (item == undefined) {
          item = {
            id: CMGridEditConfig.newId(),
            produto: {
              id: row.idproduto,
              codigo: row.codproduto,
              descr: row.descrproduto,
              usagrade1: row.produtousagrade1,
              usagrade2: row.produtousagrade2,
            },
            quantidade: "0",
            estoque: [],
          };
          this.model.itens.push(item);
        }
        item.quantidade = this.floatToValue(
          this.valueToFloat(item.quantidade) + this.valueToFloat(row.quantidade)
        );
        let itemEstoque = this.findGradeItem(item, row.idgrade);
        if (itemEstoque == undefined) {
          itemEstoque = {
            id: CMGridEditConfig.newId(),
            grade: {
              id: row.idgrade,
              descricao: row.descricaograde,
            },
            quantidade: "0",
          };
          item.estoque.push(itemEstoque);
        }
        itemEstoque.quantidade = this.floatToValue(
          this.valueToFloat(itemEstoque.quantidade) +
            this.valueToFloat(row.quantidade)
        );
      });
    });
  }
}
