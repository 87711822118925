// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
} from "./../../@cmcore/component";
import { GrupoDeOperacaoEditComponent } from "./grupodeoperacao-edit.component";

@Component({
  selector: "grupodeoperacao-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class GrupoDeOperacaoBrowseComponent extends CMFormBrowse2Component {
  static defaultColumns = [
    { field: "codigo", caption: "Código", width: "100" },
    { field: "descricao", caption: "Descrição", filter: true },
  ];

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "grupodeoperacao";
    this.fixedFooterFormEdit = false;
    this.formEditComponent = GrupoDeOperacaoEditComponent;
    this.columns = GrupoDeOperacaoBrowseComponent.defaultColumns;
  }

  getTitle() {
    return "Grupo de Operação";
  }

  static pesquisa(sender: any, target: any, targetProp: any = undefined) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "grupodeoperacao",
        "Pesquisa de Grupo de Operação",
        GrupoDeOperacaoBrowseComponent.defaultColumns,
        target,
        targetProp,
        "codigo",
        undefined,
        false
      );
    }
  }
}
