// tslint:disable
import { Input, Injector } from "@angular/core";

import { CMFormComponent } from "../../../component/cmform/cmform.component";
import {
  CMFormGlobaisComponent,
  CMFormBrowseComponent,
} from "../../../component";
import { AppComponent } from "./../../../../app.component";

export class CMFormEditComponent extends CMFormComponent {
  constructor(_injector: Injector) {
    super(_injector);
  }

  @Input() idDataModel: number = 0;
  @Input() controllerName: string = "";
  @Input() functionSaveName: string = "saveobject";
  public model: any = {};
  private innerModelConfig = {};
  public get modelConfig() {
    return this.innerModelConfig;
  }
  public set modelConfig(value: any) {
    this.innerModelConfig = value;
    this.model = {};
    this.generateEmptyModel(value, this.model);
  }

  public beforeComponent: any = undefined;

  activeFirstPageTab(tab) {
    let lis = tab.getElementsByTagName("LI");
    if (lis.length > 0) {
      let _as = lis[0].getElementsByTagName("A");
      if (_as.length > 0) _as[0].click();
    }
  }

  activeFirstPageTabs() {
    let tabs = document.getElementsByTagName("CMTABS");
    if (tabs) {
      if (tabs.length > 0) {
        for (let i = 0; i < tabs.length; i++) this.activeFirstPageTab(tabs[i]);
      }
    }
  }

  originalValue: any = "";
  setOriginalValue() {
    this.originalValue = this.modelJSON_noEmpty();
  }
  modified() {
    return this.originalValue != this.modelJSON_noEmpty();
  }

  private modelJSON_noEmpty() {
    return JSON.stringify(this.model, (key, value) => {
      let r = true;
      if (r) r = value !== null && value !== undefined && value !== "";
      if (r && value instanceof Array) r = value.length > 0;
      if (r && CMFormComponent.isBaseObject(value))
        r = JSON.stringify(value) !== "{}";
      if (r) {
        return value;
      }
    });
  }

  afterGetModelData() {
    this.setOriginalValue();
  }

  beforeGetModelData() {
    this.unSelectItens(this.modelConfig);
  }

  getTitle() {
    return "";
  }

  getFirstComponentName() {
    return "";
  }

  focusFirstComponentEdit() {
    if (this.getFirstComponentName() !== "")
      $("#" + this.getFirstComponentName()).focus();
  }

  customValid() {
    return super.customValid(this.modelConfig);
  }

  valid() {
    return super.valid(this.modelConfig, this.model);
  }

  novoCadastro() {
    return this.valueToInteger(this.model[this.fieldNameId()], 0) <= 0;
  }

  formBrowse: CMFormBrowseComponent = undefined;
  validProc: Function = undefined;
  save(formBrowse: CMFormBrowseComponent) {
    this.formBrowse = formBrowse;
    if (this.valid()) {
      if (this.validProc !== undefined) {
        this.validProc(() => {
          this.saveModelData(formBrowse);
        })
      }
      else {
        this.saveModelData(formBrowse);
      }
    }
  }

  fieldNameId() {
    return "id";
  }

  getModelData(
    idDataModel: number,
    afterGetModelData?: Function,
    filters?: any
  ) {
    this.idDataModel = idDataModel;
    if (filters === undefined)
      filters = [{ field: this.fieldNameId(), value: this.idDataModel }];
    this.beforeGetModelData();
    this.getObjectData(this.controllerName, filters, (object) => {
      this.model = object;
      if (this.idDataModel === -1)
        this.idDataModel = this.valueToInteger(this.model[this.fieldNameId()]);
      this.afterGetModelData();
      if (afterGetModelData) afterGetModelData(this.model);
    });
  }

  procAfterSave: Function = undefined;
  afterSave() {
    if (this.procAfterSave !== undefined) this.procAfterSave(this.model);
  }

  beforeSave(procSave: Function) {
    procSave();
  }

  beforeSaveModelData(formBrowse: CMFormBrowseComponent, dataSend) {}

  saveModelData(formBrowse: CMFormBrowseComponent) {
    CMFormGlobaisComponent.showSpinner();
    this.beforeSave(() => {
      let dataSend = {};
      dataSend["_fieldId"] = this.fieldNameId();
      dataSend[this.fieldNameId()] = this.idDataModel;
      dataSend["modeldata"] = this.model;
      this.beforeSaveModelData(formBrowse, dataSend);
      this.postSubscribe(
        AppComponent.basePathApi +
          "/api/" +
          this.controllerName +
          "/" +
          this.functionSaveName,
        JSON.stringify(dataSend),
        (result) => {
          if (result["result"] === "ok") {
            this.model[this.fieldNameId()] = result[this.fieldNameId()];
            this.afterSave();
            this.setOriginalValue();
            if (formBrowse) {
              formBrowse.modalFormEditClose();
              if (formBrowse.grid) formBrowse.grid.refreshCurrentPage();
            }
          } else {
            let str: string = '<p class="divider"></p><div class="container">';
            str += "<p><b>Mensagem:</b> " + result["result"] + "</p>";
            str += '</div><p class="divider"></p>';
            CMFormGlobaisComponent.showMessageAviso(str);
          }
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("saveModelData", error);
        }
      );
    });
  }

  public fieldByName(aFieldName: string) {
    return CMFormComponent.getValueObject(this.model, aFieldName);
  }

  public fieldIsEmpty(aFieldName: string) {
    return CMFormComponent.valueIsEmpty(this.model, aFieldName);
  }

  showButtonExtra() {
    return false;
  }

  hintButtonExtra() {
    return "";
  }

  iconButtonExtra() {
    return "";
  }

  clickButtonExtra() {}
}
