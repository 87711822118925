// tslint:disable
import {Component, Injector} from '@angular/core';

import {CMFormEditComponent, CMInputConfig} from './../../@cmcore/component';

@Component({
  selector: 'caixa-sat-edit',
  templateUrl: 'caixa-sat-edit.component.html'
})
export class CaixaSATEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return 'model_numerocaixa';
  }

  getTitle() {
    return 'Caixa SAT';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'caixasat';
    this.setModelConfig();
  }
  setModelConfig() {
    this.modelConfig = {
      numerocaixa: new CMInputConfig({
        form: this,
        caption: 'Número do Caixa',
        required: true,
        dataType: 'integer'
      }),
      assinatura: new CMInputConfig({
        form: this,
        caption: 'Assinatura'
      }),
      identificacaodispositivocomplemento: new CMInputConfig({
        form: this,
        caption: 'Identificação do Dispositivo',
        maxlength: 45
      })
    };
  }
}
