// tslint:disable
import { Component } from '@angular/core';

@Component({
    templateUrl: 'login-full-screen.component.html',
    styleUrls: ['login-full-screen.component.scss']
})

export class LoginFullScreenComponent {
  static beforeComponent: any = undefined;

  get beforeComponent() {
    return LoginFullScreenComponent.beforeComponent;
  }
}