// tslint:disable
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import {
  CanActivate,
  CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { AuthService } from "./auth.service";
import { CMFormComponent } from "../component/cmform/cmform.component";

@Injectable()
export class AuthGuard implements CanActivate, CanDeactivate<any> {
  constructor(
    private authService: AuthService,
    private router: Router,
    private http: HttpClient
  ) {}

  static useCheckMaxSimultaneousAccess = true;
  checkMaxSimultaneousAccess() {
    if (AuthGuard.useCheckMaxSimultaneousAccess) {
      CMFormComponent.postSubscribe(
        this.http,
        "/api/CheckMaxSimultaneousAccess",
        JSON.stringify({}),
        (result) => {
          if (result["ret"] == "MAXUSER") {
            if (
              this.router.url !== "maxusers" ||
              !this.router.url.startsWith("/maxusers")
            ) {
              this.router.navigate(["/maxusers"]);
            }
          }
        }
      );
    }
  }

  checkAssinaturaVencida(route: ActivatedRouteSnapshot) {
    if (
      route &&
      route.url &&
      route.url.length > 0 &&
      route.url[0].path !== "minhaconta"
    ) {
      const user = CMFormComponent.currentUserStorage();
      if (user["AssinaturaVencida"] == true) {
         this.router.navigate(["minhaconta", "pagarfatura"]);
      }
    }
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (this.authService.logado) {
      this.checkMaxSimultaneousAccess();
      this.checkAssinaturaVencida(route);
      return true;
    } else {
      let extras = {
        queryParams: {},
      };
      if (route.queryParams) {
        Object.assign(extras.queryParams, route.queryParams);
      }
      const url = route.url.join("/");
      if (url !== "" && url !== "/") {
        Object.assign(extras.queryParams, {
          routerok: url,
        });
      }
      this.router.navigate(["login"], extras);
      return false;
    }
  }

  canDeactivate(component: any) {
    if (component.areFormsSaved) return component.areFormsSaved();
    else return true;
  }
}
