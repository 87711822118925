// tslint:disable
import { Component, Injector } from '@angular/core';
import { FuncsGlobais } from '../funcs.globais';

import { CMInputConfig, CMFormExecComponent,
         CMFormGlobaisComponent } from './../../@cmcore/component';

@Component({
    templateUrl: 'geracaoarquivocnab-component.html'
})
export class GeracaoArquivoCNABComponent extends CMFormExecComponent {

  constructor(_injector: Injector) {
    super(_injector);
    this.filterConfig = {
      dataini: new CMInputConfig({form: this,
                                  caption: 'Data Inicial',
                                  mask: '99/99/9999',
																	required: true
                                 }),
      datafim: new CMInputConfig({form: this,
                                  caption: 'Data Final',
                                  mask: '99/99/9999',
																	required: true
                                 }),
		}
    this.filter.dataini = this.firstDate();
    this.filter.datafim = this.lastDate();
	}

	innerExec() {
		super.innerExec();
		this.geraArquivoCNAB()
	}

  geraArquivoCNAB() {
		let filter = this.filter;
    CMFormGlobaisComponent.showSpinner();
    let date = new Date;
    let dia = date.getDate();
    let diaformatado;
    let mes = date.getMonth()+1;
    let mesformatado;
    
    if (dia < 10) {
      diaformatado = "0" + dia;
    }
    else{
      diaformatado = dia;
    }

    if (mes === 10){
      mesformatado = "O";
    }
    else if (mes === 11){
      mesformatado = "N";
    }
    else if (mes === 12){
      mesformatado = "D";
    }
    else{
      mesformatado = mes;
    }

    console.log(this.paramEmpresa);
    
    this.postSubscribeT<Array<string>>('/api/duplicatareceber/GeraArquivoCNAB/FuncaoExtra',
                  JSON.stringify(filter),
            (result) => {
              let result_str = result.pop();
              if (result_str === 'ok') {
                let contentFile = result.pop();
                console.log(date.getDay(), ' ', date);
                if (this.paramEmpresa.Convenio) {
                  let fileName = this.paramEmpresa.Convenio+mesformatado+diaformatado+'.crm';
                  this.downloadContent(contentFile, fileName);
                }
                else{
                  let fileName = "00000"+mesformatado+diaformatado+'.crm';
                  this.downloadContent(contentFile, fileName);
                }
              }
              else {
                CMFormGlobaisComponent.toastErro(result_str);
              }
              CMFormGlobaisComponent.hideSpinner();
            },
            error => {
              CMFormGlobaisComponent.hideSpinner();
              this.showMessageErrorHTTP('geraArquivoCNAB', error);
            });
  }
  

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  /*
  loadDadosCedente() {
    this.getObjectData('convenio',
      [{'field': 'id', 'value': this.currentUserStorage.Empresa}],
      object => {
				this.modelNFeInutilizacao = object;
      });
  }*/

}