import { PessoaBrowseComponent } from "..";
import { CMFormPesquisaComponent } from "../../@cmcore/component";

// tslint:disable
export class FornecedorBrowseComponent {
  static pesquisa(
    sender: any,
    target: any,
    targetProp?: string,
    onAfterSelect?: Function,
    produtos?: number[]
  ) {
    if (target && !CMFormPesquisaComponent.inPesquisa) {
      if (targetProp == undefined) targetProp = "fornecedor";

      let extraFilters: any = {};
      let extraColumns = [];
      if (sender === undefined && produtos !== undefined) {
        if (produtos.length > 0) {
          let i = {
            field: "filtraproduto",
            caption: "Filtra por produto",
            filter: true,
            visible: false,
            tipo: "checkbox",
            value: "Sim",
            columns: 12
          };
          extraFilters.produtos = produtos;
          extraColumns.push(i);
        }
      }

      PessoaBrowseComponent.pesquisa(
        sender,
        target,
        "Fornecedor",
        targetProp,
        onAfterSelect,
        extraFilters,
        extraColumns
      );
    }
  }
}
