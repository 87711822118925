// tslint:disable
import { Component, Input, Output, EventEmitter } from "@angular/core";

import { CMInput } from "../../decorator";
import { CMInputComponent } from "../../component";

@Component({
  selector: "cmbutton",
  templateUrl: "./cmbutton.component.html",
  styleUrls: ["./cmbutton.component.scss"],
})
export class CMButtonComponent {
  @Output()
  cmclick: EventEmitter<CMButtonComponent> = new EventEmitter<CMButtonComponent>();
  @Input() type: string = "";
  @CMInput()
  @Input()
  disabled: boolean = false;
  @CMInput()
  @Input()
  visible: boolean = true;
  @CMInput()
  @Input()
  flat: boolean = false;
  @CMInput()
  @Input()
  large: boolean = false;
  @CMInput()
  @Input()
  floating: boolean = false;
  _hint: string = "";
  @Input()
  get hint() {
    return this._hint;
  }
  set hint(value) {
    this._hint = value;
  }
  @CMInput()
  @Input()
  useInputField: boolean = true;
  @CMInput()
  @Input()
  useInputFieldFloating: boolean = false;
  @Input("class") extraClass: string = "";
  @Input() classbtn: string = "";
  @Input() lineheight: string = "";
  @Input() marginTop: string = "";
  @Input() marginRight: string = "";
  @Input() fontsize: string = "";
  @Input() background: string = "";
  @Input() padding: string = "";
  @Input() paddingLeft: string = "";
  @Input() paddingRight: string = "";
  @Input()
  name: string = "bt_" + CMInputComponent.nextGlobalId().toString();

  getStyle() {
    let r: string = "";
    if (this.lineheight !== "") r += "line-height: " + this.lineheight + ";";
    if (this.marginTop !== "") r += "margin-top: " + this.marginTop + ";";
    if (this.marginRight !== "") r += "margin-right: " + this.marginRight + ";";
    if (this.padding !== "") r += "padding: " + this.padding + ";";
    if (this.paddingLeft !== "") r += "padding-left: " + this.paddingLeft + ";";
    if (this.paddingRight !== "")
      r += "padding-right: " + this.paddingRight + ";";
    if (this.fontsize !== "") r += "font-size: " + this.fontsize + ";";
    if (this.background !== "") r += "background: " + this.background + ";";
    return r;
  }

  getUseInputField() {
    if (this.floating) return this.useInputFieldFloating;
    else return this.useInputField;
  }

  getClass() {
    let r: string = "waves-effect waves-light " + this.classbtn;
    if (this.getUseInputField()) r += " input-field";
    if (this.disabled) r += " disabled";
    if (this.flat) r += " btn-flat";
    if (this.large) r += " btn-large";
    if (this.floating) r += " btn-floating";
    if (!this.floating) r += " btn cmbtn";
    if (this.extraClass !== "") r += " cmbtn-all-inherit";
    if (!this.visible) r += " cmbthide";
    r += " color-cmbutton";
    return r;
  }

  olddisabled: boolean;
  clickbutton(event) {
    if (!this.disabled) {
      this.removeWaves();
      this.olddisabled = this.disabled;
      this.disabled = true;
      setTimeout(() => {
        this.disabled = this.olddisabled;
        this.cmclick.emit(this);
      }, 100);
    }
  }

  removeWaves() {
    setTimeout(() => {
      const waves: HTMLCollectionOf<Element> = document.getElementsByClassName(
        "waves-ripple"
      );
      for (let index = 0; index < waves.length; index++) {
        const wave = waves[index];
        wave.remove();
      }
    }, 1000);
  }

  onMouseDown(event) {
    this.removeWaves();
  }
}
