// tslint:disable
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMInputConfig } from './../../@cmcore/component';
import { ContaPagarEditComponent } from './conta-pagar-edit.component';
import { FornecedorBrowseComponent } from './../fornecedor/fornecedor-browse.component';

//@Raul
import { ContaContabilBrowseComponent } from '..';

@Component({
  selector: 'conta-pagar-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class ContaPagarBrowseComponent extends CMFormBrowse2Component {

  getTitle() {
    return 'Contas a Pagar';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'contapagar';
    this.formEditComponent = ContaPagarEditComponent;
    this.columns = [{field: 'codigo', caption: '#',
                     filter: new CMInputConfig({
                       dataType: 'integer'
                     })
                    },
                    {field: 'origem', caption: 'Origem',
                     filter: new CMInputConfig({
                       values: [{value: 'Cadastro', text: 'Cadastro'},
                                {value: 'NFeRecebimento', text: 'NF-e de Recebimento'},
                                {value: 'Todas'}]
                     }),
                     value: 'Todas'
                    },
                    {field: 'nfatura', caption: 'Fatura', filter: true},
                    {field: 'destinatario_nome', caption: 'Fornecedor',
                     filterField: 'destinatario.id',
                     filterValue: 'destinatario.codigo',
                     fieldBottom: 'destinatario.nome',
                     filter: new CMInputConfig({
                       dataType: 'integer',
                       pesqFunc: sender => {
                         FornecedorBrowseComponent.pesquisa(sender, this.valuesFilters, 'destinatario');
                      }
                     })
                    },
                    {field: 'voriginal', caption: 'Valor bruto',
                     filter: new CMInputConfig({
                       dataType: 'double(2)'
                     })
                    },
                    {field: 'vliquido', caption: 'Valor líquido',
                     filter: new CMInputConfig({
                       dataType: 'double(2)'
                     })
                    },

                    //@Raul
                    {
                      field: 'contacontabil_descricao', caption: 'Conta Contábil',
                      filterField: 'contacontabil.id',
                      filterValue: 'contacontabil.codigo',
                      fieldBottom: 'contacontabil.nome',
                      filter: new CMInputConfig({
                        dataType: "integer",
                        pesqFunc: (sender) => {
                          ContaContabilBrowseComponent.pesquisa(sender, this.valuesFilters, "Conta Contábil");
                        },
                      })
                    },
                  ];
  }

}