// tslint:disable
import { Directive, ElementRef, Input, OnInit, 
       } from '@angular/core'

import { createTextMaskInputElement } from './createTextMaskInputElement'

@Directive({
  host: {
    '(input)': 'onInput($event)',
    '(blur)': '_onTouched($event)'
  },
  selector: '[textMask]'
})
export class CMMaskedInputDirective implements OnInit {
  private textMaskInputElement: any
  private inputElement: HTMLInputElement

  @Input()
  textMaskConfig = {
    mask2: '',
    guide: false,
    placeholderChar: ' ',
    pipe: undefined,
    keepCharPositions: false,
    onReject: undefined,
    onAccept: undefined
  }

  innerMask = '';
  @Input('textMask')
  get mask() {
    return this.innerMask;
  }
  set mask(value: any) {
    this.innerMask = value;
  }

  _onTouched = ($event) => {}

  constructor(private element: ElementRef) {}

  mask_enabled() {
    return ((this.mask) &&
            (this.mask.length > 0));
  }

  getMask(textMask: CMMaskedInputDirective, safeRawValue, info) {
    return textMask.mask;
  }

  ngOnInit() {
    if (this.mask_enabled()) {
      if (this.element.nativeElement.tagName === 'INPUT') {
        this.inputElement = this.element.nativeElement;
      } 
      else {
        this.inputElement = this.element.nativeElement.getElementsByTagName('INPUT')[0];
      }
      let config = Object.assign(
        {inputElement: this.inputElement, 
         mask: this.getMask,
         textMask: this
        }, this.textMaskConfig);
      this.textMaskInputElement = createTextMaskInputElement(config);
    }
  }

  onInput($event) {
    if (this.mask_enabled()) {
      this.textMaskInputElement.update($event.target.value);
    }
  }

}