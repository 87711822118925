import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "../../component/cmform/cmform.component";
import { MatDialog } from "@angular/material";
import { MaxUsersConfirmComponent } from ".";

@Component({
  selector: "app-maxusers",
  templateUrl: "maxusers.component.html",
  styleUrls: ["maxusers.component.css"]
})
export class MaxUsersComponent extends CMFormComponent {
  displayedColumns: string[] = [
    "lastaccess",
    "usuario",
    "fullname",
    "remoteip",
    "useragent",
    "actions"
  ];

  dataSource = [];

  getListUsersLogin() {
    this.dataSource = [];
    CMFormComponent.postSubscribe(
      this.http,
      "/api/GetListUsersLogin",
      JSON.stringify({}),
      result => {
        if (result["usuarios"]) {
          this.dataSource = result["usuarios"];
        }
      },
      error => {
        this.showMessageErrorHTTP("getListUsersLogin", error);
      }
    );
  }

  atualizarLista() {
    CMFormComponent.postSubscribe(
      this.http,
      "/api/CheckMaxSimultaneousAccess",
      JSON.stringify({}),
      result => {
        if (result["ret"] == "MAXUSER") {
          this.getListUsersLogin();
        } else {
          this.router.navigate(["/"]);
        }
      },
      error => {
        this.showMessageErrorHTTP("atualizarLista", error);
      }
    );
  }

  sair() {
    this.router.navigate(["/login"]);
  }

  constructor(injector: Injector, public dialog: MatDialog) {
    super(injector);
    this.getListUsersLogin();
  }

  forceSair(row) {
    const dialogRef = this.dialog.open(MaxUsersConfirmComponent, {
      width: "400px",
      maxWidth: "90%",
      data: row
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.forceLogoutUser(data);
      }
    });
  }

  forceLogoutUser(data) {
    CMFormComponent.postSubscribe(
      this.http,
      "/api/ForceLogoutUser",
      JSON.stringify({ filename: data.filename }),
      result => {
        this.atualizarLista();
      },
      error => {
        this.atualizarLista();
      }
    );
  }
}
