// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormBrowse2Component, CMInputConfig } from './../../@cmcore/component';
import { AssociadoEditComponent } from './associado-edit.component';

@Component({
  selector: 'associado-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class AssociadoBrowseComponent extends CMFormBrowse2Component {

  getTitle() {
    return 'Associado';
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'associado';
    this.formEditComponent = AssociadoEditComponent;
    this.columns = [{field: 'id', caption: '#', width: '100',
                     filter: new CMInputConfig({
                       dataType: 'integer'
                     })
                    },
                    {field: 'codigo', caption: 'Código', width: '100',
                     filter: new CMInputConfig({
                       dataType: 'integer'
                     })
                    },
                    {field: 'cnpj', caption: 'CNPJ',  width: '200',
                     filter: new CMInputConfig({
                       mask: '99.999.999/9999-99'
                     })
                    },
                    {field: 'nome', caption: 'Razão Social',
                     filter: new CMInputConfig({
                       maxlength: 100
                     })
                    }
                   ];
  }

}