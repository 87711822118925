// tslint:disable
import { Injector, Component } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent, CMInputConfig } from './../../@cmcore/component';
import { ContaContabilEditComponent } from './conta-contabil-edit.component';
import { HttpClient } from '@angular/common/http';
import { FuncsGlobais } from '../funcs.globais';
import { CategoriaContaContabilBrowse } from '..';

@Component({
  selector: 'conta-contabil-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class ContaContabilBrowseComponent extends CMFormBrowse2Component {

    constructor(_injector: Injector) {
        super(_injector);
        this.controllerName = 'ContaContabil';
        this.formEditComponent = ContaContabilEditComponent;
        this.fixedFooterFormEdit = false;
        this.columns = [{field: 'codigo', caption: '#', filter: true},
        {field: 'descricao',   caption: 'Conta Contabil', filter: true},
        {field: 'provisao',   caption: 'Orçamento Prev.', filter: true},
        { 
          field: 'categoriacontacontabil_descricao',   
          caption: 'Categoria Contabil',
          filterField: "categoriacontacontabil.id",
          filterValue: "categoriacontacontabil.codigo",
          fieldBottom: "categoriacontacontabil.descricao", 
          filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            CategoriaContaContabilBrowse.pesquisa(sender, this.valuesFilters, "Categoria de Conta Contábil");
          },
        }),},
        {field: 'entsai',   caption: 'Conta de:', filter: new CMInputConfig({
          values: [{value: 'Pagamento'}, {value: 'Recebimento'}],
        })},
        {
          field: 'tipo',   
          caption: 'Tipo de Conta DRE:', 
          filter: new CMInputConfig({
            values: [
              {value: 'DespesaFixa', text: 'Despesa Fixa'},
              {value: 'CustoVariavel', text: 'Custo Variável'}, 
              {value: 'ReceitaRecorrente', text: 'Receita Recorrente'}, 
              {value: 'ReceitaFinanceira', text: 'Receita Financeira'}, 
              {value: 'ReceitaComProdutos', text: 'Receita Com Produtos'}, 
              {value: 'ImpostoDeRenda', text: 'Imposto de Renda'}, 
              {value: 'DespesaFinanceira', text: 'Despesa Financeira'}, 
              {value: 'Investimento', text: 'Investimento'}, 
              {value: 'CustoMercadoriaAdquirida', text: 'Custo de Mercadoria Adquirida'}, 
              {value: 'NaoAplicavel', text: 'Não Aplicável'}
            ],
          }),
        },
        //{field: 'especial',   caption: 'Especial', filter: true},
        //{field: 'tipo',   caption: 'Tipo de Conta - DRE', filter: true},
        //{field: 'ativo',   caption: 'Ativo', filter: true}
                      ];
      }
    
    getTitle() {
    return 'Conta Contábil';
    }
    
    static pesquisa(
      sender: any,
      target: any,
      tipo: string,
      targetProp?: string,
      onAfterSelect?: Function,
      extraFilters?: Object,
      extraColumns?: Array<any>
    ) {
      if (target) {
        let columns = [
          { field: "codigo", caption: "#" , filter: true},
          {
            field: "descricao",
            caption: "Descrição",
            filter: new CMInputConfig({
              maxlength: 60,
            }),
          },
          {
            field: "provisao",
            caption: "Orçamento Prev:",
            filter: false,
          },
          {
            field: 'entsai',   
            caption: 'Conta de:', 
            filter: new CMInputConfig({
              values: [{value: 'Pagamento'}, {value: 'Recebimento'}],
            }),
          },
          {
            field: 'tipo',   
            caption: 'Tipo de Conta DRE:', 
            filter: new CMInputConfig({
              values: [
                {value: 'DespesaFixa'},
                {value: 'CustoVariavel'}, 
                {value: 'ReceitaRecorrente'}, 
                {value: 'ReceitaFinanceira'}, 
                {value: 'ReceitaComProdutos'}, 
                {value: 'ImpostoDeRenda'}, 
                {value: 'DespesaFinanceira'}, 
                {value: 'Investimento'}, 
                {value: 'CustoMercadoriaAdquirida'}, 
                {value: 'NaoAplicavel'}
              ],
            }),
          },
          { 
            field: 'cdescricao',   
            caption: 'Categoria Contabil',
            filterField: "cdescricao.id",
            filterValue: "cdescricao.codigo",
            fieldBottom: "cdescricao.descricao", 
            filter: true,
          }
        ];
        if (extraColumns) {
          extraColumns.forEach((column) => {
            columns.push(column);
          });
        }
        CMFormPesquisaComponent.defaultPesquisa(
          sender,
          "contacontabil",
          "Pesquisa de " + tipo,
          columns,
          target,
          targetProp,
          "codigo",
          onAfterSelect,
          undefined,
          undefined,
          extraFilters
        );
        //console.log("sender: ", sender, "contacontabil: ", "tipo: ", tipo, "columns: ", columns, "target: ", target, "targetProp: ", targetProp, "codigo",
        //"onAfterSelect", onAfterSelect, "extraFilters: ", extraFilters)
      }
    }
    
}
