// tslint:disable
import { Component, Input, Output, EventEmitter } from '@angular/core';

import { CMInputConfig, CMGridConfig } from '../../component';
import { CMInput } from '../../decorator';

@Component({
    selector: 'cmimage',
    templateUrl: 'cmimage.component.html',
    styleUrls: ['cmimage.component.scss']
})

export class CMImageComponent {

  public idInnerInputFile = 'innerInputFile'+CMGridConfig.newId();
  private _src: string = undefined;
  private urlError: boolean = false;
  @Input()
  get src() {
    return this._src;
  }
  set src(value: string) {
    if (this._src !== value) {
      this.urlError = false;
      this._src = CMInputConfig.trimValue(value);
      this.loaded = (this._src === '');
    }
  }

  getSrc() {
    let r = CMInputConfig.trimValue(this._src);
    if ((r === '') || (this.urlError))
      r = this.default;
    return r;
  }

  @Input()
  href: string = '';

  @Input()
  target: string = '';

  @Input() default: string = 'sem-imagem.png';

  @Input() @CMInput() allowChange: boolean = false;
  @Input() maxHeightChange: number = 200;

  @Output('load')
  _load = new EventEmitter();

  public loaded: boolean = false;

  _urlError() {
    this.urlError = true;
  }

  loadedComplete() {
    this.loaded = true;
    this._load.emit(this);
  }

  render(src, outputFormat, MAX_HEIGHT){
	  var image = new Image();
	  image.onload = () => {
		  var canvas = document.createElement("canvas");
		  if (image.height > MAX_HEIGHT) {
			  image.width *= MAX_HEIGHT / image.height;
			  image.height = MAX_HEIGHT;
		  }
		  var ctx = canvas.getContext("2d");
		  ctx.clearRect(0, 0, canvas.width, canvas.height);
		  canvas.width = image.width;
		  canvas.height = image.height;
		  ctx.drawImage(image, 0, 0, image.width, image.height);
      this.src = canvas.toDataURL(outputFormat);
	  };
	  image.src = src;
  }

  change(event) {
    if ((this.allowChange) && (event.target.files.length > 0)) {
      let file = event.target.files[0];
			if (file.type.match(/image.*/)) {
        this.loaded = false;
        let URL = window.URL;
        let src = URL.createObjectURL(file);
        this.render(src, file.type, this.maxHeightChange);
      }
      else
        this.src = '';
    }
    event.target.value = '';
  }

  selectFile() {
    $('#'+this.idInnerInputFile).click();
  }

  clear() {
    this.src = '';
  }

}