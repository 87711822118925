// tslint:disable
import { Component, Injector } from "@angular/core";
import { FuncsGlobais } from "../funcs.globais";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMValidatorEmail,
} from "./../../@cmcore/component";

@Component({
  selector: "config-geral-edit",
  templateUrl: "config-geral-edit.component.html",
})
export class ConfigGeralEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_htmlatualizacoes";
  }

  getTitle() {
    return "Configuração Geral";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "ConfigGeral";
    this.setModelConfig();
  }

  setModelConfig() {
    this.modelConfig = {
      modooperacao: new CMInputConfig({
        form: this,
        caption: "Modo de Operação",
        values: [{ value: "Nivel1" }, { value: "Nivel2" }],
      }),
      htmlatualizacoes: new CMInputConfig({
        form: this,
        caption: "O que há de novo",
        required: true,
      }),
      sat_cnpjsoftwarehouse: new CMInputConfig({
        form: this,
        caption: "CNPJ da Software House",
        required: true,
        mask: "99.999.999/9999-99",
      }),
      sat_infocertificadodigital: new CMInputConfig({
        form: this,
        caption: "Informações do CD",
      }),
      sat_senhacertificadodigital: new CMInputConfig({
        form: this,
        caption: "Senha",
        required: true,
        criptografado: true,
      }),
      urlhubmarketplace: new CMInputConfig({
        form: this,
        caption:
          "Url do Hube de Marketplace (Ex: http://servidor.com.br:porta)",
        required: false,
        maxlength: 255,
        visibleFunc: () => this.modoOperacao === "Nivel2",
      }),
      email: new CMInputConfig({
        form: this,
        caption: "e-Mail",
        maxlength: 100,
        customvalidators: [new CMValidatorEmail()],
      }),
      smtp: new CMInputConfig({
        form: this,
        caption: "Servidor (SMTP)",
        maxlength: 50,
      }),
      portsmtp: new CMInputConfig({
        form: this,
        caption: "Porta",
        dataType: "integer",
      }),
      protocol: new CMInputConfig({
        form: this,
        caption: "Protocolo",
        values: [
          { value: "Nenhum" },
          { value: "Padrao", text: "Padrão" },
          { value: "SSL" },
          { value: "TLS" },
          { value: "TLSv1" },
          { value: "TLSv1R", text: "TSLv1 requerido" },
          { value: "STARTTLS" },
        ],
      }),
      usernamesmtp: new CMInputConfig({
        form: this,
        caption: "Usuário",
        maxlength: 100,
      }),
      passwordsmtp: new CMInputConfig({
        form: this,
        caption: "Senha",
        maxlength: 50,
        criptografado: true,
      }),
      valorassinatura: new CMInputConfig({
        form: this,
        caption: "Valor da assinatura",
        dataType: "Double",
      }),
      diasprimeirovencimentoassinatura: new CMInputConfig({
        form: this,
        caption: "Dias para o primeiro vencimento da assinatura",
        dataType: "integer",
      }),
    };
  }

  get modoOperacao() {
    return FuncsGlobais.paramEmpresa.modoOperacao;
  }  

  onCompleteUpload(sender: any) {
    CMFormGlobaisComponent.showSpinner();
    sender.form.postSubscribe(
      "/api/" + sender.form.controllerName + "/UploadCertificadoDigital",
      JSON.stringify({
        keyserver: sender.keyserver,
        senha: sender.form.model.sat_senhacertificadodigital,
      }),
      (result) => {
        if (result["result"] === "ok") {
          sender.form.model.sat_infocertificadodigital = result["info"];
          CMFormGlobaisComponent.toastSucesso("Arquivo enviado com sucesso");
        } else {
          let str: string = '<p class="divider"></p><div class="container">';
          str += "<p><b>Mensagem:</b> " + result["result"] + "</p>";
          str += '</div><p class="divider"></p>';
          CMFormGlobaisComponent.showMessageAviso(str);
        }
        sender.form.modalFormCertificadoClose();
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        sender.form.modalFormCertificadoClose();
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      }
    );
  }

  carregarCertificadoDigital() {
    if (this.model.sat_senhacertificadodigital === "")
      CMFormGlobaisComponent.toastErro(
        "Informe a senha do certificado digital"
      );
    else CMFormModalComponent.open("modalFormCertificado");
  }

  modalFormCertificadoClose() {
    CMFormModalComponent.close("modalFormCertificado");
  }
}
