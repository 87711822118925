// tslint:disable
import { Component, OnInit, Input, HostListener } from "@angular/core";

import { CMInputComponent } from "../../component";
import { CMInput } from "../../decorator";
import { CMMenuItemComponent } from "./cmmenu-item.component";

declare var $: any;

@Component({
  selector: "cmmenu",
  templateUrl: "./cmmenu.component.html",
  styleUrls: ["./cmmenu.component.scss"],
})
export class CMMenuComponent implements OnInit {
  @Input()
  @CMInput()
  usaMenuMobile: boolean = true;

  @Input()
  @CMInput()
  fixed: boolean = true;

  @Input()
  additionalClass: string = "";

  @Input()
  @CMInput()
  paddingLeft: number = 0;

  @Input()
  @CMInput()
  paddingRight: number = 0;

  @Input()
  @CMInput()
  minHeightDesktop: string = "";

  @Input()
  @CMInput()
  maxWidthDesktop: string = "";

  @Input()
  name: string = "cmmenu_" + CMInputComponent.nextGlobalId().toString();

  getStyles() {
    let styles = {};
    if (this.IsShowMenuDesktop()) {
      if (this.minHeightDesktop !== "")
        styles["min-height"] = this.minHeightDesktop;
      if (this.maxWidthDesktop !== "")
        styles["max-width"] = this.maxWidthDesktop;
    }
    return styles;
  }

  getMobileId() {
    return "mobile_" + this.name;
  }

  static windowWidth: number = window.innerWidth;
  static windowHeight: number = window.innerHeight;

  private setWindowWidth(value) {
    CMMenuComponent.windowWidth = value;
  }
  private setWindowHeight(value) {
    CMMenuComponent.windowHeight = value;
  }

  public items: CMMenuItemComponent[] = [];

  constructor() {}

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    try {
      this.setWindowWidth(document.getElementsByTagName("body")[0].clientWidth);
      this.setWindowHeight(
        document.getElementsByTagName("body")[0].clientHeight
      );
    } catch (error) {
      this.setWindowWidth(window.innerWidth);
      this.setWindowHeight(window.innerHeight);
    }
  }

  ngOnInit() {
    if (this.usaMenuMobile) {
      setTimeout(() => {
        $(".sidenav").sidenav({
          draggable: true, // Choose whether you can drag to open on touch screens
        });
      }, 1000);
    }
  }

  static inDesktop(usaMenuMobile: boolean = true) {
    if (usaMenuMobile) return CMMenuComponent.windowWidth > 992;
    else return true;
  }

  IsShowMenuDesktop() {
    return CMMenuComponent.inDesktop(this.usaMenuMobile);
  }

  addItem(item: CMMenuItemComponent) {
    this.items.push(item);
    if (item.ordem !== 0) this.items.sort((a, b) => a.ordem - b.ordem);
  }
}
