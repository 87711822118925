// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMFormPesquisaComponent,
  CMInputConfig,
} from "./../../@cmcore/component";
import { CFeEditComponent } from "./../index";

@Component({
  selector: "cfe-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class CFeBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "cfe";
    this.formEditComponent = CFeEditComponent;
    this.columns = [
      {
        field: "id",
        caption: "#",
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "nnf",
        caption: "Núm. Cupom",
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "cnf",
        caption: "Caixa",
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "serie",
        caption: "Serie SAT",
        filter: new CMInputConfig({
          dataType: "integer",
        }),
      },
      {
        field: "dhemi",
        caption: "Data",
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
      },
      {
        field: "vnf",
        caption: "Valor Total",
      },
      {
        field: "status",
        caption: "Status",
      },
    ];
  }

  getTitle() {
    return "CF-e";
  }

  static pesquisa(
    sender: any,
    target: any,
    targetProp?: string,
    onAfterSelect?: Function,
    idCliente?: number,
    tipoPagamento?: string
  ) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "cfe",
        "Pesquisa de CF-e",
        [
          {
            field: "id",
            caption: "#",
            filter: new CMInputConfig({
              dataType: "integer",
            }),
          },
          {
            field: "nnf",
            caption: "Núm. Cupom",
            filter: new CMInputConfig({
              dataType: "integer",
            }),
          },
          { field: "pessoa_nome", caption: "Cliente", filter: true },
          { field: "dhemi", caption: "Data", filter: true, mask: "99/99/9999" },
        ],
        target,
        targetProp,
        "id",
        onAfterSelect,
        undefined,
        undefined,
        {
          idcliente: idCliente,
          tipopagamento: tipoPagamento,
          status: "Autorizado",
        }
      );
    }
  }
}
