// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormBrowse2Component, CMFormPesquisaComponent } from './../../@cmcore/component';
import { CESTEditComponent } from './cest-edit.component';

@Component({
  selector: 'cest-browse',
  templateUrl: './../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html',
  styleUrls: ['./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss']
})
export class CESTBrowseComponent extends CMFormBrowse2Component {

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = 'cest';
    this.formEditComponent = CESTEditComponent;
    this.fixedFooterFormEdit = false;
    this.columns = [{field: 'codigo', caption: 'Código', width: '150', filter: true},
                    {field: 'descricao', caption: 'Descrição', filter: true},
                    {field: 'ncmsh',   caption: 'NCM / SH', filter: true, visible: false},
                   ];
  }

  getTitle() {
    return 'CEST';
  }

  static pesquisa(sender: any, target: any, ncmsh: string) {
    if (target) {
      let caption: string = 'Pesquisa de CEST';
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        'cest',
        caption,
        [{field: 'codigo', caption: 'Código', width: '150', filter: true},
         {field: 'descricao', caption: 'Descrição', filter: true},
         {field: 'ncmsh', caption: 'NCM / SH', filter: true, value: ncmsh, visible: false},
        ],
        target,
        undefined,
        'codigo',
        undefined,
        undefined,
        "auto");
    }
  }
  
  static pesquisa2(sender: any, target: any, targetProp: any = undefined) {
    if (target) {
      CMFormPesquisaComponent.defaultPesquisa(
        sender,
        "cest",
        "Pesquisa de CEST",
        [{field: 'codigo', caption: 'Código', width: '150', filter: true},
         {field: 'descricao', caption: 'Descrição', filter: true},
         {field: 'ncmsh', caption: 'NCM / SH', filter: true, value: "", visible: false},
        ],
        target,
        targetProp,
        "codigo",
        undefined,
        undefined,
        "auto"
      );
    }
  }

}