// tslint:disable
import { Component, Injector } from "@angular/core";
import { DatePipe } from '@angular/common';

import {
  CMInputConfig,
  CMFormExecComponent,
  CMFormGlobaisComponent,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMFormModalComponent,
  CMFormPesquisaV2Component,
} from "../../@cmcore/component";
import { FuncsGlobais } from "../funcs.globais";
import { AppComponent } from "src/app/app.component";
import { ProdutoBrowseComponent } from "../produto";
import { ClienteBrowseComponent } from "../cliente";
import { isNumber } from "util";
import { PDVAcoesComponent } from "../pdv-acoes";
import { PreVendaBrowseComponent } from "../prevenda";

declare var $: any;

@Component({
  templateUrl: "pdv.component.html",
  styleUrls: ["pdv.component.scss"]
})
export class PDVComponent extends CMFormExecComponent {
  private sub: any;
  title: string = "";
  titleDestinatario: string = "";
  static defaultClassRowMain: string = "row";
  static heightMenuLateral2: string = "unset";
  static heightRouterOutlet: string = "100%";
  specialKeys = [8, 9, 13, 16, 17, 18, 19, 20, 27, 32, 33, 34, 35, 36, 37, 38, 39, 40, 45, 46,
     56, 91, 92, 93, 106, 110, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 144, 145, 188, 190, 194];

  datahoje: string = new Date().toLocaleDateString('pt-BR', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  });
  currentTime: Date = new Date();
  hora: string;
  minutos: string;

  getFirstComponentName(){
    return "model_novo_produto";
  }

  ngOnDestroy() {
    this.geraPreVenda();
  }
  
  defaultClassRowMain() {
    return PDVComponent.defaultClassRowMain;
  }

  isCharacterKey(event) {
    return !this.specialKeys.includes(event.keyCode);
  }
  
  get heightRouterOutlet() {
    return PDVComponent.heightRouterOutlet;
  }

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }

  constructor(_injector: Injector) {
    super(_injector);
    
    let menuprincipal = document.getElementsByClassName('menuPrincipalInnerRoute')[0];
    menuprincipal.setAttribute('style', 'display: none;');

    let menulateral = document.getElementsByTagName('menu-lateral')[0];
    menulateral.setAttribute('style', 'display: none;');
    this.setfilterConfig();

    this.getObjectData('cliente',
    [
      {'field': 'nome', 'value': 'CONSUMIDOR'},
    ],
    object => {
      this.filter.filtro.cliente = object;
      
      if (object.codigo === "0") {
        
        this.getObjectData('cliente',
        [
          {'field': 'nome', 'value': 'Consumidor'},
        ],
        object => {
          this.filter.filtro.cliente = object;
          
          if (object.codigo === "0") {
            
            this.getObjectData('cliente',
            [
              {'field': 'nome', 'value': 'consumidor'},
            ],
            object => {
              this.filter.filtro.cliente = object;
            });
      
          }
        });
      }
    });


  }

  ngOnInit() {
    super.ngOnInit();
    window.onbeforeunload = function() {
      var message = 'Do you want to leave this page?';
      return message;
  }
    setInterval(() => {
      this.currentTime = new Date();
      this.hora = this.currentTime.getHours().toString().padStart(2,"0");
      this.minutos = this.currentTime.getMinutes().toString().padStart(2,"0");
    }, 1000);
  }

  ngAfterViewInit() {window.onbeforeunload = function() {
    var message = 'Do you want to leave this page?';
    return message;
  }
    this.focusFirstComponentEdit();
  }

  heightScreen() {
    PDVComponent.heightMenuLateral2 = this.windowHeight + "px";
    return PDVComponent.heightMenuLateral2;
  }
  
  get heightMenuLateral2() {
    return AppComponent.heightMenuLateral2;
  }
  
  get overflowMenuLateral2() {
    if (AppComponent.useMenuLateral2) return "hidden";
    else return "unset";
  }
  
  getMainRouterOutletDisplay() {
    if (AppComponent.useMenuLateral2) return "flex";
    else return "block";
  }
  
  protected onKeydown(keyCode: number, event: KeyboardEvent) {
    super.onKeydown(keyCode, event);
    if (event.code === 'Escape'){
      let panelDelete = document.getElementById("formAcoesExcluirItemPDV");
      let panelFechamento = document.getElementById("formAcoesPDV");
      let formPesquisa = document.getElementById("modalFormPesquisaV2");

      if (((panelDelete === null) && (panelFechamento === null)) && (formPesquisa.style.display !== "block")) {
        event.preventDefault();
        this.router.navigate(["/home"]);
        let menuprincipal = document.getElementsByClassName('menuPrincipalInnerRoute')[0];
        menuprincipal.setAttribute('style', 'display: block;');
    
        let menulateral = document.getElementsByTagName('menu-lateral')[0];
        menulateral.setAttribute('style', 'display: block;');
      }
      else if (formPesquisa.style.display === "block") {
        CMFormModalComponent.close("modalFormPesquisaV2");
        CMFormPesquisaV2Component.inPesquisa = false;
      }
    }

    else if (event.code === 'F1'){
      event.preventDefault();

      this.geraPreVenda();

      this.filter.filtro.novo_quantidade = "1,00";
      this.filter.filtro.novo_valortotal = "";
      this.filter.filtro.novo_valorunitario = "";
      this.filter.filtro.novo_subtotal = "";
      this.filter.filtro.descricaoproduto = "";
      this.filter.dados.produtos = [];
      this.filter.filtro.novo_produto = {};
      this.filter.filtro.produto = {};
      this.filter.filtro["produto"] = {};
      this.filterConfig.filtro.novo_produto.components[0].focus();

    }
    else if (event.code === 'F2'){
      ClienteBrowseComponent.pesquisaPDV(undefined, this.filter.filtro, undefined, (cliente) => {
        //this.filter.filtro.cliente = Object.assign({}, cliente);

      });
    }
    else if (event.keyCode === 86 && event.ctrlKey) {
      event.preventDefault();
    }
    else if (event.code === 'Space'){
      let formPesquisa = document.getElementById("modalFormPesquisaV2");

      if (formPesquisa.style.display !== "block"){
        this.beforeComponent = PDVAcoesComponent;
        setTimeout(() => {
          CMFormModalComponent.open("formAcoesPDV");
        }, 100);
      }
    }
    else if (event.code === 'Delete'){
      let formPesquisa = document.getElementById("modalFormPesquisaV2");
      let formAcoesPDV = document.getElementById("formAcoesPDV");
      if ((formPesquisa.style.display !== "block") && (formAcoesPDV === null)){
        this.beforeComponent = PDVAcoesComponent;
        setTimeout(() => {
          CMFormModalComponent.open("formAcoesExcluirItemPDV");
        }, 100);
      }
    }
    else if (event.code === 'F3'){
      event.preventDefault();
      PreVendaBrowseComponent.pesquisaPDV(
        undefined,
        this.filter.filtro,
        undefined,
        (prevenda) => {

          if (prevenda.cobranca.duplicatas.length === 0){
            this.filter.filtro.cliente = prevenda.cliente;
            this.filter.filtro.novo_valortotal = prevenda.vtotal;
            this.filter.filtro.idprevenda = prevenda.id;
            let indice = 0;
            prevenda.itens.forEach((item) => {
              indice += 1;
              item.produto.descricaocompleta = item.produto.descr;
              this.filter.dados.produtos.push({
                indice: indice,
                codigo: this.trimValue(item.produto.codigo),
                produto: item.produto,
                un: item.produto.un,
                quantidade: item.quantidade,
                vunitario: item.vunitario,
                vtotal: item.vtotal,
                especificacoes: item.produto.observacao
              })
            })
          }
          else{
            CMFormGlobaisComponent.toastErro("A Pré-venda seleciona já possui movimentação financeira.");
          }

        },
        undefined,
        undefined
      );
    }
  }

  setfilterConfig() {
    this.filterConfig = {
      filtro: {

        novo_produto: new CMInputConfig({
          form: this,
          padInfo: { size: 30 },
          maxlength: 30,
          customStyle: "background: white",
          caption: "Produto",
          onKeyUp: (sender) => {
            setTimeout(() => {
              console.log("sender.event: ", sender.event);
              if ((this.isCharacterKey(sender.event)) && (!(sender.event.key >= '0' && sender.event.key <= '9'))) {
                console.log("sender.event: ", sender.event);
                this.filter.filtro.produto = Object.assign({}, {});
                ProdutoBrowseComponent.pesquisaPDV(undefined, this.filter.filtro, "Todos", this.trimValue(sender.value), undefined, (prod, row) => {
                  if (row.descricaograde !== undefined){
                    prod.descricaocompleta = prod.descr + row.descricaograde;
                  }
                  else{
                    prod.descricaocompleta = prod.descr;
                  }
                  prod.idgrade = row.idgrade;

                  this.filter.filtro.produto = Object.assign({}, prod);

                  if (this.valueToFloat(this.filter.filtro.novo_valorunitario = prod.precos[0].vuncom) === 0){
                    this.filter.filtro.novo_valorunitario = "";
                    this.filterConfig.filtro.novo_valorunitario.components[0].focus();
                  }
                  else{
                    this.filter.filtro.novo_valortotal = this.floatToValue(this.valueToFloat(this.filter.filtro.novo_valortotal, 0) +
                    (this.valueToFloat(this.filter.filtro.novo_quantidade) * this.valueToFloat(this.filter.filtro.novo_valorunitario)));

                    let produto = {
                      indice: this.filter.dados.produtos.length+1,
                      codigo: this.trimValue(this.filter.filtro.produto.codigo),
                      produto: this.filter.filtro.produto,
                      un: this.filter.filtro.produto.un,
                      quantidade: this.filter.filtro.novo_quantidade,
                      vunitario: this.filter.filtro.novo_valorunitario,
                      vtotal: this.floatToValue(this.valueToFloat(this.filter.filtro.novo_quantidade) * 
                      this.valueToFloat(this.filter.filtro.novo_valorunitario)),
                      especificacoes: this.filter.filtro.produto.observacao,
                      idgrade: row.idgrade,
                    }

                    this.filter.filtro.novo_produto = "";
                    this.filter.dados.produtos.push(produto);
                    this.filter.filtro.novo_quantidade = 1;
                    this.filter.filtro.novo_valorunitario = "";
                    this.filter.filtro.novo_subtotal = "";
                    setTimeout(async () => {
                      sender.value = "";
                      sender.focus();
                    }, 100);
                    //sender.customStyleLabel = "color: #9e9e9e; top: -3px";
                  }
                });
                setTimeout(async () => {
                  sender.value = "";
                  sender.focus();
                }, 300);
              }
            }, 300);
            if (sender.event.key === 'Enter') {
              
              if (this.filter.filtro.novo_quantidade <= '0'){
                CMFormGlobaisComponent.toastErro('Quantidade Inválida');
              }
              else {

                if (this.trimValue(this.filterConfig.filtro.novo_produto.components[0].value).length > 11) {
                  this.getObjectData('produto',
                  [
                    {'field': 'ean', 'value': this.filterConfig.filtro.novo_produto.components[0].value},
                  ],
                  object => {
                    object.descricaocompleta = object.descr;
                    this.filter.filtro.produto = Object.assign({}, object);
                    if (this.valueToFloat(this.filter.filtro.novo_valorunitario = object.precos[0].vuncom) === 0){
                      this.filter.filtro.novo_valorunitario = "";
                      this.filterConfig.filtro.novo_valorunitario.components[0].focus();
                    }
                    else {
                      this.filter.filtro.novo_valortotal = this.floatToValue(this.valueToFloat(this.filter.filtro.novo_valortotal, 0) +
                      (this.valueToFloat(this.filter.filtro.novo_quantidade) * this.valueToFloat(this.filter.filtro.novo_valorunitario)));
                      
                      let produto = {
                        indice: this.filter.dados.produtos.length+1,
                        codigo: this.trimValue(this.filter.filtro.produto.codigo),
                        produto: this.filter.filtro.produto,
                        un: this.filter.filtro.produto.un,
                        quantidade: this.filter.filtro.novo_quantidade,
                        vunitario: this.filter.filtro.novo_valorunitario,
                        vtotal: this.floatToValue(this.valueToFloat(this.filter.filtro.novo_quantidade) * 
                        this.valueToFloat(this.filter.filtro.novo_valorunitario)),
                        especificacoes: this.filter.filtro.produto.observacao,
                        idgrade: object.idgrade
                      }
    
                      this.filter.dados.produtos.push(produto);
                      this.filter.filtro.novo_quantidade = 1;
                      this.filter.filtro.novo_valorunitario = "";
                      this.filter.filtro.novo_subtotal = "";
                      this.filter.filtro.novo_produto = "";
                      setTimeout(() => {
                        sender.value = "";
                        sender.focus();
                      }, 50);

                      //sender.customStyleLabel = "color: #9e9e9e; top: -3px";
    
                    }
                  });
                }
                else{
                  this.filter.filtro.produto = Object.assign({}, {});
                  
                  sender.value = CMInputConfig.trimValue(sender.value);
                  let padInfo = undefined;
                  if (sender.inputConfig) {
                    padInfo = sender.inputConfig.padInfo;
                  } else {
                    padInfo = sender.padInfo;
                  }
                  if (padInfo !== undefined) {
                    let v = CMInputConfig.pad(
                      sender.value,
                      padInfo.size,
                      padInfo.char,
                      padInfo.left
                    );
                    if (CMInputConfig.trimValue(v) === "") sender.value = "";
                    else sender.value = v;
                  }
                  ProdutoBrowseComponent.pesquisaPDV(sender, this.filter.filtro, "Todos", sender.event.key, undefined, (prod) => {
                    prod.descricaocompleta = prod.descr;
                    this.filter.filtro.produto = Object.assign({}, prod);
                    if (this.valueToFloat(this.filter.filtro.novo_valorunitario = prod.precos[0].vuncom) === 0){
                      this.filter.filtro.novo_valorunitario = "";
                      this.filterConfig.filtro.novo_valorunitario.components[0].focus();
                    }
                    else {
                      this.filter.filtro.novo_valortotal = this.floatToValue(this.valueToFloat(this.filter.filtro.novo_valortotal, 0) +
                      (this.valueToFloat(this.filter.filtro.novo_quantidade) * this.valueToFloat(this.filter.filtro.novo_valorunitario)));
                      
                      let produto = {
                        indice: this.filter.dados.produtos.length+1,
                        codigo: this.trimValue(this.filter.filtro.produto.codigo),
                        produto: this.filter.filtro.produto,
                        un: this.filter.filtro.produto.un,
                        quantidade: this.filter.filtro.novo_quantidade,
                        vunitario: this.filter.filtro.novo_valorunitario,
                        vtotal: this.floatToValue(this.valueToFloat(this.filter.filtro.novo_quantidade) * 
                        this.valueToFloat(this.filter.filtro.novo_valorunitario)),
                        especificacoes: this.filter.filtro.produto.observacao,
                        idgrade: prod.idgrade
                      }

                      this.filter.dados.produtos.push(produto);
                      this.filter.filtro.novo_quantidade = 1;
                      this.filter.filtro.novo_valorunitario = "";
                      this.filter.filtro.novo_subtotal = "";
                      this.filter.filtro.novo_produto = "";
                      setTimeout(() => {
                        sender.value = "";
                        sender.focus();
                      }, 50);
                      //sender.customStyleLabel = "color: #9e9e9e; top: -3px";
    
                    }
                    //this.filter.filtro.novo_valorunitario = prod.precos[0].vuncom;
                    //this.filterConfig.filtro.novo_quantidade.components[0].focus();
                  });
                  
                }
              }

              //this.filterConfig.filtro.novo_produto.components[0].innerValue = "";
            }
            else if (sender.event.key === "*") {
              let element = sender.getElement();
              if ((element.value === "") || (element.value === "0")) {
                alert("Quantidade Inválida");
                setTimeout(() => {
                  sender.value = "";
                  sender.focus();
                }, 50);
              }
              else{
                this.filterConfig.filtro.novo_quantidade.components[0].value = element.value.replace(".", ",");
                setTimeout(() => {
                  sender.value = "";
                  sender.focus();
                }, 50);
              }
            }
          },
          onEnter: (elm) => {
            setTimeout(() => {
              if (this.filter.filtro.novo_quantidade === ""){
                this.filter.filtro.novo_quantidade = 1;
                this.filterConfig.filtro.novo_quantidade.components[0].customStyleLabel = "color: white; top: -3px";
              }
            }, 50)
            elm.customStyleLabel = "color: white; top: -3px";
          },
        }),
        produto: new CMInputConfig({
          form: this,
          required: true,
          padInfo: { size: 30 },
          caption: "Produto",
        }),
        
        novo_quantidade: new CMInputConfig({
          form: this,
          caption: "Quantidade",
          required: true,
          maxlength: 20,
          customStyle: "background: white",
          dataType: "double(2)",
          onKeyDown: (sender) => {
            if (sender.event.key === 'Enter') {
              if (this.filter.filtro.novo_quantidade <= 0){
                this.filter.filtro.novo_quantidade = 1;
              }
              sender.customStyleLabel = "color: #9e9e9e; top: -3px";
              this.filter.filtro.novo_subtotal = this.floatToValue(
                this.valueToFloat(this.filter.filtro.novo_quantidade) * this.valueToFloat(this.filter.filtro.novo_valorunitario)
              );
              this.filterConfig.filtro.novo_valorunitario.components[0].focus();
            }
          },
          onEnter: (elm) => {
            elm.customStyleLabel = "color: white; top: -3px";
          },
          onExit: (elm) => {
            if (this.filter.filtro.novo_quantidade <= 0){
              this.filter.filtro.novo_quantidade = 1;
            }
            this.filter.filtro.novo_subtotal = this.floatToValue(
              this.valueToFloat(this.filter.filtro.novo_quantidade) * this.valueToFloat(this.filter.filtro.novo_valorunitario)
            );
          },
          onBlur: (elm) => {
            if (elm.innerValue === ""){
              elm.customStyleLabel = "color: #9e9e9e; top: -3px";
            }
            else {
              elm.customStyleLabel = "color: white; top: -3px";
            }
          },
        }),
        novo_valorunitario: new CMInputConfig({
          form: this,
          caption: "Valor Unitário",
          required: true,
          maxlength: 20,
          customStyle: "background: white",
          dataType: "double(2)",
          onKeyDown: (sender) => {
            if (sender.event.key === 'Enter') {

              if (this.filter.filtro.novo_valorunitario <= '0'){
                CMFormGlobaisComponent.toastErro('Valor unitário inválido');
              }
              else if (!this.filter.filtro.produto.hasOwnProperty('codigo')){
                CMFormGlobaisComponent.toastErro('Produto inválido');
              }
              else if ((this.filter.filtro.novo_valorunitario !== "") || (this.filter.filtro.novo_produto !== "")) {

                sender.onBlur();

                this.filter.filtro.novo_valortotal = this.floatToValue(this.valueToFloat(this.filter.filtro.novo_valortotal, 0) +
                  (this.valueToFloat(this.filter.filtro.novo_quantidade) * this.valueToFloat(this.filter.filtro.novo_valorunitario)));
  
                let produto = {
                  indice: this.filter.dados.produtos.length+1,
                  codigo: this.trimValue(this.filter.filtro.produto.codigo),
                  produto: this.filter.filtro.produto,
                  un: this.filter.filtro.produto.un,
                  quantidade: this.filter.filtro.novo_quantidade,
                  vunitario: this.filter.filtro.novo_valorunitario,
                  vtotal: this.floatToValue(this.valueToFloat(this.filter.filtro.novo_quantidade) * 
                  this.valueToFloat(this.filter.filtro.novo_valorunitario)),
                  especificacoes: this.filter.filtro.produto.observacao
                }
  
                this.filter.dados.produtos.push(produto);
                this.filter.filtro.novo_quantidade = 1;
                this.filter.filtro.novo_valorunitario = "";
                this.filter.filtro.novo_subtotal = "";
                this.filter.filtro.novo_produto = "";
                //this.filterConfig.filtro.novo_produto.components[0].value = "";
                //Object.assign(this.filterConfig.filtro.novo_produto, {});
                this.filter.filtro.novo_valorunitario = "";
                this.filter.filtro.produto = Object.assign({}, {});
                sender.customStyleLabel = "color: #9e9e9e; top: -3px";
                setTimeout(() => {
                  this.filterConfig.filtro.novo_produto.components[0].value = "";
                  this.filterConfig.filtro.novo_produto.components[0].focus();
                }, 50);

              }
            }
          },
          onEnter: (elm) => {
            elm.customStyleLabel = "color: white; top: -3px";
          },
          onBlur: (elm) => {
            if (elm.innerValue === ""){
              elm.customStyleLabel = "color: #9e9e9e; top: -3px";
            }
            else {
              elm.customStyleLabel = "color: white; top: -3px";
            }
          },
        }),
        novo_valortotal: new CMInputConfig({
          form: this,
          caption: "Valor Total",
          required: true,
          disabled: true,
          customStyle: "background: lightyellow",
          dataType: "double(2)",
          onEnter: (elm) => {
            elm.customStyleLabel = "color: white; top: -3px";
          },
          onExit: (elm) => {
            elm.customStyleLabel = "color: #9e9e9e; top: -3px";
          },
        }),
        novo_subtotal: new CMInputConfig({
          form: this,
          caption: "Sub Total",
          visible: false,
          disabled: true,
          customStyle: "background: lightyellow;",
          dataType: "double(2)",
          onEnter: (elm) => {
            elm.customStyleLabel = "color: white; top: -3px";
          },
          onExit: (elm) => {
            elm.customStyleLabel = "color: #9e9e9e; top: -3px";
          },
        }),
        cliente: new CMInputConfig({
          form: this,
          caption: "Cliente",
          required: true,
          customStyle: "background: white",
          dataType: "double(2)",
          onEnter: (elm) => {
            elm.customStyleLabel = "color: white; top: -3px";
          },
          onExit: (elm) => {
            elm.customStyleLabel = "color: #9e9e9e; top: -3px";
          },
        }),
        descricaoproduto: new CMInputConfig({
          form: this,
          required: true,
          caption: "Descrição do Produto",
          customStyleLabel: "color: white",
          onChange: (elm) => {
            elm.customStyleLabel = "color: white; top: -3px";
          },
          onEnter: (elm) => {
            elm.customStyleLabel = "color: white; top: -3px";
          },
          onExit: (elm) => {
            elm.customStyleLabel = "color: #9e9e9e; top: -3px";
          },
        }),
        idprevenda: new CMInputConfig({
          form: this,
          dataType: "Integer",
          disabled: true,
        }),
      },
      dados: {
        produtos: new CMGridEditConfig({
          form: this,
          required: false,
          stickyNavBar: true,
          fields: {
            indice: new CMInputConfig({
              form: this,
              caption: "#",
              dataType: "Integer",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn()
            }),
            codigo: new CMInputConfig({
              form: this,
              caption: "Código",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn()
            }),
            produto: new CMInputConfig({
              form: this,
              caption: 'Produto',
              disabled: true,
              gridConfig: new CMGridEditConfigColumn({
                width: "500px",
                tipo: 'input-pesquisa',
                getValue: (row) => {
                  return this.getValueObject(row, 'produto.descricaocompleta');
                },
              })
            }),
            un: new CMInputConfig({
              form: this,
              caption: "UN",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn()
            }),
            quantidade: new CMInputConfig({
              form: this,
              caption: "QTD",
              dataType: "double(2)",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn(),
              hintButton: "Editar",
              showButtonClear: false,
            }),
            vunitario: new CMInputConfig({
              form: this,
              caption: "Valor",
              dataType: "double(2)",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn(),
              hintButton: "Preço",
              //dropdownValues: (row) => this.dropdownValuesVUnCom(row),
            }),
            vtotal: new CMInputConfig({
              form: this,
              caption: "Total",
              dataType: "double(2)",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn(),
              required: true,
            }),
            especificacoes: new CMInputConfig({
              form: this,
              caption: "Especificações",
              disabled: true,
              gridConfig: new CMGridEditConfigColumn()
            }),
          }
        }),
      }
    };
  }

  innerExec() {
    super.innerExec();
		this.geraPreVenda();
  }


  geraPreVenda() {

    CMFormGlobaisComponent.showSpinner();

    this.postSubscribe("/api/prevenda/GerarPreVendaPDV/FuncaoExtra",
    JSON.stringify({
      prevenda: this.filter.filtro,
      produtos: this.filter.dados.produtos,
      pagamento: {recebido: this.filter.filtro.novo_valortotal},
      duplicatas: []
    }),
    (result) => {
      if (result["result"] === "ok") {

        this.filter.filtro.novo_quantidade = "1,00";
        this.filter.filtro.novo_valortotal = "";
        this.filter.filtro.novo_valorunitario = "";
        this.filter.filtro.novo_subtotal = "";
        this.filter.filtro.descricaoproduto = "";
        this.filter.dados.produtos = [];
        this.filter.filtro.novo_produto = {};
        this.filter.filtro.produto = {};
        this.filter.filtro["produto"] = {};
        PDVAcoesComponent.inAddParcela = false;

        this.filterConfig.filtro.novo_produto.components[0].focus();
      } else {
        CMFormGlobaisComponent.toastErro(result["msg"]);
      }
      CMFormGlobaisComponent.hideSpinner();
    },
    (error) => {
      CMFormGlobaisComponent.hideSpinner();
      this.showMessageErrorHTTP("geraBaixas", error);
    })
  }
}
