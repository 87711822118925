// tslint:disable
import { PessoaBrowseComponent } from "..";

export class TransportadoraBrowseComponent {
  static pesquisa(
    sender: any,
    target: any,
    targetProp?: string,
    onAfterSelect?: Function
  ) {
    if (targetProp == undefined) targetProp = "transportadora";
    PessoaBrowseComponent.pesquisa(
      sender,
      target,
      "Transportadora",
      targetProp
    );
  }
}
