// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMInputConfig,
  CMFormGlobaisComponent,
  CMFormModalComponent,
} from "../../@cmcore/component";
import { ContaPagarReceberConsultaEditComponent } from "./conta-pagar-receber-consulta-edit.component";
import { ClienteBrowseComponent } from "../cliente/cliente-browse.component";
import { FuncsGlobais } from "../funcs.globais";
import { DuplicataReceberRelatoriosComponent } from "../duplicata-receber-relatorios/duplicata-receber-relatorios.component";
import { ContaContabilBrowseComponent } from "../conta-contabil";
import { FormaPagamentoBrowseComponent } from "../formapagamento";
import { ContaPagarReceberBaixaOutrosComponent } from "../conta-pagar-receber-baixa-outros";
import { PessoaBrowseComponent } from "../pessoa";

@Component({
  selector: "conta-pagar-receber-consulta-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class ContaPagarReceberConsultaBrowseComponent extends CMFormBrowse2Component {
  valuesOrigem = [];

  setValuesOrigem() {
    if (this.paramEmpresa.modoOperacao === "Nivel1"){
    this.valuesOrigem = [
      { value: "PreVenda", text: "Pré-Venda" },
      { value: "NFe", text: "NF-e" },
      { value: "CFe", text: "CF-e" },
      { value: "Lote", text: "Lote" },
      { value: "CupomDesconto", text: "Cupom de Desconto" },
      { value: "Todas" },
    ];
    }else {this.valuesOrigem = [
      { value: "PreVenda", text: "Pré-Venda" },
      { value: "NFe", text: "NF-e" },
      { value: "CFe", text: "CF-e" },
      { value: "Lote", text: "Lote" },
      { value: "CupomDesconto", text: "Cupom de Desconto" },
      { value: "NFCe", text: "NFC-e" },
      { value: "Todas" },
    ];

    }

  }

  constructor(_injector: Injector) {
    super(_injector);
    this.setValuesOrigem();
    this.controllerName = "duplicatareceber";
    this.formEditComponent = ContaPagarReceberConsultaEditComponent;
    this.showPrintButton = true;
    this.buttonsExtra = 1;
    this._hideShowFilters = true;
    this.columns = [
      {
        field: "origem",
        caption: "Origem",
        filter: new CMInputConfig({
          values: this.valuesOrigem,
        }),
        value: "Todas",
      },
      { field: "nduplicata", caption: "Documento" },
      { field: "dvenc", caption: "Data de Vencimento" },
      { field: "datacadastro", caption: "Data de Emissão" },
      {
        field: "dataini",
        caption: "Data de Vencimento Inicial",
        visible: false,
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
        value: this.firstDateLastMonth(),
      },
      {
        field: "datafim",
        caption: "Data de Vencimento Final",
        visible: false,
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
        value: this.lastDateNextMonth(),
      },
      {
        field: "cliente_nome",
        caption: "Cliente",
        filterField: "cliente.id",
        filterValue: "cliente.codigo",
        fieldBottom: "cliente.nome",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            ClienteBrowseComponent.pesquisa(sender, this.valuesFilters);
          },
        }),
      },
      {
        field: "datainiliquidacao",
        caption: "Data de Baixa Inicial",
        visible: false,
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
        //value: this.firstDateLastMonth(),
      },
      {
        field: "datafimliquidacao",
        caption: "Data de Baixa Final",
        visible: false,
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
        //value: this.lastDateNextMonth(),
      },
      {
        field: "datacadastroini",
        caption: "Data de Emissão Inicial",
        visible: false,
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
        //value: this.firstDateLastMonth(),
      },
      {
        field: "datacadastrofim",
        caption: "Data de Emissão Final",
        visible: false,
        filter: new CMInputConfig({
          mask: "99/99/9999",
        }),
        //value: this.lastDateNextMonth(),
      },
      {
        field: "nduplicata",
        caption: "Documento",
        visible: false,
        filter: new CMInputConfig({
          maxlength: 60,
        }),
      },
      {field: 'contacontabil_descricao', caption: 'Conta Contábil',
       filterField: 'contacontabil.id',
       filterValue: 'contacontabil.codigo',
       fieldBottom: 'contacontabil.descricao',
       filter: new CMInputConfig({
         dataType: "integer",
         pesqFunc: (sender) => {
           ContaContabilBrowseComponent.pesquisa(sender, this.valuesFilters, "Conta Contábil");
         },
       })
      },
      {
        field: "formapagamento_descricao",
        caption: "Forma de Pagamento",
        width: 250,
        filterField: "formapagamento.id",
        fieldBottom: "formapagamento.descricao",
        filter: new CMInputConfig({
          dataType: "integer",
          pesqFunc: (sender) => {
            FormaPagamentoBrowseComponent.pesquisa("todos", sender, this.valuesFilters);
          },
        }),
      },
      {
        field: "status",
        caption: "Status",
        visible: false,
        filter: new CMInputConfig({
          values: [
            { value: "Abertas" },
            { value: "Baixadas" },
            { value: "Todas" },
          ],
        }),
        value: "Abertas",
      },
      { field: "valor", caption: "Valor" },
      { field: "vsaldo", caption: "Saldo" },
      { field: "databaixa", caption: "Data de baixa" },
      { field: "geracao", caption: "Geração Boleto" },
    ];
    if (DuplicataReceberRelatoriosComponent.getRelatorioCustomizados().length === 0){
      this.getRelatoriosCustomizados();
    }
    console.log(DuplicataReceberRelatoriosComponent.getRelatorioCustomizados());
  }

  
  _print() {
    console.log('method override')
    if (this.diretoImprimir) this.print();
  }
  
  print() {
    console.log('method overriden');
    if (DuplicataReceberRelatoriosComponent.getRelatorioCustomizados().length > 0){
      this.openFormRelatoriosDuplicataReceber();
    }
    else{
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat("Print", data, this.controllerName);
    }
  }

  
  getRelatoriosCustomizados(){
    this.postSubscribeT<Array<Object>>(
      "/api/cupomdesconto/GetRelatoriosCustomizados",
      JSON.stringify({}),
      (result) => {
        result.forEach(element => {
          if (element['tipo'] === 'DuplicataReceber'){
            DuplicataReceberRelatoriosComponent.addRelatorioCustomizado({value: element['id'], text: element['descricao']});
          }
        });
      },
    );
  }
  
  openFormRelatoriosDuplicataReceber() {
    this.beforeComponent = DuplicataReceberRelatoriosComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formRelatoriosDuplicataReceber");
    }, 100);
  }

  getTitle() {
    return "Conta a Receber";
  }

  getShowActionAdd() {
    return false;
  }

  getShowActionRemove() {
    return false;
  }

  baixaTotal() {
    this.postSubscribeT<Array<string>>(
      "/api/" + this.controllerName.toLowerCase() + "/baixatotal/FuncaoExtra",
      JSON.stringify({ id: this.grid.fieldByName("id") }),
      (result) => {
        let result_str = result.pop();
        if (result_str === "ok") {
          CMFormGlobaisComponent.toastSucesso("Documento baixado");
          this.refresh();
          return true;
        } else {
          CMFormGlobaisComponent.toastErro(result_str);
          return false;
        }
      },
      (error) => {
        this.showMessageErrorHTTP("onPage", error);
      }
    );
  }

  geraboleto() {
    this.postSubscribeT<Array<string>>(
      "/api/" + this.controllerName.toLowerCase() + "/geraboleto/FuncaoExtra",
      JSON.stringify({ id: this.grid.fieldByName("id") }),
      (result) => {
        let result_str = result.pop();
        if (result_str === "ok") {
          CMFormGlobaisComponent.toastSucesso("Boleto Gerado");
          this.refresh();
          return true;
        } else {
          CMFormGlobaisComponent.toastErro(result_str);
          return false;
        }
      },
      (error) => {
        this.showMessageErrorHTTP("onPage", error);
      }
    );
  }

  loadDireitoButtonExtra(index: number) {
    if (index === 0) {
      this.getDireitosMenuBrowseButtonsExtra(index, "ImpressaoDuplicataReceberReduzida");
    }
  }

  buttonsExtraClick(index: number) {
    super.buttonsExtraClick(index);
    if (index === 0) {
      let data = { id: this.grid.fieldByName(this.fieldNameId()) };
      data = this.getDataPrint(data);
      this.showRelat(
        "/ImpressaoDuplicataReceberReduzida/FuncaoExtra",
        data,
        this.controllerName
      );
    }
  }

  buttonsExtraIcon(index: number) {
    let r: string = "";
    if (index === 0) r = "receipt";
    return r;
  }

  buttonsExtraHint(index: number) {
    let r: string = "";
    if (index === 0) r = "Imprimir Reduzido";
    return r;
  }

  buttonsExtraVisible(index: number) {
    let r: boolean = super.buttonsExtraVisible(index);
    if (r) {
      let isCurrent: boolean = false;
      if (this.grid) {
        isCurrent = this.grid.isCurrent;
      }
      if (index === 0) r = isCurrent;
    }
    return r;
  }

  get paramEmpresa() {
    return FuncsGlobais.paramEmpresa;
  }
}
