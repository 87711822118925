// tslint:disable
import {Component, Injector, ViewChild} from '@angular/core';

import {CMFormComponent} from './../../@cmcore/component/cmform/cmform.component';

import {
  CMInputConfig,
  CMValidatorEmail,
  CMFormGlobaisComponent,
  CMTabComponent,
  CMFormModalComponent,
} from './../../@cmcore/component';
import {NFCeBrowseComponent} from './../index';
@Component({
  selector: 'nfce-acoes',
  templateUrl: 'nfce-acoes.component.html',
  styleUrls: ['nfce-acoes.component.scss'],
})
export class NFCeAcoesComponent extends CMFormComponent {
  _owner: NFCeBrowseComponent = undefined;
  get owner() {
    return this._owner;
  }
  set owner(value) {
    this._owner = value;
    if (this.owner) {
      this.loadDadosNFCe();
      this.voltarAcoes();
    }
  }

  configNFCe: any = {
    
    cancelamento: {
      xjust: new CMInputConfig({
        form: this,
        caption: 'Justificativa',
        disabledFunc: () => {
          return !this.nfceAutorizado();
        },
      }),
    },
    email: {
      para: new CMInputConfig({
        form: this,
        caption: 'Para',
        required: true,
        customvalidators: [new CMValidatorEmail()],
      }),
      cc: new CMInputConfig({
        form: this,
        caption: 'CC',
        customvalidators: [new CMValidatorEmail()],
      }),
      assunto: new CMInputConfig({form: this, caption: 'Assunto', required: true}),
      mensagem: new CMInputConfig({form: this, caption: 'Mensagem', required: true}),
    },
  };

  dadosNFCe: any = {
    infnfe: {
      status: "",
      ide: {
        tpemiss: "",
        nnfcontingencia: "0",
      },
    },
    cancelamento: {
      temxml: '',
      xjust: '',
    },
    protnfe: {
      temxml: "",
      result_cstat: "",
      result_xmotivo: "",
    },
    email: {
      para: '',
      cc: '',
      assunto: '',
      mensagem: '',
    },
  };
  loadDadosNFCe() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      "/api/nfce/GetDadosAcoesNFCe/FuncaoExtra",
      JSON.stringify({ id: this.owner.idFormAcoesNFe}),
      (result) => {
        this.dadosNFCe = result;
        this.dadosNFCe.email.assunto = "";
        this.dadosNFCe.email.mensagem = "";
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      },
    );
  }

  constructor(_injector: Injector) {
    super(_injector);
  }

  nfceAutorizadoCancelado() {
    return (
      this.nfceAutorizado() || this.dadosNFCe.infnfe.status === "Cancelado"
    );
  }

 nfceContingencia() {
    return this.dadosNFCe.infnfe.ide.tpemis === "Contingência";
  }

  nfceEmContingencia() {
    return this.dadosNFCe.infnfe.ide.nnfcontingencia !== "0";
  }
  nfceCancelado() {
    return this.dadosNFCe.infnfe.status === "Cancelado";
  }

  nfceAutorizado() {
    return this.dadosNFCe.infnfe.status === "Autorizado";
  }

  nfceEmProcessamento() {
    return this.dadosNFCe.infnfe.status === "EmProcessamento";
  }

  nfceEmDigitacao() {
    return this.dadosNFCe.infnfe.status === "EmDigitacao";
  }

 nfceEmPreAutorizacao() {
    return this.dadosNFCe.infnfe.status === "PreAutorizacao";
  }

  nfeDenegada() {
    return this.dadosNFCe.infnfe.status === "Denegado";
  }
  nfceEmDigitacaoEmProcessamento() {
    return (
      this.nfceEmDigitacao() ||
      this.nfceEmProcessamento() ||
      this.nfceEmPreAutorizacao()
    );
  }

  @ViewChild('tbAcoes', {static: true}) tbAcoes: CMTabComponent;
  voltarAcoes() {
    this.tbAcoes.active = true;
  }

  getCaptionTransmissao(padrao) {
    let r: string = padrao;
    if (this.nfceEmContingencia()) r = "Consultar processamento";
    else if (this.nfceEmDigitacao() || this.nfceEmPreAutorizacao())
      r = "Transmitir";
    else if (this.nfceEmProcessamento()) r = "Consultar processamento";
    else if (
      this.dadosNFCe.protnfe.temxml === "True" &&
      this.dadosNFCe.protnfe.result_cstat === "100"
    ) {
      r = "Enviar e-Mail";
    }
    return r;
  }

  getCaptionCancelamento() {
    let r: string = "";
    if (this.nfceAutorizado()) r = "Cancelar NFC-e";
    else if (
      this.nfceCancelado() &&
      this.dadosNFCe.cancelamento.temxml === "True"
    ) {
      r = "Enviar e-Mail";
    }
    return r;
  }

  clickTransmissao() {
    if (
      this.nfceEmDigitacao() ||
      this.nfceEmProcessamento() ||
      this.nfceEmPreAutorizacao()
    )
      this.transmitirNFCe();
    else if (this.dadosNFCe.protnfe.temxml === "True")
      this.openEmail("protnfe");
  }

  clickCancelamento() {
    if (this.nfceAutorizado()) this.cancelarNFCe();
    else if (this.dadosNFCe.cancelamento.temxml === "True")
      this.openEmail("cancelamento");
  }

  setActiveTab(tab, enabled) {
    if (enabled) tab.active = true;
  }

  titleEmail: string = '';
  tipoEmail: string = '';
  openEmail(tipoEmail) {
    this.titleEmail = '';
    this.tipoEmail = tipoEmail;
    if (tipoEmail === "protnfe")
      this.titleEmail = "e-Mail do XML de transmissão";
    else if (tipoEmail === "cancelamento")
      this.titleEmail = "e-Mail do XML de cancelamento";
    this.dadosNFCe.email.assunto = this.dadosNFCe[tipoEmail].email_assunto;
    this.dadosNFCe.email.mensagem = this.dadosNFCe[tipoEmail].email_mensagem;
    CMFormModalComponent.open("formAcoesNFCeEmail");
  }

  enviarEmail() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      '/api/nfce/EnviarEmail',
      JSON.stringify({
        id: this.dadosNFCe.id,
        tipo: this.tipoEmail,
        para: this.dadosNFCe.email.para,
        cc: this.dadosNFCe.email.cc,
        assunto: this.dadosNFCe.email.assunto,
        mensagem: this.dadosNFCe.email.mensagem,
      }),
      (result) => {
        this.processaRetorno(this.titleEmail, result, "formAcoesNFCeEmail");
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      },
    );
  }

  getCaptionImpressao() {
    let r: string = "Imprimir DANFE";
    if (this.nfceEmDigitacao() || this.nfceEmPreAutorizacao())
      r = "Imprimir Pré-DANFE";
    if (this.nfeDenegada()) r = "Imprimir Pré-DANFE";
    return r;
  }

  impressaoDANFE() {
    this.showRelat(
      "Print",
      { id: this.dadosNFCe.id, nnf: this.dadosNFCe.infnfe.ide.nnf },
      "NFCe"
    );
  }

  transmitirNFCe() {
    if (this.nfceEmDigitacaoEmProcessamento()) {
      CMFormGlobaisComponent.showSpinner();
      this.postSubscribe(
        "/api/nfce/GerarNFCe",
        JSON.stringify({ id: this.dadosNFCe.id }),
        (result) => {
          this.processaRetorno("Retorno da Transmissão", result);
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("transmitirNFCe", error);
        }
      );
    }
  }

  cancelarNFCe() {
    let ok: boolean = true;
    if (this.trimValue(this.dadosNFCe.cancelamento.xjust).length < 15) {
      ok = false;
      CMFormGlobaisComponent.toastErro(
        this.configNFCe.cancelamento.xjust.caption +
          " deve ter pelo menos 15 caracteres"
      );
    }
    if (ok) {
      if (this.nfceAutorizado()) {
        CMFormGlobaisComponent.showSpinner();
        this.postSubscribe(
          "/api/nfce/CancelaNFCe",
          JSON.stringify({
            id: this.dadosNFCe.id,
            xjust: this.dadosNFCe.cancelamento.xjust,
          }),
          (result) => {
            this.processaRetorno("Retorno do Cancelamento", result);
            CMFormGlobaisComponent.hideSpinner();
          },
          (error) => {
            CMFormGlobaisComponent.hideSpinner();
            CMFormGlobaisComponent.showMessageError(error);
          },
        );
      }
    }
  }

  close(formName) {
    if (this.owner) {
      this.owner.refresh();
      this.owner.beforeComponent = undefined;
    }
    CMFormModalComponent.close(formName);
  }

  retorno: any = {
    title: '',
    result: '',
    tipo: '',
    msg: '',
    cstat: '',
    xml: '',
    xml_conferencia: '',
    result_str: '',
    json: '',
    nnf: "",
    tipomsg: "",
    contingencia: "",
  };

  setRetorno(title, retorno) {
    this.retorno.title = title;
    this.retorno.result = this.trimValue(retorno.result);
    this.retorno.tipo = this.trimValue(retorno.tipo);
    this.retorno.msg = this.trimValue(retorno.msg);
    this.retorno.cstat = this.trimValue(retorno.cstat);
    this.retorno.xml = this.trimValue(retorno.xml);
    this.retorno.xml_conferencia = this.trimValue(retorno.xml_conferencia);
    this.retorno.result_str = this.trimValue(retorno.result_str);
    this.retorno.nnf = this.trimValue(retorno.nnf);
    this.retorno.tipomsg = this.trimValue(retorno.tipomsg);
    this.retorno.contingencia = this.trimValue(retorno.contingencia65);

    delete retorno.result;
    delete retorno.tipo;
    delete retorno.msg;
    delete retorno.cstat;
    delete retorno.xml;
    delete retorno.xml_conferencia;
    delete retorno.result_str;
    delete retorno.nnf;
    delete retorno.tipomsg;
    delete retorno.contingencia;
    this.retorno.json = retorno;
  }

  processaRetorno(title, retorno, closeFormOK = undefined) {
    this.setRetorno(title, retorno);
    if (this.retorno.result === "True") {
      this.loadDadosNFCe();
      if (this.retorno.tipomsg === "erro")
        CMFormGlobaisComponent.toastErro(this.retorno.result_str);
      else CMFormGlobaisComponent.toastSucesso(this.retorno.result_str);
      if (closeFormOK) this.close(closeFormOK);
    } else {
      if (this.retorno.tipo !== "str") {
        let myNode = document.getElementById("div_json");
        while (myNode.firstChild) myNode.removeChild(myNode.firstChild);
        let ui = this.tree(this.retorno.json);
        ui.className = "tree";
        myNode.appendChild(ui);
      }
      CMFormModalComponent.open("formAcoesNFCeRetorno");
    }
  }

  tree(data) {
    let r: HTMLElement = undefined;
    let ul = document.createElement('ul');
    for (var i in data) {
      let li = document.createElement('li');
      ul.appendChild(li);
      var t = document.createTextNode(i);
      li.appendChild(t);
      if (data[i] instanceof Object) li.appendChild(this.tree(data[i]));
      else {
        let textNode = document.createTextNode(' - ' + data[i]);
        li.appendChild(textNode);
      }
    }
    r = ul;
    return r;
  }

  nfceGerarContingencia() {
    if (this.nfceEmDigitacaoEmProcessamento()) {
      CMFormGlobaisComponent.showSpinner();
      this.postSubscribe(
        "/api/nfce/GerarContingencia",
        JSON.stringify({ id: this.dadosNFCe.id }),
        (result) => {
          this.processaRetorno("Retorno da Transmissão", result);
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("nfceGerarContingencia", error);
        }
      );
    }
  }
}
