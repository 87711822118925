// tslint:disable
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { routerOptions } from "./__recursos/recursos.routing";

const routes: Routes = [
  // otherwise redirect to home
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
