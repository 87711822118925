// tslint:disable
import { Component, Input, EventEmitter, Output } from '@angular/core';

import { CMInput } from '../../../decorator';

@Component({
  selector: 'cmform-exec',
  templateUrl: './cmform-exec-default.component.html',
  styleUrls: ['./cmform-exec-default.component.scss']
})
export class CMFormExecDefaultComponent {

    @Output()
    clickExec = new EventEmitter();
    _clickButtonExec(event: any) {
      this.clickExec.emit(event);
    }

    @Input()
    @CMInput()
    caption: string = '';

    
    @Input()
    showExecButton = true;

    @Input()
    @CMInput()
    iconeExec: string = '';

    @Input()
    @CMInput()
    hintExec: string = '';

    @Input()
    @CMInput()
    posicaoButtons: string = 'bottom';

}