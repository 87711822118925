// tslint:disable
import { Component, Injector } from "@angular/core";
import { CategoriaContaContabilBrowse, ContaBancariaBrowseComponent, ContaContabilBrowseComponent, FormaPagamentoBrowseComponent } from "..";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
  CMFormGlobaisComponent,
} from "./../../@cmcore/component";

import { FornecedorBrowseComponent } from "./../fornecedor/fornecedor-browse.component";

@Component({
  selector: "conta-pagar-edit",
  templateUrl: "conta-pagar-edit.component.html",
})
export class ContaPagarEditComponent extends CMFormEditComponent {
  valuesQtdParcelas = []

  setValuesQtdParcelas() {
    this.valuesQtdParcelas = [
      { value: "Um", text: "1x" },
      { value: "Dois", text: "2x" },
      { value: "Tres", text: "3x" },
      { value: "Quatro", text: "4x" },
      { value: "Cinco", text: "5x" },
      { value: "Seis", text: "6x" },
      { value: "Sete", text: "7x" },
      { value: "Oito", text: "8x" },
      { value: "Nove", text: "9x" },
      { value: "Dez", text: "10x" },
      { value: "Onze", text: "11x" },
      { value: "Doze", text: "12x" },
    ]
  }

  getFirstComponentName() {
    return "model_fatura";
  }

  getTitle() {
    return "Contas a Pagar";
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "contapagar";
    this.setValuesQtdParcelas();
    this.setModelConfig();
  }

  afterGetModelData() {
    super.afterGetModelData();
    if (!this.disabledAll) {
      this.disabledAll = this.model.podeeditar !== "Sim";
    }
    let dataprimeirovencimento = this.valueToDate(this.date);
    dataprimeirovencimento.setMonth(dataprimeirovencimento.getMonth() + 1);
    this.model.local = {
      dataprimeirovencimento: this.dateToValue(dataprimeirovencimento),
    };
  }

  setModelConfig() {
    this.modelConfig = {
      codigo: new CMInputConfig({ form: this, caption: "#", disabled: true }),
      nfatura: new CMInputConfig({
        form: this,
        caption: "Fatura",
        required: true,
        maxlength: 56,
      }),
      voriginal: new CMInputConfig({
        form: this,
        caption: "Valor bruto",
        dataType: "double(2)",
        onExit: () => {
          this.calcValorLiquido();
        },
      }),
      vdesconto: new CMInputConfig({
        form: this,
        caption: "Desconto financeiro",
        dataType: "double(2)",
        onExit: () => {
          this.calcValorLiquido();
        },
      }),
      vliquido: new CMInputConfig({
        form: this,
        caption: "Valor liquído",
        dataType: "double(2)",
        disabled: true,
      }),
      destinatario: new CMInputConfig({
        form: this,
        caption: "Fornecedor",
        required: true,
        pesqFunc: (sender) => {
          FornecedorBrowseComponent.pesquisa(
            sender,
            this.model,
            "destinatario"
          );
        },
      }),
      datacadastro: new CMInputConfig({
        form: this,
        caption: "Data de cadastro",
        mask: "99/99/9999",
        required: true,
        disabled: true,
      }),
      vacrescimo: new CMInputConfig({
        form: this,
        caption: "Acréscimo financeiro",
        dataType: "double(2)",
        onExit: () => {
          this.calcValorLiquido();
        },
      }),
      contabancaria: new CMInputConfig({
        form: this,
        caption: "Conta Bancária",
        pesqFunc: (sender) => {
          ContaBancariaBrowseComponent.pesquisa(sender, this.model, "Conta Bancária", this.model.contabancaria);
          console.log('thismodel: ', this.model);
        },
      }),

      //@Raul
      contacontabil: new CMInputConfig({
                  form: this,
                  required: true,
                  caption: "Conta Contabil",
                  pesqFunc: (sender) => {
                    ContaContabilBrowseComponent.pesquisa(sender, this.model, "Conta Contábil");
                  },
      }),
      formapagamento: new CMInputConfig({
        form: this,
        caption: "Forma de pagamento",
        gridConfig: new CMGridEditConfigColumn({
          tipo: "select",
          onGetValue: (row) =>
            this.getValueObject(row, "formapagamento.id"),
          onSetValue: (row, value) => {
            this.modelConfig.contareceber.duplicatas.fields.formapagamento.setValueInObject(
              row,
              "formapagamento",
              value
            );
          },
        }),
        values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
        valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
      }),

      qtdparcelas: new CMInputConfig({
        form: this,
        required: true,
        caption: "Condição de pagamento",
        values: this.valuesQtdParcelas
      }),

      
      local: {
        dataprimeirovencimento: new CMInputConfig({
          form: this,
          required: true,
          caption: "Data primeiro vencimento para nova geração",
          mask: "99/99/9999",
        }),
      },
      duplicatas: new CMGridEditConfig({
        form: this,
        caption: "Contas a Pagar",
        onNewItem: (item) => {
          if (this.model.duplicatas.length === 1) {
            Object.assign(item, {
              nduplicata: this.model.nfatura + "/000",
              dvenc: this.date,
              valor: this.model.vliquido,
              formapagamento : this.model.formapagamento
            });
          }
        },
        moreActions: [
          new CMGridEditConfigMore({
            hint: "Mais detalhes",
            icone: () => {
              if (this.disabledAll) return "visibility";
              else return "edit";
            },
          }),
        ],
        fields: {
          nduplicata: new CMInputConfig({
            form: this,
            caption: "Núm. documento",
            maxlength: 60,
            gridConfig: new CMGridEditConfigColumn(),
            required: true,
          }),
          dvenc: new CMInputConfig({
            form: this,
            caption: "Data de vencimento",
            mask: "99/99/9999",
            gridConfig: new CMGridEditConfigColumn(),
            required: true,
          }),
          valor: new CMInputConfig({
            form: this,
            caption: "Valor",
            dataType: "double(2)",
            gridConfig: new CMGridEditConfigColumn(),
            required: true,
          }),
          formapagamento: new CMInputConfig({
            form: this,
            caption: "Forma de pagamento",
            required: true,
            gridConfig: new CMGridEditConfigColumn({
              tipo: "select",
              onGetValue: (row) =>
                this.getValueObject(row, "formapagamento.id"),
              onSetValue: (row, value) => {
                this.modelConfig.contareceber.duplicatas.fields.formapagamento.setValueInObject(
                  row,
                  "formapagamento",
                  value
                );
              },
            }),
            values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
            valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
          }),
          observacao: new CMInputConfig({ form: this, caption: "Observação" }),
          numero: new CMInputConfig({ form: this, caption: "#", disabled: true }),

        },
      }),
    };
  }

  calcValorLiquido() {
    let vliquido =
      this.valueToFloat(this.model.voriginal, 0) -
      this.valueToFloat(this.model.vdesconto, 0) +
      this.valueToFloat(this.model.vacrescimo, 0);
    this.model.vliquido = this.floatToValue(vliquido);
  }

  customValid() {
    let r = super.customValid();
    if (r === "") {
      if (this.valueToFloat(this.model.vliquido, 0) <= 0)
        r = "Valor líquido deve ser maior que zero";
    }
    if (r === "") {
      let duplicatas = this.model.duplicatas;
      let vTotalDup: number = 0;
      for (let i = 0; i < duplicatas.length; i++){
        if (duplicatas[i].alteradoformapagamento === 'Não')
          vTotalDup += this.valueToFloat(duplicatas[i].valor);
      }
      let vliquido = this.valueToFloat(this.model.vliquido, 0);
      if (this.arredondar(vliquido) !== this.arredondar(vTotalDup))
        r = "Valor líquido difere do valor total das Contas a Pagar";
    }
    return r;
  }

  gerarDuplicatas() {
    CMFormGlobaisComponent.showSpinner();
    let duplicatas = [];
    let numeroParcelas;
    //let numeroParcelas = this.valueToInteger(this.model.local.qtdparcelas.num, 0);
    if (this.model.qtdparcelas === "Um") {
      numeroParcelas = "1";
    }
    else if (this.model.qtdparcelas === "Dois" ) {
      numeroParcelas = "2";
    }
    else if (this.model.qtdparcelas === "Tres" ) {
      numeroParcelas = "3";
    }
    else if (this.model.qtdparcelas === "Quatro" ) {
      numeroParcelas = "4";
    }
    else if (this.model.qtdparcelas === "Cinco" ) {
      numeroParcelas = "5";
    }
    else if (this.model.qtdparcelas === "Seis" ) {
      numeroParcelas = "6";
    }
    else if (this.model.qtdparcelas === "Sete" ) {
      numeroParcelas = "7";
    }
    else if (this.model.qtdparcelas === "Oito" ) {
      numeroParcelas = "8";
    }
    else if (this.model.qtdparcelas === "Nove" ) {
      numeroParcelas = "9";
    }
    else if (this.model.qtdparcelas === "Dez" ) {
      numeroParcelas = "10";
    }
    else if (this.model.qtdparcelas === "Onze" ) {
      numeroParcelas = "11";
    }
    else if (this.model.qtdparcelas === "Doze" ) {
      numeroParcelas = "12";
    }
    console.log(this.model.qtdparcelas)
    console.log(numeroParcelas);
    if (numeroParcelas > 0) {
      let vliquido = this.valueToFloat(this.model.vliquido, 0);
      let valorParcela = this.valueToFloat(
        this.arredondar(vliquido / numeroParcelas)
      );
      let dvenc = this.valueToDate(this.model.local.dataprimeirovencimento);
      dvenc.setMonth(dvenc.getMonth() - 1);
      for (let i = 0; i < numeroParcelas; i++) {
        dvenc.setMonth(dvenc.getMonth() + 1);
        duplicatas.push({
          id: CMGridEditConfig.newId(),
          nduplicata:
            this.model.nfatura + "/" + (i + 1).toString().padStart(3, "0"),
          dvenc: this.dateToValue(dvenc),
          valor: this.floatToValue(valorParcela),
          formapagamento: this.model.formapagamento,
          alteradoformapagamento: 'Não',
        });
      }
      let dif = this.valueToFloat(
        this.arredondar(vliquido - valorParcela * numeroParcelas)
      );
      if (dif !== 0) {
        let valor = this.valueToFloat(duplicatas[0].valor);
        valor += dif;
        duplicatas[0].valor = this.floatToValue(valor);
      }
      setTimeout(() => {
        this.modelConfig.duplicatas.unSelect();
        this.model.duplicatas = duplicatas;
        CMFormGlobaisComponent.hideSpinner();
      }, 500);
    }
  }
}
