// tslint:disable
import { Component, Injector } from '@angular/core';

import { CMFormRelatComponent, CMInputConfig
       } from './../../../@cmcore/component';

@Component({
    selector: 'conta-contabil',
    templateUrl: 'conta-contabil.component.html'
})
export class RelatContasContabeisComponent extends CMFormRelatComponent {

  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = 'ImpressaoContaContabil';
    this.filterConfig = {
      situacao: new CMInputConfig({form: this,
                              caption: 'Situação',
                              values: [
                                {value: 'Sim', text: 'Ativo'},
                                {value: 'Não', text: 'Inativo'},
                                {value: 'Todos', text: 'Todos'},
                              ],
                             })
    }
  }

}