// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "./../../@cmcore/component/cmform/cmform.component";

import { FormaPagamentoBrowseComponent } from "../formapagamento";

import {
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMGridEditConfig,
  CMInputConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
  CMValidatorEmail,
  CMValidatorGraterThanZero,
} from "./../../@cmcore/component";
import {
    PDVComponent,
} from "./../index";
@Component({
  selector: "pdv-acoes",
  templateUrl: "pdv-acoes.component.html",
  styleUrls: ["pdv-acoes.component.scss"],
})
export class PDVAcoesComponent extends CMFormComponent {


  _owner: PDVComponent = undefined;
  formasPagamento = [];
  formasPagamentoObjects = {};
  static inAddParcela: boolean = false;
  public beforeComponent: any = undefined;

  get owner() {
    return this._owner;
  }
  
  protected onKeydown(keyCode: number, event: KeyboardEvent) {
    super.onKeydown(keyCode, event);

    if (event.key >= '0' && event.key <= '9') {
      let deletePanel = document.getElementById("formAcoesExcluirItemPDV");
      if (deletePanel) {
        this.config.indiceRemover.components[0].focus();
      }
    }
    else if (event.code === 'Escape'){
      let deletePanel = document.getElementById("formAcoesExcluirItemPDV");
      let formAcoesPDV = document.getElementById("formAcoesPDV");
      let finalizarVenda = document.getElementById("formAcoesFinalizarVenda");
      let globalMessageError = document.getElementById("globalMessageError");
      console.log("formAcoesPDV", formAcoesPDV);
      console.log("deletePanel.style.display", deletePanel.style.display);
      console.log("finalizarVenda", finalizarVenda);
      console.log("finalizarVenda.style.display", finalizarVenda.style.display);
      /*
      if (deletePanel.style.display === "formAcoesPDV") {
        this.dados = {
          subtotal: "",
          desconto: "",
          acrescimo: "",
          total: "",
          recebido: "0,00",
          troco: "0,00",
          duplicatas: [],
          prevenda: {},
          produtos: [],
          indiceRemover: "",
        };
        PDVAcoesComponent.inAddParcela = false;
        this.close("formAcoesPDV");
      }
      */
      
      console.log("deletePanel", deletePanel);
      if (deletePanel.style.display === "block") {
        this.close("formAcoesExcluirItemPDV");
      }
      else if (finalizarVenda.style.display === "block") {
        this.close("formAcoesFinalizarVenda");
      }
      else if (globalMessageError.style.display === "block") {
        this.close("globalMessageError");
      }
      else{
        this.close("formAcoesPDV");
      }
      
    }
    else if (event.code === 'F11'){
      event.preventDefault();
      let valorreceber = 0;
      this.dados.duplicatas.forEach((duplicata) => {
        valorreceber = valorreceber + this.valueToFloat(duplicata.valor);
      });
      this.dados.recebido = this.floatToValue(valorreceber);

      if ((this.valueToFloat(this.dados.recebido) > 0) && (this.valueToFloat(this.dados.recebido) >= this.valueToFloat(this.dados.total)) ) {
        this.geraPreVendaPDV();
      }
      else{
        CMFormGlobaisComponent.showMessageError("O valor recebido é inferior ao valor total.");
      }
    }
    else if (event.code === 'F10'){
      event.preventDefault();
      let valorreceber = 0;
      this.dados.duplicatas.forEach((duplicata) => {
        valorreceber = valorreceber + this.valueToFloat(duplicata.valor);
      });
      this.dados.recebido = this.floatToValue(valorreceber);

      if ((this.valueToFloat(this.dados.recebido) > 0) && (this.valueToFloat(this.dados.recebido) >= this.valueToFloat(this.dados.total)) ) {
        this.geraNFCePDV();
      }
      else{
        CMFormGlobaisComponent.showMessageError("O valor recebido é inferior ao valor total.");
      }

    }
    else if ((event.code === 'F9') && (!PDVAcoesComponent.inAddParcela)){
      event.preventDefault();
      PDVAcoesComponent.inAddParcela = true;
      let valorreceber = this.valueToFloat(this.dados.total, 0) - this.valueToFloat(this.dados.recebido, 0);
      if (valorreceber <= 0) {
        //this.dados.troco = this.floatToValue(this.valueToFloat(this.dados.troco,0) - this.valueToFloat(valorreceber,));
        valorreceber = 0;
      }

      let firstObject;
      for (const key in FormaPagamentoBrowseComponent.valuesObjectSelectDinheiro()) {
        firstObject = FormaPagamentoBrowseComponent.valuesObjectSelectDinheiro()[key];
        break;
      }

      let randomId = CMInputConfig.getRandomInt(1, 9999)*-1;
      this.dados.duplicatas.push({
        indice: this.dados.duplicatas.length+1,
        formapagamento: firstObject,
        valor: this.floatToValue(valorreceber),
        id: randomId
      });

      let comp = this.config.duplicatas.gridName + "valor" + randomId;

      setTimeout( () => {
        $("#" + comp).trigger("focus");
        $("#" + comp).trigger("select");
      }, 200);

      this.config.duplicatas.fields.formapagamento.values = FormaPagamentoBrowseComponent.valuesSelectDinheiro(this.http);
      this.config.duplicatas.fields.formapagamento.valuesObject = FormaPagamentoBrowseComponent.valuesObjectSelectDinheiro();

    }
    else if ((event.key === 'F8') && (!PDVAcoesComponent.inAddParcela)){
      event.preventDefault();
      PDVAcoesComponent.inAddParcela = true;
      let valorreceber = this.valueToFloat(this.dados.total, 0) - this.valueToFloat(this.dados.recebido, 0);
      if (valorreceber <= 0) {
        valorreceber = 0;
      }

      this.config.duplicatas.fields.formapagamento.values = FormaPagamentoBrowseComponent.valuesSelectBoleto(this.http);
      this.config.duplicatas.fields.formapagamento.valuesObject = FormaPagamentoBrowseComponent.valuesObjectSelectBoleto();

      let firstObject;
      for (const key in FormaPagamentoBrowseComponent.valuesObjectSelectBoleto()) {
        firstObject = FormaPagamentoBrowseComponent.valuesObjectSelectBoleto()[key];
        break;
      }

      let randomId = CMInputConfig.getRandomInt(1, 9999)*-1;
      this.dados.duplicatas.push({
        indice: this.dados.duplicatas.length+1,
        formapagamento: firstObject,
        valor: this.floatToValue(valorreceber),
        id: randomId
      });

      let comp = this.config.duplicatas.gridName + "formapagamento" + randomId;

      setTimeout( () => {
        $("#" + comp).trigger("focus");
        //$("#" + comp).trigger("click");
      }, 200);

    }
    else if ((event.code === 'F7') && (!PDVAcoesComponent.inAddParcela)){
      event.preventDefault();
      PDVAcoesComponent.inAddParcela = true;
      let valorreceber = this.valueToFloat(this.dados.total, 0) - this.valueToFloat(this.dados.recebido, 0);
      if (valorreceber <= 0) {
        valorreceber = 0;
      }

      this.config.duplicatas.fields.formapagamento.values = FormaPagamentoBrowseComponent.valuesSelectCartao(this.http);
      this.config.duplicatas.fields.formapagamento.valuesObject = FormaPagamentoBrowseComponent.valuesObjectSelectCartao();

      let firstObject;
      for (const key in FormaPagamentoBrowseComponent.valuesObjectSelectCartao()) {
        firstObject = FormaPagamentoBrowseComponent.valuesObjectSelectCartao()[key];
        break;
      }

      let randomId = CMInputConfig.getRandomInt(1, 9999)*-1;
      this.dados.duplicatas.push({
        indice: this.dados.duplicatas.length+1,
        formapagamento: firstObject,
        valor: this.floatToValue(valorreceber),
        id: randomId
      });

      let comp = this.config.duplicatas.gridName + "formapagamento" + randomId;

      setTimeout( () => {
        $("#" + comp).trigger("focus");
        //$("#" + comp).trigger("click");
      }, 200);

    }
    else if ((event.code === 'F6') && (!PDVAcoesComponent.inAddParcela)){
      event.preventDefault();
      PDVAcoesComponent.inAddParcela = true;
      let valorreceber = this.valueToFloat(this.dados.total, 0) - this.valueToFloat(this.dados.recebido, 0);
      if (valorreceber <= 0) {
        valorreceber = 0;
      }

      this.config.duplicatas.fields.formapagamento.values = FormaPagamentoBrowseComponent.valuesSelectCheque(this.http);
      this.config.duplicatas.fields.formapagamento.valuesObject = FormaPagamentoBrowseComponent.valuesObjectSelectCheque();

      let firstObject;
      for (const key in FormaPagamentoBrowseComponent.valuesObjectSelectCheque()) {
        firstObject = FormaPagamentoBrowseComponent.valuesObjectSelectCheque()[key];
        break;
      }

      let randomId = CMInputConfig.getRandomInt(1, 9999)*-1;
      this.dados.duplicatas.push({
        indice: this.dados.duplicatas.length+1,
        formapagamento: firstObject,
        valor: this.floatToValue(valorreceber),
        id: randomId
      });

      let comp = this.config.duplicatas.gridName + "formapagamento" + randomId;

      setTimeout( () => {
        $("#" + comp).trigger("focus");
        //$("#" + comp).trigger("click");
      }, 200);

    }
    else if (event.code === 'F5'){
      event.preventDefault();
      this.config.desconto.components[0].focus();
    }
    else if (event.code === 'F4'){
      event.preventDefault();
      this.config.acrescimo.components[0].focus();
    }
    else if (event.code === 'Delete'){
      if (!PDVAcoesComponent.inAddParcela){
        this.beforeComponent = PDVAcoesComponent;
        setTimeout(() => {
          CMFormModalComponent.open("formAcoesExcluirItemPDV");
        }, 100);
      }
    }
  }

  dados = {
    subtotal: "",
    desconto: "",
    acrescimo: "",
    total: "",
    recebido: "0,00",
    troco: "0,00",
    duplicatas: [],
    prevenda: {},
    produtos: [],
    indiceRemover: "",
  };

  config = {
    indiceRemover: new CMInputConfig({
      form: this,
      caption: "Índice",
      dataType: "Integer",
      maxlength: 7,
      onKeyDown: (sender) => {
        if (sender.event.key === 'Enter') {
          let i = 0;
          let formAcoesPDV = document.getElementById("formAcoesPDV");
          if (formAcoesPDV.style.display === "block") {
            for (let i = 0; i < this.dados.duplicatas.length; i++) {
              if (this.dados.duplicatas[i].indice == this.dados.indiceRemover) {
                let temp = this.dados.duplicatas.splice(i, 1);
                this.dados.recebido = 
                  this.floatToValue(this.valueToFloat(this.dados.recebido) - this.valueToFloat(temp[0].valor));
                this.close('formAcoesExcluirItemPDV');
              }
            }
            for (let i = 0; i < this.dados.duplicatas.length; i++) {
              this.dados.duplicatas[i].indice = i+1;
            }
            this.dados.indiceRemover = "";
          }
          else {
            for (let i = 0; i < this.owner.filter.dados.produtos.length; i++) {
              if (this.owner.filter.dados.produtos[i].indice == this.dados.indiceRemover) {
                let temp = this.owner.filter.dados.produtos.splice(i, 1);
                this.owner.filter.filtro.novo_valortotal = 
                  this.floatToValue(this.valueToFloat(this.owner.filter.filtro.novo_valortotal) - this.valueToFloat(temp[0].vtotal))
                this.owner.filterConfig.filtro.novo_produto.components[0].focus();
                this.owner.filter.filtro.produto = {};
                this.close('formAcoesExcluirItemPDV');
              }
            }
            for (let i = 0; i < this.owner.filter.dados.produtos.length; i++) {
              this.owner.filter.dados.produtos[i].indice = i+1;
            }
            this.dados.indiceRemover = "";
          }
        }
      },
    }),
    subtotal: new CMInputConfig({
      form: this,
      caption: "Sub Total",
      dataType: "double(2)",
      disabled: true,
    }),
    desconto: new CMInputConfig({
      form: this,
      caption: "Desconto",
      dataType: "double(2)",
      maxlength: 20,
      onKeyDown: (sender) => {
        if (sender.event.key === 'Enter') {
          this.dados.total = this.floatToValue(this.valueToFloat(this.dados.subtotal, 0) - this.valueToFloat(this.dados.desconto, 0) + this.valueToFloat(this.dados.acrescimo, 0));
          this.config.acrescimo.components[0].focus();
        }
      },
      onExit: () => {
        this.dados.total = this.floatToValue(this.valueToFloat(this.dados.subtotal, 0) - this.valueToFloat(this.dados.desconto, 0) + this.valueToFloat(this.dados.acrescimo, 0));
      }
    }),
    acrescimo: new CMInputConfig({
      form: this,
      caption: "Acréscimo",
      dataType: "double(2)",
      maxlength: 20,
      onKeyDown: (sender) => {
        if (sender.event.key === 'Enter') {
          this.dados.total = this.floatToValue(this.valueToFloat(this.dados.subtotal, 0) - this.valueToFloat(this.dados.desconto, 0) + this.valueToFloat(this.dados.acrescimo, 0));
        }
      },
      onExit: () => {
        this.dados.total = this.floatToValue(this.valueToFloat(this.dados.subtotal, 0) - this.valueToFloat(this.dados.desconto, 0) + this.valueToFloat(this.dados.acrescimo, 0));
      }
    }),
    total: new CMInputConfig({
      form: this,
      caption: "Total",
      dataType: "double(2)",
      disabled: true,
    }),
    recebido: new CMInputConfig({
      form: this,
      disabled: true,
      dataType: "double(2)",
    }),
    troco: new CMInputConfig({
      form: this,
      disabled: true,
      dataType: "double(2)",
    }),
    duplicatas: new CMGridEditConfig({
      caption: "Valores Recebidos",
      form: this,
      fields: { 
        indice: new CMInputConfig({
          form: this,
          caption: "#",
          dataType: "Integer",
          disabled: true,
          gridConfig: new CMGridEditConfigColumn()
        }),
        formapagamento: new CMInputConfig({
          form: this,
          caption: "Forma de pagamento",
          required: true,
          onKeyDown: (sender) => {
            if (sender.event.key === "Enter"){
              let compvalor = this.config.duplicatas.gridName + "valor" + sender.gridRow.id;
              $("#" + compvalor).trigger("focus");
            }
          },
          onExit: (sender) => {
            this.calculaTotalDuplicatas();
          },
          gridConfig: new CMGridEditConfigColumn({
            tipo: "select",
            onGetValue: (row) =>
              this.getValueObject(row, "formapagamento.id"),
            onSetValue: (row, value) => {
              this.config.duplicatas.fields.formapagamento.setValueInObject(
                row,
                "formapagamento",
                value
              );
            },
          }),
          values: FormaPagamentoBrowseComponent.valuesSelect(this.http),
          valuesObject: FormaPagamentoBrowseComponent.valuesObjectSelect(),
        }),
        valor: new CMInputConfig({
          form: this,
          caption: "Valor",
          dataType: "double(2)",
          maxlength: 20,
          customvalidators: [new CMValidatorGraterThanZero(undefined)],
          onKeyDown: (sender) => {
            if (sender.event.key === "Enter"){
              $(document.activeElement).blur();
            }
          },
          onExit: (sender) => {

            if (!(sender.value <= '0')){
              this.calculaTotalDuplicatas();

              let valorreceber = this.valueToFloat(this.dados.total, 0) - this.valueToFloat(this.dados.recebido, 0);

              PDVAcoesComponent.inAddParcela = false;
              $(document.activeElement).blur();

              if (valorreceber <= 0) {
                this.dados.troco = this.floatToValue(this.valueToFloat(this.dados.troco,0) - this.valueToFloat(valorreceber,));
                valorreceber = 0;

                
                if (!PDVAcoesComponent.inAddParcela){
                  this.beforeComponent = PDVAcoesComponent;
                  setTimeout(() => {
                    CMFormModalComponent.open("formAcoesFinalizarVenda", () => {
                      let btn_nfce = $("#btn_nfce");
                      let btn_prevenda = $("#btn_prevenda");
                      btn_nfce.on('keydown', (event) => {
                        if (event.keyCode === 39) {
                          // Your code to handle the right arrow key press
                          btn_prevenda.focus();
                        }
                        if (event.keyCode === 13) {
                          // Your code to handle the right arrow key press
                                              
                          this.geraNFCePDV();
                        }
                      });
                      btn_prevenda.on('keydown', (event) => {
                        if (event.keyCode === 37) {
                          // Your code to handle the right arrow key press
                          btn_nfce.focus();
                        }
                        if (event.keyCode === 13) {
                          // Your code to handle the right arrow key press
                                              
                          this.geraPreVendaPDV();
                        }
                      });
                      btn_nfce.focus();
                    });
                  }, 100);
                }
              }
            }
          },
          gridConfig: new CMGridEditConfigColumn(),
        }),
      }   
    }),
  };

  geraPreVendaPDV(){

    CMFormGlobaisComponent.showSpinner();

    this.postSubscribe("/api/prevenda/GerarPreVendaPDV/FuncaoExtra",
    JSON.stringify({
      prevenda: this.dados.prevenda,
      produtos: this.dados.produtos,
      pagamento: this.dados,
      duplicatas: this.dados.duplicatas
    }),
    (result) => {
      if (result["result"] === "ok") {
        this.close('formAcoesPDV');
        
        this.dados = {
          subtotal: "",
          desconto: "",
          acrescimo: "",
          total: "",
          recebido: "0,00",
          troco: "0,00",
          duplicatas: [],
          prevenda: {},
          produtos: [],
          indiceRemover: "",
        };

        this.owner.filter.filtro.novo_quantidade = "1,00";
        this.owner.filter.filtro.novo_valortotal = "";
        this.owner.filter.filtro.novo_valorunitario = "";
        this.owner.filter.filtro.novo_subtotal = "";
        this.owner.filter.filtro.descricaoproduto = "";
        this.owner.filter.dados.produtos = [];
        this.owner.filter.filtro.novo_produto = "";
        this.owner.filter.filtro["produto"] = {};
        PDVAcoesComponent.inAddParcela = false;

        let data = {
          id: result["idprevenda"],
          numero: result["numero"]
        };
        this.showRelat(
          "/ImpressaoPreVendaReduzida/FuncaoExtra",
          data,
          "prevenda"
        );

        CMFormGlobaisComponent.toastSucesso("Venda gerada com sucesso.");

        this.owner.filterConfig.filtro.novo_produto.components[0].focus();
      } else {
        CMFormGlobaisComponent.toastErro(result["msg"]);
      }
      CMFormGlobaisComponent.hideSpinner();
    },
    (error) => {
      CMFormGlobaisComponent.hideSpinner();
      this.showMessageErrorHTTP("geraBaixas", error);
    })
  }

  geraNFCePDV(){

    CMFormGlobaisComponent.showSpinner();

    this.postSubscribe("/api/prevenda/GerarNFCePDV/FuncaoExtra",
    JSON.stringify({
      prevenda: this.dados.prevenda,
      produtos: this.dados.produtos,
      pagamento: this.dados,
      duplicatas: this.dados.duplicatas
    }),
    (result) => {
      if (result["result"] === "ok") {
        this.close('formAcoesPDV');
        
        this.dados = {
          subtotal: "",
          desconto: "",
          acrescimo: "",
          total: "",
          recebido: "0,00",
          troco: "0,00",
          duplicatas: [],
          prevenda: {},
          produtos: [],
          indiceRemover: "",
        };

        this.owner.filter.filtro.novo_quantidade = "1,00";
        this.owner.filter.filtro.novo_valortotal = "";
        this.owner.filter.filtro.novo_valorunitario = "";
        this.owner.filter.filtro.novo_subtotal = "";
        this.owner.filter.filtro.descricaoproduto = "";
        this.owner.filter.dados.produtos = [];
        this.owner.filter.filtro.novo_produto = "";
        this.owner.filter.filtro["produto"] = {};
        PDVAcoesComponent.inAddParcela = false;
        
        console.log('{ id: result["idnfce"], nnf: result["nnf"] }: ', { id: result["idnfce"], nnf: result["nnf"] });

        this.showRelat(
          "Print",
          { id: result["idnfce"], nnf: result["nnf"] },
          "NFCe"
        );

        CMFormGlobaisComponent.toastSucesso("NFCe gerada com sucesso.");

        this.owner.filterConfig.filtro.novo_produto.components[0].focus();
      } else {
        this.close("formAcoesFinalizarVenda");
        CMFormGlobaisComponent.showMessageError(result["msg"]);
        //CMFormGlobaisComponent.toastErro(result["msg"]);
      }
      CMFormGlobaisComponent.hideSpinner();
    },
    (error) => {
      CMFormGlobaisComponent.hideSpinner();
      this.showMessageErrorHTTP("geraBaixas", error);
    })
  }

  calculaTotalDuplicatas() {
    let vTotal = 0;
    this.dados.duplicatas.forEach(item => {
      vTotal += this.valueToFloat(item.valor, 0);
    })
    this.dados.recebido = this.floatToValue(vTotal, 2);
  }

  podeCadastrarProduto: boolean = false;
  set owner(value) {
    if (value !== undefined)
      this._owner = value;
    this.dados.subtotal = this.owner.filter.filtro.novo_valortotal;
    this.dados.total = this.owner.filter.filtro.novo_valortotal;

    this.dados.prevenda = this.owner.filter.filtro;
    this.dados.produtos = this.owner.filter.dados.produtos;
    
  }

  constructor(_injector: Injector) {
    super(_injector);
  }


  close(formName) {
    if ((formName !== "globalMessageError") && (formName !== "formAcoesExcluirItemPDV") && (formName !== "formAcoesFinalizarVenda")){
      if (this.owner) this.owner.beforeComponent = undefined;
    }
    CMFormModalComponent.close(formName);
  }
}
