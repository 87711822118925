// tslint:disable
import {CMInputConfig} from '../component';

export class CMValidator {

  validate(value: string, gridRow) {
    if (this.validFunc)
      return this.validFunc(gridRow);
    else
      return undefined;
  };

  constructor (protected validFunc: Function) {}
  static trimValue(value: any) {
    return CMInputConfig.trimValue(value);
  }
}
