// tslint:disable
import { Component, Injector } from "@angular/core";

import { CMFormComponent } from "../../@cmcore/component/cmform/cmform.component";

import {
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMInputConfig,
} from "../../@cmcore/component";
import {
  ContaBancariaBrowseComponent,
    DuplicataReceberBrowseComponent,
    FormaPagamentoBrowseComponent,
} from "../index";
@Component({
  selector: "conta-pagar-receber-baixa-outros",
  templateUrl: "conta-pagar-receber-baixa-outros.component.html",
  styleUrls: ["conta-pagar-receber-baixa-outros.component.scss"],
})
export class ContaPagarReceberBaixaOutrosComponent extends CMFormComponent {
  _owner: ContaBancariaBrowseComponent = undefined;

  get owner() {
    return this._owner;
  }

  teste = 0;

  dados = {
    formapagamento: {
      id: ""
    }
  };

  config = {
    formapagamento: new CMInputConfig({
      form: this,
      caption: "Forma de pagamento",
      values: FormaPagamentoBrowseComponent.valuesSelectBaixaCreditoLoja(this.http),
      valuesObject: FormaPagamentoBrowseComponent.valuesObjecSelectBaixaCreditoLoja(),
    }),
  };

  baixar(){

    console.log("this.dados.id: ", this.dados);

    this.postSubscribeT<Array<string>>(
      "/api/contapagarreceber/baixatotal/FuncaoExtra",
      JSON.stringify({ 
        id: this.owner.grid.fieldByName("id_duplicata"),
        idformapagamento: this.dados.formapagamento.id
      }),
      (result) => {
        let result_str = result.pop();
        if (result_str === "ok") {
          CMFormGlobaisComponent.toastSucesso("Documento baixado");
          this.owner.refresh();
          this.close('formBaixaFormaPagamentoOutros');
          return true;
        } else {
          CMFormGlobaisComponent.toastErro(result_str);
          return false;
        }
      },
      (error) => {
        this.showMessageErrorHTTP("onPage", error);
      }
    );

    /*
    if (this.dados.formapagamento === ""){
        let data = { id: this.owner.grid.fieldByName(this.owner.fieldNameId()) };
        console.log(data);
        data = this.owner.getDataPrint(data);
        this.showRelat(
          "/Print",
          data,
          "duplicatareceber"
        );
    }
    else{
      let data = { 
        id: this.owner.grid.fieldByName(this.owner.fieldNameId()),
        idrelatorio: this.dados.formapagamento
      };
      console.log(data);
      data = this.owner.getDataPrint(data);
      this.showRelat(
        "/ImpressaoDuplicataReceberCustomizado",
        data,
        "duplicatareceber"
      );
      
    }
    */
  }

  podeCadastrarProduto: boolean = false;
  set owner(value) {
    this._owner = value;
  }

  constructor(_injector: Injector) {
    super(_injector);
    console.log('construtor');
    //this.config.tiporelatorio.values.push.apply(this.config.tiporelatorio.values, DuplicataReceberRelatoriosComponent.relatoriosCustomizados)
  }


  close(formName) {
    if (this.owner) this.owner.beforeComponent = undefined;
    CMFormModalComponent.close(formName);
  }
}
