// tslint:disable
import {Component, Injector, ViewChild} from '@angular/core';

import {CMFormComponent} from './../../@cmcore/component/cmform/cmform.component';

import {
  CMInputConfig,
  CMValidatorEmail,
  CMFormGlobaisComponent,
  CMTabComponent,
  CMFormModalComponent,
} from './../../@cmcore/component';
import {NFeBrowseComponent, NFeRelatoriosComponent} from './../index';
@Component({
  selector: 'nfe-acoes',
  templateUrl: 'nfe-acoes.component.html',
  styleUrls: ['nfe-acoes.component.scss'],
})
export class NFeAcoesComponent extends CMFormComponent {
  _owner: NFeBrowseComponent = undefined;
  get owner() {
    return this._owner;
  }
  set owner(value) {
    this._owner = value;
    if (this.owner) {
      this.loadDadosNFe();
      this.voltarAcoes();
    }
  }

  public beforeComponent: any = undefined;

  configNFe: any = {
    cartacorrecao: {
      xcorrecao: new CMInputConfig({
        form: this,
        caption: 'Texto da Carta de Correção',
        disabledFunc: () => {
          return !this.nfeAutorizado();
        },
      }),
    },
    cancelamento: {
      xjust: new CMInputConfig({
        form: this,
        caption: 'Justificativa',
        disabledFunc: () => {
          return !this.nfeAutorizado();
        },
      }),
    },
    email: {
      para: new CMInputConfig({
        form: this,
        caption: 'Para',
        required: true,
        customvalidators: [new CMValidatorEmail()],
      }),
      cc: new CMInputConfig({
        form: this,
        caption: 'CC',
        customvalidators: [new CMValidatorEmail()],
      }),
      assunto: new CMInputConfig({form: this, caption: 'Assunto', required: true}),
      mensagem: new CMInputConfig({form: this, caption: 'Mensagem', required: true}),
    },
  };

  dadosNFe: any = {
    infnfe: {
      status: '',
    },
    cartacorrecao: {
      xcorrecao: '',
      temxml: '',
    },
    cancelamento: {
      temxml: '',
      xjust: '',
    },
    protnfe: {
      temxml: '',
    },
    email: {
      para: '',
      cc: '',
      assunto: '',
      mensagem: '',
    },
  };
  loadDadosNFe() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      '/api/nfe/GetDadosAcoesNFe/FuncaoExtra',
      JSON.stringify({id: this.owner.idFormAcoesNFe}),
      (result) => {
        this.dadosNFe = result;
        this.dadosNFe.email.assunto = '';
        this.dadosNFe.email.mensagem = '';
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      },
    );
  }

  constructor(_injector: Injector) {
    super(_injector);
  }

  nfeAutorizadoCancelado() {
    return this.nfeAutorizado() || this.dadosNFe.infnfe.status === 'Cancelado';
  }

  nfeCancelado() {
    return this.dadosNFe.infnfe.status === 'Cancelado';
  }

  nfeAutorizado() {
    return this.dadosNFe.infnfe.status === 'Autorizado';
  }

  nfeEmProcessamento() {
    return this.dadosNFe.infnfe.status === 'EmProcessamento';
  }

  nfeEmDigitacao() {
    return this.dadosNFe.infnfe.status === 'EmDigitacao';
  }

  nfeEmDigitacaoEmProcessamento() {
    return this.nfeEmDigitacao() || this.nfeEmProcessamento();
  }

  @ViewChild('tbAcoes', {static: true}) tbAcoes: CMTabComponent;
  voltarAcoes() {
    this.tbAcoes.active = true;
  }

  getCaptionTransmissao() {
    let r: string = '';
    if (this.nfeEmDigitacao()) r = 'Transmitir';
    else if (this.nfeEmProcessamento()) r = 'Consultar processamento';
    else if (this.dadosNFe.protnfe.temxml === 'True') {
      r = 'Enviar e-Mail';
    }
    return r;
  }

  getCaptionCancelamento() {
    let r: string = '';
    if (this.nfeAutorizado()) r = 'Cancelar NF-e';
    else if (this.nfeCancelado() && this.dadosNFe.cancelamento.temxml === 'True') {
      r = 'Enviar e-Mail';
    }
    return r;
  }

  clickTransmissao() {
    if (this.nfeEmDigitacao() || this.nfeEmProcessamento()) this.transmitirNFe();
    else if (this.dadosNFe.protnfe.temxml === 'True') this.openEmail('protnfe');
  }

  clickCancelamento() {
    if (this.nfeAutorizado()) this.cancelarNFe();
    else if (this.dadosNFe.cancelamento.temxml === 'True') this.openEmail('cancelamento');
  }

  clickEmailCartaCorrecao() {
    if (this.dadosNFe.cartacorrecao.temxml === 'True') this.openEmail('cartacorrecao');
  }

  setActiveTab(tab, enabled) {
    if (enabled) tab.active = true;
  }

  titleEmail: string = '';
  tipoEmail: string = '';
  openEmail(tipoEmail) {
    this.titleEmail = '';
    this.tipoEmail = tipoEmail;
    if (tipoEmail === 'protnfe') this.titleEmail = 'e-Mail do XML de transmissão';
    else if (tipoEmail === 'cancelamento') this.titleEmail = 'e-Mail do XML de cancelamento';
    else if (tipoEmail === 'cartacorrecao') this.titleEmail = 'e-Mail do XML da carta de correção';
    this.dadosNFe.email.assunto = this.dadosNFe[tipoEmail].email_assunto;
    this.dadosNFe.email.mensagem = this.dadosNFe[tipoEmail].email_mensagem;
    CMFormModalComponent.open('formAcoesNFeEmail');
  }

  enviarEmail() {
    CMFormGlobaisComponent.showSpinner();
    this.postSubscribe(
      '/api/nfe/EnviarEmail',
      JSON.stringify({
        id: this.dadosNFe.id,
        tipo: this.tipoEmail,
        para: this.dadosNFe.email.para,
        cc: this.dadosNFe.email.cc,
        assunto: this.dadosNFe.email.assunto,
        mensagem: this.dadosNFe.email.mensagem,
      }),
      (result) => {
        this.processaRetorno(this.titleEmail, result, 'formAcoesNFeEmail');
        CMFormGlobaisComponent.hideSpinner();
      },
      (error) => {
        CMFormGlobaisComponent.hideSpinner();
        CMFormGlobaisComponent.showMessageError(error);
      },
    );
  }

  getCaptionImpressao() {
    let r: string = 'Imprimir DANFE';
    if (this.nfeEmDigitacao()) r = 'Imprimir Pré-DANFE';
    return r;
  }

  impressaoDANFE() {
    if (NFeRelatoriosComponent.getRelatorioCustomizados().length > 0){
      this.openFormRelatoriosNFe();
    }
    else{
      this.showRelat('Print', {id: this.dadosNFe.id, nnf: this.dadosNFe.infnfe.ide.nnf}, 'NFe');
    }
  }
  
  openFormRelatoriosNFe() {
    this.owner.beforeComponent = NFeRelatoriosComponent;
    setTimeout(() => {
      CMFormModalComponent.open("formRelatoriosNFe");
    }, 100);
  }

  transmitirNFe() {
    if (this.nfeEmDigitacaoEmProcessamento()) {
      CMFormGlobaisComponent.showSpinner();
      this.postSubscribe(
        '/api/nfe/GerarNFe',
        JSON.stringify({id: this.dadosNFe.id}),
        (result) => {
          console.log('hereretorno: ', result);
          this.processaRetorno('Retorno da Transmissão', result);
          CMFormGlobaisComponent.hideSpinner();
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          console.log('Error: ', error.error);
          console.log('Error Verbose: ', error);
          console.log('ErroIncludes: ', error.error.includes('httpstat.us'));

          let errorArray = error.error.split(/\r?\n/);

          console.log('errorArray: ', errorArray)

          //if (error.error.includes('httpstat.us')){
          let html = "<div style='margin: 1em;'>";
          html += `<p>
                      <span>Servidor da receita (`+errorArray[2]+`) retornou o `+errorArray[1]+`</span>
                  </p>
                  `;

          if (errorArray[0].includes('Erro Interno: 10060')){
            html += `<p>
                        <span>O servidor da receita está indisponível no momento.</span>
                    </p>
                    `;
            html += `<p>
                        <span><a href="https://www.nfe.fazenda.gov.br/portal/disponibilidade.aspx?versao=2.00" target="_blank">Consultar status do servidor da receita</a></span>
                    </p>
                    `;
          }
          
          if (errorArray[1].includes('Erro HTTP: 403')){
            html += `<p>
                        <span>A requisição web foi negada pelo servidor remoto da receita.</span>
                    </p>
                    `;
            html += `<p>
                        <span><strong>Possíveis Causas:</strong> As informações cadastrais de envio de NFe estão incorretas ou o certificado digital está vencido ou inválido.</span>
                    </p>
                    `;
            html += `<p>
                        <span><strong>Possível Solução:</strong> Verifique se as informações cadastrais de envio de NFe estão corretas e se o certificado digital é válido.</span>
                    </p>
                    `;
          }

          
          if (errorArray[1].includes('Erro HTTP: 500')){
            html += `<p>
                        <span>O servidor remoto da receita apresentou um erro interno.</span>
                    </p>
                    `;
            html += `<p>
                        <span><a href="https://www.nfe.fazenda.gov.br/portal/disponibilidade.aspx?versao=2.00" target="_blank">Consultar status do servidor da receita</a></span>
                    </p>
                    `;
          }

          
          if (errorArray[1].includes('Erro HTTP: 504')){
            html += `<p>
                        <span>O servidor da receita está indisponível no momento.</span>
                    </p>
                    `;
            html += `<p>
                        <span><a href="https://www.nfe.fazenda.gov.br/portal/disponibilidade.aspx?versao=2.00" target="_blank">Consultar status do servidor da receita</a></span>
                    </p>
                    `;
          }
          
          if (errorArray[1].includes('Erro HTTP: 503')){
            html += `<p>
                        <span>O servidor remoto da receita não foi capaz de processar a requisição web no momento.</span>
                    </p>
                    `;
            html += `<p>
                        <span><a href="https://www.nfe.fazenda.gov.br/portal/disponibilidade.aspx?versao=2.00" target="_blank">Consultar status do servidor da receita</a></span>
                    </p>
                    `;
          }

          if (errorArray[1].includes('Erro HTTP: 502')){
            html += `<p>
                        <span>O servidor remoto da receita retornou uma resposta inválida para a requisição web solicitada.</span>
                    </p>
                    `;
            html += `<p>
                        <span><a href="https://www.nfe.fazenda.gov.br/portal/disponibilidade.aspx?versao=2.00" target="_blank">Consultar status do servidor da receita</a></span>
                    </p>
                    `;
          }
          
          if (errorArray[1].includes('Erro HTTP: 417')){
            html += `<p>
                        <span>O servidor remoto da receita possui um erro interno e não foi capaz de atender as informações da requisição web enviada.</span>
                    </p>
                    `;
            html += `<p>
                        <span><a href="https://www.nfe.fazenda.gov.br/portal/disponibilidade.aspx?versao=2.00" target="_blank">Consultar status do servidor da receita</a></span>
                    </p>
                    `;
          }
          
          if (errorArray[1].includes('Erro HTTP: 424')){
            html += `<p>
                        <span>O  servidor remoto da receita não foi capaz de atender as informações da requisição web enviada.</span>
                    </p>
                    `;
            html += `<p>
                        <span><a href="https://www.nfe.fazenda.gov.br/portal/disponibilidade.aspx?versao=2.00" target="_blank">Consultar status do servidor da receita</a></span>
                    </p>
                    `;
          }
          
          html += "</div>";
          CMFormGlobaisComponent.showMessageHtml(html, "Erro ao transmitir NFe");
          //}
          //else{
            //this.showMessageErrorHTTP('transmitirNFe', error);
          //}
        },
      );
    }
  }

  emitirCCe() {
    let ok: boolean = true;
    if (this.trimValue(this.dadosNFe.cartacorrecao.xcorrecao).length < 15) {
      ok = false;
      CMFormGlobaisComponent.toastErro(
        this.configNFe.cartacorrecao.xcorrecao.caption + ' deve ter pelo menos 15 caracteres',
      );
    }
    if (ok) {
      if (this.nfeAutorizado()) {
        CMFormGlobaisComponent.showSpinner();
        this.postSubscribe(
          '/api/nfe/EmiteCCe',
          JSON.stringify({id: this.dadosNFe.id, xcorrecao: this.dadosNFe.cartacorrecao.xcorrecao}),
          (result) => {
            this.processaRetorno('Retorno da Carta de Correção', result);
            CMFormGlobaisComponent.hideSpinner();
          },
          (error) => {
            CMFormGlobaisComponent.hideSpinner();
            CMFormGlobaisComponent.showMessageError(error);
          },
        );
      }
    }
  }

  cancelarNFe() {
    let ok: boolean = true;
    if (this.trimValue(this.dadosNFe.cancelamento.xjust).length < 15) {
      ok = false;
      CMFormGlobaisComponent.toastErro(
        this.configNFe.cancelamento.xjust.caption + ' deve ter pelo menos 15 caracteres',
      );
    }
    if (ok) {
      if (this.nfeAutorizado()) {
        CMFormGlobaisComponent.showSpinner();
        this.postSubscribe(
          '/api/nfe/CancelaNFe',
          JSON.stringify({id: this.dadosNFe.id, xjust: this.dadosNFe.cancelamento.xjust}),
          (result) => {
            this.processaRetorno('Retorno do Cancelamento', result);
            CMFormGlobaisComponent.hideSpinner();
          },
          (error) => {
            CMFormGlobaisComponent.hideSpinner();
            CMFormGlobaisComponent.showMessageError(error);
          },
        );
      }
    }
  }

  close(formName) {
    if (this.owner) {
      this.owner.refresh();
      this.owner.beforeComponent = undefined;
    }
    CMFormModalComponent.close(formName);
  }

  retorno: any = {
    title: '',
    result: '',
    tipo: '',
    msg: '',
    cstat: '',
    xml: '',
    xml_conferencia: '',
    result_str: '',
    json: '',
    nnf: "",
  };

  setRetorno(title, retorno) {
    this.retorno.title = title;
    this.retorno.result = this.trimValue(retorno.result);
    this.retorno.tipo = this.trimValue(retorno.tipo);
    this.retorno.msg = this.trimValue(retorno.msg);
    this.retorno.cstat = this.trimValue(retorno.cstat);
    this.retorno.xml = this.trimValue(retorno.xml);
    this.retorno.xml_conferencia = this.trimValue(retorno.xml_conferencia);
    this.retorno.result_str = this.trimValue(retorno.result_str);
    this.retorno.nnf = this.trimValue(retorno.nnf);

    delete retorno.result;
    delete retorno.tipo;
    delete retorno.msg;
    delete retorno.cstat;
    delete retorno.xml;
    delete retorno.xml_conferencia;
    delete retorno.result_str;
    delete retorno.nnf;

    this.retorno.json = retorno;
  }

  processaRetorno(title, retorno, closeFormOK = undefined) {
    this.setRetorno(title, retorno);
    if (this.retorno.result === 'True') {
      this.loadDadosNFe();
      CMFormGlobaisComponent.toastSucesso(this.retorno.result_str);
      if (closeFormOK) this.close(closeFormOK);
    } else {
      if (this.retorno.tipo !== 'str') {
        let myNode = document.getElementById('div_json');
        while (myNode.firstChild) myNode.removeChild(myNode.firstChild);
        let ui = this.tree(this.retorno.json);
        ui.className = 'tree';
        myNode.appendChild(ui);
      }
      CMFormModalComponent.open('formAcoesNFeRetorno');
    }
  }

  tree(data) {
    let r: HTMLElement = undefined;
    let ul = document.createElement('ul');
    for (var i in data) {
      let li = document.createElement('li');
      ul.appendChild(li);
      var t = document.createTextNode(i);
      li.appendChild(t);
      if (data[i] instanceof Object) li.appendChild(this.tree(data[i]));
      else {
        let textNode = document.createTextNode(' - ' + data[i]);
        li.appendChild(textNode);
      }
    }
    r = ul;
    return r;
  }
}
