import { Component, Input, ViewChild } from "@angular/core";
import { MatPaginator, MatSort, MatTableDataSource } from "@angular/material";
import {
  CMGridV2Column,
  CMGridV2ColumnButton,
  CMGridV2ColumnFuncRowBoolean,
  CMGridV2EditDataType,
  CMGridV2EditType,
  CMGridV2ListColumns,
  getValueFuncRowBoolean,
} from "./cmgrid-column-v2.component";

@Component({
  selector: "cmgrid-v2",
  templateUrl: "./cmgrid-v2.component.html",
  styleUrls: ["./cmgrid-v2.component.scss"],
})
export class CMGridV2Component {
  editTypes = CMGridV2EditType;
  dataTypes = CMGridV2EditDataType;

  @Input()
  listColumns: CMGridV2ListColumns = new CMGridV2ListColumns();

  @Input()
  get data() {
    return this.fData;
  }
  set data(aData) {
    this.fData = aData;
    this.dataSource = new MatTableDataSource(this.fData);
    this.configDataSource();
  }
  fData = [];

  @Input()
  stickyHeader: boolean = true;

  @Input()
  showFilter: boolean = true;

  @Input()
  showHintValueField: boolean = true;

  @Input()
  get sortable() {
    return this.fSortable;
  }
  set sortable(aSortable: boolean) {
    this.fSortable = aSortable === true;
    if (this.sort) {
      this.sort.disabled = !this.sortable;
    }
  }
  fSortable: boolean = true;

  @Input()
  get showPaginator() {
    return this.fShowPaginator;
  }
  set showPaginator(aShowPaginator: boolean) {
    this.fShowPaginator = aShowPaginator === true;
    this.setPaginator();
  }
  fShowPaginator: boolean = false;

  @Input()
  pageSizeDefault: number = 10;

  dataSource: MatTableDataSource<any> = null;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  setPaginator() {
    if (this.showPaginator) {
      this.dataSource.paginator = this.paginator;
    } else {
      this.dataSource.paginator = null;
    }
    if (this.dataSource.paginator) {
      this.dataSource.paginator.pageSize = this.pageSizeDefault;
      this.dataSource.paginator.firstPage();
    }
  }
  configDataSource() {
    if (this.dataSource) {
      this.sort.disabled = !this.sortable;
      this.dataSource.sort = this.sort;
      this.setPaginator();
    }
  }

  ngAfterViewInit() {
    this.configDataSource();
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.dataSource) {
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.pageSize = this.pageSizeDefault;
        this.dataSource.paginator.firstPage();
      }
    }
  }

  getNameInput(row, column: CMGridV2Column) {
    let r = column.field;
    if (row["id"]) {
      r += row["id"];
    }
    return r;
  }

  currentRow = undefined;
  selectRow(row) {
    this.currentRow = row;
  }
  isCurrentRow(row) {
    return this.currentRow == row;
  }

  clickButton(button: CMGridV2ColumnButton, row: any) {
    if (button.onClick !== undefined) {
      button.onClick(row);
    }
  }

  getValueFuncRowBoolean(
    value: boolean | CMGridV2ColumnFuncRowBoolean,
    row: any
  ) {
    return getValueFuncRowBoolean(value, row);
  }
}
