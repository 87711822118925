// tslint:disable
import { Component, Injector } from "@angular/core";

import {
  CMFormRelatComponent,
  CMInputConfig,
  CMFormGlobaisComponent,
} from "./../../../@cmcore/component";


@Component({
  selector: "movimento-diario",
  templateUrl: "movimento-diario.component.html",
})
export class RelatMovimentoDiarioComponent extends CMFormRelatComponent {
  constructor(_injector: Injector) {
    super(_injector);
    this.relatName = "ImpressaoMovimentoDiario";
    this.filterConfig = {
      data: new CMInputConfig({
        form: this,
        caption: "Data Inicial",
        mask: "99/99/9999",
      }),
    };
    this.filter.data = CMFormRelatComponent.date();
  }

  valid() {
    let r = super.valid();
    if (r) {
      r = !this.isEmpty(this.filter.data);
      if (!r) CMFormGlobaisComponent.toastErro("Data não informada");
    }
    return r;
  }
}
