// tslint:disable
import { Component, Input } from "@angular/core";

import { CMInput } from "../../decorator";
import {
  CMFormGlobaisComponent,
  CMFormModalComponent,
  CMGridConfig,
} from "../../component";

export class CMPanelConfigMore {
  constructor(data) {
    if (data.title) this.title = data.title;
    if (data.maxWidth) this.maxWidth = data.maxWidth;
    if (data.onSave) this.onSave = data.onSave;
    if (data.onCancel) this.onCancel = data.onCancel;
    if (data.onOpen) this.onOpen = data.onOpen;
    if (data.onAction) this.onAction = data.onAction;
    if (data.onClick) this.onClick = data.onClick;
    if (data.allowCancel) this.allowCancel = data.allowCancel;
    if (data.showAction1) this.showAction1 = data.showAction1;
    if (data.hintAction1) this.hintAction1 = data.hintAction1;
    if (data.iconAction1) this.iconAction1 = data.iconAction1;
  }

  maxWidth: string = "inherit";
  title: string;
  getTitle() {
    return this.title;
  }

  onSave: Function = undefined;
  onAction: Function = undefined;
  onCancel: Function = undefined;
  onOpen: Function = undefined;
  onClick: Function = undefined;

  index: number = 0;
  allowCancel: boolean = true;
  showAction1: boolean = false;
  hintAction1: string = "";
  iconAction1: string = "";
  showning: boolean = false;
}

@Component({
  selector: "cmpanel",
  templateUrl: "cmpanel.component.html",
  styleUrls: ["./cmpanel.component.scss"],
})
export class CMPanelComponent {
  public panelName: string = "panel_" + CMGridConfig.newId();

  @Input()
  caption: string = "";

  @Input()
  helpIcon: string = "help";

  @Input()
  helpText: string = "";

  @Input()
  helpHint: string = "";

  @Input()
  @CMInput()
  nomargin: boolean = false;

  @Input()
  @CMInput()
  hideCaption: boolean = false;

  @Input()
  configExtra0: CMPanelConfigMore = undefined;

  @Input()
  @CMInput()
  visible: boolean = true;

  @Input()
  @CMInput()
  showBorder: boolean = true;

  getTitle() {
    let s = this.caption;
    return s;
  }

  showCaption() {
    return this.getTitle() !== "";
  }

  showHelpText() {
    return this.helpText !== "" || this.helpIcon !== "help";
  }

  clickHelpText() {
    if (this.configExtra0 !== undefined) {
      this.currentExtra = this.configExtra0;
      this.openExtraRow();
    } else {
      let msg =
        '<p style=" padding-left: 20px; padding-right: 10px;">' +
        this.helpText +
        "</p>";
      CMFormGlobaisComponent.showMessage(this.getTitle(), msg);
    }
  }

  @Input() height: string = "";
  @Input() minHeight: string = "";
  @Input() width: string = "";
  @Input() maxWidth: string = "";
  @Input() margin: string = "";
  @Input() marginTop: string = "";
  @Input() marginBottom: string = "";
  @Input() overflowY: string = "";
  @Input() position: string = "";
  @Input() bottom: string = "";
  @Input() boxShadow: string = "";

  getStyle() {
    let r: string = "";
    if (this.height !== "") r += "height: " + this.height + ";";
    if (this.width !== "") r += "width: " + this.width + ";";
    if (this.minHeight !== "") r += "min-height: " + this.minHeight + ";";
    if (this.maxWidth !== "") r += "max-width: " + this.maxWidth + ";";
    if (this.margin !== "") r += "margin: " + this.margin + ";";
    if (this.marginTop !== "") r += "margin-top: " + this.marginTop + ";";
    if (this.marginBottom !== "")
      r += "margin-bottom: " + this.marginBottom + ";";
    if (this.overflowY !== "") r += "overflow-y: " + this.overflowY + ";";
    if (this.position !== "") r += "position: " + this.position + ";";
    if (this.bottom !== "") r += "bottom: " + this.bottom + ";";
    if (this.boxShadow !== "") r += "box-shadow: " + this.boxShadow + ";";
    return r;
  }

  openExtraRow() {
    if (this.currentExtra.onClick !== undefined) this.currentExtra.onClick();
    else {
      CMFormModalComponent.open("extra_" + this.panelName, () => {
        this.configExtra0.showning = true;
        if (this.configExtra0.onOpen !== undefined) {
          this.configExtra0.onOpen();
        }
      });
    }
  }

  currentExtra: CMPanelConfigMore = undefined;
  extraClick(extra) {
    if (this.currentExtra == undefined) {
      this.currentExtra = extra;
      this.openExtraRow();
    }
  }

  closeExtraRow(action: string) {
    let r = true;
    if (action == "save") {
      if (this.configExtra0.onSave !== undefined)
        r = this.configExtra0.onSave();
    }
    if (action == "cancel") {
      if (this.configExtra0.onCancel !== undefined)
        this.configExtra0.onCancel();
    }
    if (action.startsWith("action")) {
      if (this.configExtra0.onAction !== undefined)
        r = this.configExtra0.onAction(action);
    }
    if (r) {
      CMFormModalComponent.close("extra_" + this.panelName);
      this.configExtra0.showning = false;
      this.currentExtra = undefined;
    }
  }
}
