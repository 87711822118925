import { Component, Injector } from "@angular/core";
import { CMFormGlobaisComponent, CMValidatorEmail } from "../../component";
import { CMFormComponent } from "../../component/cmform/cmform.component";

@Component({
  templateUrl: "esqueceu-senha.component.html",
  styleUrls: ["esqueceu-senha.component.scss"],
})
export class EsqueceuSenhaComponent extends CMFormComponent {
  dados = {
    titulo: "Esqueci minha senha",
    texto:
      "Identifique-se para receber um e-mail com as instruções e o link para criar uma nova senha.",
    titulo_email_enviado: "e-mail enviado",
    texto_email_enviado:
      "<p style='max-width: 40em; margin: 0 auto; text-align: justify; padding-top: 1em;'>Enviamos um e-mail para [email] com as instruções e o link para você trocar a senha. Caso você não receba o e-mail em alguns minutos, verifique a sua caixa de spam ou repita o processo.</p>",
    titulo_alterar_senha: "Alterar senha",
    label_edit: {
      email: "Usuário",
      senha: "Senha",
      confirmasenha: "Confirmar senha",
    },
    bt_enviar_email: "Enviar senha",
    msg_email_nao_informado: "Login não informado",
    bt_enviar_senha: "Salvar",
    msg_errocamposvazios: "Senha e Confirmação de Senha devem ser preenchidos.",
    msg_erroconfirmasenha: "Senha e Confirmação de Senha estão diferentes",
    texto_nova_senha: "Crie uma nova senha com no mínimo 8 caracteres.",
  };

  model = {
    href: "",
    route_url: "",
    email: "",
    token: "",
    senha: "",
    confirmasenha: "",
  };

  public constructor(_injector: Injector) {
    super(_injector);
  }

  private sub: any;

  ngOnInit() {
    this.model.href = window.location.href;
    this.model.route_url = this.router.url;
    this.sub = this.route.params.subscribe((params) => {
      if (params["token"]) {
        this.model.token = params["token"];
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  enviarEmail() {
    let msg = "";
    if (this.isEmpty(this.model.email))
      msg = this.dados.msg_email_nao_informado;
    if (!this.isEmpty(msg)) CMFormGlobaisComponent.toastErro(msg);
    else {
      CMFormGlobaisComponent.showSpinner();
      CMFormComponent.postSubscribe(
        this.http,
        "/api/minhaconta/esqueceusenha",
        JSON.stringify(this.model),
        (result) => {
          CMFormGlobaisComponent.hideSpinner();
          if (result["result"] == "ok") {
            setTimeout(() => {
              let msg = this.dados.texto_email_enviado;
              msg = msg.replace(/\[email\]/g, this.model.email);
              CMFormGlobaisComponent.showMessage(
                this.dados.titulo_email_enviado,
                msg
              );
              this.model.email = "";
            }, 10);
          } else
            CMFormGlobaisComponent.showMessage(
              "Recuperar Senha",
              result["result"]
            );
          return true;
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("enviarEmail", error);
        }
      );
    }
  }

  enviarSenha() {
    let msg = "";
    if (this.isEmpty(this.model.senha) || this.model.senha.length < 8)
      msg = this.dados.msg_errocamposvazios;
    else if (this.model.senha !== this.model.confirmasenha)
      msg = this.dados.msg_erroconfirmasenha;
    if (!this.isEmpty(msg)) CMFormGlobaisComponent.toastErro(msg);
    else {
      CMFormGlobaisComponent.showSpinner();
      CMFormComponent.postSubscribe(
        this.http,
        "/api/minhaconta/salvarnovasenha",
        JSON.stringify(this.model),
        (result) => {
          CMFormGlobaisComponent.hideSpinner();
          if (result["result"] == "ok") this.router.navigate(["/login"]);
          else CMFormGlobaisComponent.toastErro(result["result"]);
          return true;
        },
        (error) => {
          CMFormGlobaisComponent.hideSpinner();
          this.showMessageErrorHTTP("enviarSenha", error);
        }
      );
    }
  }
}
