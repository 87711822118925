// tslint:disable
import { Injector, Component } from "@angular/core";

import {
  CMFormBrowse2Component,
  CMInputConfig,
} from "./../../@cmcore/component";
import { ProdutoMarketplaceEditComponent } from "./produto-marketplace-edit.component";

@Component({
  selector: "produto-marketplace-browse",
  templateUrl:
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.html",
  styleUrls: [
    "./../../@cmcore/component/cmform/cmform-browse/cmform-browse2.component.scss",
  ],
})
export class ProdutoMarketplaceBrowseComponent extends CMFormBrowse2Component {
  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "ProdutoMarketplace";
    this.fixedFooterFormEdit = false;
    this.formEditComponent = ProdutoMarketplaceEditComponent;
    this.columns = [
      {
        field: "codigo",
        caption: "Código",
        width: "200",
        filter: new CMInputConfig({
          maxlength: 30,
          padInfo: { size: 30 },
        }),
      },
      { field: "descr", caption: "Descrição" },
      {
        field: "sku",
        caption: "SKU",
        filterVisible: true,
        filter: true,
        visible: false,
      },
    ];
  }

  getTitle() {
    return "Marketplace -> Produto";
  }
}
