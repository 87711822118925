// tslint:disable
import { Component, Injector, ViewChild } from "@angular/core";

import {
  CMFormEditComponent,
  CMInputConfig,
  CMGridEditConfig,
  CMGridEditConfigColumn,
  CMGridEditConfigMore,
} from "./../../@cmcore/component";
import {
  PessoaBrowseComponent,
  ProdutoBrowseComponent,
  VendedorBrowseComponent,
} from "./../index";

@Component({
  selector: "nfe-consignacao-edit",
  templateUrl: "nfe-consignacao-edit.component.html",
})
export class NFeConsignacaoEditComponent extends CMFormEditComponent {
  getFirstComponentName() {
    return "model_infnfe_dest_pessoa";
  }

  getTitle() {
    let r: string = "Consignação";
    let tmp: string = this.fieldByName("infnfe.ide.nnf");
    if (tmp !== "0") r += " - #" + tmp;
    tmp = this.fieldByName("infnfe.dest.pessoa.nome");
    if (tmp !== "") r += " - " + tmp;
    return r;
  }

  constructor(_injector: Injector) {
    super(_injector);
    this.controllerName = "nfeconsignacao";
    this.setModelConfig();
  }

  afterGetModelData() {
    super.afterGetModelData();
    if (!this.disabledAll)
      this.disabledAll = this.model.infnfe.status !== "Autorizado";
  }

  setModelConfig() {
    this.modelConfig = {
      infnfe: {
        status: new CMInputConfig({
          form: this,
          caption: "Status",
          disabled: true,
          values: [
            { value: "Autorizado", text: "Aberto" },
            { value: "Cancelado", text: "Fechado" },
          ],
        }),
        ide: {
          nnf: new CMInputConfig({
            form: this,
            caption: "#",
            disabled: true,
            required: true,
          }),
          dhemi: new CMInputConfig({
            form: this,
            caption: "Data emissão",
            mask: "99/99/9999",
            required: true,
          }),
        },
        dest: {
          pessoa: new CMInputConfig({
            form: this,
            caption: "Cliente",
            required: true,
            pesqFunc: (sender) => {
              PessoaBrowseComponent.pesquisa(
                sender,
                this.model.infnfe.dest,
                "Cliente"
              );
            },
          }),
        },
        det: new CMGridEditConfig({
          form: this,
          objName: "det",
          caption: "Produtos",
          onNewItem: (model) => {
            Object.assign(model, {
              prod: {
                qcom: "1,00",
                vuncom: "0,00",
                vprod: "0,00",
                vtotal: "0,00",
              },
              estoque: [],
            });
          },
          customValidItem: (item) => {
            return this.validEstoque(item);
          },

          onRemoveItem: (item) => {
            this.onRemoveItem(item);
          },

          extraColumns: {
            produto_descr: new CMInputConfig({
              form: this,
              caption: "Descrição",
              gridConfig: new CMGridEditConfigColumn({
                ordem: 2,
                getValue: (row) =>
                  this.getValueObject(row, "prod.produto.descr"),
              }),
            }),
          },
          fields: {
            prod: {
              produto: new CMInputConfig({
                form: this,
                caption: "Produto",
                required: true,
                maxlength: 30,
                padInfo: { size: 30 },
                gridConfig: new CMGridEditConfigColumn({
                  ordem: 1,
                  width: "350px",
                  tipo: "input-pesquisa",
                  getValue: (row) => {
                    return this.getValueObject(row, "prod.produto.codigo");
                  },
                }),
                pesqFunc: (sender) => {
                  ProdutoBrowseComponent.pesquisa(
                    sender,
                    this.modelConfig.infnfe.det.current.prod,
                    "Ativos",
                    undefined,
                    (produto) => {
                      this.modelConfig.infnfe.det.current.prod.vuncom = ProdutoBrowseComponent.get_vUniCon(
                        produto
                      );
                      if(this.currentProdutoUsaGrade()){
                        this.modelConfig.infnfe.det.current.prod.qcom = "0,00";

                        let grades = this.modelConfig.infnfe.det.current.prod.produto.grades;

                        grades.forEach((grade) => {
                          this.modelConfig.infnfe.det.current.estoque.push({
                            "datavalidadelote": "",
                            "grade": grade,
                            "id": CMInputConfig.getRandomInt(1, 9999) * -1,
                            "numerolote": "",
                            "qcom": "0,00"
                          })
                        });
                      }
                      else{
                        this.modelConfig.infnfe.det.current.prod.qcom = "1,00";
                      };
                      this.calcTotalItem();
                    }
                  );
                },
              }),
              qcom: new CMInputConfig({
                form: this,
                caption: "Quantidade",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 3,
                }),
                hintButton: "Editar",
                iconButton: () => {
                  if (this.disabledAll) return "visibility";
                  else return "edit";
                },
                showButtonClear: false,
                pesqOnlyFunc: (row) => this.mostraDetalhesQuantidade(row),
                pesqFunc: (sender) => {
                  if (
                    this.mostraDetalhesQuantidade(
                      this.modelConfig.infnfe.det.current
                    )
                  )
                    this.modelConfig.infnfe.det.extraClick(
                      new CMGridEditConfigMore({
                        index: 0,
                        title: "Detalhes da Quantidade",
                      })
                    );
                },
                onExit: () => {
                  this.calcTotalItem();
                },
                showButtonFunc: (show, row) =>
                  this.mostraDetalhesQuantidade(row),
              }),
              vuncom: new CMInputConfig({
                form: this,
                caption: "Valor unitário",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 4,
                }),
                onExit: () => {
                  this.calcTotalItem();
                },
                required: true,
                hintButton: "Preço",
                iconButton: "attach_money",
                dropdownValues: (row) => this.dropdownValuesVUnCom(row),
              }),
              vtotal: new CMInputConfig({
                form: this,
                caption: "Valor total",
                dataType: "double(2)",
                gridConfig: new CMGridEditConfigColumn({
                  width: "150px",
                  ordem: 5,
                }),
                required: true,
                disabled: true,
              }),
            },
            estoque: new CMGridEditConfig({
              form: this,
              required: false,
              onRemoveItem: (item) => {
                this.onRemoveItemEstoque(item);
              },
              fields: {
                grade: new CMInputConfig({
                  form: this,
                  caption: "Grade",
                  required: true,
                  pesqOnly: true,
                  gridConfig: new CMGridEditConfigColumn({
                    tipo: "input-pesquisa",
                    getValue: (row) => {
                      return this.getValueObject(row, "grade.descricao");
                    },
                  }),
                  pesqFunc: (sender) => {
                    ProdutoBrowseComponent.pesquisaGrade(
                      sender,
                      this.modelConfig.infnfe.det.fields.estoque.current,
                      this.modelConfig.infnfe.det.current.prod.produto.id
                    );
                  },
                  visibleFunc: () => {
                    return this.currentProdutoUsaGrade();
                  },
                }),
                numerolote: new CMInputConfig({
                  form: this,
                  caption: "Número Lote",
                  dataType: "string",
                  maxlength: 20,
                  gridConfig: new CMGridEditConfigColumn(),
                  requiredFunc: () => {
                    return this.currentProdutoUsaLote();
                  },
                  visibleFunc: () => {
                    return this.currentProdutoUsaLote();
                  },
                  pesqFunc: (sender) => {
                    ProdutoBrowseComponent.pesquisaLote(
                      sender,
                      this.modelConfig.infnfe.det.fields.estoque.current,
                      this.modelConfig.infnfe.det.current.prod.produto.id,
                      this.modelConfig.infnfe.det.fields.estoque.current.grade.id,
                      undefined,
                      (lote) => {
                        this.modelConfig.infnfe.det.fields.estoque.current.numerolote = lote.numerolote;
                        this.modelConfig.infnfe.det.fields.estoque.current.datavalidadelote = lote.datavalidadelote;
                      }
                    );
                  },
                }),
                datavalidadelote: new CMInputConfig({
                  form: this,
                  caption: "Data Validade Lote",
                  mask: "99/99/9999",
                  gridConfig: new CMGridEditConfigColumn(),
                  requiredFunc: () => {
                    return this.currentProdutoUsaValidadeLote();
                  },
                  visibleFunc: () => {
                    return this.currentProdutoUsaValidadeLote();
                  },
                }),
                qcom: new CMInputConfig({
                  form: this,
                  caption: "Quantidade",
                  dataType: "double(2)",
                  gridConfig: new CMGridEditConfigColumn(),
                  onExit: () => {
                    this.calcTotalItemEstoque();
                  },
                }),
              },
            }),
          },
        }),
        total: {
          icmstot: {
            vnf: new CMInputConfig({
              form: this,
              caption: "Valor total",
              dataType: "double(2)",
              disabled: true,
            }),
          },
        },
        infadic: {
          infcpl: new CMInputConfig({
            form: this,
            caption: "Observação",
          }),
        },
        vendedor: new CMInputConfig({
          form: this,
          caption: "Vendedor",
          pesqFunc: (sender) => {
            VendedorBrowseComponent.pesquisa(sender, this.model.infnfe);
          },
        }),
      },
    };
  }
  
  currentProdutoUsaValidadeLote() {
    return (
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usavalidadelote"
      ) === "Sim"
    );
  }
  
  validEstoque(item: any) {
    let r: string = "";
    console.log('item ', item);
    console.log('item.qcom ', item.prod.qcom);

    if (this.valueToFloat(item.prod.qcom, 0) <= 0)
      r = "Valor total deve ser maior que zero";
    return r;
  }

  onRemoveItemEstoque(item: any) {
    let itemsEstoque = this.modelConfig.infnfe.det.current;
    let qtotal = 0;
    
    itemsEstoque.estoque.forEach((est) => {
      if (item !== est){
        qtotal += this.valueToFloat(est.qcom);
      }
    });
    itemsEstoque.prod.qcom = this.floatToValue(qtotal);
    this.calcTotalItem();
  }

  dropdownValuesVUnCom(row) {
    let r = [];
    if (row && row.prod && row.prod.produto)
      r = ProdutoBrowseComponent.dropdownValuesVUnCom(row.produto);
    return r;
  }

  calcTotalItemEstoque() {
    let item = this.modelConfig.infnfe.det.current;
    let qtotal = 0;
    item.estoque.forEach((est) => {
      qtotal += this.valueToFloat(est.qcom);
    });
    item.prod.qcom = this.floatToValue(qtotal);
    this.calcTotalItem();
  }

  mostraDetalhesQuantidade(row) {
    let r = false;
    if (row && row.prod && row.prod.produto) {
      r =
        row.prod.produto.usagrade1 === "Sim" ||
        row.prod.produto.usagrade2 === "Sim" ||
        row.prod.produto.usalote === "Sim";
    }
    return r;
  }

  currentProdutoUsaLote() {
    return (
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usalote"
      ) === "Sim"
    );
  }

  currentProdutoUsaGrade() {
    return (
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usagrade1"
      ) === "Sim" ||
      this.getValueObject(
        this.modelConfig.infnfe.det.current,
        "prod.produto.usagrade2"
      ) === "Sim"
    );
  }

  //***********Calculos dos produtos e totais ***********/

  calcTotalItem() {
    let item = this.modelConfig.infnfe.det.current.prod;
    let vtotal =
      this.valueToFloat(item.qcom, 0) * this.valueToFloat(item.vuncom, 0);
    this.add_vProd(this.valueToFloat(item.vtotal, 0), vtotal);
    vtotal = vtotal - this.valueToFloat(item.vdesc, 0);
    this.add_vNF(this.valueToFloat(item.vtotal, 0), vtotal);
    item.vtotal = this.floatToValue(vtotal);
  }

  add_vProd(oldValue: number, newValue: number) {
    let vtotal = this.valueToFloat(this.model.infnfe.total.icmstot.vprod, 0);
    vtotal += newValue - oldValue;
    this.model.infnfe.total.icmstot.vprod = this.floatToValue(vtotal);
  }

  add_vNF(oldValue: number, newValue: number) {
    let vtotal = this.valueToFloat(this.model.infnfe.total.icmstot.vnf, 0);
    vtotal += newValue - oldValue;
    this.model.infnfe.total.icmstot.vnf = this.floatToValue(vtotal);
  }

  onRemoveItem(item) {
    let vtotal =
      this.valueToFloat(item.prod.qcom, 0) *
      this.valueToFloat(item.prod.vuncom, 0);
    this.add_vProd(this.valueToFloat(vtotal, 0), 0);
    this.add_vNF(this.valueToFloat(item.prod.vtotal, 0), 0);
  }
}
