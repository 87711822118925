// tslint:disable
import { Component, Input } from "@angular/core";

@Component({
  selector: "cmcontainer",
  templateUrl: "cmcontainer.component.html",
})
export class CMContainerComponent {
  @Input()
  width: string = "";

  @Input()
  maxWidth: string = "";

  @Input()
  minHeight: string = "";

  @Input()
  height: string = "";

  @Input()
  innerClass: string = "";

  @Input()
  marginTop: string = "";

  @Input()
  background: string = "";

  @Input()
  backgroundImage: string = "";

  @Input()
  backgroundSize: string = "";

  @Input()
  backgroundPosition: string = "";

  @Input()
  backgroundRepeat: string = "";

  @Input("cm-overflow")
  overflow: string = "";

  @Input()
  paddingLeft: string = "";

  @Input()
  paddingRight: string = "";

  getStyle() {
    let r: string = "";
    if (this.width !== "") {
      r += "width: " + this.width + ";";
      if (this.maxWidth !== "") r += "max-width: " + this.maxWidth + ";";
      else r += "max-width: " + this.width + ";";
    }
    if (this.minHeight !== "") r += "min-height: " + this.minHeight + ";";
    if (this.height !== "") r += "height: " + this.height + ";";
    if (this.marginTop !== "") r += "margin-top: " + this.marginTop + ";";
    if (this.background !== "") r += "background: " + this.background + ";";
    if (this.backgroundImage !== "")
      r += "background-image: " + this.backgroundImage + ";";
    if (this.backgroundSize !== "")
      r += "background-size: " + this.backgroundSize + ";";
    if (this.backgroundPosition !== "")
      r += "background-position: " + this.backgroundPosition + ";";
    if (this.backgroundRepeat !== "")
      r += "background-repeat: " + this.backgroundRepeat + ";";
    if (this.overflow !== "") r += "overflow: " + this.overflow + ";";
    if (this.paddingLeft !== "") r += "padding-left: " + this.paddingLeft + ";";
    if (this.paddingRight !== "")
      r += "padding-right: " + this.paddingRight + ";";
    return r;
  }
}
